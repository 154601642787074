import { APP_BASE_URL } from '../constant/Config';
import { axiosClient } from './axios';
import { ItemsProfile } from '../services/items/service';
import { PackagesProfile } from '../services/packages/service';
import { ItemCategoryProfile } from '../services/itemCatagories/services';
import { ClientProfile } from '../services/client/service';
import { UserProfile } from '../services/userProfile/service';
import { BusinessProfile } from '../services/business/service';
import { UserModuleProfile } from '../services/user/service';
import { RoleProfile } from '../services/role/service';
import { BankAccountProfile } from '../services/bankaccount/service';
import { BankDepositProfile } from '../services/bankdeposit/service';
import { BayProfile } from '../services/bay/service';
import { ClientGroupProfile } from '../services/clientgroup/service';
import { ItemGroupProfile } from '../services/itemgroup/service';
import { ServiceGroupProfile } from '../services/servicegroup/service';
import { InspectionFormProfile } from '../services/inspectionform/service';
import { InspectionFormItemProfile } from '../services/inspectionformitem/service';
import { MakeProfile } from '../services/make/service';
import { UserGroupProfile } from '../services/usergroup/service';
import { VehicleProfile } from '../services/vehicle/service';
import { VehicleTypeProfile } from '../services/vehicletype/service';
import { ServiceProfile } from '../services/service/service';
import { ModelProfile } from '../services/model/service';
import { ModelEngineProfile } from '../services/modelengine/service';
import { BusinessGroupProfile } from '../services/businessgroup/service';
import { UnitsProfile } from '../services/units/service';
import { StationProfile } from '../services/station/service';
import { PriceListProfile } from '../services/pricelist/service';
import { StoreProfile } from '../services/store/service';
import { WarehouseProfile } from '../services/warehouse/service';
import { VendorProfile } from '../services/vendor/service';
import { StationGroupProfile } from '../services/stationgroup/service';
import { PackageGroupProfile } from '../services/packagegroup/service';
import { TaxProcedureProfile } from '../services/taxProcedure/service';
import { ManufacturerProfile } from '../services/manufacturer/service';
import { JobCardProfile } from '../services/jobcard/service';
import { WorkOrderProfile } from '../services/workorder/service';
import { InvoiceProfile } from '../services/invoice/service';
import { PaymentProfile } from '../services/payment/service';
import { StatusesProfile } from '../services/statuses/service';
import { ChecklistProfile } from '../services/checklist/service';
import { ChecklistItemProfile } from '../services/checklistitem/service';
import { CommonProfile } from '../services/allcommon/service';
import { InventoryProfile } from '../services/inventory/service';
import { ExpenseProfile } from '../services/expense/service';
import { InspectionSectionProfile } from '../services/inspectionsection/service';
import { ActivityLogProfile } from '../services/activitylogs/service';
import { AccountSettingsProfile } from '../services/accountsettings/service';

export const serviceOptions = {
  axiosInstance: axiosClient,
  apiUrl: APP_BASE_URL,
};

export const UserProfileService = new UserProfile(serviceOptions);

export const ClientProfileService = new ClientProfile(serviceOptions);

export const BankAccountProfileService = new BankAccountProfile(serviceOptions);

export const BankDepositProfileService = new BankDepositProfile(serviceOptions);

export const ItemsProfileService = new ItemsProfile(serviceOptions);

export const PackagesProfileService = new PackagesProfile(serviceOptions);

export const ItemCategoryProfileService = new ItemCategoryProfile(serviceOptions);

export const BayProfileService = new BayProfile(serviceOptions);

export const BusinessProfileService = new BusinessProfile(serviceOptions);

export const ClientGroupProfileService = new ClientGroupProfile(serviceOptions);

export const ItemGroupProfileService = new ItemGroupProfile(serviceOptions);

export const ServiceGroupProfileService = new ServiceGroupProfile(serviceOptions);

export const InspectionFormProfileService = new InspectionFormProfile(serviceOptions);

export const InspectionFormItemProfileService = new InspectionFormItemProfile(serviceOptions);

export const RoleProfileService = new RoleProfile(serviceOptions);

export const MakeProfileService = new MakeProfile(serviceOptions);

export const UserGroupProfileService = new UserGroupProfile(serviceOptions);

export const VehicleTypeProfileService = new VehicleTypeProfile(serviceOptions);

export const ServiceProfileService = new ServiceProfile(serviceOptions);

export const ModelProfileService = new ModelProfile(serviceOptions);

export const ModelEngineProfileService = new ModelEngineProfile(serviceOptions);

export const BusinessGroupProfileService = new BusinessGroupProfile(serviceOptions);

export const UnitsProfileService = new UnitsProfile(serviceOptions);

export const UserModuleProfileService = new UserModuleProfile(serviceOptions);

export const VehicleProfileService = new VehicleProfile(serviceOptions);

export const StationProfileService = new StationProfile(serviceOptions);

export const PriceListProfileService = new PriceListProfile(serviceOptions);

export const StoreProfileService = new StoreProfile(serviceOptions);

export const WarehouseProfileService = new WarehouseProfile(serviceOptions);

export const VendorProfileService = new VendorProfile(serviceOptions);

export const StationGroupProfileService = new StationGroupProfile(serviceOptions);

export const PackageGroupProfileService = new PackageGroupProfile(serviceOptions);

export const TaxProcedureProfileService = new TaxProcedureProfile(serviceOptions);

export const ManufacturerProfileService = new ManufacturerProfile(serviceOptions);

export const JobCardProfileService = new JobCardProfile(serviceOptions);

export const WorkOrderProfileService = new WorkOrderProfile(serviceOptions);

export const InvoiceProfileService = new InvoiceProfile(serviceOptions);

export const PaymentProfileService = new PaymentProfile(serviceOptions);

export const StatusesProfileService = new StatusesProfile(serviceOptions);

export const CommonProfileService = new CommonProfile(serviceOptions);

export const ChecklistProfileService = new ChecklistProfile(serviceOptions);

export const ChecklistItemProfileService = new ChecklistItemProfile(serviceOptions);

export const InventoryProfileService = new InventoryProfile(serviceOptions);

export const ExpenseProfileService = new ExpenseProfile(serviceOptions);

export const InspectionSectionProfileService = new InspectionSectionProfile(serviceOptions);

export const ActivityLogProfileService = new ActivityLogProfile(serviceOptions);

export const AccountSettingsProfileService = new AccountSettingsProfile(serviceOptions);
