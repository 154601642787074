import { Button, Grid } from '@mui/material';
import Filter from "common/Select/SelectMultiFilter";
import { useTranslation } from 'react-i18next';
import DynamicSelect from 'common/Select/Select';
import StyledField from 'common/TextField/TexField1';

interface StockAdjustmentSearchHeaderComponentProps {
  handleChangeFilter: (event: any) => void;
  handleChangeSearch: (field: string, value: any) => void;
  handleSubmitSearch: () => void;
  searchFormData: any;
  filter: string[];
  clearSearch: () => void;
  storesData: any;
}

const StockAdjustmentSearchHeader: React.FC<StockAdjustmentSearchHeaderComponentProps> = ({
  handleChangeFilter,
  handleChangeSearch,
  handleSubmitSearch,
  searchFormData,
  filter,
  clearSearch,
  storesData
}) => {
  const { t } = useTranslation();
  const filterOptions = ['itemCode', 'storeId'];
  const stores = storesData.map((item: { id: number, title: string }) => ({ value: item.id, label: item.title }));
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSubmitSearch();
    }
  };
  
  return (
    <>
      <Grid container spacing={1} className='mt-4'>
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <Filter 
            label="Filter By"
            filterOptions={filterOptions}
            handleChangeFilter={handleChangeFilter}
            filter={filter}
          />
        </Grid>
        {filter.map((fieldName) => (
          <>
            {fieldName === 'storeId' ? 
                <Grid item key={fieldName} xs={12} sm={6} md={3} lg={2}>
                  <DynamicSelect
                    value={stores.find((option: any) => option.value == searchFormData[fieldName])}
                    isDisabled={false}
                    isLoading={false}
                    isRtl={false}
                    isSearchable={true}
                    name={`${fieldName}`}
                    options={stores}
                    isRequired={true}
                    onChange={(e) => handleChangeSearch(fieldName, e)}
                    label=""
                    onKeyDown={handleKeyPress}
                  />
                </Grid>
              : <Grid item key={fieldName} xs={12} sm={6} md={3} lg={2}>
                  <StyledField
                    fullWidth={true}
                    placeholder={t(fieldName)}
                    displayPlaceholder={true}
                    handleChange={(e) => handleChangeSearch(fieldName, e.target.value)}
                    required={true}
                    value={searchFormData?.[fieldName] || ''}
                    handleKeyDown={handleKeyPress}
                  />
                </Grid>
            }
          </>
        ))}
        <Grid item xs={12} sm={6} md={2} className="text-left flex items-center gap-2 mt-0 pt-0">
          <Button 
            variant="contained"
            disabled={filter.length ? false : true}
            onClick={handleSubmitSearch}
            sx={{ boxShadow: 'none' }}
            className={`text-white text-ellipsis overflow-hidden font-Saira font-[600] text-[14px] ${filter.length > 0 ? 'bg-primary-color' : 'bg-disabled-color'} h-[35px] px-8 py-1`}
          >{t('submit')}</Button>
          {filter.length ?
            <Button 
            variant="contained"
            onClick={clearSearch}
            sx={{ boxShadow: 'none' }}
            className={`text-primary-color text-ellipsis overflow-hidden font-Saira font-[600] text-[14px] bg-[#d8d1e0] h-[35px] px-8 py-1`}
          >{t('clear')}</Button>: null}
        </Grid>
      </Grid>
    </>
  );
};

export default StockAdjustmentSearchHeader;