import SimpleModal from 'common/Form/Form';
import StyledField from 'common/TextField/TexField1';
import { Grid, Tooltip } from '@mui/material';
import DynamicSelect from 'common/Select/Select';
import StyledInputArea from 'common/TextArea/TextArea';
import CommonCheckbox from 'common/CheckBox/Checkbox';
import { useTranslation } from 'react-i18next';
import Currency from 'common/Others/Currency';
import { useEffect, useState } from 'react';
import { ReactComponent as ModalBtn } from "assets/Modal/ModalBtn.svg"
import { checkPrivileges } from 'Helpers/CheckPrivileges';
import FormDialog from 'common/Modal/Modal';
import PackageGroupModal from './PackageGroupModal';

interface PackagesModalProps {
  handleSecField: () => void;
  showSecField: boolean;
  primaryLanguage: string;
  secondaryLanguage: string;
  openModal: () => void;
  closeModal: () => void;
  handleChange: (field: string, value: any) => void;
  handleSubmit: () => void;
  formData: any;
  taxProceduresData: any;
  itemGroupsData: any;
  openForm: boolean;
  errorMessages: { [key: string]: string };
  enableEdit?: boolean;
  isBtnEnabled: boolean;
  defaultPriceList: string;
  loggedInUserCurrency: string;
  loadNext: (field: string, scroll: any) => void;
  loadPrev: (field: string, scroll: any) => void;
  handleDropdownSearch: (field: string, search: string) => void;
  loadingDropdownSearch: boolean;
  packageGroupsData: any;
  packageGroupsRefetch: any;
}

const PackagesModal: React.FC<PackagesModalProps> = ({
  showSecField,
  handleSecField,
  primaryLanguage,
  secondaryLanguage,
  closeModal,
  handleChange,
  handleSubmit,
  formData,
  taxProceduresData,
  itemGroupsData,
  openForm,
  enableEdit,
  errorMessages,
  isBtnEnabled,
  defaultPriceList,
  loggedInUserCurrency,
  loadNext,
  loadPrev,
  handleDropdownSearch,
  loadingDropdownSearch,
  packageGroupsData,
  packageGroupsRefetch
}) => {
  const [addNew, setAddNew] = useState<boolean>(false);
  const { t } = useTranslation ();

  const handleScroll = () => {
    // Handle scroll logic here
  };
  const handleSearch = () => {
    // Handle search logic here
  };
  const pricingTypeOptions = [
    { value: 1, label: t('fixed') },
    { value: 2, label: t('serviceSum') },
  ]
  const groupOptions = [
    { value: 1, label: t('groups') },
    { value: 2, label: t('open') },
  ];
  const taxProcedureOptions = taxProceduresData.map((item: { id: number, title: string, percentageValue?: number }) => ({ value: item.id, label: item.title, percentageValue: item.percentageValue }));
  const itemGroupOptions = itemGroupsData.map((item: { id: number, title: string }) => ({ value: item.id, label: item.title }));
  const packageGroupOptions = packageGroupsData.map((item: { id: number, title: string }) => ({ value: item.id, label: item.title }));
  const currencyOptions = Currency.map((item) => ({ value: item.value, label: item.title, symbol: item.symbol }));
  const currencyLabel = currencyOptions.find((option) => option.value === loggedInUserCurrency)?.symbol || '';
  useEffect(() => {
    const priceWithTax = (parseFloat(formData?.price) + (
      parseFloat(formData?.price) * 
      parseFloat((taxProcedureOptions.find((option: any) => option.value === formData?.taxProcedureId)?.percentageValue || 0))/100
    ))?.toFixed(2);
    handleChange('priceWithTax', priceWithTax);
  }, [formData?.taxProcedureId, taxProceduresData]);
  
  return (
    <div>
      <SimpleModal
        visable={openForm}
        // title={enableEdit ? "updatePackages" : "addPackages"}
        isButtonVisable={true}
        isVisableBtn={isBtnEnabled}
        btnclosetext="Back"
        buttonText={enableEdit ? "update" : "submit"}
        attBtnNotshow={false}
        notVisableBackbtn={true}
        showPortList={handleSubmit} // Assuming this is the Submit button
        modalControle={closeModal}
        // attributeChangesMessage="Attribute changes message"
        // showPort={true}
        formSubmit={handleSubmit}
        content={
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}  >
              <StyledField
                label="packageCode"
                placeholder="Write Package Code here"
                handleChange={(e) => handleChange('packageCode', e.target.value)}
                required={true}
                fullWidth
                error={errorMessages?.packageCode}
                value={formData?.packageCode || ''}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}  >
              <StyledField
                label={`${!primaryLanguage ? t('primary') : ''} ${t('title')} ${primaryLanguage ? ` (${t(primaryLanguage)})` : ''}`}
                search='language'
                handleSecField={handleSecField}
                placeholder="Write your Title here"
                handleChange={(e) => handleChange('title', e.target.value)}
                required={true}
                fullWidth
                error={errorMessages?.title}
                value={formData?.title || ''}
              />
              {showSecField &&
                <Grid item xs={12} sm={12} md={12} lg={12} >
                  <div style={{ position: 'relative' }}>
                    <div
                      style={{
                        position: 'absolute',
                        top: -8,
                        left: 0,
                        zIndex: 1,
                        display: showSecField ? 'flex' : 'none',
                        backgroundColor: "white",
                        height: '5rem',
                        width: '100%',
                        justifyContent:'center',
                        borderRadius: '8px',
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
                      }}
                    >
                      <div className='w-full mx-2 mt-1'>
                      <StyledField
                        label={`${!secondaryLanguage ? t('secondary') : ''} ${t('title')} ${secondaryLanguage ? ` (${t(secondaryLanguage)})` : ''}`}
                        placeholder="Write your Secondary Title here"
                        handleChange={(e) => handleChange('secondaryTitle', e.target.value)}
                        required={false}
                        fullWidth
                        // error={errorMessages?.secondaryTitle}
                        value={formData?.secondaryTitle || ''}
                      />
                      </div>
                    </div>
                  </div>
                </Grid>
              }
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <DynamicSelect
                defaultValue={enableEdit ? (formData?.itemGroup ? groupOptions.find((option: any) => option.value === formData?.itemGroup) : groupOptions.find((option: any) => option.value === 2)) : null}
                value={groupOptions.find((option: any) => option.value === formData?.itemGroup)}
                isDisabled={false}
                isLoading={false}
                isClearable={false}
                isRtl={false}
                // fullWidth={false}
                isSearchable={true}
                name="itemGroup"
                options={groupOptions}
                // onScroll={handleScroll}
                // onInputChange={handleSearch}
                // isMulti
                isRequired={false}
                onChange={(e) => handleChange('itemGroup', e)}
                // error={errorMessages?.itemGroupError}
                label="Item"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              {formData?.itemGroup === 1 ?
                <DynamicSelect
                  defaultValue={enableEdit ? itemGroupOptions.find((option: any) => option.value === formData?.itemGroupId) : null}
                  value={itemGroupOptions.find((option: any) => option.value === formData?.itemGroupId)}
                  isDisabled={false}
                  isLoading={loadingDropdownSearch}
                  isClearable={false}
                  isRtl={false}
                  // fullWidth={false}
                  isSearchable={true}
                  name="itemGroupId"
                  options={itemGroupOptions}
                  // onScroll={handleScroll}
                  onInputChange={(e) => handleDropdownSearch('itemGroupId', e)}
                  // isMulti
                  isRequired={formData?.itemGroup === 1}
                  onChange={(e) => handleChange('itemGroupId', e)}
                  error={errorMessages?.itemGroupIdError}
                  label="itemGroup"
                  onMenuScrollToBottom={(e) => { loadNext('itemGroupId', e); }}
                  onMenuScrollToTop={(e) => { loadPrev('itemGroupId', e); }}
                /> : null
              }
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} className='grid' sx={{ gridTemplateColumns: '85% 10%' }}>
              <DynamicSelect
                  defaultValue={enableEdit ? packageGroupOptions.find((option: any) => option.value === formData?.packageGroupId) : null}
                  value={packageGroupOptions.find((option: any) => option.value === formData?.packageGroupId)}
                  isDisabled={false}
                  isLoading={false}
                  isClearable={true}
                  isRtl={false}
                  // fullWidth={false}
                  isSearchable={true}
                  name="packageGroupId"
                  options={packageGroupOptions}
                  // onScroll={handleScroll}
                  onInputChange={(e) => handleDropdownSearch('packageGroupId', e)}
                  // isMulti
                  isRequired={false}
                  onChange={(e) => handleChange('packageGroupId', e)}
                  // error={errorMessages?.packageGroupIdError}
                  label="packageGroup"
                />
                {checkPrivileges('ROLE_PACKAGEGROUP_CREATE') && <Tooltip onClick={() => setAddNew(true)} title={t('addPackageGroup')}><ModalBtn className='mt-5 mx-2 mb-2 cursor-pointer h-[38px]'/></Tooltip>}
                  {addNew && <FormDialog
                    open={addNew}
                    onClose={(event, reason) => event && reason !== 'backdropClick' ? setAddNew(false) : ''}
                    title={t('addPackageGroup')}
                    createPopup={true}
                    fullWidth
                    hideActions={true}
                    content={<PackageGroupModal setAddNew={setAddNew} packageGroupsRefetch={packageGroupsRefetch} />}
                  />}
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} className={`flex items-baseline justify-start`}>
              <CommonCheckbox
                label='applyToAllStations'
                onChange={(e) => handleChange('applyToAllStations', e.target.checked)}
                color="success"
                required={false}
                // error={errorMessages?.applyToAllStationsError}
                checked={formData?.applyToAllStations}
              />
            </Grid>
            <Grid container spacing={2} className='mt-[0px] ml-[5px]'>
              <Grid item xs={12} sm={6} md={4} lg={3} className={`flex items-baseline justify-start`}>
                <CommonCheckbox
                  label='isTaxable'
                  onChange={(e) => handleChange('isTaxable', e.target.checked)}
                  color="success"
                  required={false}
                  // error={errorMessages?.isTaxableError}
                  checked={formData?.isTaxable}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                {formData.isTaxable ?
                  <DynamicSelect
                    defaultValue={taxProcedureOptions.find((option: any) => option.value === formData?.taxProcedureId)}
                    value={taxProcedureOptions.find((option: any) => option.value === formData?.taxProcedureId)}
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    // fullWidth={false}
                    isSearchable={true}
                    name="color"
                    options={taxProcedureOptions}
                    onScroll={handleScroll}
                    onInputChange={handleSearch}
                    // isMulti
                    isRequired={formData?.isTaxable ? true : false}
                    onChange={(e) => handleChange('taxProcedureId', e)}
                    error={errorMessages?.taxProcedureIdError}
                    label="taxProcedureId"
                  /> : null
                }
              </Grid>
            </Grid>
            <Grid item xs={12} className='py-[10px]'>
              <h1 className='flex font-Saira font-[800] text-[20px] text-[#000000]'>{t("Pricing")}</h1>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} className='px-4 flex justify-start items-center'>
                  <h3 className='flex font-Saira font-[800] text-[16px] text-[#000000]'>{t(defaultPriceList)}</h3>
                </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}  >
              <StyledField
                type='number'
                label={t('price') + (loggedInUserCurrency ? ' (' + currencyLabel + ')' : '')}
                handleChange={(e) => {
                  handleChange('priceWithTax', (parseFloat(e.target.value) + (
                    parseFloat(e.target.value) * 
                    parseFloat((taxProcedureOptions.find((option: any) => option.value === formData?.taxProcedureId)?.percentageValue || 0))/100
                  ))?.toFixed(2));
                  handleChange('price', e.target.value);
                }}
                required={true}
                fullWidth
                error={errorMessages?.priceError}
                value={formData?.price || 0}
              />
            </Grid>
            {formData?.isTaxable && formData?.taxProcedureId ? <><Grid item xs={12} sm={6} md={4} lg={3} className='relative'>
                  <StyledField
                    type='number'
                    label={t('tax') + ' (%)'}
                    required={false}
                    fullWidth
                    value={taxProcedureOptions.find((option: any) => option.value === formData?.taxProcedureId)?.percentageValue || '0'}
                    readOnly={true}
                  />
                  <span className='font-Saira absolute top-[45px] right-[10px] text-[14px]'>{formData?.price ? (
                        parseFloat(formData?.price) * 
                        parseFloat((taxProcedureOptions.find((option: any) => option.value === formData?.taxProcedureId)?.percentageValue || 0))/100
                      )?.toFixed(2) + (loggedInUserCurrency ? ' ' + currencyLabel + '' : '') : null}</span>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}  >
                  <StyledField
                    type='number'
                    label={t('priceWithTax') + (loggedInUserCurrency ? ' (' + currencyLabel + ')' : '')}
                    handleChange={(e) => {
                      handleChange('priceWithTax', e.target.value);
                      const price = parseFloat(e.target.value) / (
                        1 + (parseFloat((taxProcedureOptions.find((option: any) => option.value === formData?.taxProcedureId)?.percentageValue))/100)
                      );
                      handleChange('price', (price - Math.floor(price)) > 0.9 ? Math.round(price) : price?.toFixed(2));
                    }}
                    required={false}
                    fullWidth
                    value={formData?.priceWithTax || 0}
                  />
                </Grid></> : null}
            <Grid container spacing={2} className='mt-[0px] ml-[5px]'>
            <Grid item xs={12} sm={6} md={4} lg={3}  >
              <DynamicSelect
                defaultValue={enableEdit ? pricingTypeOptions.find((option: any) => option.value === formData?.pricingType) : 2}
                value={pricingTypeOptions.find((option: any) => option.value === formData?.pricingType)}
                isDisabled={false}
                isLoading={false}
                isClearable={false}
                isRtl={false}
                // fullWidth={false}
                isSearchable={true}
                name="color"
                options={pricingTypeOptions}
                onScroll={handleScroll}
                onInputChange={handleSearch}
                // isMulti
                isRequired={false}
                onChange={(e) => handleChange('pricingType', e)}
                // error={errorMessages?.pricingType}
                label="pricingType"
              />
            </Grid>
            </Grid>
            <Grid container spacing={2} className='mt-[10px] ml-[5px]'>
              <Grid item xs={12}>
                <h1 className='flex font-Saira font-[700] text-[20px] text-[#000000]'>{t('extraNotes')}</h1>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}  >
                <StyledField
                  type='number'
                  label="Sort Order (Value 1-100)"
                  handleChange={(e) => handleChange('sortOrder', e.target.value)}
                  required={false}
                  fullWidth
                  // error={errorMessages?.sortOrder}
                  value={formData?.sortOrder || 0}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <StyledInputArea
                  label="description"
                  rowsNo={2}
                  handleChange={(e) => handleChange('description', e.target.value)}
                  required={false}
                  fullWidth
                  // error={errorMessages?.description}
                  value={formData?.description || ''}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <StyledInputArea
                  label="extraNotes"
                  handleChange={(e) => handleChange('extraNotes', e.target.value)}
                  required={false}
                  rowsNo={2}
                  fullWidth
                  // error={errorMessages?.extraNotes}
                  value={formData?.extraNotes || ''}
                />
              </Grid>
            </Grid>
            {/* <Grid item xs={12} sm={6} md={4} lg={3} className={` flex items-baseline justify-start`}>
              <CommonCheckbox
                label='isDefault'
                onChange={(e) => handleChange('isDefault', e.target.checked)}
                color="success"
                required={false}
                // error={errorMessages?.isDefaultError}
                checked={formData?.isDefault}
              />
            </Grid> */}
          </Grid>
        }
      />
    </div>
  );
};

export default PackagesModal;
