import SimpleModal from 'common/Form/Form';
import StyledField from 'common/TextField/TexField1';
import { Grid, Typography } from '@mui/material';
import DynamicSelect from 'common/Select/Select';
import StyledInputArea from 'common/TextArea/TextArea';
import BasicDatePicker from 'common/Pickers/Pickers';
import CommonCheckbox from 'common/CheckBox/Checkbox';
import { useTranslation } from 'react-i18next';
import PaymentsInvoicesTable from './PaymentsInvoicesTable';
import StyledInput from "common/SearchField/Search";

interface PaymentModalComponentProps {
  openModal: () => void;
  closeModal: () => void;
  handleChange: (field: string, value: any) => void;
  handleSubmit: () => void;
  formData: any;
  openForm: boolean;
  errorMessages: { [key: string]: string };
  enableEdit?: boolean;
  isBtnEnabled: boolean;
  loadNext: (field: string, scroll: any) => void;
  loadPrev: (field: string, scroll: any) => void;
  handleDropdownSearch: (field: string, search: string) => void;
  loadingDropdownSearch: boolean;
  clientsData: any;
  paymentsCreditAmount: any;
  invoicesColumns: any[];
  pageNo: number;
  pageSize: number;
  handleChangePage: (field: number) => void;
  handleChangeRows: (field: number) => void;
  paymentsInvoiceData: any;
  assignUnassignInvoices: (isChecked: boolean, row: any) => void;
  clearSearchInvoice: () => void;
  handleSearchClick: () => void;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  searchInvoice: string; 
  loadingSearchInvoice: boolean;
  paymentModesData: any;
}

const PaymentModalComponent: React.FC<PaymentModalComponentProps> = ({
  closeModal,
  handleChange,
  handleSubmit,
  formData,
  openForm,
  enableEdit,
  errorMessages,
  isBtnEnabled,
  loadNext,
  loadPrev,
  handleDropdownSearch,
  loadingDropdownSearch,
  clientsData,
  paymentsCreditAmount,
  invoicesColumns,
  pageNo,
  pageSize,
  handleChangePage,
  handleChangeRows,
  paymentsInvoiceData,
  assignUnassignInvoices,
  clearSearchInvoice,
  handleSearchClick,
  handleInputChange,
  searchInvoice,
  loadingSearchInvoice,
  paymentModesData
}) => {
  const { t } = useTranslation();
  // const paymentModeOptions = [
  //   { value: 551, label: 'CASH' },
  //   { value: 552, label: 'CHEQUE' },
  //   { value: 553, label: 'ONLINE' },
  //   { value: 550, label: 'OTHER' },
  // ];
  const paymentModeOptions = paymentModesData.map((item: { id: number, name: string }) => ({ value: item.id, label: item.name }));
  const adjustmentTypeOptions = [
    { value: 591, label: t('FIFO') },
    { value: 592, label: t('MANUAL') },
  ];
  const clientOptions = clientsData.map((item: { id: number, name: string }) => ({ value: item.id, label: item.name }));

  return (
    <div>
      <SimpleModal
        visable={openForm}
        title={enableEdit ? "updatePayment" : "addPayment"}
        isButtonVisable={true}
        isVisableBtn={isBtnEnabled}
        btnclosetext="Back"
        buttonText={enableEdit ? "update" : "submit"}
        attBtnNotshow={false}
        notVisableBackbtn={true}
        showPortList={handleSubmit} // Assuming this is the Submit button
        modalControle={closeModal}
        // attributeChangesMessage="Attribute changes message"
        // showPort={true}
        formSubmit={handleSubmit}
        content={
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}  >
              <DynamicSelect
                defaultValue={enableEdit ? clientOptions.find((option: any) => option.value === formData?.clientId) : null}
                value={clientOptions.find((option: any) => option.value === formData?.clientId)}
                isDisabled={false}
                isLoading={loadingDropdownSearch}
                isClearable={true}
                isRtl={false}
                // fullWidth={false}
                isSearchable={true}
                name="clientId"
                options={clientOptions}
                // onScroll={handleScroll}
                onInputChange={(e) => handleDropdownSearch('clientId', e)}
                // isMulti
                isRequired={true}
                onChange={(e) => handleChange('clientId', e)}
                error={errorMessages?.clientIdError}
                label="Client"
                onMenuScrollToBottom={(e) => { loadNext('clientId', e); }}
                onMenuScrollToTop={(e) => { loadPrev('clientId', e); }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}  >
              <DynamicSelect
                defaultValue={enableEdit ? paymentModeOptions.find((option: any) => option.value === formData?.paymentMode) : null}
                value={paymentModeOptions.find((option: any) => option.value === formData?.paymentMode)}
                isDisabled={false}
                isLoading={false}
                isClearable={true}
                isRtl={false}
                // fullWidth={false}
                isSearchable={true}
                name="paymentMode"
                options={paymentModeOptions}
                // onScroll={handleScroll}
                // onInputChange={(e) => handleDropdownSearch('paymentMode', e)}
                // isMulti
                isRequired={true}
                onChange={(e) => handleChange('paymentMode', e)}
                error={errorMessages?.paymentModeError}
                label="PaymentMode"
              />
            </Grid>
            <Grid item  xs={12} sm={6} md={4} lg={3}  >
              <StyledField
                type="number"
                label="amount"
                placeholder="Write amount here"
                handleChange={(e) => handleChange('amount', e.target.value)}
                required={true}
                fullWidth
                error={errorMessages?.amountError}
                value={formData?.amount || ''}
              />
            </Grid>
            <Grid item  xs={12} sm={6} md={4} lg={3}  >
              <StyledField
                label={t('referenceNo')}
                placeholder="Write reference number here"
                handleChange={(e) => handleChange('referenceNo', e.target.value)}
                required={true}
                fullWidth
                error={errorMessages?.referenceNo}
                value={formData?.referenceNo || ''}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} className='relative'>
              <DynamicSelect
                defaultValue={enableEdit ? adjustmentTypeOptions.find((option: any) => option.value === formData?.adjustmentType) : null}
                value={adjustmentTypeOptions.find((option: any) => option.value === formData?.adjustmentType)}
                isDisabled={false}
                isLoading={false}
                isClearable={false}
                isRtl={false}
                // fullWidth={false}
                isSearchable={true}
                name="adjustmentType"
                options={adjustmentTypeOptions}
                // onScroll={handleScroll}
                // onInputChange={(e) => handleDropdownSearch('adjustmentType', e)}
                // isMulti
                isRequired={true}
                onChange={(e) => handleChange('adjustmentType', e)}
                error={errorMessages?.adjustmentTypeError}
                label="adjustmentType"
              />
            </Grid>
            <Grid item  xs={12} sm={6} md={4} lg={3} >
              <BasicDatePicker
                type = "datePicker"
                label="paymentDate"
                fullWidth
                onChange={(e) => handleChange('paymentDate', e)}
                required={true}
                error={errorMessages?.paymentDate}
                value={enableEdit ? formData?.paymentDate?.split('T')[0] : null}
              />
            </Grid>
            <Grid item  xs={12} sm={6} md={4} lg={3} >
              <BasicDatePicker
                type = "datePicker"
                label="recipentDate"
                fullWidth
                onChange={(e) => handleChange('recipientDate', e)}
                required={true}
                error={errorMessages?.recipientDate}
                value={enableEdit ? formData?.recipientDate?.split('T')[0] : null}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <StyledInputArea
                label="remarks"
                handleChange={(e) => handleChange('remarks', e.target.value)}
                required={false}
                rowsNo={1}
                fullWidth
                // error={errorMessages?.remarks}
                value={formData?.remarks || ''}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} className={`flex items-center justify-start`}>
              <CommonCheckbox
                label="useCredit"
                onChange={(e) => handleChange('useCredit', e.target.checked)}
                color="success"
                required={false}
                // error={errorMessages?.useCreditError}
                checked={formData?.useCredit}
              />
            </Grid>
            {formData?.clientId && formData?.useCredit ? 
              <Grid item xs={12}>
                <Typography className="font-Saira text-start font-[600] text-[18px] leading-[22px]" >
                  {t('creditAmount')} = {paymentsCreditAmount}
                </Typography>
              </Grid> : null
            }
            {formData?.adjustmentType === 592 && formData?.clientId ? 
              <Grid item xs={12} sx={{ border: '3px dashed #4C0055', padding: '15px', margin: '10px 0 0 15px' }}>
                <PaymentsInvoicesTable 
                  columns={invoicesColumns}
                  data={paymentsInvoiceData.data?.content || []}
                  pageNo={pageNo}
                  pageSize={pageSize}
                  tableData={paymentsInvoiceData?.data || {}}
                  showActions={true}
                  enableSorting={true}
                  showHeader={true}
                  showSearch={true}
                  showFilter={true}
                  showCreateButton={false}
                  actions={[]}
                  handleChangePage={handleChangePage}
                  handleChangeRows={handleChangeRows}
                  assignUnassignInvoices={assignUnassignInvoices}
                  enableEdit={enableEdit}
                  loadingSearch={loadingSearchInvoice}
                  headerComponent={(
                    <Grid container spacing={2}>  
                      <Grid item xs={12} md={7} lg={5}>
                        <StyledInput
                          fullWidth
                          placeholder={t("invoiceNo")}
                          handleSearchClick={handleSearchClick}
                          handleChange={handleInputChange}
                          value={searchInvoice}
                          clearSearch={clearSearchInvoice}
                        />
                      </Grid>
                    </Grid>
                  )}
                />
              </Grid> : null
            }
          </Grid>
        }
      />
    </div>
  );
};

export default PaymentModalComponent;