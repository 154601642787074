import SimpleModal from 'common/Form/Form';
import StyledField from 'common/TextField/TexField1';
import {  Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
interface InspectionSectionModalComponentProps {
  openModal: () => void;
  closeModal: () => void;
  handleSecField: () => void;
  showIFISecField: boolean;
  primaryLanguage:string;
  secondaryLanguage:string;
  handleChange: (field: string, value: any) => void;
  handleSubmit: () => void;
  formData: any;
  openForm: boolean;
  errorMessages:{ [key: string]: string };
  enableEdit?:boolean;
  isBtnEnabled: boolean;
}

const InspectionSectionModalComponent: React.FC<InspectionSectionModalComponentProps> = ({
  closeModal,
  handleChange,
  handleSubmit,
  formData,
  openForm,
  enableEdit,
  handleSecField,
  showIFISecField,
  primaryLanguage,
  secondaryLanguage,
  errorMessages,
  isBtnEnabled,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <SimpleModal
        visable={openForm}
        // title={enableEdit ? "updateVehicleInspection" : "addVehicleInspection"}
        isButtonVisable={true}
        isVisableBtn={isBtnEnabled}
        btnclosetext="Back"
        buttonText={enableEdit ? "update" : "submit"}
        attBtnNotshow={false}
        notVisableBackbtn={true}
        showPortList={handleSubmit} // Assuming this is the Submit button
        modalControle={closeModal}
        // attributeChangesMessage="Attribute changes message"
        // showPort={true}
        formSubmit={handleSubmit}
        content={
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <StyledField
                label={`${!primaryLanguage ? t('primary') : ''} ${t('title')} ${primaryLanguage ? ` (${t(primaryLanguage)})` : ''}`}
                search='language'
                handleSecField={handleSecField}
                placeholder="Write your title here"
                handleChange={(e) => handleChange('title', e.target.value)}
                required={true}
                fullWidth
                error={errorMessages?.title}
                value={formData?.title || ''}
              />
            {showIFISecField && 
            <Grid item xs={12} sm={12} md={12} lg={12} >
            <div style={{ position: 'relative' }}>
              <div
                style={{
                  position: 'absolute',
                  top: -8,
                  left: 0,
                  zIndex: 1,
                  display: showIFISecField ? 'flex' : 'none',
                  backgroundColor: "white",
                  height: '5rem',
                  width: '100%',
                  justifyContent:'center',
                  borderRadius: '8px',
                  boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
                }}
              >
                <div className='w-full mx-2 mt-1'>
                  <StyledField
                    label={`${!secondaryLanguage ? t('secondary') : ''} ${t('title')} ${secondaryLanguage ? ` (${t(secondaryLanguage)})` : ''}`}
                    placeholder="Write your secondaryTitle here"
                    handleChange={(e) => handleChange('secondaryTitle', e.target.value)}
                    required={false}
                    fullWidth
                    // error={errorMessages?.secondaryTitle}
                    value={formData?.secondaryTitle || ''}
                  />
                      </div>
                    </div>
                  </div>
                </Grid>
              }
            </Grid>
            {/* <Grid item xs={12} sm={6} md={4} lg={3}>
              <StyledField
                type='number'
                label="Sort Order (Value 1-100)"
                handleChange={(e) => handleChange('sortOrder', e.target.value)}
                required={false}
                fullWidth
                // error={errorMessages?.sortOrder}
                value={formData?.sortOrder || 0}
              />
            </Grid> */}
          </Grid>
        }
      />
    </div>
  );
};

export default InspectionSectionModalComponent;