import { Box, Button, Divider, Grid, Step, StepLabel, Stepper, TextField, Typography } from "@mui/material"
import { useForm } from "react-hook-form"
import { useEffect, useState } from "react";
import FormDialog from "common/Modal/Modal";
import AddIcon from '@mui/icons-material/Add';
import { InventoryItemsTable } from "components/Inventory/InventoryItemsTable";
import { useSnackbar } from "notistack";
import { InventoryServicesTable } from "components/Inventory/InventoryServicesTable";
import { useGetItemsService } from "subModule/src/services/items/useItems";
import { useGetServiceService } from "subModule/src/services/service/useService";
import { selectUserInfo } from "features/user/userSlice";
import { useSelector } from "react-redux";
import AlertMessages from "common/Alert/AlertMessages";
import { useNavigate, useParams } from "react-router-dom";
import { InventoryProfileService, TaxProcedureProfileService } from "subModule/src/core/services";
import { NewPurchaseOrderResponse, PurchaseOrderResponse } from "subModule/src/services/inventory/interface";
import Loader from "layout/Loader";
import { useTranslation } from "react-i18next";
import { DeleteData } from "subModule/src/type";
import StockReceived from "./StockReceived";
import CreditNote from "./CreditNote";
import Unstock from "./Unstock";
import PurchasePayment from "./PurchasePayment";
import { GetDefaultTaxProcedureResponse } from "@subModule/src/services/taxProcedure/interface";

interface FormData {
    [key: string]: any;
}

export default function InventorySteps() {
    const params = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const loggedInUserCurrency = useSelector(selectUserInfo)?.currency;
    const { enqueueSnackbar } = useSnackbar();
    const { control, register, handleSubmit, setValue, formState: { errors }, watch, resetField } = useForm<FormData>({defaultValues: {
        purchaseOrderItems: [],
        purchaseOrderSvc: [],
    }});
    const { data: itemsData, refetch: itemsRefetch } = useGetItemsService({ pageNumber: 1, size: 8000 });
    const { data: servicesData, refetch: servicesRefetch } = useGetServiceService({ pageNumber: 1, size: 500 });
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [confirm, setConfirm] = useState<boolean>(false);
    const [purchaseOrderItems, setPurchaseOrderItems] = useState<any[]>([]);
    const [purchaseOrderSvc, setPurchaseOrderSvc] = useState<any[]>([]);
    const [entity, setEntity] = useState<FormData>({});
    const [loading, setLoading] = useState<boolean>(true);
    const [po, setPO] = useState<PurchaseOrderResponse>();
    const [checkStatus, setCheckStatus] = useState<number | null>(1);
    const [taxPercentage, setTaxPercentage] = useState<number>(0);

    useEffect(() => {
        itemsRefetch();
        servicesRefetch();
        if(params?.id) {
            getPOById();
        }
    }, [params]);
    const getPOById = () => {
        InventoryProfileService.getPurchaseOrderById(params?.id).then((res: NewPurchaseOrderResponse) => {
            if(res.success) {
                setPO(res.data);
                setPurchaseOrderItems(res.data?.purchaseOrderItems);
                setPurchaseOrderSvc(res.data?.purchaseOrderSvc);
                setValue('poMemo', res.data?.poMemo);
                setValue('purchaseOrderItems', res.data.purchaseOrderItems);
                setValue('purchaseOrderSvc', res.data.purchaseOrderSvc);
                setCheckStatus(res.data?.status);
                getTaxPercentage(res.data?.taxProcedureId);
                res.data?.stockType === 4 ? steps = ['Purchase Order', 'Stock Received', 'Payment']
                : steps = ['Purchase Order', 'Payment', 'Stock Received', 'Debit Note', 'Unstock']
                setLoading(false);
            } else {
                enqueueSnackbar(<AlertMessages text={res?.message} />, { variant: 'error' });
                setLoading(false);
            }
        }).catch((err:any) => { console.error("===", err); setLoading(false); });
    }
    const getTaxPercentage = (taxId: number | null) => {
        TaxProcedureProfileService.getTaxProcedureById(taxId).then((res: GetDefaultTaxProcedureResponse) => {
            setTaxPercentage((res.data.percentageValue || 0) / 100)
        }).catch((err:any) => { console.error("===", err); });
    }
    const columns = [
        { id: 'itemId' },
        { id: 'itemCode'},
        { id: 'unitId' },
        { id: 'itemName', label: t('Item') + ' *', numeric: false, disablePadding: false, type: 'text' },
        { id: 'supplierSKU', label: t('SKU'), numeric: false, disablePadding: false, type: 'text' },
        { id: 'quantity', label: t('Quantity'), numeric: false, disablePadding: false, type: 'number' },
        { id: 'unitTitle' },
        { id: 'unitPrice', label: t('Cost'), numeric: false, disablePadding: false, type: 'number' },
        { id: 'discount', label: t('Discount'), numeric: false, disablePadding: false, type: 'number' },
        { id: 'total', label: t('Total') },
    ];
    const columnsService = [
        { id: 'serviceId' },
        { id: 'serviceCode'},
        { id: 'serviceName', label: t('service') + ' *', numeric: false, disablePadding: false, type: 'text' },
        { id: 'quantity', label: t('Quantity'), numeric: false, disablePadding: false, type: 'number' },
        { id: 'unitPrice', label: t('Cost'), numeric: false, disablePadding: false, type: 'number' },
        { id: 'discount', label: t('Discount'), numeric: false, disablePadding: false, type: 'number' },
        { id: 'total', label: t('Total') },
    ];
    const DeleteItem = (row: any, typeName: string) => {
        setLoading(true);
        if(typeName === "purchaseOrderItems") {
            InventoryProfileService.deleteEntityItem((row as any).id, 'purchaseorder/items').then((response: DeleteData) => {
                if(response.success)  enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('deleted') + ' ' + t('successfully')} />, { variant: 'success' });
                else enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                setLoading(false);
                getPOById();
            }).catch((err:any) => { 
                console.error("===", err);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
                setLoading(false);
            });
        } else {
            InventoryProfileService.deletePurchaseOrderService((row as any).id).then((response: DeleteData) => {
                if(response.success)  enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('deleted') + ' ' + t('successfully')} />, { variant: 'success' });
                else enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                setLoading(false);
                getPOById();
            }).catch((err:any) => { 
                console.error("===", err);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
                setLoading(false);
            });
        }
    }
    const addNewItem = (typeName: string) => {
        if(typeName === "purchaseOrderItems") {
            const newItem = columns.reduce((acc, column) => ({ ...acc, [column.id]: column.type === 'number' ? 0 : '' }), {});
            setPurchaseOrderItems((prevRows) => [...prevRows, newItem]);
        } else {
            const newService = columnsService.reduce((acc, column) => ({ ...acc, [column.id]: column.type === 'number' ? 0 : '' }), {});
            setPurchaseOrderSvc((prevRows) => [...prevRows, newService]);
        }
    };
    const handleItem = (row: any, typeName: string, action: string) => {
        if(typeName === "purchaseOrderItems") {
            if(action === 'create') {
                InventoryProfileService.createPurchaseOrderItem({...row, poId: params?.id, storeId: po?.storeId}).then((response: NewPurchaseOrderResponse) => {
                    if(response.success)  enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Purchase Order Item') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
                    else enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                    getPOById();
                }).catch((err:any) => { 
                    console.error("===", err);
                    enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
                    getPOById();
                });
            } else {
                InventoryProfileService.updatePurchaseOrderItem({...row, poId: params?.id}).then((response: NewPurchaseOrderResponse) => {
                    if(response.success)  enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Purchase Order Item') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
                    else enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                    getPOById();
                }).catch((err:any) => { 
                    console.error("===", err);
                    enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
                    getPOById();
                });
            }
        } else {
            if(action === 'create') {
                InventoryProfileService.createPurchaseOrderService({...row, poId: params?.id, storeId: po?.storeId}).then((response: NewPurchaseOrderResponse) => {
                    if(response.success)  enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Purchase Order Service') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
                    else enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                    getPOById();
                }).catch((err:any) => { 
                    console.error("===", err);
                    enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
                    getPOById();
                });
            } else {
                InventoryProfileService.updatePurchaseOrderService({...row, poId: params?.id}).then((response: NewPurchaseOrderResponse) => {
                    if(response.success)  enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Purchase Order Service') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
                    else enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                    getPOById();
                }).catch((err:any) => { 
                    console.error("===", err);
                    enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
                    getPOById();
                });
            }
        }
    }

    const handleInputChange = (index: number, columnId: string, value: any, typeName: string, type: string) => {
        if(typeName === "purchaseOrderItems") {
            const updatedItems = [...purchaseOrderItems];
            updatedItems[index][columnId] = type === 'number' ? parseFloat(value) : value;
            setPurchaseOrderItems(updatedItems);
        } else {
            const updatedServices = [...purchaseOrderSvc];
            updatedServices[index][columnId] = value;
            setPurchaseOrderSvc(updatedServices);
        }
    };
    const onSubmit = (data: FormData) => {
        if(checkStatus === 1) {
            const filteredItems = purchaseOrderItems.filter((item: any) => item?.itemName);
            const filteredServices = purchaseOrderSvc.filter((service: any) => service?.serviceName);
            if(filteredItems.length === 0 && filteredServices.length === 0) {
                enqueueSnackbar(<AlertMessages text="Please add at least one item or service" />, { variant: 'error' });
                return;
            }
            setConfirm(true);
            setEntity({...data, purchaseOrderItems: filteredItems, purchaseOrderSvc: filteredServices});
        } else {
            setCurrentStep((po as any).stockType === 4 ? steps.indexOf('Stock Received') : steps.indexOf('Payment'));
        }
    };
    const calculateTotals = () => {
        const itemTotals = purchaseOrderItems.reduce((acc, item) => {
            return {
                unitPrice: parseFloat(acc.unitPrice) + parseFloat(item.unitPrice || 0),
                discount: parseFloat(acc.discount) + parseFloat(item.discount || 0),
                subTotal: parseFloat(acc.subTotal) + parseFloat(item.unitPrice || 0) * parseInt(item.quantity || 0),
                total: parseFloat(acc.total) + ((parseFloat(item.unitPrice || 0) * parseFloat(item.quantity) || 0)) - parseFloat(item.discount || 0),
            };
        }, { unitPrice: 0, discount: 0, subTotal: 0, total: 0 });
    
        const serviceTotals = purchaseOrderSvc.reduce((acc, service) => {
            return {
                unitPrice: parseFloat(acc.unitPrice) + parseFloat(service.unitPrice || 0),
                discount: parseFloat(acc.discount) + parseFloat(service.discount || 0),
                subTotal: parseFloat(acc.subTotal) + parseFloat(service.unitPrice || 0) * parseInt(service.quantity || 0),
                total: parseFloat(acc.total) + ((parseFloat(service.unitPrice || 0) * parseFloat(service.quantity) || 0)) - parseFloat(service.discount || 0),
            };
        }, { unitPrice: 0, discount: 0, subTotal: 0, total: 0 });
    
        return {
            unitPrice: itemTotals.unitPrice + serviceTotals.unitPrice,
            discount: itemTotals.discount + serviceTotals.discount,
            subTotal: itemTotals.subTotal + serviceTotals.subTotal,
            total: itemTotals.total + serviceTotals.total,
        };
    };
    const totals = calculateTotals();

    return (
        loading ? <Loader /> : currentStep === steps.indexOf('Purchase Order') ? <Grid container component="form" id="form" className="px-[8px] items-baseline"  rowGap={2} columnGap={4} onSubmit={handleSubmit(onSubmit)}>
            <Grid container className="items-center">
                <Grid item xs={12} sm={6} className="text-start">
                    <Typography className="font-Saira text-[12px] font-semibold text-primary-color">{t(steps[currentStep])}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} className="hide-on-print">
                    <div className="flex gap-2 justify-end">
                        <Button type="submit" className="primary" variant="contained" disableElevation onClick={() => onSubmit}>{checkStatus === 1 ? t('Authorize') : t('Next')}</Button>
                        {/*  <Button className="secondary" variant="contained" disableElevation>{t('email')}</Button> */}
                        <Button className="secondary" variant="contained" disableElevation onClick={() => window.print()}>{t('Print')}</Button>
                        <Button className="secondary" variant="contained" disableElevation onClick={() => navigate('/inventory')}>{t('Back to list')}</Button>
                    </div>
                </Grid>
            </Grid>
            <Grid item xs={12}><Divider className="bg-[#AEB3C4] hide-on-print" /></Grid>
            <Grid container className="bg-primary-color py-[12px] px-[18px] rounded-[8px] mb-[16px] items-center">
                <Grid item xs={4}>
                    <Typography className="font-Saira text-[16px] font-bold text-[#fff] text-left">{t(steps[currentStep])}</Typography>
                    <Typography className="font-Saira text-[14px] text-[#fff] text-left"><span className="font-bold">{t(steps[currentStep])} # </span> <span>{po?.poNumber || ''}</span></Typography>
                    <Typography className="font-Saira text-[14px] text-[#fff] text-left"><span className="font-bold">{t('Date')}</span> <span>{po?.poDate?.split('T')[0] || ''}</span></Typography>
                </Grid>
                <Grid item xs={8}>
                    <div className="flex justify-end gap-10">
                        <Typography className="font-Saira text-[12px] text-[#fff] text-center"><span className="font-bold">{t('Supplier')} {t('name')}</span> <br /><span className="font-medium">{po?.vendorTitle || ''}</span></Typography>
                        <Typography className="font-Saira text-[12px] text-[#fff] text-center"><span className="font-bold">{t('Requested')}</span> <br /><span className="font-medium">{po?.totalQuantity || 0}</span></Typography>
                        {/* <Typography className="font-Saira text-[12px] text-[#fff] text-center"><span className="font-bold">{t('Received')}</span> <br /><span className="font-medium">{po?.totalQuantity || 0}</span></Typography> */}
                        <Typography className="font-Saira text-[12px] text-[#fff] text-center"><span className="font-bold">{t('Subtotal')}</span> <br /><span className="font-medium">{new Intl.NumberFormat('en-US', {}).format((po as PurchaseOrderResponse)?.subtotalAmount || 0) || 0}</span></Typography>
                        <Typography className="font-Saira text-[12px] text-[#fff] text-center"><span className="font-bold">{t('Discount')}</span> <br /><span className="font-medium">{new Intl.NumberFormat('en-US', {}).format((po as PurchaseOrderResponse)?.discount || 0) || 0}</span></Typography>
                        <Typography className="font-Saira text-[12px] text-[#fff] text-center"><span className="font-bold">{t('tax')}</span> <br /><span className="font-medium">{new Intl.NumberFormat('en-US', {}).format((po as PurchaseOrderResponse)?.taxAmount || 0) || 0}</span></Typography>
                        <Typography className="font-Saira text-[12px] text-[#fff] text-center"><span className="font-bold">{t('Total')}</span> <br /><span className="font-medium">{new Intl.NumberFormat('en-US', {}).format((po as PurchaseOrderResponse)?.totalAmount || 0) || 0}</span></Typography>
                    </div>
                </Grid>
            </Grid>
            <Grid container><Grid item xs={12}>
                <NewPurchaseSteps form={{ control, register, handleSubmit, setValue, errors, watch, resetField }} addNewItem={addNewItem} handleInputChange={handleInputChange} items={itemsData?.pages[0]?.data?.content || []} services={servicesData?.pages[0]?.data?.content || []} DeleteItem={DeleteItem} handleItem={handleItem} {...{currentStep, setCurrentStep, columns, columnsService, purchaseOrderItems, purchaseOrderSvc, loggedInUserCurrency, params, checkStatus, t}} />
            </Grid></Grid>
            <Typography className="font-Saira text-[12px] font-semibold text-[#171826] text-left mb-[-5px]">{t('Purchase Order Memo')}</Typography>
            <Grid container xs={12} spacing={1}>
                <Grid item xs={12} md={6}>
                    <TextField 
                        {...register("poMemo")} 
                        placeholder={t('Purchase Order Memo')} 
                        fullWidth
                        error={!!errors.poMemo} 
                        helperText={errors.poMemo?.message as string}
                        InputLabelProps={{ shrink: true }}
                        multiline
                        rows={5}
                        sx={{ '& .MuiInputBase-fullWidth.MuiInputBase-multiline': { width: '100%' }}}
                        disabled={checkStatus === 2}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box className="bg-[#D8D1E0] rounded-[8px]" sx={{ border: '1px solid #5f6770', width: 'calc(100% + 8px)', padding: '8px 14px' }}>
                        <Box className="flex justify-end gap-12 mt-[9px]">
                            <Typography className="font-Saira text-[12px] text-[#3A3A3A] w-[auto]">{t('Subtotal')}</Typography>
                            <Typography className="font-Saira text-[12px] text-[#3A3A3A] w-[20%] text-end">{new Intl.NumberFormat('en-US', {}).format(totals.subTotal || (po as PurchaseOrderResponse)?.subtotalAmount || 0)}</Typography>
                        </Box>
                        <Box className="flex justify-end gap-12 mt-[9px]">
                            <Typography className="font-Saira text-[12px] text-[#3A3A3A] w-[auto]">{t('Discount')}</Typography>
                            <Typography className="font-Saira text-[12px] text-[#3A3A3A] w-[20%] text-end">{new Intl.NumberFormat('en-US', {}).format(totals.discount || (po as PurchaseOrderResponse)?.discount || 0)}</Typography>
                        </Box>
                        <Box className="flex justify-end gap-12 mt-[9px]">
                            <Typography className="font-Saira text-[12px] text-[#3A3A3A] w-[auto]">{t('tax') + ` (${taxPercentage * 100}%)`}</Typography>
                            <Typography className="font-Saira text-[12px] text-[#3A3A3A] w-[20%] text-end">{new Intl.NumberFormat('en-US', {}).format((po as PurchaseOrderResponse)?.taxAmount || 0)}</Typography>
                        </Box>
                        <Box className="flex justify-end gap-12 mt-[9px]">
                            <Typography className="font-Saira text-[12px] text-[#3A3A3A] font-semibold w-[auto]">{t('Total')}</Typography>
                            <Typography className="font-Saira text-[12px] text-[#3A3A3A] font-semibold w-[20%] text-end"><span className="uppercase">({loggedInUserCurrency})</span> {new Intl.NumberFormat('en-US', {}).format(totals.total || (po as PurchaseOrderResponse)?.totalAmount || 0)}</Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Grid item xs={12} className="hide-on-print"><Divider className="bg-[#AEB3C4] mt-[8px]" /></Grid>
            <Grid container className="items-center hide-on-print">
                <Grid item xs={12} sm={6} className="text-start"></Grid>
                <Grid item xs={12} sm={6}>
                    <div className="flex gap-2 justify-end">
                        <Button type="submit" className="primary" variant="contained" disableElevation onClick={() => onSubmit}>{checkStatus === 1 ? t('Authorize') : t('Next')}</Button>
                        {/*  <Button className="secondary" variant="contained" disableElevation>{t('email')}</Button> */}
                        <Button className="secondary" variant="contained" disableElevation onClick={() => window.print()}>{t('Print')}</Button>
                        <Button className="secondary" variant="contained" disableElevation onClick={() => navigate('/inventory')}>{t('Back to list')}</Button>
                    </div>
                </Grid>
            </Grid>
            {confirm &&
                <FormDialog
                  open={confirm}
                  onClose={(event, reason) => event && reason !== 'backdropClick' ? setConfirm(false) : ''}
                  title={t('Authorize') + ' ' + t(steps[currentStep])}
                  saveFunction={() => {
                    delete entity.typeName;
                    entity.purchaseOrderItems.every((row:any) => delete row.total);
                    entity.purchaseOrderSvc.every((row:any) => delete row.total);
                    setConfirm(false);
                    InventoryProfileService.changeStatusEntity({typeName: 'purchaseorder', entityId: params.id, status: 2}).then((response: NewPurchaseOrderResponse) => {
                        if(response.success) {
                            setConfirm(false);
                            (currentStep + 1) === steps.length ? setCurrentStep(currentStep) : setCurrentStep((po as any).stockType === 4 ? steps.indexOf('Stock Received') : steps.indexOf('Payment')); 
                            getPOById();
                        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Status') + ' ' + t('changed') + ' ' + t('successfully')} />, { variant: 'success' });
                        } else {
                            setConfirm(false);
                            enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                        }
                    }).catch((err:any) => { 
                        console.error("===", err);
                        setConfirm(false);
                        enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
                    });
                }}
                  content={  
                    <><Typography>{t('Are you sure?')}</Typography></>
                  }
                  submitButtonText="OK"
                />
              }
        </Grid> : currentStep === steps.indexOf('Payment') ? <PurchasePayment steps={steps} setCurrentSteps={setCurrentStep} /> : currentStep === steps.indexOf('Stock Received') ? <StockReceived steps={steps} setCurrentSteps={setCurrentStep} />: currentStep === steps.indexOf('Debit Note') ? <CreditNote steps={steps} setCurrentSteps={setCurrentStep} /> : <Unstock steps={steps} setCurrentSteps={setCurrentStep} />
    )
}

let steps = ['Purchase Order', 'Payment', 'Stock Received', 'Debit Note', 'Unstock'];

interface NewPurchaseStepsProps {
    form: FormData;
    addNewItem: (typeName: string) => void;
    handleInputChange: (index: number, columnId: string, value: any, typeName: string, type: string) => void;
    currentStep: number;
    setCurrentStep: any;
    columns: any[];
    columnsService: any[];
    purchaseOrderItems: any[];
    purchaseOrderSvc: any[];
    items: any[];
    services: any[];
    loggedInUserCurrency?: string;
    DeleteItem: (row: any, typeName: string) => void;
    params?: any;
    handleItem?: (row: any, typeName: string, action: string) => void;
    checkStatus?: number | null;
    t?:any;
}

export const NewPurchaseSteps: React.FC<NewPurchaseStepsProps> = ({ form, addNewItem, handleInputChange, currentStep, columns, columnsService, purchaseOrderItems, purchaseOrderSvc, items, services, loggedInUserCurrency, DeleteItem, params, handleItem, checkStatus, t }) => {
    return (<>
        <Stepper className='hide-on-print' activeStep={currentStep} connector={<></>}>
            {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {};
                const labelProps: { optional?: React.ReactNode; } = {};
                return (
                    <Step key={index} {...stepProps}><StepLabel {...labelProps}>{t(label)}</StepLabel></Step>
                );
            })}
        </Stepper>
        <Box className="bg-white py-[12px] px-[18px] rounded-[8px] mt-[16px]">
            <Typography className="font-Saira text-[12px] font-semibold text-[#171826] text-left">{t(steps[currentStep])}</Typography>
            <InventoryItemsTable form={form} columns={columns} handleInputChange={handleInputChange} rows={purchaseOrderItems} typeName="purchaseOrderItems" items={items} currency={loggedInUserCurrency} DeleteItem={DeleteItem} handleItem={handleItem} {...{params, checkStatus}} />
            <Grid item xs={12} sm={6} className="hide-on-print">
                <div className="flex gap-2 justify-start">
                    <Button className="secondary flex gap-1" variant="contained" disableElevation disabled={checkStatus === 2} onClick={() => addNewItem('purchaseOrderItems')}><AddIcon className="w-[20px] h-[20px]" /> {t('Add More Items')}</Button>
                    {/* <Button className="secondary" variant="contained" disableElevation disabled={checkStatus === 2}>{t('Import')}</Button>
                    <Button className="secondary" variant="contained" disableElevation disabled={checkStatus === 2}>{t("Export")}</Button> */}
                </div>
            </Grid>
        </Box>
        <Box className="flex gap-2 justify-start mt-[16px]">
            <Button className="secondary cursor-text" variant="contained" disableElevation>{t('Additional Costs')}</Button>
        </Box>
        <Box className="bg-white py-[12px] px-[18px] rounded-[8px] mt-[16px]">
            <Typography className="font-Saira text-[12px] font-semibold text-[#171826] text-left">{t('Additional Cost')}</Typography>
            <InventoryServicesTable form={form} columns={columnsService} handleInputChange={handleInputChange} rows={purchaseOrderSvc} typeName="purchaseOrderSvc" services={services} currency={loggedInUserCurrency}  DeleteItem={DeleteItem} handleItem={handleItem} {...{params, checkStatus}} />
            <Grid item xs={12} sm={6} className="hide-on-print">
                <div className="flex gap-2 justify-start">
                    <Button className="secondary flex gap-1" variant="contained" disableElevation disabled={checkStatus === 2} onClick={() => addNewItem('purchaseOrderSvc')}><AddIcon className="w-[20px] h-[20px]" /> {t('Add More Items')}</Button>
                </div>
            </Grid>
            <Grid item xs={12}><Divider className="bg-[#AEB3C4] mt-[15px] mb-[8px]" /></Grid>
        </Box>
    </>);
}
