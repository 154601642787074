import React from 'react';
import { CircularProgress, Backdrop } from '@mui/material';

const Loader: React.FC = () => {
  return (
    <Backdrop
      sx={{ 
        color: '#fff', 
        // zIndex: (theme) => theme.zIndex.drawer + 1,
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.1)'
      }}
      open={true}
    >
      <CircularProgress className='text-primary-color' />
    </Backdrop>
  );
};

export default Loader;