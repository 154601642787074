import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import BankAccountComponent from 'components/BankAccount/index';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useBankAccountService, useChangeStatusBankAccountService, useDeleteBankAccountService, useGetBankAccountsService, useUpdateBankAccountService } from 'subModule/src/services/bankaccount/useBankAccount';
import { BankAccountRequest } from 'subModule/src/services/bankaccount/interface';
import { useSnackbar } from 'notistack';
import AlertMessages from 'common/Alert/AlertMessages';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUserInfo } from 'features/user/userSlice';
import { SelectChangeEvent } from '@mui/material';
import { checkPrivileges } from 'Helpers/CheckPrivileges';

interface FormData {
  accountTitle: string;
  secondaryAccountTitle: string;
  bankName: string,
  bankNameSecondary: string,
  id: number,
  accountNumber: string,
  iban: string,
  active: boolean
}

interface SearchFormData {
  [key: string]: string;
}

const requiredFields = ['bankName', 'accountNumber', 'iban', 'accountTitle'];

export default function BankAccount() {
  const [showSecAccountTitleField, setShowSecAccountTitleField] = useState<boolean>(false);
  const [showSecBankNameField, setShowSecBankNameField] = useState<boolean>(false);
  const [openForm, setOpenForm] = useState(false);
  const [openDeleteModal, setDeleteModal] = useState(false);
  const [enableEdit, setEnableEdit] = useState(false);
  const [pageNo, setPageNo] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [statusModal, setStatusModal] = useState(false);
  const [statusText, setStatusText] = useState<string>('');
  const [isBtnEnabled, setIsBtnEnabled] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [filter, setFilter] = useState<string[]>(['accountTitle']);
  const [search, setSearch] = useState<any>({});
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  // const [data] = useState(rows);
  const [prevFormData, setPrevFormData] = useState<object>({});
  const [formData, setFormData] = useState<FormData>({
    accountTitle: '',
    secondaryAccountTitle: '',
    bankName: '',
    bankNameSecondary: '',
    id: 0,
    accountNumber: '',
    iban: '',
    active: false
  });

  const [searchFormData, setSearchFormData] = useState<SearchFormData>({
    accountTitle: '',
    bankName: '',
    accountNumber: '',
  });

  const { data: tableData, isLoading, error, refetch } = useGetBankAccountsService({ pageNumber: pageNo, size: pageSize, ...search });

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const loggedInUserData = useSelector(selectUserInfo);
  const primaryLanguage = loggedInUserData?.primaryLanguage;
  const secondaryLanguage = loggedInUserData?.secondaryLanguage;

  useEffect(() => {
    if (!isLoading && !error && !openForm && !loadingSearch) { // Only refetch if not already loading or in error state
      refetch();
    }
    if (tableData) {
      setLoading(false);
      if ((tableData?.pages[0] as any).status && !(tableData?.pages[0] as any).success) {
        enqueueSnackbar(<AlertMessages statusCode={(tableData?.pages[0] as any)?.status} />, { variant: 'error' })
      }
    }
  }, [pageNo, pageSize, refetch, isLoading, error, tableData, openForm]);
  useEffect(() => {
    if (enableEdit && JSON.stringify(formData) !== JSON.stringify(prevFormData) || openForm && JSON.stringify(formData) !== JSON.stringify(prevFormData)) {
      setIsBtnEnabled(true);
    } else if (enableEdit && JSON.stringify(formData) === JSON.stringify(prevFormData)) {
      setIsBtnEnabled(false);
    }
  }, [enableEdit, formData]);

  useEffect(() => {
    const getData = setTimeout(async () => {
      if (search && !isLoading && !error && loadingSearch) {
        await refetch();
        setLoadingSearch(false);
      }
    }, 300)
    return () => clearTimeout(getData)
  }, [search]);

  const handleChangePage = (pageNo: number) => {
    setLoading(true);
    setPageNo(pageNo + 1);
    setPageSize(pageSize)

  }
  const handleChangeRows = (pageSize: number) => {
    setLoading(true);
    setPageSize(pageSize)
    setPageNo(1)
  }

  const [searchErrorMessages, setSearchErrorMessages] = useState<Partial<SearchFormData>>({});

  const [errorMessages, setErrorMessages] = useState<Partial<FormData>>({});

  const openModal = () => {
    setOpenForm(!openForm);
    setIsBtnEnabled(true);
    setEnableEdit(false);
    setErrorMessages({});
    setShowSecAccountTitleField(false)
    setShowSecBankNameField(false)
    setFormData({
      accountTitle: '',
      secondaryAccountTitle: '',
      bankName: '',
      bankNameSecondary: '',
      id: 0,
      accountNumber: '',
      iban: '',
      active: false
    });
    if (!openForm && !enableEdit) {
      clearSearch();
    }
  };

  const handleSecField = (field: string) => {
    if (field === 'accountTitle') {
      setShowSecAccountTitleField((prevValue) => !prevValue);
    } else if (field === 'bankName') {
      setShowSecBankNameField((prevValue) => !prevValue);
    }
  };

  const handleChange = (field: string, value: any) => {
    const val = value;
    setFormData((prevData) => ({
      ...prevData,
      [field]: val,
    }));

    if (requiredFields.includes(field) && !val) {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        [field]: 'This field is required.',
      }));
    } else {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        [field]: undefined,
      }));
    }
    if (enableEdit) {
      setIsBtnEnabled(true);
    }
  };


  const validateFormData = (data: FormData) => {
    const errors: Partial<FormData> = {};

    if (!data.accountNumber?.trim()) {
      errors.accountNumber = t('This field is required.');
    }
    if (!data.bankName?.trim()) {
      errors.bankName = t('This field is required.');
    }
    if (!data.accountTitle?.trim()) {
      errors.accountTitle = t('This field is required.');
    }

    if (!data.iban?.trim()) {
      errors.iban = t('This field is required.');
    }

    return errors;
  };

  const { onCreateBankAccount } = useBankAccountService({
    onSuccess: (msg) => {
      const response: any = {};
      Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
      setOpenForm(false);
      if (response.success) {
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('bankAccount') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('accountNumber') + ' ' + t('or') + ' ' + t('IBAN')} />, { variant: 'error' });
      }
      refetch(); // This will use the updated pageNo and pageSize
    },
    onError: (err: any) => {
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
      if (err instanceof Error) {
        console.error(err.stack);
      }
    }
  })

  const { onUpdateBankAccount } = useUpdateBankAccountService({
    onSuccess: (data) => {
      const response: any = {};
      Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
      setEnableEdit(false);
      setOpenForm(false);
      if (response.success) {
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('bankAccount') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('accountNumber') + ' ' + t('or') + ' ' + t('IBAN')} />, { variant: 'error' });
      }
      refetch(); // This will use the updated pageNo and pageSize
    },
    onError: (err: any) => {
      console.log("err====,  ", err);
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
    },
    id: formData.id,
  }
  );

  const handleSubmit = () => {
    const errors = validateFormData(formData);
    setErrorMessages(errors);

    if (Object.keys(errors).length === 0) {
      setIsBtnEnabled(false);
      setLoading(true);
      const bankAccountData: BankAccountRequest = {
        ...formData,
        accountTitle: formData.accountTitle,
        secondaryAccountTitle: formData.secondaryAccountTitle,
        bankName: formData.bankName,
        bankNameSecondary: formData.bankNameSecondary,
        id: formData.id,
        accountNumber: formData.accountNumber,
        iban: formData.iban,
        active: formData.active
      };

      if (enableEdit) {
        onUpdateBankAccount(
          bankAccountData
        );
      } else {
        onCreateBankAccount(bankAccountData);
      }
    }
  };

  const onEdit = (row: any) => {
    setEnableEdit(true);
    setFormData(row);
    clearSearch();
    setPrevFormData(row);
    setOpenForm(true);
    setIsBtnEnabled(false);
  }

  const onDelete = (row: any) => {
    setFormData(row)
    setDeleteModal(true)
  }

  const { onDeleteBankAccount } = useDeleteBankAccountService({
    id: formData.id,
    onSuccess: (msg) => {
      if (msg.success) {
        enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('bankAccount') + ' ' + t('deleted') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
      }
      refetch(); // This will use the updated pageNo and pageSize
      if (tableData?.pages[0].data.content.length === 1) {
        handleChangePage(pageNo - 1)
      }
    },
    onError: (err: any) => {
      console.log("err====,  ", err);
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
    },
  });

  const handleDelete = () => {
    setDeleteModal(false);
    setLoading(true);
    onDeleteBankAccount()
  }
  const cancelDelete = () => {
    setDeleteModal(false)
  }

  const clearSearch = () => {
    setFilter(['accountTitle']);
    setSearchFormData({
      accountTitle: '',
      bankName: '',
      accountNumber: '',
    });
    setSearchErrorMessages({});
    if(Object.keys(search).length) {
      setLoadingSearch(true);
      setSearch({});
    }
  } 

  const handleChangeFilter = (event: SelectChangeEvent<typeof filter>) => {
    const { target: { value }, } = event;
    setFilter(typeof value === 'string' ? value.split(',') : value,);
    setSearchFormData(prevFormData => 
      Object.fromEntries(Object.keys(prevFormData).map(key => 
        [key, value.includes(key) ? prevFormData[key] : '']
      ))
    );
    if(!value.length && Object.keys(search).length) {
      clearSearch();
    }
  };
  const handleChangeSearch = (field: string, value: any) => {
    const val = value;

    setSearchFormData((prevData) => ({
      ...prevData,
      [field]: val,
    }));

    if (filter.includes(field) && !val) {
      setSearchErrorMessages((prevErrors) => ({
        ...prevErrors,
        [field]: 'This field is required.',
      }));
    } else {
      setSearchErrorMessages((prevErrors) => ({
        ...prevErrors,
        [field]: undefined,
      }));
    }
  };

  const validateSearchFormData = (data: SearchFormData) => {
    const errors: Partial<SearchFormData> = {};

    filter.forEach(fieldName => {
      if (!data[fieldName]) { 
        errors[fieldName] = t('required');
      }
    });

    return errors;
  };
  const handleSubmitSearch = () => {
    const errors = validateSearchFormData(searchFormData);
    setSearchErrorMessages(errors);

    if (Object.keys(errors).length === 0) {
      setLoadingSearch(true);
      const filteredSearch: Record<string, string> = {};

      Object.entries(searchFormData).forEach(([key, value]) => {
          if (value) {
            filteredSearch[key] = value?.trim();
          }
      });

      setSearch(filteredSearch);
      setPageNo(1);
      setPageSize(pageSize);
    }
  };

  const onChangeStatus = (row: any) => {
    row.active ? setStatusText('deactivate') : setStatusText('activate');
    setFormData(row);
    setStatusModal(true);
  }
  const cancelChangeStatus = () => {
    setStatusModal(false);
  }
  const submitChangeStatus = () => {
    setStatusModal(false);
    setLoading(true);
    onChangeStatusBankAccount({});
  }
  const { onChangeStatusBankAccount } = useChangeStatusBankAccountService({
    id: formData.id,
    onSuccess: (msg) => {
      if (msg.success) {
        enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('bankAccount') + ' ' + t(statusText) + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
      }
      refetch();
    },
    onError: (err) => {
      console.error("===", err)
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
    },
    active: formData.active ? "inactive" : "active"
  });
  const showRowView = (row: any) => {
    onEdit(row);
  }
  const adjustActions = (actions: any[]) => {
    return checkPrivileges('ROLE_BANKACCOUNT_UPDATE') 
      ? (checkPrivileges('ROLE_BANKACCOUNT_ARCHIVE') 
          ? actions 
          : actions.filter(action => action.label !== 'delete')) 
      : (checkPrivileges('ROLE_BANKACCOUNT_ARCHIVE') 
          ? actions.filter(action => action.label !== 'edit') 
          : []);
  }

  return (
    <Box>
      <BankAccountComponent
        handleSecField={handleSecField}
        secondaryLanguage={secondaryLanguage}
        primaryLanguage={primaryLanguage}
        openModal={openModal}
        closeModal={openModal}
        // handleSearchClick={handleSearchClick}
        // handleInputChange={handleInputChange}
        handleDelete={handleDelete}
        cancelDelete={cancelDelete}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        formData={formData}
        handleChangePage={handleChangePage}
        handleChangeRows={handleChangeRows}
        onChangeStatus={onChangeStatus}
        cancelChangeStatus={cancelChangeStatus}
        submitChangeStatus={submitChangeStatus}
        showRowView={showRowView}
        columns={checkPrivileges("ROLE_BANKACCOUNT_ACTIVE") ? [
          { id: 'accountTitle', label: 'accountTitle', numeric: false },
          { id: 'bankName', label: 'bankName', numeric: false },
          { id: 'accountNumber', label: 'accountNumber', numeric: false },
          { id: 'active', label: 'status', numeric: false },
          { id: 'actions', label: 'actions', numeric: false },
        ] : [
          { id: 'accountTitle', label: 'accountTitle', numeric: false },
          { id: 'bankName', label: 'bankName', numeric: false },
          { id: 'accountNumber', label: 'accountNumber', numeric: false },
          { id: 'actions', label: 'actions', numeric: false },
        ]}
        data={tableData?.pages[0] || {}}
        {...{ openForm, openDeleteModal, enableEdit, pageNo, pageSize, statusModal, statusText, isBtnEnabled, loading, search, loadingSearch, showSecAccountTitleField, showSecBankNameField,filter }}
        errorMessages={errorMessages}
        searchFormData={searchFormData}
        handleChangeFilter={handleChangeFilter}
        handleChangeSearch={handleChangeSearch}
        handleSubmitSearch={handleSubmitSearch}
        searchErrorMessages={searchErrorMessages}
        clearSearch={clearSearch}
        actions1={adjustActions([
          { label: "edit", onClick: onEdit, icon: <EditIcon /> },
          { label: "delete", onClick: onDelete, icon: <DeleteIcon /> },
        ])}
      />
    </Box>
  )
}