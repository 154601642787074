import { selectUserInfo } from "features/user/userSlice";
import { Button, Grid, TextField } from "@mui/material"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ItemGroupProfileService } from "subModule/src/core/services";
import AlertMessages from "common/Alert/AlertMessages";
import { useSnackbar } from "notistack";

interface FormData { [key: string]: any;}
interface ItemGroupProps {
    setAddNew?: any;
    itemGroupsRefetch?: any;
}
export default function ItemGroupModal(props: ItemGroupProps) {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { register, handleSubmit, formState: { errors } } = useForm<FormData>({defaultValues: {}});
    const loggedInUserData = useSelector(selectUserInfo);
    const primaryLanguage = loggedInUserData?.primaryLanguage;
    const secondaryLanguage = loggedInUserData?.secondaryLanguage;
    const onSubmit = (data: any) => {
        ItemGroupProfileService.createItemGroup(data).then((response: any) => {
            if(response.success) {
                props?.setAddNew(false);
                props?.itemGroupsRefetch();
                enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('itemGroup') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
            } else {
                enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
            }
        }).catch((err:any) => { 
            console.error("===", err);
            enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
        });
    };

    return (
        <Grid container component="form" id="form" className="items-baseline" spacing={3} onSubmit={handleSubmit(onSubmit)}>
            <Grid item xs={12} md={4}>
                <TextField 
                    {...register("title", { required: t('This field is required.') })} 
                    placeholder={t("title")}
                    label={`${!primaryLanguage ? t('primary') : ''} ${t('title')} ${primaryLanguage ? ` (${t(primaryLanguage)})` : ''} *`}
                    fullWidth
                    error={!!errors.title} 
                    helperText={errors.title?.message as string} 
                    InputLabelProps={{ shrink: true }}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField 
                    {...register("secondaryTitle")} 
                    placeholder={t("secondary") + ' ' + t('title')}
                    label={`${!secondaryLanguage ? t('secondary') : ''} ${t('title')} ${secondaryLanguage ? ` (${t(secondaryLanguage)})` : ''}`}
                    fullWidth
                    error={!!errors.secondaryTitle} 
                    helperText={errors.secondaryTitle?.message as string} 
                    InputLabelProps={{ shrink: true }}
                />
            </Grid>
            <Grid item xs={12}>
                <div className="flex gap-2 justify-end">
                    <Button type="submit" className="primary" variant="contained" disableElevation onClick={() => handleSubmit(onSubmit)}>{t('submit')}</Button>
                </div>
            </Grid>
        </Grid>
    )
}