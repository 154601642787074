import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
// import email from "assets/Modal/emaill.png";
import { Box, FormControl, Grid, MenuItem, Select, Typography } from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import Loader from "layout/Loader";

interface ActivityLogCardsProps {
  data: any[];
  cardsData? :any;
  handleChangePage: (field: number) => void;
  handleChangeRows: (field: number) => void;
  pageNo: number;
  pageSize: number;
  headerComponent?:React.ReactNode,
  loading: boolean;
}
const ActivityLogCards: React.FC<ActivityLogCardsProps> = ({
  data,
  cardsData,
  handleChangePage,
  handleChangeRows,
  pageSize,
  headerComponent,
  loading
}) => {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(pageSize);
  const { t, i18n } = useTranslation();
  const handleChangePage1 = (action:string) => {
    if (cardsData) {
      if (action === "first") {
        if (cardsData?.pageable?.pageNumber > 0) {
         handleChangePage(0);
        }
      } else if (action === "prev") {
        if (cardsData.pageable && cardsData?.pageable?.pageNumber > 0) {
          handleChangePage(cardsData?.pageable?.pageNumber - 1);
        }
      } else if (action === "next") {
        if (!cardsData?.last) {
          handleChangePage(cardsData?.pageable?.pageNumber + 1);
        }
      } else if (action === "last") {
        if (!cardsData?.last) {
          handleChangePage(cardsData?.totalPages-1);
        }
      }
    }
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    handleChangeRows(parseInt(event.target.value))
    setPage(0);
  };
  const visibleRows = useMemo(() => {
    return data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [data, page, rowsPerPage]);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box sx={{ margin: '12px 0 0 0' }}>{headerComponent}</Box>
      {loading ? <Loader /> :
        <>
          {visibleRows.length === 0 ? (
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: 'center', background: '#fff', borderRadius: '8px', margin: '10px 0', padding: '20px' }}>
              <Typography className="font-Saira italic text-primary-color font-[500] text-[15px]">{t('noRecordFound')}</Typography>
            </Box>
            ) : (visibleRows.map((row, index) => {
            return (
              <Grid 
                container 
                key={index}
                sx={{ background: '#fff', borderRadius: '8px', margin: '10px 0', padding: '8px 20px', cursor: 'pointer' }}
              >
                <Grid container xs={12} sm={6}>
                  <Grid xs={12} className="text-left">
                    <Typography className="font-Saira text-black font-[600] text-[14px]">
                      {t(row?.itemName)} 
                      <span className="text-primary-color font-[600] ms-1">{t(row?.attributes?.jobCardNo || row?.attributes?.workOrderNo || row?.attributes?.invoiceNo || row?.attributes?.paymentNo || row?.attributes?.creditNoteNo || '')}</span>
                    </Typography>
                  </Grid>
                  <Grid xs={12} className="text-left pr-5">
                    <Typography className="font-Saira text-primary-color font-[500] text-[13px]">{t(row?.actionName)}</Typography>
                  </Grid>
                </Grid>
                <Grid container xs={12} sm={6}>
                    <Grid xs={12} className="text-end">
                        <span className="font-Saira italic text-txt-color font-[500] text-[10px]">{t('date') + '@'}</span>
                        <span className="font-Saira text-txt-color font-[600] text-[12px] ml-2">
                          {new Intl.DateTimeFormat(i18n.language, { year: 'numeric', month: 'long', day: 'numeric', 
                            hour: '2-digit', minute: '2-digit', second: '2-digit', 
                            numberingSystem: i18n.language === 'ur' || i18n.language === 'ar' ? 'arab' : 'latn' 
                          }).format(new Date(row?.actionTime))}
                        </span>
                      </Grid>
                    <Grid xs={12} className="text-end">
                        <span className="font-Saira italic text-txt-color font-[500] text-[10px]">{t('user') + ':'}</span>
                        <span className="font-Saira text-txt-color font-[600] text-[11px] ml-2">{row?.userEmail}</span>
                    </Grid>
                </Grid>
              </Grid>
          )}))}
          <Box sx={{ display: "flex", alignItems: "center", background: '#fff', borderRadius: '8px', margin: '10px 0', padding: '8px 20px' }}>
            <Box className="hidden-xs mr-2 font-Saira font-[400] text-[14px]">{t("rowsPerPage")}</Box>
            <Box sx={{ marginRight: 10, minWidth: 60 }}>
              <FormControl className='m-1 min-w-[70]' size="small">
                <Select
                  className='font-Saira font-[500] text-[14px] h-[25px] mt-[2px]'
                  value={cardsData && cardsData?.pageable?.pageSize || pageSize}
                  onChange={(event) => handleChangeRowsPerPage(event as React.ChangeEvent<HTMLInputElement>)}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {rowsPerPageOptions.map((option) => (
                    <MenuItem className='font-Saira font-[500] text-[14px]' key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ flex: 1 }}></Box>
            <Box
              className='mr-2 whitespace-nowrap max-w-[300] font-Saira font-[400] text-[14px]'
            >
              {t("page")}
              <strong className='font-Saira ml-2 font-[700] text-[15px]' >{cardsData && cardsData.pageable ? cardsData.pageable.pageNumber + 1 : 1}</strong>{" "}
              <Box className="visible-responsive">
              </Box>
            </Box>
            <Box className="hidden-responsive font-Saira mr-2 font-[400] text-[14px]">
              {t("outOf")}
              <strong className='ml-2 font-Saira font-[700] text-[15px]'>{cardsData && cardsData.totalPages ? cardsData.totalPages : 1}</strong>
            </Box>
            <Box className="hidden-responsive font-Saira mr-2 font-[400] text-[14px]">
              {t("totalRecords")}
              <strong className='ml-2 font-Saira font-[700] text-[15px]'>{cardsData && cardsData.totalElements ? cardsData.totalElements : 0}</strong>
            </Box>
            <Box className='mr-2'>
              <FirstPageIcon
                fontSize="small"
                onClick={() => handleChangePage1("first")}
                sx={{
                  color: (
                    cardsData?.content?.length && cardsData?.content?.length !== 0 && !cardsData.first
                  ) ? "#1F2020" : "#B8C0C6",
                  cursor: (
                    cardsData?.content?.length && cardsData?.content?.length !== 0 && !cardsData.first
                  ) ? "pointer" : "text",
                }}
              />
            </Box>
            <Box className='mr-2'>
              <NavigateBeforeIcon
                fontSize="small"
                onClick={() => handleChangePage1("prev")}
                sx={{
                  color: (
                    cardsData?.content?.length && cardsData?.content?.length !== 0 && !cardsData.first
                  ) ? "#1F2020" : "#B8C0C6",
                  cursor: (
                    cardsData?.content?.length && cardsData?.content?.length !== 0 && !cardsData.first
                  ) ? "pointer" : "text",
                }}
              />
            </Box>
            <Box className='mr-2'>
              <NavigateNextIcon
                fontSize="small"
                onClick={() => handleChangePage1("next")}
                sx={{
                  color: (
                    cardsData?.content?.length && cardsData?.content?.length !== 0 && !cardsData.last
                  ) ? "#1F2020" : "#B8C0C6",
                  cursor: (
                    cardsData?.content?.length && cardsData?.content?.length !== 0 && !cardsData.last
                  ) ? "pointer" : "text",
                }}
              />
            </Box>
            <Box className='mr-2'>
              <LastPageIcon
                fontSize="small"
                onClick={() => handleChangePage1("last")}
                sx={{
                  color: (
                    cardsData?.content?.length && cardsData?.content?.length !== 0 && !cardsData.last
                  ) ? "#1F2020" : "#B8C0C6",
                  cursor: (
                    cardsData?.content?.length && cardsData?.content?.length !== 0 && !cardsData.last
                  ) ? "pointer" : "text",
                }}
              />
            </Box>
          </Box>
        </>}
    </Box>
  );
};

export default ActivityLogCards;
const rowsPerPageOptions = [5, 10, 15, 20];