import { Box, Grid, Typography } from "@mui/material";
import BackendPaginationTable from "common/Table/TableWithPagination";
import { ReactComponent as Inspection } from "assets/SideBarIcons/inspection.svg";
import InspectionSectionModalComponent from "./InspectionSectionModal";
import FormDialog from "common/Modal/Modal";
import { useTranslation } from 'react-i18next';
import StyledInput from "common/SearchField/Search";
import Loader from "layout/Loader";
import { checkPrivileges } from "Helpers/CheckPrivileges";
import ISHeader from "./ISHeader";

interface InspectionSectionComponentProps {
  openModal: () => void;
  handleSecField: () => void;
  closeModal: () => void;
  primaryLanguage: string;
  secondaryLanguage: string;
  handleChange: (field: string, value: any) => void;
  handleChangePage: (field: number) => void;
  handleChangeRows: (field: number) => void;
  handleSubmit: () => void;
  handleDelete: () => void;
  cancelDelete: () => void;
  onChangeStatus: (row: any) => void;
  cancelChangeStatus: () => void;
  submitChangeStatus: () => void;
  statusModal: boolean;
  loadingSearch: boolean;
  search: string;
  handleSearchClick: () => void;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  formData: any;
  openForm: boolean;
  showIFISecField: boolean;
  columns: any[];
  data: any;
  errorMessages: any,
  statusText?: string;
  actions1: { label: string; onClick: (value: any) => void; icon?: React.ReactNode }[];
  openDeleteModal: boolean;
  enableEdit?: boolean;
  pageNo: number;
  pageSize: number;
  isBtnEnabled: boolean;
  loading: boolean;
  showRowView: (row: any) => void;
  clearSearch: () => void;
}

const InspectionSectionComponent: React.FC<InspectionSectionComponentProps> = ({
  openModal,
  closeModal,
  handleChange,
  handleSubmit,
  primaryLanguage,
  showIFISecField,
  secondaryLanguage,
  formData,
  openForm,
  columns,
  handleSecField,
  data,
  errorMessages,
  actions1,
  openDeleteModal,
  statusModal,
  handleDelete,
  loadingSearch,
  cancelDelete,

  enableEdit,
  handleSearchClick,
  handleInputChange,
  search,
  handleChangePage,
  handleChangeRows,
  pageNo,
  onChangeStatus,
  cancelChangeStatus,
  submitChangeStatus,
  pageSize,
  isBtnEnabled,
  loading,
  showRowView,
  clearSearch
}) => {
  const { t } = useTranslation();

  return (
    <Box>
      <ISHeader
        showBtn={openForm ? false : true}
        showSaveBtn={openForm}
        onClick={openModal}
        onSubmit={handleSubmit}
        heading={!enableEdit && openForm ? "addInspectionSection" : enableEdit && openForm ? "editInspectionSection" : t('InspectionSection')}
        icon={<Inspection height={28} width={28} />}
        btnText={openForm ? "back" : "create"}
        btnType={openForm ? "back" : "create"}
        isBtnEnabled={isBtnEnabled}
        showCreateBtn={checkPrivileges("ROLE_INSPECTIONFORM_SECTION_CREATE")}
      // btnType="create"
      />

      {openForm ?
        <InspectionSectionModalComponent
          enableEdit={enableEdit}
          openModal={openModal}
          closeModal={closeModal}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          secondaryLanguage={secondaryLanguage}
          primaryLanguage={primaryLanguage}
          handleSecField={handleSecField}
          showIFISecField={showIFISecField}
          formData={formData}
          openForm={openForm}
          errorMessages={errorMessages}
          isBtnEnabled={isBtnEnabled}
        />
        : <Box>
          {loading ? <Loader /> :
            <BackendPaginationTable
              columns={actions1.length ? columns : columns.filter(action => action.id !== 'actions')}
              data={data.data?.content || []}
              pageNo={pageNo}
              pageSize={pageSize}
              tableData={data?.data || {}}
              showCheckbox={false}
              showActions={true}
              enableSorting={true}
              onChangeStatus={onChangeStatus}
              showHeader={true}
              showSearch={true}
              showFilter={true}
              showCreateButton={true}
              actions={actions1}
              handleChangePage={handleChangePage}
              handleChangeRows={handleChangeRows}
              rowClick={checkPrivileges("ROLE_INSPECTIONFORM_SECTION_UPDATE")}
              showRowView={showRowView}
              loadingSearch={loadingSearch}
              headerComponent={
                <Grid container spacing={2}>
                  <Grid item xs={12} md={8} lg={6}>
                    <StyledInput
                      fullWidth
                      placeholder={t("title")}
                      handleSearchClick={handleSearchClick}
                      handleChange={handleInputChange}
                      value={search}
                      clearSearch={clearSearch}
                    />
                  </Grid>
                </Grid>
              }
            />
          }
        </Box>
      }

      {openDeleteModal &&
        <FormDialog
          open={openDeleteModal}
          onClose={cancelDelete}
          title={t('delete') + ' ' + t('InspectionSection')}
          // icon={<div><Home/></div>}
          content={
            <Grid container >
              <Grid item xs={12} sm={12} md={12} lg={12}  >
                <Typography>{t('Are you sure you want to delete?')}</Typography>
              </Grid>
            </Grid>
          }
          saveFunction={handleDelete}
          submitButtonText="Delete"
        />}

      {statusModal &&
        <FormDialog
          open={statusModal}
          onClose={cancelChangeStatus}
          title="Change Status"
          // icon={<div><Home/></div>}
          content={
            <Grid container >
              <Grid item xs={12} sm={12} md={12} lg={12}  >
                <Typography>
                  {t(`areYouSure`)}?
                </Typography>
              </Grid>
            </Grid>
          }
          saveFunction={submitChangeStatus}
          submitButtonText="OK"
        />
      }


    </Box>

  )
}

export default InspectionSectionComponent;