import DynamicSelect from "common/Select/Select";
import { Box, Button, Divider, FormControlLabel, Grid, Radio, RadioGroup, Step, StepLabel, Stepper, TextField, Tooltip, Typography } from "@mui/material"
import { Controller, useForm } from "react-hook-form"
import { ReactComponent as ModalBtn } from "assets/Modal/ModalBtn.svg"
import BasicDatePicker from "common/Pickers/Pickers";
import CommonCheckbox from "common/CheckBox/Checkbox";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import { InventoryItemsTable } from "components/Inventory/InventoryItemsTable";
import { useSnackbar } from "notistack";
import AlertMessages from "common/Alert/AlertMessages";
import { useGetVendorService } from "subModule/src/services/vendor/useVendor";
import { useGetStoreService } from "subModule/src/services/store/useStore";
import { useGetTaxProcedureDropdownService } from "subModule/src/services/taxProcedure/useTaxProcedure";
import { InventoryProfileService, VendorProfileService } from "subModule/src/core/services";
import { GetVendorByIdResponse } from "subModule/src/services/vendor/interface";
import { InventoryServicesTable } from "components/Inventory/InventoryServicesTable";
import { useGetItemsService } from "subModule/src/services/items/useItems";
import { useGetServiceService } from "subModule/src/services/service/useService";
import { selectUserInfo } from "features/user/userSlice";
import { useSelector } from "react-redux";
import { NewPurchaseOrderResponse } from "subModule/src/services/inventory/interface";
import { useTranslation } from "react-i18next";
import { checkPrivileges } from "Helpers/CheckPrivileges";
import Vendors from "components/Inventory/Vendors";
import FormDialog from "common/Modal/Modal";
import Stores from "components/Inventory/Stores";
import TaxProcedures from "components/Inventory/TaxProcedures";

interface FormData {
    [key: string]: any;
}

export default function NewPurchase() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const loggedInUserCurrency = useSelector(selectUserInfo)?.currency;
    const { enqueueSnackbar } = useSnackbar();
    const { control, register, handleSubmit, setValue, formState: { errors, isSubmitSuccessful }, watch, resetField } = useForm<FormData>({defaultValues: {
        purchaseOrderItems: [],
        purchaseOrderSvc: []
    }});
    const { data: vendorsData, refetch: vendorsRefetch } = useGetVendorService({ pageNumber: 1, size: 1000 });
    const { data: storesData, refetch: storesRefetch } = useGetStoreService({ pageNumber: 1, size: 1000 });
    const { data: taxProceduresData, refetch: taxProceduresRefetch } = useGetTaxProcedureDropdownService({ pageNumber: 1, size: 1000 });
    const { data: itemsData, refetch: itemsRefetch } = useGetItemsService({ pageNumber: 1, size: 8000 });
    const { data: servicesData, refetch: servicesRefetch } = useGetServiceService({ pageNumber: 1, size: 500 });
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [confirm, setConfirm] = useState<boolean>(false);
    const [purchaseOrderItems, setPurchaseOrderItems] = useState<any[]>([]);
    const [purchaseOrderSvc, setPurchaseOrderSvc] = useState<any[]>([]);
    const [addNew, setAddNew] = useState<string>('');
    const [taxPercentage, setTaxPercentage] = useState<number>(0);

    useEffect(() => {
        vendorsRefetch();
        storesRefetch();
        taxProceduresRefetch();
        itemsRefetch();
        servicesRefetch();
    }, []);
    
    const getVendor = (vendorId: number | null) => {
        if(vendorId) {
            VendorProfileService.getVendorById(vendorId).then((res: GetVendorByIdResponse) => {
                setValue('phone', (res?.data as any).phoneNumber || '');
                setValue('address', (res?.data as any).address || '');
            }).catch((err:any) => { console.error("===", err); });
        }
    }
    const supplierOptions = (vendorsData?.pages[0]?.data?.content || []).map((item: { id: number, title: string }) => ({ value: item.id, label: item.title }));
    const storeOptions = (storesData?.pages[0]?.data?.content || []).map((item: { id: number, title: string }) => ({ value: item.id, label: item.title }));
    const taxProcedureOptions = (taxProceduresData?.pages[0]?.data?.content || []).map((item: { id: number, title: string, percentageValue?: number }) => ({ value: item.id, label: item.title, percentageValue: item.percentageValue }));
    const columns = [
        { id: 'itemId' },
        { id: 'itemCode'},
        { id: 'unitId' },
        { id: 'itemName', label: t('Item') + ' *', numeric: false, disablePadding: false, type: 'text' },
        { id: 'supplierSKU', label: t('SKU'), numeric: false, disablePadding: false, type: 'text' },
        { id: 'quantity', label: t('Quantity'), numeric: false, disablePadding: false, type: 'number' },
        { id: 'unitTitle' },
        { id: 'unitPrice', label: t('Cost'), numeric: false, disablePadding: false, type: 'number' },
        { id: 'discount', label: t('Discount'), numeric: false, disablePadding: false, type: 'number' },
        { id: 'total', label: t('Total') },
    ];
    const columnsService = [
        { id: 'serviceId' },
        { id: 'serviceCode'},
        { id: 'serviceName', label: t('service') + ' *', numeric: false, disablePadding: false, type: 'text' },
        { id: 'quantity', label: t('Quantity'), numeric: false, disablePadding: false, type: 'number' },
        { id: 'unitPrice', label: t('Cost'), numeric: false, disablePadding: false, type: 'number' },
        { id: 'discount', label: t('Discount'), numeric: false, disablePadding: false, type: 'number' },
        { id: 'total', label: t('Total') },
    ];
    const DeleteItem = (row: any, typeName: string) => {
        if(typeName === "purchaseOrderItems")  setPurchaseOrderItems((prevRows) => prevRows.filter((rows) => rows.id !== row.id)); 
        else setPurchaseOrderSvc((prevRows) => prevRows.filter((rows) => rows.id !== row.id));
    }
    const addNewItem = (typeName: string) => {
        if(typeName === "purchaseOrderItems") {
            const newItem = columns.reduce((acc, column) => ({ ...acc, [column.id]: column.type === 'number' ? 0 : '' }), {});
            (newItem as any).id = Date.now();
            setPurchaseOrderItems((prevRows) => [...prevRows, newItem]);
        } else {
            const newService = columnsService.reduce((acc, column) => ({ ...acc, [column.id]: column.type === 'number' ? 0 : '' }), {});
            (newService as any).id = Date.now();
            setPurchaseOrderSvc((prevRows) => [...prevRows, newService]);
        }
    };

    const handleInputChange = (index: number, columnId: string, value: any, typeName: string, type: string) => {
        if(typeName === "purchaseOrderItems") {
            const updatedItems = [...purchaseOrderItems];
            updatedItems[index][columnId] = type === 'number' ? parseFloat(value) : value;
            setPurchaseOrderItems(updatedItems);
        } else {
            const updatedServices = [...purchaseOrderSvc];
            updatedServices[index][columnId] = value;
            setPurchaseOrderSvc(updatedServices);
        }
    };
    const onSubmit = (data: FormData) => {
        const filteredItems = purchaseOrderItems.filter((item: any) => item?.itemName);
        const filteredServices = purchaseOrderSvc.filter((service: any) => service?.serviceName);
        if(filteredItems.length === 0 && filteredServices.length === 0) {
            enqueueSnackbar(<AlertMessages text="Please add at least one item or service" />, { variant: 'error' });
            return;
        }
        setConfirm(true);
        handleSubmission({...data, purchaseOrderItems: filteredItems, purchaseOrderSvc: filteredServices});
    };
    const handleSubmission = (po:any) => {
        delete po.typeName;
        po.purchaseOrderItems.every((row:any) => delete row.total && delete row.id);
        po.purchaseOrderItems.every((row:any) => delete row.unitTitle);
        po.purchaseOrderSvc.every((row:any) => delete row.total && delete row.id);
        InventoryProfileService.savePurchaseOrder({ ...po, 
            supplierId: parseInt?.(po.supplierId?.value) || null,
            storeId: parseInt?.(po.storeId?.value) || null,
            taxProcedureId: parseInt?.(po?.taxProcedureId?.value) || null,
            stockType: parseInt(po.stockType)
        }).then((response: NewPurchaseOrderResponse) => {
            if(response.success) {
                navigate(`/inventory/purchase/${response.data.id}`);
                setConfirm(false);
                enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Purchase Order') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
            } else {
                setConfirm(false);
                enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
            }
        }).catch((err:any) => { 
            console.error("===", err);
            setConfirm(false);
            enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
        });
    }
    const calculateTotals = () => {
        const itemTotals = purchaseOrderItems.reduce((acc, item) => {
            return {
                unitPrice: parseFloat(acc.unitPrice) + parseFloat(item.unitPrice || 0),
                subtotal: (parseFloat(acc.subtotal) + (parseFloat(item.unitPrice || 0) * parseFloat(item.quantity) || 0)),
                discount: parseFloat(acc.discount) + parseFloat(item.discount || 0),
                total: parseFloat(acc.total) + ((parseFloat(item.unitPrice || 0) * parseFloat(item.quantity) || 0)) - parseFloat(item.discount || 0),
            };
        }, { unitPrice: 0, subtotal: 0, discount: 0, total: 0 });
    
        const serviceTotals = purchaseOrderSvc.reduce((acc, service) => {
            return {
                unitPrice: parseFloat(acc.unitPrice) + parseFloat(service.unitPrice || 0),
                subtotal: (parseFloat(acc.subtotal) + (parseFloat(service.unitPrice || 0) * parseFloat(service.quantity) || 0)),
                discount: parseFloat(acc.discount) + parseFloat(service.discount || 0),
                total: parseFloat(acc.total) + ((parseFloat(service.unitPrice || 0) * parseFloat(service.quantity) || 0)) - parseFloat(service.discount || 0),
            };
        }, { unitPrice: 0, subtotal: 0, discount: 0, total: 0 });
    
        return {
            subtotal: itemTotals.subtotal + serviceTotals.subtotal,
            discount: itemTotals.discount + serviceTotals.discount,
            taxableTotal: itemTotals.total,
            total: itemTotals.total + serviceTotals.total,
        };
    };
    const totals = calculateTotals();

    return (
        <Grid container component="form" id="form" className="px-[8px] items-baseline" rowGap={2} columnGap={4} onSubmit={handleSubmit(onSubmit)}>
            <Grid container className="items-center">
                <Grid item xs={12} sm={6} className="text-start">
                    {isSubmitSuccessful ? <Typography className="font-Saira text-[12px] font-semibold text-primary-color">{t(steps[currentStep])}</Typography>
                        : <Typography className="font-Saira text-[12px] text-[#171826]">
                            <span>{t('Home')}</span>
                            <span> / {t('Purchase')} / </span>
                            <span className="font-semibold text-primary-color">{t('New Purchase')}</span>
                        </Typography>
                    }
                </Grid>
                <Grid item xs={12} sm={6} className="hide-on-print">
                    <div className="flex gap-2 justify-end">
                        <Button type="submit" disabled={confirm} className="primary" variant="contained" disableElevation onClick={() => onSubmit}>{t('submit')}</Button>
                        <Button className="secondary" variant="contained" disableElevation onClick={() => navigate('/inventory')}>{t('Back to list')}</Button>
                        {/* <Button className="secondary" variant="contained" disableElevation>{t('Tutorial')}</Button> */}
                    </div>
                </Grid>
            </Grid>
            <Grid item xs={12}><Divider className="bg-[#AEB3C4] hide-on-print" /></Grid>
            {currentStep === 0 ? <>
                <Grid container item xs={12} md={4} lg={3} rowGap={2}>
                <Grid item xs={12}><Typography className="font-Saira text-primary-color text-[14px] leading-[16px] font-semibold text-left">{t('vendor')} {t("Details")}</Typography></Grid>
                <Grid item xs={12} className='grid' sx={{ gridTemplateColumns: '85% 10%' }}>
                    <Controller
                        name="supplierId"
                        control={control}
                        defaultValue={null}
                        rules={{ required: t('This field is required.') }}
                        render={({ field }) => (
                            <DynamicSelect
                                label={t('vendors')}
                                {...field}
                                options={supplierOptions}
                                isClearable
                                isSearchable
                                onChange={(selectedOption: any) => {field.onChange(selectedOption); getVendor(selectedOption.value)}}
                                error={errors.supplierId?.message as string}
                            />
                        )}
                    />
                    {checkPrivileges('ROLE_VENDOR_CREATE') && <Tooltip title={'Add New'}>
                            <ModalBtn onClick={() => setAddNew('vendors')} className='mt-5 mx-2 mb-2 cursor-pointer h-[38px]'/>
                        </Tooltip>
                    }
                    {addNew !== '' && <FormDialog
                            open={addNew !== ''}
                            onClose={(event, reason) => event && reason !== 'backdropClick' ? setAddNew('') : ''}
                            title={addNew === 'vendors' ? t('addVendor') : addNew === 'stores' ? t('addStores') : t('addTaxProcedure')}
                            createPopup={true}
                            fullWidth
                            hideActions={true}
                            content={addNew === 'vendors' ? 
                                <Vendors setAddNew={setAddNew} vendorsRefetch={vendorsRefetch} /> 
                                : addNew === 'stores' ? <Stores setAddNew={setAddNew} storesRefetch={storesRefetch} />
                                : <TaxProcedures setAddNew={setAddNew} taxProceduresRefetch={taxProceduresRefetch} />
                            }
                        />
                    }
                </Grid>
                <Grid item xs={12}>
                    <TextField 
                        type="text"
                        {...register("phone", {
                            validate: {
                                phoneLength: (value) =>
                                    value === '' || (value.length >= 5 && value.length <= 17) || t('phoneLength'),
                                nonNegative: (value) =>
                                    value === '' || /^[\p{N}]+$/u.test(value) || t('onlyPositiveNumbers'),
                            },
                        })}
                        placeholder={t("Contact")}
                        label={t("Contact")}
                        fullWidth
                        error={!!errors.phone} 
                        helperText={errors.phone?.message as string} 
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField 
                        {...register("address")} 
                        placeholder={t("Vendor Address")}
                        label={t("Vendor Address")}
                        fullWidth
                        error={!!errors.address} 
                        helperText={errors.address?.message as string} 
                        InputLabelProps={{ shrink: true }}
                        // sx={{ '& .MuiFormHelperText-contained': { bottom: '-11px !important' } }}
                    />
                </Grid>
                </Grid>
                <Grid container item xs={12} md={4}  lg={3} rowGap={2}>
                    <Grid item xs={12}><Typography className="font-Saira text-primary-color text-[14px] leading-[16px] font-semibold text-left">{t('Accounting')} {t("Details")}</Typography></Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="stockType"
                            control={control}
                            rules={{ required: t('This field is required.') }}
                            render={({ field }) => (<>
                                <label className="text-left block text-[#F1F6F9] m-0">Stock Type</label>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    {...field}
                                >
                                    <FormControlLabel value={4} control={<Radio />} label={t('Stock First')} sx={{ '& .MuiTypography-root': { position: 'relative', top: '-8px' } }} />
                                    <FormControlLabel value={5} control={<Radio />} label={t('Payment First')} sx={{ '& .MuiTypography-root': { position: 'relative', top: '-8px', whiteSpace: 'nowrap', width: '100px' } }} />
                                </RadioGroup>
                                {errors.stockType?.message && (
                                    <Typography color="error" variant="caption" className="font-Saira text-[12px] leading-[18px] font-medium italic text-left block text-[#EF5350] absolute mt-[-10px]">
                                        {errors.stockType?.message as string}
                                    </Typography>
                                )}
                            </>)}
                        />
                    </Grid>
                    <Grid item xs={12} className='grid' sx={{ gridTemplateColumns: '85% 10%' }}>
                        <Controller
                            name="storeId"
                            control={control}
                            defaultValue={null}
                            rules={{ required: t('This field is required.') }}
                            render={({ field }) => (
                                <DynamicSelect
                                    label={t("stores")}
                                    {...field}
                                    options={storeOptions}
                                    isClearable
                                    isSearchable
                                    onChange={(selectedOption: any) => field.onChange(selectedOption)}
                                    error={errors.storeId?.message as string}
                                />
                            )}
                        />
                        {checkPrivileges('ROLE_STORE_CREATE') && <Tooltip onClick={() => setAddNew('stores')} title={'Add New'}><ModalBtn className='mt-5 mx-2 mb-2 cursor-pointer h-[38px]'/></Tooltip>}
                    </Grid>
                    <Grid item xs={12} className="mt-[-9px]">
                        <Controller
                            name="taxable"
                            control={control}
                            defaultValue={false}
                            render={({ field }) => (
                                <CommonCheckbox
                                    {...field}
                                    label={t("Tax Exclusive")}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        field.onChange(event.target.checked);
                                        if(!(event.target.checked)) {resetField('taxProcedureId'); setTaxPercentage(0);}
                                    }}
                                    checked={field.value}
                                    color="success"
                                />
                            )}
                        />
                    </Grid>
                    {watch("taxable") ? <Grid item xs={12} className='grid mt-[-22px]' sx={{ gridTemplateColumns: '85% 10%' }}>
                        <Controller
                            name="taxProcedureId"
                            control={control}
                            defaultValue={null}
                            rules={{ required: watch("taxable") ? "This field is required." : false }}
                            render={({ field }) => (
                                <DynamicSelect
                                    label={t("Tax Procedures")}
                                    {...field}
                                    options={taxProcedureOptions}
                                    isClearable
                                    isSearchable
                                    onChange={(selectedOption: any) => { field.onChange(selectedOption); setTaxPercentage(selectedOption.percentageValue / 100) }}
                                    error={errors.taxProcedureId?.message as string}
                                />
                            )}
                        />
                        {checkPrivileges('ROLE_TAXPROCEDURE_CREATE') && <Tooltip onClick={() => setAddNew('taxprocedures')} title={'Add New'}><ModalBtn className='mt-5 mx-2 mb-2 cursor-pointer h-[38px]'/></Tooltip>}
                    </Grid> : null}
                </Grid>
                <Grid container item xs={12} md={4} lg={3} rowGap={2}>
                    <Grid item xs={12}><Typography className="font-Saira text-primary-color text-[14px] leading-[16px] font-semibold text-left">{t('Shipping')} {t("Details")}</Typography></Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="poDate"
                            control={control}
                            rules={{ validate: (value) => {
                                const date = new Date(value);
                                return isNaN(date.getTime()) ? t("Invalid Date") : true;
                            } }}
                            render={({ field }) => (
                                <BasicDatePicker
                                    type="datePicker"
                                    {...field}
                                    label="Date"
                                    fullWidth
                                    onChange={(value: any) => field.onChange(new Date(value).toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })?.split('/').reverse().join('-'))}
                                    required={false}
                                    error={errors.poDate?.message as string}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField 
                            {...register("terms")} 
                            placeholder={t("Terms")}
                            label={t("Terms")}
                            fullWidth
                            error={!!errors.terms} 
                            helperText={errors.terms?.message as string} 
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={9} className="mt-[-20px]">
                    <TextField 
                        {...register("comments")} 
                        placeholder={t("Comments")} 
                        label={t("Comments")}
                        fullWidth
                        error={!!errors.comments} 
                        helperText={errors.comments?.message as string}
                        InputLabelProps={{ shrink: true }}
                        multiline
                        rows={2}
                    />
                </Grid>
            </> : ''}
            <Grid container><Grid item xs={12}>
                <NewPurchaseSteps form={{ control, register, handleSubmit, setValue, errors, isSubmitSuccessful, watch, resetField }} addNewItem={addNewItem} handleInputChange={handleInputChange} items={itemsData?.pages[0]?.data?.content || []} services={servicesData?.pages[0]?.data?.content || []} DeleteItem={DeleteItem} {...{currentStep, setCurrentStep, columns, columnsService, purchaseOrderItems, purchaseOrderSvc, loggedInUserCurrency, t}} />
            </Grid></Grid>
            <Typography className="font-Saira text-[12px] font-semibold text-[#171826] text-left mb-[-5px]">{t('Purchase Order Memo')}</Typography>
            <Grid container xs={12} spacing={1}>
                <Grid item xs={12} md={6}>
                    <TextField 
                        {...register("poMemo")} 
                        placeholder={t('Purchase Order Memo')} 
                        fullWidth
                        error={!!errors.poMemo} 
                        helperText={errors.poMemo?.message as string}
                        InputLabelProps={{ shrink: true }}
                        multiline
                        rows={5}
                        sx={{ '& .MuiInputBase-fullWidth.MuiInputBase-multiline': { width: '100%' }}}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box className="bg-[#D8D1E0] rounded-[8px]" sx={{ border: '1px solid #5f6770', width: 'calc(100% + 8px)', padding: '8px 14px' }}>
                        <Box className="flex justify-end gap-12 mt-[9px]">
                            <Typography className="font-Saira text-[12px] text-[#3A3A3A] w-[auto]">{t('Before Discount')}</Typography>
                            <Typography className="font-Saira text-[12px] text-[#3A3A3A] w-[20%] text-end">{new Intl.NumberFormat('en-US', {}).format(totals.subtotal)}</Typography>
                        </Box>
                        <Box className="flex justify-end gap-12 mt-[9px]">
                            <Typography className="font-Saira text-[12px] text-[#3A3A3A] w-[auto]">{t('Discount')}</Typography>
                            <Typography className="font-Saira text-[12px] text-[#3A3A3A] w-[20%] text-end">{new Intl.NumberFormat('en-US', {}).format(totals.discount)}</Typography>
                        </Box>
                        <Box className="flex justify-end gap-12 mt-[9px]">
                            <Typography className="font-Saira text-[12px] text-[#3A3A3A] w-[auto]">{t('tax') + ` (${taxPercentage * 100}%)`}</Typography>
                            <Typography className="font-Saira text-[12px] text-[#3A3A3A] w-[20%] text-end">{new Intl.NumberFormat('en-US', {}).format((totals.taxableTotal * taxPercentage) || 0)}</Typography>
                        </Box>
                        <Box className="flex justify-end gap-12 mt-[9px]">
                            <Typography className="font-Saira text-[12px] text-[#3A3A3A] font-semibold w-[auto]">{t('Total')}</Typography>
                            <Typography className="font-Saira text-[12px] text-[#3A3A3A] font-semibold w-[20%] text-end"><span className="uppercase">({loggedInUserCurrency})</span> {new Intl.NumberFormat('en-US', {}).format(totals.total + (totals.taxableTotal * taxPercentage))}</Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Grid item xs={12} className="hide-on-print"><Divider className="bg-[#AEB3C4] mt-[4px]" /></Grid>
            <Grid container className="items-center hide-on-print">
                <Grid item xs={12} sm={6} className="text-start"></Grid>
                <Grid item xs={12} sm={6}>
                    <div className="flex gap-2 justify-end">
                        <Button type="submit" disabled={confirm} className="primary" variant="contained" disableElevation onClick={() => onSubmit}>{t('submit')}</Button>
                        <Button className="secondary" variant="contained" disableElevation onClick={() => navigate('/inventory')}>{t('Back to list')}</Button>
                        {/* <Button className="secondary" variant="contained" disableElevation>{t('Tutorial')}</Button> */}
                    </div>
                </Grid>
            </Grid>
        </Grid>
    )
}

const steps = ['Purchase Order', 'Payment', 'Stock Received', 'Debit Note', 'Unstock'];

interface NewPurchaseStepsProps {
    form: FormData;
    addNewItem: (typeName: string) => void;
    handleInputChange: (index: number, columnId: string, value: any, typeName: string, type: string) => void;
    currentStep: number;
    setCurrentStep: any;
    columns: any[];
    columnsService: any[];
    purchaseOrderItems: any[];
    purchaseOrderSvc: any[];
    items: any[];
    services: any[];
    loggedInUserCurrency?: string;
    DeleteItem: (row: any, typeName: string) => void;
    t?:any;
}

export const NewPurchaseSteps: React.FC<NewPurchaseStepsProps> = ({ form, addNewItem, handleInputChange, currentStep, columns, columnsService, purchaseOrderItems, purchaseOrderSvc, items, services, loggedInUserCurrency, DeleteItem, t }) => {
    return (<>
        <Stepper className='hide-on-print' activeStep={currentStep} connector={<></>}>
            {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {};
                const labelProps: { optional?: React.ReactNode; } = {};
                return (
                    <Step key={index} {...stepProps}><StepLabel {...labelProps}>{t(label)}</StepLabel></Step>
                );
            })}
        </Stepper>
        <Box className="bg-white py-[12px] px-[18px] rounded-[8px] mt-[16px]">
            <Typography className="font-Saira text-[12px] font-semibold text-[#171826] text-left">{t(steps[currentStep])}</Typography>
            <InventoryItemsTable form={form} columns={columns} handleInputChange={handleInputChange} rows={purchaseOrderItems} typeName="purchaseOrderItems" items={items} currency={loggedInUserCurrency} DeleteItem={DeleteItem} />
            <Grid item xs={12} sm={6} className="hide-on-print">
                <div className="flex gap-2 justify-start">
                    <Button className="secondary flex gap-1" variant="contained" disableElevation onClick={() => addNewItem('purchaseOrderItems')}><AddIcon className="w-[20px] h-[20px]" /> {t('Add More Items')}</Button>
                    {/* <Button className="secondary" variant="contained" disableElevation>{t('Import')}</Button>
                    <Button className="secondary" variant="contained" disableElevation>{t("Export")}</Button> */}
                </div>
            </Grid>
        </Box>
        <Box className="flex gap-2 justify-start mt-[16px]">
            <Button className="secondary cursor-text" variant="contained" disableElevation>{t('Additional Costs')}</Button>
        </Box>
        <Box className="bg-white py-[12px] px-[18px] rounded-[8px] mt-[16px]">
            <Typography className="font-Saira text-[12px] font-semibold text-[#171826] text-left">{t('Additional Cost')}</Typography>
            <InventoryServicesTable form={form} columns={columnsService} handleInputChange={handleInputChange} rows={purchaseOrderSvc} typeName="purchaseOrderSvc" services={services} currency={loggedInUserCurrency}  DeleteItem={DeleteItem} />
            <Grid item xs={12} sm={6} className="hide-on-print">
                <div className="flex gap-2 justify-start">
                    <Button className="secondary flex gap-1" variant="contained" disableElevation onClick={() => addNewItem('purchaseOrderSvc')}><AddIcon className="w-[20px] h-[20px]" /> {t('Add More Items')}</Button>
                </div>
            </Grid>
            <Grid item xs={12}><Divider className="bg-[#AEB3C4] mt-[15px] mb-[8px]" /></Grid>
        </Box>
    </>);
}
