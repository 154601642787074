import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Call } from "assets/Modal/call.svg";
// import email from "assets/Modal/emaill.png";
import { Box, FormControl, Grid, MenuItem, Select, Typography, useMediaQuery } from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import car from "assets/Images/white-car.png";
import truck from "assets/Images/truck-container.png";
import van from "assets/Images/van-white.png";
import jeep from "assets/Images/jeep.png";
import others from "assets/Images/othervehicle.png";
import Loader from "layout/Loader";
import PersonIcon from '@mui/icons-material/Person';

interface CardWithPaginationProps {
  data: any[];
  cardsData? :any;
  handleChangePage: (field: number) => void;
  handleChangeRows: (field: number) => void;
  pageNo: number;
  pageSize: number;
  showRowView: (row: any) => void;
  actions?: { label: string; onClick: (value: any) => void; icon?: React.ReactNode }[];
  rowClick: boolean;
  headerComponent?:React.ReactNode,
  loadingSearch: boolean;
  type: string;
}

const CardWithPagination: React.FC<CardWithPaginationProps> = ({
  data,
  cardsData,
  handleChangePage,
  handleChangeRows,
  // pageNo,
  pageSize,
  showRowView,
  // actions,
  rowClick,
  headerComponent,
  loadingSearch,
  type
}) => {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(pageSize);

  const { t, i18n } = useTranslation();
  const isScreen900pxOrGreater = useMediaQuery('(min-width:900px)');

  const handleChangePage1 = (action:string) => {
    if (cardsData) {
      if (action === "first") {
        if (cardsData?.pageable?.pageNumber > 0) {
         handleChangePage(0);
        }
      } else if (action === "prev") {
        if (cardsData.pageable && cardsData?.pageable?.pageNumber > 0) {
          handleChangePage(cardsData?.pageable?.pageNumber - 1);
        }
      } else if (action === "next") {
        if (!cardsData?.last) {
          handleChangePage(cardsData?.pageable?.pageNumber + 1);
        }
      } else if (action === "last") {
        if (!cardsData?.last) {
          handleChangePage(cardsData?.totalPages-1);
        }
      }
    }
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    handleChangeRows(parseInt(event.target.value))
    setPage(0);
  };

  const visibleRows = useMemo(() => {
    return data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [data, page, rowsPerPage]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box sx={{ margin: '12px 0 0 0' }}>{headerComponent}</Box>
      {loadingSearch ? <Loader /> :
        <>
          {visibleRows.length === 0 ? (
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: 'center', background: '#fff', borderRadius: '8px', margin: '10px 0', padding: '20px' }}>
                      <Typography className="font-Saira italic text-primary-color font-[500] text-[15px]">{t('noRecordFound')}</Typography>
                    </Box>
                  ) : (visibleRows.map((row, index) => {
            return (
              <Grid 
                container 
                key={index} 
                onClick={() => {rowClick ? showRowView(row) : ''}}
                sx={{ background: '#fff', borderRadius: '8px', margin: '10px 0', padding: '8px 20px', cursor: 'pointer' }}
                className={type === 'jobCard' || type === 'workOrder' ? row?.active ? 'opacity-100' : 'opacity-50' : ''}
              >
                <Grid container xs={12} md={6}>
                  <Grid xs={12} sm={2}>
                    <img width={'100px'} className="rounded-[50%] p-[8px]"
                      src={row?.attributes?.vehicleType?.toLowerCase() === 'car' ? car 
                        : row?.attributes?.vehicleType?.toLowerCase() === 'truck' ? truck
                        : row?.attributes?.vehicleType?.toLowerCase() === 'van' ? van
                        : row?.attributes?.vehicleType?.toLowerCase() === 'jeep' ? jeep
                        // : !row?.attributes?.vehicleType ? ''
                        : others
                      }
                    />
                  </Grid>
                  <Grid container xs={12} sm={4}>
                    <Grid xs={12} className="text-left">
                      <Typography className="font-Saira text-black font-[600] line-clamp-1 text-[17px]">{row?.attributes?.makeTitle}-{row?.attributes?.modelTitle}{row?.attributes?.year ? '-' + row?.attributes?.year : ''}</Typography>
                    </Grid>
                    <Grid xs={12} className="text-left pr-5">
                      <div className="h-[40px] border-2 rounded-md px-2 border-primary-color flex items-center justify-center bg-[#E5E5E5] ">
                        <Typography className="font-Saira text-primary-color line-clamp-1 font-[700] text-[15px]">{row?.vehiclePlateNo}</Typography>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container xs={12} sm={6} rowGap={1} className={`relative ${isScreen900pxOrGreater && 'left-[32%]'}`}>
                    <Grid xs={12} className="text-left">
                      <Typography className="font-Saira italic text-txt-color font-[400] text-[15px]">
                        {t(type) + ' ' + t('number') + ' : '} 
                        <span className="font-[700]">{row[`${type}No`]}</span>
                      </Typography>
                    </Grid>
                    <Grid container xs={12} rowGap={1} className="text-left">
                      <Grid item container xs={8}>
                        <Grid xs={12} sx={{ lineHeight: 0 }}>
                          <Typography className="font-Saira flex italic text-txt-color font-[500] text-[12px] items-center ms-[-3px]">
                            {<PersonIcon className="mr-1 w-[18px] text-[#000]" />} {row?.attributes?.customerName}
                          </Typography>
                        </Grid>
                        <Grid container xs={12}>
                          <Typography className="font-Saira flex italic text-txt-color font-[500] text-[12px]">
                            {<Call height={'12px'} className="mt-[2px] mr-1" width={'12px'} />} {row?.attributes?.customerPhone}
                          </Typography>
                        </Grid>
                      </Grid>
                      {type === 'jobCard' || type === 'workOrder' ? <Grid item xs={4} className="flex gap-3 items-center relative top-[-10px]">
                        <Typography className="font-Saira italic text-txt-color font-[400] text-[15px]">{t('status') + ':'}</Typography>
                        <div className={(row?.active ? 'status-active' : 'status-inactive') + " px-3 py-1 rounded-lg"}>
                          <Typography className="font-Saira text-[15px]">{row?.active ? t("open") : t("closed")}</Typography>
                        </div>
                      </Grid> : null}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container xs={12} sm={6}>
                  <Grid xs={12} className="text-right">
                    <span className="font-Saira italic text-txt-color font-[500] text-[15px]">{t('vehicleNumber') + ':'}</span>
                    <span className="font-Saira italic text-black font-[700] text-[15px] ml-2">{row?.vehicleVin}</span>
                  </Grid>
                  <Grid container xs={12} className="text-right">
                    <Grid xs={12}>
                      <Grid xs={12}>
                        <span className="font-Saira italic text-txt-color font-[500] text-[10px]">{t('station') + ':'}</span>
                        <span className="font-Saira text-txt-color font-[600] text-[10px] ml-2">{row?.attributes?.stationName}</span>
                      </Grid>
                      <Grid xs={12}>
                        <span className="font-Saira italic text-txt-color font-[500] text-[10px]">{t('created') + ':'}</span>
                        <span className="font-Saira text-txt-color font-[600] text-[12px] ml-2">
                          {new Intl.DateTimeFormat(i18n.language, { year: 'numeric', month: 'long', day: 'numeric', 
                            hour: '2-digit', minute: '2-digit', second: '2-digit', 
                            numberingSystem: i18n.language === 'ur' || i18n.language === 'ar' ? 'arab' : 'latn' 
                          }).format(new Date(row?.createdAt))}
                        </span>
                      </Grid>
                    </Grid>
                    {/* <Grid container xs={12} sm={3} className="mt-[7px]">
                      {actions?.map((option) => (
                        <Grid xs={6}>
                          <Box onClick={(e) => {e.stopPropagation(); option.onClick(row);}}>{option.icon}</Box>
                        </Grid>
                      ))}
                    </Grid> */}
                  </Grid>
                </Grid>
              </Grid>
          )}))}
          <Box sx={{ display: "flex", alignItems: "center", background: '#fff', borderRadius: '8px', margin: '10px 0', padding: '8px 20px' }}>
            <Box className="hidden-xs mr-2 font-Saira font-[400] text-[14px]">{t("rowsPerPage")}</Box>
            <Box sx={{ marginRight: 10, minWidth: 60 }}>
              <FormControl className='m-1 min-w-[70]' size="small">
                <Select
                  className='font-Saira font-[500] text-[14px] h-[25px] mt-[2px]'
                  value={cardsData && cardsData?.pageable?.pageSize || pageSize}
                  onChange={(event) => handleChangeRowsPerPage(event as React.ChangeEvent<HTMLInputElement>)}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {rowsPerPageOptions.map((option) => (
                    <MenuItem className='font-Saira font-[500] text-[14px]' key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ flex: 1 }}></Box>

            <Box
              className='mr-2 whitespace-nowrap max-w-[300] font-Saira font-[400] text-[14px]'
            >
              {t("page")}
              <strong className='font-Saira ml-2 font-[700] text-[15px]' >{cardsData && cardsData.pageable ? cardsData.pageable.pageNumber + 1 : 1}</strong>{" "}
              <Box className="visible-responsive">
              </Box>
            </Box>
            <Box className="hidden-responsive font-Saira mr-2 font-[400] text-[14px]">
              {t("outOf")}
              <strong className='ml-2 font-Saira font-[700] text-[15px]'>{cardsData && cardsData.totalPages ? cardsData.totalPages : 1}</strong>
            </Box>
            <Box className="hidden-responsive font-Saira mr-2 font-[400] text-[14px]">
              {t("totalRecords")}
              <strong className='ml-2 font-Saira font-[700] text-[15px]'>{cardsData && cardsData.totalElements ? cardsData.totalElements : 0}</strong>
            </Box>
            <Box className='mr-2'>
              <FirstPageIcon
                fontSize="small"
                onClick={() => handleChangePage1("first")}
                sx={{
                  color: (
                    cardsData?.content?.length && cardsData?.content?.length !== 0 && !cardsData.first
                  ) ? "#1F2020" : "#B8C0C6",
                  cursor: (
                    cardsData?.content?.length && cardsData?.content?.length !== 0 && !cardsData.first
                  ) ? "pointer" : "text",
                }}
              />
            </Box>
            <Box className='mr-2'>
              <NavigateBeforeIcon
                fontSize="small"
                onClick={() => handleChangePage1("prev")}
                sx={{
                  color: (
                    cardsData?.content?.length && cardsData?.content?.length !== 0 && !cardsData.first
                  ) ? "#1F2020" : "#B8C0C6",
                  cursor: (
                    cardsData?.content?.length && cardsData?.content?.length !== 0 && !cardsData.first
                  ) ? "pointer" : "text",
                }}
              />
            </Box>
            <Box className='mr-2'>
              <NavigateNextIcon
                fontSize="small"
                onClick={() => handleChangePage1("next")}
                sx={{
                  color: (
                    cardsData?.content?.length && cardsData?.content?.length !== 0 && !cardsData.last
                  ) ? "#1F2020" : "#B8C0C6",
                  cursor: (
                    cardsData?.content?.length && cardsData?.content?.length !== 0 && !cardsData.last
                  ) ? "pointer" : "text",
                }}
              />
            </Box>
            <Box className='mr-2'>
              <LastPageIcon
                fontSize="small"
                onClick={() => handleChangePage1("last")}
                sx={{
                  color: (
                    cardsData?.content?.length && cardsData?.content?.length !== 0 && !cardsData.last
                  ) ? "#1F2020" : "#B8C0C6",
                  cursor: (
                    cardsData?.content?.length && cardsData?.content?.length !== 0 && !cardsData.last
                  ) ? "pointer" : "text",
                }}
              />
            </Box>
          </Box>
        </>}
    </Box>
  );
};

export default CardWithPagination;
const rowsPerPageOptions = [5, 10, 15, 20, 25, 50];
