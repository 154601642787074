import SimpleModal from 'common/Form/Form';
import StyledField from 'common/TextField/TexField1';
import { Grid } from '@mui/material';
import DynamicSelect from 'common/Select/Select';
import { useTranslation } from 'react-i18next';

interface TaxProcedureModalComponentProps {
  primaryLanguage: string;
  openModal: () => void;
  closeModal: () => void;
  handleChange: (field: string, value: any) => void;
  handleSubmit: () => void;
  formData: any;
  openForm: boolean;
  errorMessages: { [key: string]: string };
  enableEdit?: boolean;
  isBtnEnabled: boolean;
}
const TaxProcedureType = [
  { value: 1, label: 'Normal' },
  { value: 2, label: 'Part Default' },
  { value: 3, label: 'Service Default' },
  { value: 4, label: 'Package Default' },
];

const TaxProcedureModalComponent: React.FC<TaxProcedureModalComponentProps> = ({
  closeModal,
  handleChange,
  handleSubmit,
  formData,
  primaryLanguage,
  openForm,
  enableEdit,
  errorMessages,
  isBtnEnabled
}) => {


  const { t } = useTranslation();
  const handleScroll = () => {
    // Handle scroll logic here
   
};

const handleSearch = () => {
    // Handle search logic here
  
}
  return (
    <div>
      <SimpleModal
        visable={openForm}
        // title={enableEdit ? "updateTaxProcedure" : "addTaxProcedure"}
        isButtonVisable={true}
        isVisableBtn={isBtnEnabled}
        btnclosetext="Back"
        buttonText={enableEdit ? "update" : "submit"}
        attBtnNotshow={false}
        notVisableBackbtn={true}
        showPortList={handleSubmit} // Assuming this is the Submit button
        modalControle={closeModal}
        // attributeChangesMessage="Attribute changes message"
        // showPort={true}
        formSubmit={handleSubmit}
        content={
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}  >
              <StyledField
                label={`${!primaryLanguage ? t('primary') : ''} ${t('title')} ${primaryLanguage ? ` (${t(primaryLanguage)})` : ''}`}
                // search='language'
                placeholder="Write your Title here"
                handleChange={(e) => handleChange('title', e.target.value)}
                required={true}
                fullWidth
                error={errorMessages?.title}
                value={formData?.title || ''}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}  >
            <DynamicSelect
                defaultValue={enableEdit ? TaxProcedureType.find((option: any) => option.value === formData?.type) : null}
                value={TaxProcedureType.find((option: any) => option.value === formData?.type)}
                isDisabled={false}
                isLoading={false}
                isClearable={true}
                isRtl={false}
                // fullWidth={false}
                isSearchable={true}
                name="color"
                options={TaxProcedureType}
                onScroll={handleScroll}
                onInputChange={handleSearch}
                // isMulti
                isRequired={true}
                onChange={(e) => handleChange('type', e)}
                error={errorMessages?.typeError}
                label="type"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}  >
            <StyledField
                 type='number'
                label="percentageValue"
                placeholder="Write percentageValue here"
                handleChange={(e) => handleChange('percentageValue', e.target.value)}
                required={true}
                fullWidth
                error={errorMessages?.percentageValueError}
                value={formData?.percentageValue || 0}
              />
            </Grid>
          </Grid>
        }
      />
    </div>
  );
};

export default TaxProcedureModalComponent;