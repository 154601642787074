import { Box, Grid, Typography } from "@mui/material";
import BackendPaginationTable from "common/Table/TableWithPagination";
import { ReactComponent as Station } from "assets/SideBarIcons/station.svg";
import ComponentHeaderTwo from "common/ComponentHeaderTwo/ComponentHeaderTwo";
import StationModalComponent from "./StationModal";
import FormDialog from "common/Modal/Modal";
import { useTranslation } from 'react-i18next';
import StyledInput from "common/SearchField/Search";
import VerticalTabs from 'common/ComponentSidebar/ComponentSideBar';
import Loader from "layout/Loader";
import StationBankAccountSearchHeader from "./StationBankAccountSearchHeader";
import { checkPrivileges } from "Helpers/CheckPrivileges";
import StationUserSearchHeader from "./StationUserSearchHeader";

interface StationComponentProps {
  handleSecField: () => void;
  showSecField: boolean;
  primaryLanguage:string;
  secondaryLanguage:string;
  openModal: () => void;
  closeModal: () => void;
  handleChange: (field: string, value: any) => void;
  handleChangePage: (field: number) => void;
  handleChangeRows: (field: number) => void;
  handleSubmit: () => void;
  handleDelete: () => void;
  cancelDelete: () => void;
  search: string;
  handleSearchClick: () => void;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeStatus: (row: any) => void;
  cancelChangeStatus: () => void;
  submitChangeStatus: () => void;
  statusModal: boolean;
  statusText?: string;
  formData: any;
  priceListData: any;
  businessGroupData: any;
  openForm: boolean;
  columns: any[];
  data: any;
  errorMessages: any,
  actions1: { label: string; onClick: (value: any) => void; icon?: React.ReactNode }[];
  openDeleteModal: boolean;
  enableEdit?: boolean;
  pageNo: number;
  pageSize: number;
  storeAssignData: any;
  warehouseAssignData: any;
  userAssignData: any;
  bayAssignData: any;
  bankAccountAssignData: any;
  showRowView: (row: any) => void;
  rowView: boolean;
  handleChangeTabs: (tabId: number) => void;
  assignUnassignSingleEntity: (isChecked: boolean, row: any) => void;
  assignUnassignAllEntities: (isChecked: boolean) => void;
  allStoresAssigned: unknown;
  allWarehousesAssigned: unknown;
  allUsersAssigned: unknown;
  allBaysAssigned: unknown;
  allBankAccountsAssigned: unknown;
  isBtnEnabled: boolean;
  loading: boolean;
  loadingSearch: boolean;
  searchAssign: string;
  loadingSearchAssign: boolean;
  tab:number;
  searchAssignFormData: any;
  handleChangeAssignFilter: (event: any) => void;
  handleChangeSearchAssign: (field: string, value: any) => void;
  handleSubmitSearchAssign: () => void;
  searchAssignErrorMessages: any;
  filterAssign: string[];
  clearSearchAssign: () => void;
  searchMultiAssign: any;
  userTypesOption: any;
  clearSearch: () => void;
}

const StationComponent: React.FC<StationComponentProps> = ({
  showSecField,
  handleSecField,
  primaryLanguage,
  secondaryLanguage,
  openModal,
  closeModal,
  handleChange,
  handleSubmit,
  formData,
  openForm,
  columns,
  data,
  priceListData,
  businessGroupData,
  errorMessages,
  actions1,
  openDeleteModal,
  handleDelete,
  cancelDelete,
  enableEdit,
  handleSearchClick,
  handleInputChange,
  search,
  handleChangePage,
  handleChangeRows,
  onChangeStatus,
  cancelChangeStatus,
  submitChangeStatus,
  statusModal,

  pageNo,
  pageSize,
  storeAssignData,
  warehouseAssignData,
  userAssignData,
  bayAssignData,
  bankAccountAssignData,
  showRowView,
  rowView,
  handleChangeTabs,
  assignUnassignSingleEntity,
  assignUnassignAllEntities,
  allStoresAssigned,
  allWarehousesAssigned,
  allUsersAssigned,
  allBaysAssigned,
  isBtnEnabled,
  loading,
  loadingSearch,
  allBankAccountsAssigned,
  searchAssign,
  loadingSearchAssign,
  tab,
  searchAssignFormData,
  searchMultiAssign,
  handleChangeAssignFilter,
  handleChangeSearchAssign,
  handleSubmitSearchAssign,
  searchAssignErrorMessages,
  clearSearchAssign,
  filterAssign,
  userTypesOption,
  clearSearch
}) => {
  const { t } = useTranslation();

  const storeColumns = [
    { id: 'title', label: 'storeName', numeric: false },
    { id: 'storeNumber', label: 'storeNumber', numeric: false },
  ]
  const warehouseColumns = [
    { id: 'title', label: 'title', numeric: false },
  ]
  const usersColumns = [
    { id: 'name', label: 'name', numeric: false },
    { id: 'username', label: 'email', numeric: false },
    { id: 'type', label: 'type', numeric: false },
  ]
  const baysColumns = [
    { id: 'title', label: 'title', numeric: false },
  ]
  const bankAccountsColumns = [
    { id: 'accountTitle', label: 'accountTitle', numeric: false },
    { id: 'bankName', label: 'bankName', numeric: false },
    { id: 'accountNumber', label: 'accountNumber', numeric: false },
  ]

  const childTabContent = (columns: any, data: any, tableData: any, allEntitiesAssigned: unknown, assignPrivilege: string, unassignPrivilege: string) => {
    return (
      <BackendPaginationTable
        columns={columns}
        data={data}
        pageNo={pageNo}
        pageSize={pageSize}
        tableData={tableData}
        showCheckbox={true}
        showActions={true}
        enableSorting={true}
        showHeader={true}
        showSearch={true}
        showFilter={true}
        showCreateButton={false}
        actions={[]}
        handleChangePage={handleChangePage}
        handleChangeRows={handleChangeRows}
        assignUnassignSingleEntity={assignUnassignSingleEntity}
        assignUnassignAllEntities={assignUnassignAllEntities}
        allEntitiesAssigned={allEntitiesAssigned}
        loadingSearch={loadingSearchAssign}
        assignPrivilege={checkPrivileges(assignPrivilege)}
        unassignPrivilege={checkPrivileges(unassignPrivilege)}
        headerComponent={tab === 3 ? <StationUserSearchHeader 
            searchAssignFormData={searchAssignFormData}
            handleChangeAssignFilter={handleChangeAssignFilter}
            handleChangeSearchAssign={handleChangeSearchAssign}
            handleSubmitSearchAssign={handleSubmitSearchAssign}
            searchAssignErrorMessages={searchAssignErrorMessages}
            clearSearchAssign={clearSearchAssign}
            searchAssign={searchMultiAssign}
            {...{filterAssign, userTypesOption}}
          /> : tab === 5 ? <StationBankAccountSearchHeader 
            searchAssignFormData={searchAssignFormData}
            handleChangeAssignFilter={handleChangeAssignFilter}
            handleChangeSearch={handleChangeSearchAssign}
            handleSubmitSearch={handleSubmitSearchAssign}
            searchErrorMessages={searchAssignErrorMessages}
            clearSearchAssign={clearSearchAssign}
            search={searchMultiAssign}
            {...{filterAssign}}
          /> : (
            <Grid container spacing={2}>
              <Grid item xs={12} md={7} lg={5}>
                <StyledInput
                  fullWidth
                  placeholder={tab === 3 ? t("name") : tab === 1 ? t("storeName") : t("title")}
                  handleSearchClick={handleSearchClick}
                  handleChange={handleInputChange}
                  value={searchAssign}
                  clearSearch={clearSearch}
                />
              </Grid>
            </Grid>
        )}
      />
    );
  }
  const tabs = [
    {
      id: 0, title: `edit`,
      child: <StationModalComponent
        enableEdit={enableEdit}
        openModal={openModal}
        closeModal={closeModal}
        handleChange={handleChange}
        handleSecField={handleSecField}
        showSecField={showSecField}
        secondaryLanguage={secondaryLanguage}
        primaryLanguage={primaryLanguage}
        handleSubmit={handleSubmit}
        formData={formData}
        openForm={true}
        errorMessages={errorMessages}
        isBtnEnabled={isBtnEnabled}
        priceListData={priceListData.data?.content || []}
        businessGroupData={businessGroupData.data?.content || []}
      />
    },
    { id: 1, title: `store`, child: childTabContent(storeColumns, storeAssignData.data?.content || [], storeAssignData?.data || {}, allStoresAssigned, 'ROLE_STATION_STORE_ASSIGN', 'ROLE_STATION_STORE_UNASSIGN') },
    { id: 2, title: `warehouse`, child: childTabContent(warehouseColumns, warehouseAssignData.data?.content || [], warehouseAssignData?.data || {}, allWarehousesAssigned, 'ROLE_STATION_WAREHOUSE_ASSIGN', 'ROLE_STATION_WAREHOUSE_UNASSIGN') },
    { id: 3, title: `users`, child: childTabContent(usersColumns, userAssignData.data?.content || [], userAssignData?.data || {}, allUsersAssigned, 'ROLE_STATION_USER_ASSIGN', 'ROLE_STATION_USER_UNASSIGN') },
    { id: 4, title: `bay`, child: childTabContent(baysColumns, bayAssignData.data?.content || [], bayAssignData?.data || {}, allBaysAssigned, 'ROLE_STATION_BAY_ASSIGN', 'ROLE_STATION_BAY_UNASSIGN') },
    { id: 5, title: `bankAccount`, child: childTabContent(bankAccountsColumns, bankAccountAssignData.data?.content || [], bankAccountAssignData?.data || {}, allBankAccountsAssigned, 'ROLE_STATION_BANKACCOUNT_ASSIGN', 'ROLE_STATION_BANKACCOUNT_UNASSIGN') }
  ];
  const disableTabs = tabs.map(tab => tab.id).filter(tabId => {
    switch (tabId) {
      case 0:
        return !checkPrivileges('ROLE_STATION_UPDATE');
      case 1:
        return !checkPrivileges('ROLE_STATION_STORE_ASSIGN') && !checkPrivileges('ROLE_STATION_STORE_UNASSIGN');
      case 2:
        return !checkPrivileges('ROLE_STATION_WAREHOUSE_ASSIGN') && !checkPrivileges('ROLE_STATION_WAREHOUSE_UNASSIGN');
      case 3:
        return !checkPrivileges('ROLE_STATION_USER_ASSIGN') && !checkPrivileges('ROLE_STATION_USER_UNASSIGN');
      case 4:
        return !checkPrivileges('ROLE_STATION_BAY_ASSIGN') && !checkPrivileges('ROLE_STATION_BAY_UNASSIGN');
      case 5:
        return !checkPrivileges('ROLE_STATION_BANKACCOUNT_ASSIGN') && !checkPrivileges('ROLE_STATION_BANKACCOUNT_UNASSIGN');
      default:
        return false;
    }
  });

  return (

    <Box>
      <ComponentHeaderTwo
        showBtn={true}
        showSaveBtn={openForm}
        onClick={openModal}
        onSubmit={handleSubmit}
        heading={(openForm&&!enableEdit)?"addStations":(enableEdit&&openForm||rowView)?"editStations":t('stations')}
        icon={<Station height={28} width={28} />}
        btnText={openForm || rowView ? "back" : "create"}
        btnType={openForm || rowView ? "back" : "create"}
        // btnType="create"
        // priceListData={priceListData.data?.content || []}
        // businessGroupData={businessGroupData.data?.content || []}
        isBtnEnabled={isBtnEnabled}
        showCreateBtn={checkPrivileges("ROLE_STATION_CREATE")}
      />

      {openForm ?
        <StationModalComponent
          enableEdit={enableEdit}
          openModal={openModal}
          closeModal={closeModal}
          handleChange={handleChange}
          handleSecField={handleSecField}
          showSecField={showSecField}
          secondaryLanguage={secondaryLanguage}
          primaryLanguage={primaryLanguage}
          handleSubmit={handleSubmit}
          formData={formData}
          priceListData={priceListData.data?.content || []}
          businessGroupData={businessGroupData.data?.content || []}
          openForm={openForm}
          errorMessages={errorMessages}
          isBtnEnabled={isBtnEnabled}
        />
        : rowView ?
          <VerticalTabs tabs={tabs} selectedIndex={0} handleChangeTabs={handleChangeTabs} disableTabNo={disableTabs} />
          : <Box>
            {loading ? <Loader /> :
              <BackendPaginationTable
                columns={actions1.length ? columns : columns.filter(action => action.id !== 'actions')}
                data={data.data?.content || []}
                pageNo={pageNo}
                pageSize={pageSize}
                tableData={data?.data || {}}
                showCheckbox={false}
                showActions={true}
                enableSorting={true}
                showHeader={true}
                showSearch={true}
                showFilter={true}
                showCreateButton={true}
                actions={actions1}
                handleChangePage={handleChangePage}
                handleChangeRows={handleChangeRows}
                onChangeStatus={onChangeStatus}
                rowClick={disableTabs.length === tabs.length ? false : true}
                showRowView={showRowView}
                loadingSearch={loadingSearch}
                headerComponent={
                  <Grid item xs={12} md={6} lg={3}>
                    <Grid item md={3}>
                      <StyledInput
                        fullWidth
                        placeholder={t("name")}
                        handleSearchClick={handleSearchClick}
                        handleChange={handleInputChange}
                        value={search}
                        clearSearch={clearSearch}
                      />
                    </Grid>
                  </Grid>
                }
              />
            }
          </Box>
      }

      {openDeleteModal &&
        <FormDialog
          open={openDeleteModal}
          onClose={cancelDelete}
          title={t('delete') + ' ' + t('station')}
          // icon={<div><Home/></div>}
          content={
            <Grid container >
              <Grid item xs={12} sm={12} md={12} lg={12}  >
                <Typography>
                  {t('Are you sure you want to delete?')}
                </Typography>
              </Grid>
            </Grid>
          }
          saveFunction={handleDelete}
          submitButtonText="Delete"
        />}
      {statusModal &&
        <FormDialog
          open={statusModal}
          onClose={cancelChangeStatus}
          title="Change Status"
          // icon={<div><Home/></div>}
          content={
            <Grid container >
              <Grid item xs={12} sm={12} md={12} lg={12}  >
                <Typography>
                  {t(`areYouSure`)}?
                </Typography>
              </Grid>
            </Grid>
          }
          saveFunction={submitChangeStatus}
          submitButtonText="OK"
        />
      }

    </Box>

  )
}

export default StationComponent;