import { Box, Grid, Typography } from "@mui/material";
import BackendPaginationTable from "common/Table/TableWithPagination";
import { ReactComponent as Bay  } from "assets/SideBarIcons/bay.svg";
import ComponentHeaderTwo from "common/ComponentHeaderTwo/ComponentHeaderTwo";
import BayModalComponent from "./BayModal";
import FormDialog from "common/Modal/Modal";
import { useTranslation } from 'react-i18next';
import StyledInput from "common/SearchField/Search";
import Loader from "layout/Loader";
import { checkPrivileges } from "Helpers/CheckPrivileges";

interface BayComponentProps {
  handleSecField: () => void;
  showSecField: boolean;
  primaryLanguage:string;
  secondaryLanguage:string;
  openModal: () => void;
  closeModal: () => void;
  handleChange: (field: string, value: any) => void;
  handleChangePage: (field: number) => void;
  handleChangeRows: (field: number) => void;
  handleSubmit: () => void;
  handleDelete:  () => void;
  cancelDelete:  () => void;
  search: string;
  handleSearchClick: () => void;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeStatus: (row: any) => void;
  cancelChangeStatus: () => void;
  submitChangeStatus: () => void;
  statusModal: boolean;
  statusText?: string;
  formData: any;
  openForm: boolean;
  columns: any[]; 
  data: any; 
  errorMessages: any,
  actions1: { label: string; onClick: ( value: any) => void; icon?: React.ReactNode }[];
  openDeleteModal: boolean;
  enableEdit?:boolean;
  pageNo:number;
  pageSize:number;
  showRowView: (row: any) => void;
  isBtnEnabled: boolean;
  loading: boolean;
  loadingSearch: boolean;
  clearSearch: () => void;
}

const BayComponent: React.FC <BayComponentProps> = ({
  showSecField,
  handleSecField,
  primaryLanguage,
  secondaryLanguage,
  openModal,
  closeModal,
  handleChange,
  handleSubmit,
  formData,
  openForm,
  columns,
  data,
  errorMessages,
  actions1,
  openDeleteModal,
  handleDelete,
  cancelDelete,
  enableEdit,
  handleSearchClick,
  handleInputChange,
  search,
  handleChangePage,
  handleChangeRows,
  onChangeStatus,
  cancelChangeStatus,
  submitChangeStatus,
  statusModal,

  pageNo,
  pageSize,
  isBtnEnabled,
  loading,
  showRowView,
  loadingSearch,
  clearSearch
}) => {
  const { t } = useTranslation();

    return(

        <Box>
          <ComponentHeaderTwo
            showBtn={true}
            showSaveBtn={openForm}
            onClick={openModal}
            onSubmit={handleSubmit}
            heading={!enableEdit && openForm ? "addBay" : enableEdit && openForm ? "editBay" : t('bays')}
            icon={<Bay height={28} width={28} />}
            btnText={openForm ? "back" : "create"}
            btnType={openForm ? "back" : "create"}
            isBtnEnabled={isBtnEnabled}
            showCreateBtn={checkPrivileges("ROLE_BAY_CREATE")}
            // btnType="create"
          />

            {openForm ? 
            <BayModalComponent
              enableEdit={enableEdit}
              handleSecField={handleSecField}
              showSecField={showSecField}
              secondaryLanguage={secondaryLanguage}
              primaryLanguage={primaryLanguage}
              openModal={openModal}
              closeModal={closeModal}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              formData={formData}
              openForm={openForm}
              errorMessages={errorMessages}
              isBtnEnabled={isBtnEnabled}
            />
                  : <Box>
            {loading ? <Loader /> :
              <BackendPaginationTable
                columns={actions1.length ? columns : columns.filter(action => action.id !== 'actions')}
                data={data.data?.content || []}
                pageNo={pageNo}
                pageSize={pageSize}
                tableData={data?.data || {}}
                showCheckbox={false}
                showActions={true}
                enableSorting={true}
                showHeader={true}
                showSearch={true}
                showFilter={true}
                showCreateButton={true}
                actions={actions1}
                handleChangePage={handleChangePage}
                handleChangeRows={handleChangeRows}
                onChangeStatus={onChangeStatus}
                rowClick={checkPrivileges("ROLE_BAY_UPDATE")}
                showRowView={showRowView}
                loadingSearch={loadingSearch}
                headerComponent={
                  <Grid container spacing={2}>  
                    <Grid item xs={12} md={7} lg={5}>
                      <StyledInput
                        fullWidth
                        placeholder={t("title")}
                        handleSearchClick={handleSearchClick}
                        handleChange={handleInputChange}
                        value={search}
                        clearSearch={clearSearch}
                      />
                    </Grid>
                  </Grid>
                  }
              />
            }
          </Box>
                  }
      {openDeleteModal && 
            <FormDialog
                open={openDeleteModal}
                onClose={cancelDelete}
                title={t('delete') + ' ' + t('bay')}
                // icon={<div><Home/></div>}
                content={  
                    <Grid container >
                  <Grid item  xs={12} sm={12} md={12} lg={12}  >
                  <Typography>
                    {t('Are you sure you want to delete?')}
                  </Typography>
                  </Grid>
                  </Grid>
                  }
                  saveFunction={handleDelete}
                submitButtonText="Delete"
              />}
{statusModal && 
          <FormDialog
            open={statusModal}
            onClose={cancelChangeStatus}
            title="Change Status"
            // icon={<div><Home/></div>}
            content={  
              <Grid container >
                <Grid item  xs={12} sm={12} md={12} lg={12}  >
                  <Typography>
                  {t(`areYouSure`)}?
                  </Typography>
                </Grid>
              </Grid>
            }
            saveFunction={submitChangeStatus}
            submitButtonText="OK"
          />
        }


        </Box>

    )
}

export default BayComponent;