import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import AlertMessages from 'common/Alert/AlertMessages';
import { useTranslation } from 'react-i18next';
import { SelectChangeEvent } from '@mui/material';
import ComponentHeaderTwo from 'common/ComponentHeaderTwo/ComponentHeaderTwo';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import Loader from 'layout/Loader';
import { useGetActionsListService, useGetActivityLogsService } from 'subModule/src/services/activitylogs/useActivityLogs';
import ActivityLogCards from 'components/ActivityLogs/ActivityLogCards';
import ActivityLogsSearchHeader from 'components/ActivityLogs/ActivityLogsSearchHeader';

interface SearchFormData {
    [key: string]: string | any;
}

export default function ActivityLogsListing() {
    const [pageNo, setPageNo] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(5);
    const [loading, setLoading] = useState<boolean>(true);
    const [filter, setFilter] = useState<string[]>(['itemType']);
    const [search, setSearch] = useState<any>({});
    const [searchFormData, setSearchFormData] = useState<SearchFormData>({ itemType: 0 });
    const { data: tableData, refetch } = useGetActivityLogsService({ pageNumber: pageNo, size: pageSize, ...search });
    const { data: actionsListData, refetch: actionsListRefetch } = useGetActionsListService();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    useEffect(() => {
        const fetchData = async () => {
            await refetch();
            if (tableData) {
                setLoading(false);
                if ((tableData?.pages[0] as any).status && !(tableData?.pages[0] as any).success) {
                    enqueueSnackbar(<AlertMessages statusCode={(tableData?.pages[0] as any)?.status} />, { variant: 'error' })
                }
            }
        }
        fetchData();
    }, [pageNo, pageSize, tableData, search]);
    useEffect(() => {
        actionsListRefetch();
    }, []);
    const handleChangePage = (pageNo: number) => {
        setLoading(true);
        setPageNo(pageNo + 1);
        setPageSize(pageSize);
    }
    const handleChangeRows = (pageSize: number) => {
        setLoading(true);
        setPageSize(pageSize);
        setPageNo(1);
    }
    const clearSearch = () => {
        setFilter(['itemType']);
        setSearchFormData({ itemType: 0 });
        setLoading(true);
        setSearch({});
    }
    const handleChangeFilter = (event: SelectChangeEvent<typeof filter>) => {
        const { target: { value }, } = event;
        setFilter(typeof value === 'string' ? value.split(',') : value,);
        setSearchFormData(prevFormData => 
            Object.fromEntries(Object.keys(prevFormData).map(key => 
              [key, value.includes(key) ? prevFormData[key] : (typeof prevFormData[key] === 'string' ? '' : 0)]
            ))
        )
        if(!value.length && Object.keys(search).length) {
            clearSearch();
        }
    };
    const handleChangeSearch = (field: string, value: any) => {
        const val = field === 'itemType' ? (value ? parseInt(value.value) || 0 : 0) : value;
        setSearchFormData((prevData) => ({
            ...prevData,
            [field]: val,
        }));
    };
    const handleSubmitSearch = () => {
        setLoading(true);
        const filteredSearch: Record<string, string | number | boolean> = {};
        Object.entries(searchFormData).forEach(([key, value]) => {
            if (value) {
            filteredSearch[key] = typeof value === 'string' ? value?.trim() : value;
            }
        });
        setSearch(filteredSearch);
        setPageNo(1);
        setPageSize(pageSize);
    };
    return (
        <Box>
            <ComponentHeaderTwo
                showBtn={false}
                showSaveBtn={false}
                heading={t('ActivityLogs')}
                icon={<SummarizeOutlinedIcon sx={{ fill: '#000', width: '30px', height: '30px', marginTop: '3px' }} />}
                btnText={"create"}
                btnType={"create"}
                isBtnEnabled={false}
                showCreateBtn={false}
            />
            {loading ? <Loader /> : <ActivityLogCards 
                data={tableData?.pages[0]?.data?.content || []}
                cardsData={tableData?.pages[0]?.data || {}}
                handleChangePage={handleChangePage}
                handleChangeRows={handleChangeRows}
                {...{ pageNo, pageSize, loading }}
                headerComponent={
                  <ActivityLogsSearchHeader 
                    searchFormData={searchFormData}
                    handleChangeFilter={handleChangeFilter}
                    handleChangeSearch={handleChangeSearch}
                    handleSubmitSearch={handleSubmitSearch}
                    clearSearch={clearSearch}
                    actionsListData={actionsListData?.data[0] || {}}
                    {...{filter, search}}
                  />
                }
              />}
        </Box>
    )
}