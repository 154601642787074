import SimpleModal from 'common/Form/Form';
import StyledField from 'common/TextField/TexField1';
import {  Grid } from '@mui/material';
import StyledInputArea from 'common/TextArea/TextArea';
import { useTranslation } from 'react-i18next';
import ImageClickCoordinates from './ClickableImage';
import InspectionVehicle from "assets/inspection-vehicle.svg";
import DynamicSelect from 'common/Select/Select';
interface InspectionFormItemModalComponentProps {
  openModal: () => void;
  closeModal: () => void;
  handleSecField: () => void;
  showIFISecField: boolean;
  primaryLanguage:string;
  secondaryLanguage:string;
  handleChange: (field: string, value: any) => void;
  handleSubmit: () => void;
  formData: any;
  openForm: boolean;
  errorMessages:{ [key: string]: string };
  enableEdit?:boolean;
  isBtnEnabled: boolean;
  sectionsData: any;
}

const InspectionFormItemModalComponent: React.FC<InspectionFormItemModalComponentProps> = ({
  closeModal,
  handleChange,
  handleSubmit,
  formData,
  openForm,
  enableEdit,
  handleSecField,
  showIFISecField,
  primaryLanguage,
  secondaryLanguage,
  errorMessages,
  isBtnEnabled,
  sectionsData
}) => {
  const { t } = useTranslation();
  const sectionOptions = sectionsData.map((item: { id: number, title: string }) => ({ value: item.id, label: item.title }));
  return (
    <div>
      <SimpleModal
        visable={openForm}
        // title={enableEdit ? "updateVehicleInspection" : "addVehicleInspection"}
        isButtonVisable={true}
        isVisableBtn={isBtnEnabled}
        btnclosetext="Back"
        buttonText={enableEdit ? "update" : "submit"}
        attBtnNotshow={false}
        notVisableBackbtn={true}
        showPortList={handleSubmit} // Assuming this is the Submit button
        modalControle={closeModal}
        // attributeChangesMessage="Attribute changes message"
        // showPort={true}
        formSubmit={handleSubmit}
        content={
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}  >
              <StyledField
                label={`${!primaryLanguage ? t('primary') : ''} ${t('title')} ${primaryLanguage ? ` (${t(primaryLanguage)})` : ''}`}
                search='language'
                handleSecField={handleSecField}
                placeholder="Write your title here"
                handleChange={(e) => handleChange('title', e.target.value)}
                required={true}
                fullWidth
                error={errorMessages?.title}
                value={formData?.title || ''}
              />
              {showIFISecField && 
                <Grid item xs={12} sm={12} md={12} lg={12} >
                      <div style={{ position: 'relative' }}>
                        <div
                          style={{
                            position: 'absolute',
                            top: -8,
                            left: 0,
                            zIndex: 1,
                            display: showIFISecField ? 'flex' : 'none',
                            backgroundColor: "white",
                            height: '5rem',
                            width: '100%',
                            justifyContent:'center',
                            borderRadius: '8px',
                            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
                          }}
                        >
                          <div className='w-full mx-2 mt-1'>
                        <StyledField
                          label={`${!secondaryLanguage ? t('secondary') : ''} ${t('title')} ${secondaryLanguage ? ` (${t(secondaryLanguage)})` : ''}`}
                          placeholder="Write your secondaryTitle here"
                          handleChange={(e) => handleChange('secondaryTitle', e.target.value)}
                          required={false}
                          fullWidth
                          // error={errorMessages?.secondaryTitle}
                          value={formData?.secondaryTitle || ''}
                        />
                      </div>
                    </div>
                  </div>
                </Grid>
              }
              <DynamicSelect
                defaultValue={enableEdit ? sectionOptions.find((option: any) => option.value === formData?.manufacturer) : null}
                value={sectionOptions.find((option: any) => option.value === formData?.inspectionFormSectionId)}
                isDisabled={false}
                isLoading={false}
                isClearable={false}
                isRtl={false}
                // fullWidth={false}
                isSearchable={true}
                name="inspectionFormSectionId"
                options={sectionOptions}
                onScroll={() => {}}
                onInputChange={() => {}}
                // isMulti
                isRequired={false}
                onChange={(e) => handleChange('inspectionFormSectionId', e)}
                error={errorMessages?.inspectionFormSectionIdError}
                label="InspectionSection"
              />
            </Grid>
            <Grid item  xs={12} sm={6} md={4} lg={3}  >
                <StyledInputArea
                    fullWidth={true}
                    label="description"
                    // placeholder="Write your Description here"
                    rowsNo={1}
                    handleChange={(e) => handleChange('description', e.target.value)}
                    required={false}
                    // error={errorMessages?.description}
                    value={formData?.description || ''}
                />
            </Grid>
            <Grid xs={12} sm={6} md={4} lg={3}>
              <ImageClickCoordinates 
                src={InspectionVehicle} 
                alt='' 
                callback={(coordinates: number[]) => handleChange('coordinates', `${coordinates}`)} 
                value={enableEdit ? formData?.coordinates?.split(',')?.map(Number) || [149, 75] : [149, 75]}
              />
            </Grid>
          </Grid>
        }
      />
    </div>
  );
};

export default InspectionFormItemModalComponent;