// AccountSettingsProfileService.tsx
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { AccountSettingsProfileService } from '../../core/services';
import { PageSize } from '../../type';
import { useCallback } from 'react';
import { AssignUnassignInvoiceRequest, AssignUnassignResponse } from './interface';

type AssignServiceProps = {
  onSuccess: (data: AssignUnassignResponse) => void;
  onError: (err: unknown) => void;
};
type UnassignServiceProps = {
  onSuccess: (data: AssignUnassignResponse) => void;
  onError: (err: unknown) => void;
};
// type AssignAllServiceProps = {
//   onSuccess: (data: AssignUnassignAllResponse) => void;
//   onError: (err: unknown) => void;
// };
// type UnassignAllServiceProps = {
//   onSuccess: (data: AssignUnassignAllResponse) => void;
//   onError: (err: unknown) => void;
// };

export const useGetBusinessInvoiceService = (initialParams: PageSize, businessId: number) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-business-invoices-data'],
      queryFn: async ({ pageParam }) => {
        return AccountSettingsProfileService.getBusinessInvoice({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          businessId: businessId,
          title: initialParams.title,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => {
        return !res?.last ? res?.number + 1 : undefined;
      },
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useAssignBusinessInvoice = ({ onError, onSuccess }: AssignServiceProps) => {
  const {
    mutateAsync: assignService,
    isPending: isAssignServiceLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-business-invoices-request'],
    mutationFn: (req: AssignUnassignInvoiceRequest) => AccountSettingsProfileService.assignInvoice(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignService = useCallback(
    async (assignServiceData: AssignUnassignInvoiceRequest) => {
      try {
        assignService(assignServiceData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignService]
  );

  return {
    onAssignService,
    isAssignServiceLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignBusinessInvoice = ({ onError, onSuccess }: UnassignServiceProps) => {
  const {
    mutateAsync: unassignService,
    isPending: isUnassignServiceLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-business-invoices-request'],
    mutationFn: (req: AssignUnassignInvoiceRequest) => AccountSettingsProfileService.unassignInvoice(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignService = useCallback(
    async (unassignServiceData: AssignUnassignInvoiceRequest) => {
      try {
        unassignService(unassignServiceData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignService]
  );

  return {
    onUnassignService,
    isUnassignServiceLoading,
    data,
    error,
    isError,
  };
};

// export const useAssignAllBusinessInvoice = ({ onError, onSuccess }: AssignAllServiceProps) => {
//   const {
//     mutateAsync: assignAllServices,
//     isPending: isAssignAllServiceLoading,
//     data,
//     error,
//     isError,
//   } = useMutation({
//     mutationKey: ['assign-all-business-invoices-request'],
//     mutationFn: (req: AssignUnassignAllRequest) => AccountSettingsProfileService.assignAllInvoices(req),
//     onSuccess: onSuccess,
//     onError: onError,
//   });

//   const onAssignAllServices = useCallback(
//     async (assignAllServiceData: AssignUnassignAllRequest) => {
//       try {
//         assignAllServices(assignAllServiceData);
//       } catch (err) {
//         console.error(err);
//       }
//     },
//     [assignAllServices]
//   );

//   return {
//     onAssignAllServices,
//     isAssignAllServiceLoading,
//     data,
//     error,
//     isError,
//   };
// };

// export const useUnassignAllBusinessInvoice = ({ onError, onSuccess }: UnassignAllServiceProps) => {
//   const {
//     mutateAsync: unassignAllServices,
//     isPending: isUnassignAllServiceLoading,
//     data,
//     error,
//     isError,
//   } = useMutation({
//     mutationKey: ['unassign-all-business-invoices-request'],
//     mutationFn: (req: AssignUnassignAllRequest) => AccountSettingsProfileService.unassignAllInvoices(req),
//     onSuccess: onSuccess,
//     onError: onError,
//   });

//   const onUnassignAllServices = useCallback(
//     async (unassignAllServiceData: AssignUnassignAllRequest) => {
//       try {
//         unassignAllServices(unassignAllServiceData);
//       } catch (err) {
//         console.error(err);
//       }
//     },
//     [unassignAllServices]
//   );

//   return {
//     onUnassignAllServices,
//     isUnassignAllServiceLoading,
//     data,
//     error,
//     isError,
//   };
// };
