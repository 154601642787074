import { Box, Button, CircularProgress, Grid, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React, { useState } from 'react';
import DropdownMenu from 'common/DropDownMenu/DropDownMenu';
import FormDialog from 'common/Modal/Modal';
import { useImportService } from 'subModule/src/services/allcommon/useCommon';
import { enqueueSnackbar } from 'notistack';
import AlertMessages from 'common/Alert/AlertMessages';
import { CommonProfileService } from 'subModule/src/core/services';

type SearchHeaderMenuProps = {
  typeName: string;
  isMulti?: boolean;
  refetch?: () => void;
};
  
  const SearchHeaderMenu: React.FC<SearchHeaderMenuProps> = ({ typeName, isMulti, refetch }) => {
    const [modal, setModal] = useState<boolean>(false);
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [downloading, setDownloading] = useState<boolean>(false);
    const [fileName, setFileName] = useState<string>('');
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      setFileName(file ? file.name : '');
    };

    const onExport = async () => {
      setDownloading(true);
      CommonProfileService.exportEntity({ typeName: typeName }).then((res: Blob) => {
        const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${typeName.charAt(0).toUpperCase() + typeName.slice(1) + 'Sample'}.xlsx`); // The downloaded file name
        document.body.appendChild(link);
        link.click();

        link.remove();
        window.URL.revokeObjectURL(url);
        enqueueSnackbar(<AlertMessages text={t('Sample') + ' ' + t('downloaded') + ' ' + t('successfully')} />, { variant: 'success' });
      }).catch((err) => {
        console.error("===", err);
        enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
      }).finally(() => {
        setDownloading(false);
      });
    };
    
    const { onImport } = useImportService({
      onSuccess: (msg) => {
        const response: any = {};
        Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
        if (response.success) {
          refetch && refetch();
          enqueueSnackbar(<AlertMessages statusCode={response.status} text={t(typeName) + ' ' + t('uploaded') + ' ' + t('successfully')} />, { variant: 'success' });
          setModal(false);
          setLoading(false);
          setFileName('');
        } else {
          enqueueSnackbar(<AlertMessages text={response.message} />, { variant: 'error' });
          setLoading(false);
        }
      },
      onError: (err: any) => {
        enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        if (err instanceof Error) {
          console.error(err.stack);
        }
        setLoading(false);
      }
    })
  
    return <Box sx={isMulti ? { 
      display: 'block', margin: '15px 0 auto 0', 
      // borderLeft: '2px solid', 
      // marginLeft: '10px', 
      // paddingLeft: '2px' 
    } : {}}>
      <Button
        id="import-button"
        variant="contained"
        disableElevation
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}
        endIcon={<KeyboardArrowDownIcon />}
        className={`bg-white text-primary-color font-Saira font-[600] text-[14px] h-[31px] px-4 py-1 cursor-pointer rounded-[6px] ms-[10px]`} 
        sx={{ outline: '2px solid' }}
      >{downloading ? <CircularProgress  className='text-primary-color' size={24} /> : t('Upload')}</Button>
      <DropdownMenu anchorEl={anchorEl} menuItems={[
          { label: (t('Download Sample') + ' ' + t('(Excel)')), onClick: () => onExport()},
          { label: (t('Upload') + ' ' + t('(Excel)')), onClick: () => setModal(true)},
        ]} 
        onClose={() => setAnchorEl(null)} 
      />
      {modal &&
        <FormDialog
          open={modal}
          onClose={(event: any, reason: any) => {
            if(event && (reason !== 'backdropClick') && !loading) {
              setModal(false);
              setFileName('');
            }
          }}
          title={t('Upload') + ' ' + t(typeName)}
          createPopup={true}
          hideActions={true}
          maxWidth="md"
          content={  
            <Grid container className='items-center' component='form' noValidate={false} spacing={1} onSubmit={(e) => {
              e.preventDefault();
              setLoading(true);
              const data = new FormData(e.target as HTMLFormElement);
              const file = data.get('file') as File;
              onImport({
                file: file,
                typeName: typeName,
              });
            }}>
              <Grid item xs={12} md={8} id="importfile">
                <span className='italic font-Saira bg-[#e5e5e5] text-[14px] font-medium absolute mt-[8px] ms-[11px] py-[3px] w-[92px] text-center cursor-default'>{t('choose')}</span>
                <input type="file" name="file" 
                  required 
                  accept=".xls,.xlsx,.csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv"
                  style={{ color: fileName ? 'inherit' : '#fff' }}
                  onChange={handleFileChange}
                  title={fileName}
                  onInvalid={(e) => (e.target as HTMLInputElement).setCustomValidity(t('uploadFileRequired'))}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField type="submit" disabled={loading} margin='dense' value={t('Upload')} 
                  sx={{ '& div': {borderRadius: '8px', border: '0 !important'}, ['& input']: { fontFamily: "'Saira', sans-serif",
                    fontSize: '15px', lineHeight: '28px', fontWeight: 600, color:'#fff', padding: '0.52rem 2.22rem 0.6rem 2.22rem',
                    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
                    backgroundImage: 'linear-gradient(135deg, #FC5F3A, #FE9303)', border: '0 !important', borderRadius: '8px', cursor: 'pointer',
                    textTransform: 'uppercase', height: '25px'}
                  }}
                />
              </Grid>
            </Grid>
          }
        />
      }
    </Box>;
  };
  
  export default SearchHeaderMenu;