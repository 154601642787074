import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import ModelEngineComponent from 'components/ModelEngine';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useGetMakeDropdownService } from 'subModule/src/services/make/useMake';
import { useGetModelsByMakeService } from 'subModule/src/services/modelengine/useModelEngine';
import { ModelEngineRequest } from 'subModule/src/services/modelengine/interface';
import { MakeRequest } from 'subModule/src/services/make/interface';
import { ModelRequest } from 'subModule/src/services/model/interface';
import { useModelService } from 'subModule/src/services/model/useModel';
import { useMakeService } from 'subModule/src/services/make/useMake';
import { useModelEngineService,useDeleteModelEngineService,useGetModelEngineService,useUpdateModelEngineService,useChangeStatusModelEngineService } from 'subModule/src/services/modelengine/useModelEngine';
import { useSnackbar } from 'notistack';
import AlertMessages from 'common/Alert/AlertMessages';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUserInfo } from 'features/user/userSlice';
import { checkPrivileges } from "Helpers/CheckPrivileges";

interface FormData {
    title: string,
    secondaryTitle: string,
    makeId: number,
    makeIdError?: string,
    modelId: number,
    modelIdError?: string,
    default: boolean,
    id: number,
    active: boolean
}

interface MakeFormData {
  title: string,
  secondaryTitle: string,
  id: number,
  default: boolean,
  active: boolean
}
interface ModelFormData {
  title: string,
  secondaryTitle: string,
  makeId: number,
  makeIdError?: string,
  id: number,
  default: boolean,
  active: boolean
}

  const requiredFields = ['title', 'makeId', 'modelId'];

  const makeRequiredFields = ['title'];
  const modelRequiredFields = ['title','makeId'];

  export default function ModelEngine () {
    const [showModelEngineSecField, setShowModelEngineSecField] = useState<boolean>(false);
    const [showModelEngineMakeSecField, setShowModelEngineMakeSecField] = useState<boolean>(false);
    const [showModelEngineModelSecField, setShowModelEngineModelSecField] = useState<boolean>(false);
    const [openForm, setOpenForm] = useState(false);
    const [openDeleteModal, setDeleteModal] = useState(false);
    const [enableEdit, setEnableEdit] = useState(false);
    const [pageNo, setPageNo] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);
    const [statusModal, setStatusModal] = useState(false);
    const [statusText, setStatusText] = useState<string>('');
    const [searchTerm, setSearchTerm] = useState<any>(null);
    const [isBtnEnabled, setIsBtnEnabled] = useState<boolean>(true);
    const [openMakePopup, setOpenMakePopup] = useState(false);
    const [openModelPopup, setOpenModelPopup] = useState(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [make, setMake] = useState<number>(0);
    const [clearModel, setClearModel] = useState<boolean>(false);
    const [search, setSearch] = useState('');
    const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
    // const [data] = useState(rows);
    const [prevFormData, setPrevFormData] = useState<object>({});
    const [makesPageParams, setMakesPageParams] = useState<any>({ number: 1, size: 50 });
    const [modelsPageParams, setModelsPageParams] = useState<any>({ number: 1, size: 50 });
    const [dropdownSearchTerm, setDropdownSearchTerm] = useState<any>('');
    const [dropdown, setDropdown] = useState<string>('makeId');
    const [loadingDropdownSearch, setLoadingDropdownSearch] = useState<boolean>(false);
    const [initialRender, setInitialRender] = useState<boolean>(true);
    const [formData, setFormData] = useState<FormData>({
      title: '',
      secondaryTitle: '',
      id: 0,
      makeId: 0,
      modelId: 0,
      default: false,
      active: true
    });

    const [makeformData, setMakeFormData] = useState<MakeFormData>({
      title: '',
      secondaryTitle: '',
      id: 0,
      default: false,
      active: true
    });
    const [modelformData, setModelFormData] = useState<ModelFormData>({
      title: '',
      secondaryTitle: '',
      id: 0,
      default: false,
      active: true,
      makeId: 0,
      makeIdError: '',
    });

    const { data: tableData,  isLoading, error,  refetch} = searchTerm ? useGetModelEngineService({ pageNumber: pageNo, size: pageSize, title: searchTerm }) : useGetModelEngineService({ pageNumber: pageNo, size: pageSize });

    const { data: makesData, isLoading: makesIsLoading, error: makesError, refetch: makesRefetch } = useGetMakeDropdownService({pageNumber: makesPageParams.number, size: makesPageParams.size, title: dropdownSearchTerm});
    
    const { data: modelsData, isLoading: modelsIsLoading, error: modelsError, refetch: modelsbymakeRefetch } = useGetModelsByMakeService({ pageNumber: modelsPageParams.number, size: modelsPageParams.size, title: dropdownSearchTerm }, make);

    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const loggedInUserData = useSelector(selectUserInfo);
    const primaryLanguage = loggedInUserData?.primaryLanguage;
    const secondaryLanguage = loggedInUserData?.secondaryLanguage;
    
    useEffect(() => {
      if (!isLoading && !error && !openForm && !loadingSearch) { // Only refetch if not already loading or in error state
        refetch();
      }
      if(tableData) {
        setLoading(false);
        if((tableData?.pages[0] as any).status && !(tableData?.pages[0] as any).success) {
          enqueueSnackbar(<AlertMessages statusCode={(tableData?.pages[0] as any)?.status} />, { variant: 'error' })
        }
      }
    }, [pageNo, pageSize, isLoading, error, modelsIsLoading, modelsError, tableData, openForm]);
    useEffect(() => {
      if(enableEdit && JSON.stringify(formData) !== JSON.stringify(prevFormData) || openForm && JSON.stringify(formData) !== JSON.stringify(prevFormData)) {
        setIsBtnEnabled(true);
      } else if(enableEdit && JSON.stringify(formData) === JSON.stringify(prevFormData)) {
        setIsBtnEnabled(false);
      }
    }, [enableEdit, formData]);
    useEffect(() => {
      if(enableEdit && make && openForm) {
        setModelsPageParams({ number: 1, size: 50 });
        modelsbymakeRefetch();
        if(clearModel) { setFormData({ ...formData, modelId: 0 }); }
      } else if(!enableEdit && make && openForm) {
        setModelsPageParams({ number: 1, size: 50 });
        modelsbymakeRefetch();
        if(clearModel) { setFormData({ ...formData, modelId: 0 }); }
      }
    }, [make]);

    useEffect(() => {
      const getData = setTimeout(async() => {
      if (searchTerm !== null && !isLoading && !error && loadingSearch) { 
        await refetch();
        setLoadingSearch(false);
      }
    }, 1000);
    return () => clearTimeout(getData)
    }, [searchTerm, loadingSearch]);

    useEffect(() => {
      const getData = setTimeout(async() => {
        if ((dropdownSearchTerm || dropdownSearchTerm === '') && !makesIsLoading && !makesError && openForm && !initialRender) {
          if(dropdown === 'makeId') {
            setLoadingDropdownSearch(true);
            setMakesPageParams({ number: 1, size: 50 });
            await makesRefetch();
            setLoadingDropdownSearch(false);
          } else if(dropdown === 'modelId') {
            setLoadingDropdownSearch(true);
            setModelsPageParams({ number: 1, size: 50 });
            await modelsbymakeRefetch();
            setLoadingDropdownSearch(false);
          }
        }
      }, 500)
    return () => clearTimeout(getData)
    }, [dropdownSearchTerm, makesIsLoading, makesError, openForm]);

    useEffect(() => {
      if(makesPageParams && openForm && dropdown === 'makeId') {
        makesRefetch();
      } else if(modelsPageParams && openForm && dropdown === 'modelId') {
        modelsbymakeRefetch();
      }
    }, [makesPageParams, modelsPageParams])
    
    const handleDropdownSearch = (field:string, search: string) => {
      if(field === 'makeId') {
        setInitialRender(false);
        setDropdownSearchTerm(search);
        setDropdown('makeId');
      } else if(field === 'modelId') {
        setInitialRender(false);
        setDropdownSearchTerm(search);
        setDropdown('modelId');
      }
    }
    const loadNext = (field: string, scroll: any) => {
      if(scroll.deltaY > 90 || scroll.touches[0].clientY > 90) {
        if(field === 'makeId') {
          if(!(makesData?.pages[0]?.data as any).last) {
            setDropdown('makeId');
            setMakesPageParams({ number: makesPageParams.number + 1, size: makesPageParams.size });
          }
        } else if(field === 'modelId') {
          if(!(modelsData?.pages[0]?.data as any).last) {
            setDropdown('modelId');
            setModelsPageParams({ number: modelsPageParams.number + 1, size: modelsPageParams.size });
          }
        }
      }
    }
    const loadPrev = (field: string, scroll: any) => {
      if(scroll.deltaY < 10 || scroll.touches[0].identifier === 0) {
        if(field === 'makeId') {
          if(!(makesData?.pages[0]?.data as any).first) {
            setDropdown('makeId');
            setMakesPageParams({ number: makesPageParams.number - 1, size: makesPageParams.size });
          }
        } else if(field === 'modelId') {
          if(!(modelsData?.pages[0]?.data as any).first) {
            setDropdown('modelId');
            setModelsPageParams({ number: modelsPageParams.number - 1, size: modelsPageParams.size });
          }
        }
      }
    }
    const handleChangePage = (pageNo : number) => {  
      setLoading(true);
      setPageNo(pageNo + 1);
      setPageSize(pageSize);
    }
    const handleChangeRows = (pageSize : number) => {
      setLoading(true);
      setPageSize(pageSize);
      setPageNo(1);
    }

    const [errorMessages, setErrorMessages] = useState<Partial<FormData>>({});

    
    const [makeErrorMessages, setMakeErrorMessages] = useState<Partial<MakeFormData>>({});
    const [modelErrorMessages, setModelErrorMessages] = useState<Partial<ModelFormData>>({});

    const openModal = () => {
        setOpenForm(!openForm);
        setIsBtnEnabled(true);
        setEnableEdit(false);
        setErrorMessages({});
        setDropdown('makeId');
        setDropdownSearchTerm('');
        setInitialRender(true);
        setClearModel(false);
        setFormData({
          title: '',
          secondaryTitle: '',
          id: 0,
          makeId: 0,
          modelId: 0,
          default: false,
          active: true
        });
        if(!openForm && !enableEdit) {
          setMakesPageParams({ number: 1, size: 50 });
          setSearch('');
          setSearchTerm('');
          setShowModelEngineSecField(false)
          setShowModelEngineModelSecField(false)
          setShowModelEngineMakeSecField(false)
        }
      };

      const handleSecField = (field: string) => {
        
        if (field === "modelEngineTitle") {
          setShowModelEngineSecField((prevValue) => !prevValue);
        } else if (field === "modelTitle") {
          setShowModelEngineModelSecField((prevValue) => !prevValue);
        }else if (field === "makeTitle"){
          setShowModelEngineMakeSecField((prevValue) => !prevValue);
        }
      }

      const handleChange = (field: string, value: any) => {
        const val = (field === "makeId" || field === "modelId") ? (value ? parseInt(value.value) || 0 : 0) 
          : (field === "default") ? value ? Boolean(value) : false
          : value;
        
        setFormData((prevData) => ({
          ...prevData,
          [field]: val,
        }));
      
        if (requiredFields.includes(field) && !val) {
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: 'This field is required.',
          }));
        } else {
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: undefined,
          }));
        }
        if(field === "makeId") {
          setMake(value ? parseInt(value.value) : 0);
          setClearModel(true);
        } if(field === "modelId") { 
          setClearModel(false);
        }
      };
      
    
      const validateFormData = (data: FormData) => {
        const errors: Partial<FormData> = {};

        if (!data.makeId) {
          errors.makeIdError = t('This field is required.');
        }
        if (!data.modelId) {
          errors.modelIdError = t('This field is required.');
        }
        if (!data.title?.trim()) {
          errors.title = t('This field is required.');
        }
    
        return errors;
      };

      const { onCreateModelEngine,  } = useModelEngineService({
       onSuccess: (msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          setOpenForm(false);
          if(response.success) {
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('modelEngine') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
          refetch();
        },
        onError: (err) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
          console.error(err.stack);
        }
      }})

      const {onUpdateModelEngine } = useUpdateModelEngineService({
        onSuccess: (data) => {
          const response: any = {};
          Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
          setEnableEdit(false);
          setOpenForm(false);
          if(response.success) {
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('modelEngine') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
          refetch();
        },
        onError: (err) => {
          console.error('Login error======', err, enableEdit);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
        id: formData.id,
      }
      );

      const onOpenModelPopup = () => {
        setOpenModelPopup(!openMakePopup);
        setModelErrorMessages({})
        setModelFormData({
          title: '',
          secondaryTitle: '',
          id: 0,
          default: false,
          active: true,
          makeId: 0,
          makeIdError: '',
        });
      }
      const closeModelPopup = () => {
        setOpenModelPopup(false);
      }
      const handleChangeModel = (field: string, value: any) => {
        const val = (field === "makeId") ? (value ? parseInt(value.value) || 0 : 0) 
        : (field === "default") ? value ? Boolean(value) : false
        : value;

        setModelFormData((prevData) => ({
          ...prevData,
          [field]: val,
        }));
      
        if (modelRequiredFields.includes(field) && !val) {
          setModelErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: 'This field is required.',
          }));
        } else {
          setModelErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: undefined,
          }));
        }
      };
      const validateModelFormData = (data: ModelFormData) => {
        const errors: Partial<ModelFormData> = {};

        if (!data.title?.trim()) {
          errors.title = t('This field is required.');
        }
        if (!data.makeId) {
          errors.makeIdError = t('This field is required.');
        }
    
        return errors;
      };
      const submitModel = () => {
        const errors = validateModelFormData(modelformData);
        setModelErrorMessages(errors);
   
        if (Object.keys(errors).length === 0) {
          const modelData: ModelRequest = {
            ...modelformData, 
            title: modelformData.title,
            secondaryTitle: modelformData.secondaryTitle,
            makeId:modelformData.makeId,
          };
          onCreateModel(modelData);
        }
      }

      const { onCreateModel } = useModelService({
        onSuccess: (msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          if(response.success) {
            setOpenModelPopup(false);
            modelsbymakeRefetch();
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('make') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
          console.error(err.stack);
        }
      }})

// <--------------------MakePopUpData--------------------->

      const onOpenMakePopup = () => {
        setOpenMakePopup(!openMakePopup);
        setMakeErrorMessages({})
        setMakeFormData({
            title: '',
            secondaryTitle: '',
            id: 0,
            default: false,
            active: true
        });
      }
      const closeMakePopup = () => {
        setOpenMakePopup(false);
      }
      const handleChangeMake = (field: string, value: any) => {
        const  val = (field === "default") ? value ? Boolean(value) : false : value;

        setMakeFormData((prevData) => ({
          ...prevData,
          [field]: val,
        }));
      
        if (makeRequiredFields.includes(field) && !val) {
          setMakeErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: 'This field is required.',
          }));
        } else {
          setMakeErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: undefined,
          }));
        }
      };
      const validateMakeFormData = (data: MakeFormData) => {
        const errors: Partial<MakeFormData> = {};

        if (!data.title?.trim()) {
          errors.title = t('This field is required.');
        }
    
        return errors;
      };
      const submitMake = () => {
        const errors = validateMakeFormData(makeformData);
        setMakeErrorMessages(errors);
   
        if (Object.keys(errors).length === 0) {
          const makeData: MakeRequest = {
            ...makeformData, 
            title: makeformData.title,
            secondaryTitle: makeformData.secondaryTitle,
          };
          onCreateMake(makeData);
        }
      }




      const { onCreateMake } = useMakeService({
        onSuccess: (msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          if(response.success) {
            setOpenMakePopup(false);
            makesRefetch();
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('make') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
          console.error(err.stack);
        }
      }})

      const handleSubmit = () => {
        const errors = validateFormData(formData);
        setErrorMessages(errors);
   
        if (Object.keys(errors).length === 0) {
          setIsBtnEnabled(false);
          setLoading(true);
          const modelEngineData: ModelEngineRequest = {
            ...formData, 
            title: formData.title,
            secondaryTitle: formData.secondaryTitle,
            makeId: formData.makeId,
            modelId: formData.modelId,
            default: formData.default,
            active:formData.active
          };
     
          if (enableEdit) {
            onUpdateModelEngine(
                modelEngineData
            );
          } else {
            onCreateModelEngine(modelEngineData);
          }
        }
      };

      const onEdit = (row:any) => {
        setEnableEdit(true);
        setFormData(row);
        setPrevFormData(row);
        setMake(row.makeId);
        setOpenForm(true);
        setIsBtnEnabled(false);
        setMakesPageParams({ number: 1, size: 50 });
        setInitialRender(true);
        setClearModel(false);
        setDropdownSearchTerm('');
        setSearch('');
        setSearchTerm('');
      }

      const onDelete = (row:any) => {
        setFormData(row)
        setDeleteModal(true)
      }

      const { onDeleteModelEngine } = useDeleteModelEngineService({
        id: formData.id,
        onSuccess: (msg) => {
          if(msg.success) {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('modelEngine') + ' ' + t('deleted') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
          }
          refetch();
          if(tableData?.pages[0].data.content.length === 1) {
            handleChangePage(pageNo - 1)
          }
        },
        onError: (err) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
      });

      const handleDelete = () => {
        setDeleteModal(false);
        setLoading(true);
        onDeleteModelEngine();
      }
      const cancelDelete = () => {
        setDeleteModal(false)
      }

      const clearSearch = () => {
        setSearch('');
        setSearchTerm('');
        if(searchTerm) { handleSearch(''); }
      }
      const handleSearch = (search: string) => {
        setLoadingSearch(true);
        setSearchTerm(search);
        setPageNo(1);
        setPageSize(pageSize);
      };
    
      const handleSearchClick = () => {
        const trimmedSearch = search.trim();
        if (trimmedSearch !== '') {
          handleSearch(trimmedSearch);
        }
      };
    
      const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value.trim());
        if (!event.target.value.trim()) handleSearch('');
      };

      const onChangeStatus = (row: any) => {
        row.active ? setStatusText('deactivate') : setStatusText('activate');
        setFormData(row);
        setStatusModal(true);
      }
      const cancelChangeStatus = () => {
        setStatusModal(false);
      }
      const submitChangeStatus = () => {
        setStatusModal(false);
        setLoading(true);
        onChangeStatusModelEngine({});
      }
      const { onChangeStatusModelEngine } = useChangeStatusModelEngineService({
        id: formData.id,
        onSuccess: (msg) => {
          if(msg.success) {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('modelEngine') + ' ' + t(statusText) + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
          }
          refetch();
        },
        onError: (err: any) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
        active: formData.active ? "inactive" : "active"
      });
      const showRowView = (row: any) => {
        onEdit(row);
      }
      const adjustActions = (actions: any[]) => {
        return checkPrivileges('ROLE_MODELENGINE_UPDATE') 
          ? (checkPrivileges('ROLE_MODELENGINE_ARCHIVE') 
              ? actions 
              : actions.filter(action => action.label !== 'delete')) 
          : (checkPrivileges('ROLE_MODELENGINE_ARCHIVE') 
              ? actions.filter(action => action.label !== 'edit') 
              : []);
      }

    return(
        <Box>
            <ModelEngineComponent
                handleSecField={handleSecField}
                secondaryLanguage={secondaryLanguage}
                primaryLanguage={primaryLanguage}
                openModal={openModal}
                closeModal={openModal}
                handleSearchClick={handleSearchClick}
                handleInputChange={handleInputChange}
                handleDelete={handleDelete}
                cancelDelete={cancelDelete}
                handleChange={handleChange} 
                handleSubmit={handleSubmit}
                formData={formData}
                handleChangePage={handleChangePage}
                handleChangeRows={handleChangeRows}
                onChangeStatus={onChangeStatus}
                cancelChangeStatus={cancelChangeStatus}
                submitChangeStatus={submitChangeStatus}
                onOpenMakePopup={onOpenMakePopup}
                closeMakePopup={closeMakePopup}
                submitMake={submitMake}
                handleChangeMake={handleChangeMake}
                makeformData={makeformData}
                showRowView={showRowView}
                onOpenModelPopup={onOpenModelPopup}
                closeModelPopup={closeModelPopup}
                submitModel={submitModel}
                handleChangeModel={handleChangeModel}
                modelformData={modelformData}
                loadNext={loadNext}
                loadPrev={loadPrev}
                handleDropdownSearch={handleDropdownSearch}
                clearSearch={clearSearch}
                columns={checkPrivileges("ROLE_MODELENGINE_ACTIVE") ? [
                    { id: 'title', label: 'title', numeric: false },
                    { id: 'makeTitle', label: 'make', numeric: false },
                    { id: 'modelTitle', label: 'model', numeric: false },
                    { id: 'active', label: 'status', numeric: false },
                    { id: 'actions', label: 'actions', numeric: false },
                ] : [
                  { id: 'title', label: 'title', numeric: false },
                  { id: 'makeTitle', label: 'make', numeric: false },
                  { id: 'modelTitle', label: 'model', numeric: false },
                  { id: 'actions', label: 'actions', numeric: false },
              ]}
                data={tableData?.pages[0] || {}}
                makesData={makesData?.pages[0] || {}}
                modelsData={modelsData?.pages[0] || {}}
                // tableDataMake={tableDataMake?.pages[0] || {}}
                {...{ openForm, openDeleteModal, enableEdit, pageNo, pageSize, statusModal,openMakePopup,openModelPopup, statusText, isBtnEnabled, loading, search, loadingSearch, loadingDropdownSearch, clearModel,showModelEngineSecField,showModelEngineMakeSecField,showModelEngineModelSecField }}
                errorMessages={errorMessages}
                makeErrorMessages={makeErrorMessages}
                modelErrorMessages={modelErrorMessages}
                actions1 = {adjustActions([
                { label: "edit", onClick: onEdit, icon: <EditIcon /> },
                { label: "delete", onClick: onDelete, icon: <DeleteIcon /> },
                ])}
            />
        </Box>
    )
  }