import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import ServiceGroupComponent from 'components/ServiceGroup';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { ServiceGroupRequest } from 'subModule/src/services/servicegroup/interface';
import { useChangeStatusServiceGroupService, useServiceGroupService, useDeleteServiceGroupService, useGetServiceGroupService, useUpdateServiceGroupService, useGetServiceGroupServiceService } from 'subModule/src/services/servicegroup/useServiceGroup';
import { useSnackbar } from 'notistack';
import AlertMessages from 'common/Alert/AlertMessages';
import { useTranslation } from 'react-i18next';
import { useAssignServiceService, useUnassignServiceService, useAssignAllServiceService, useUnassignAllServiceService } from 'subModule/src/services/servicegroup/useServiceGroup';
import { SelectChangeEvent } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectUserInfo } from 'features/user/userSlice';
import { checkPrivileges } from 'Helpers/CheckPrivileges';

interface FormData {
  title: string,
  secondaryTitle: string,
  id: number,
  active: boolean
}
interface SearchFormData {
  [key: string]: string;
}
  const requiredFields = ['title'];

  export default function ServiceGroup () {
    const [showSecField, setShowSecField] = useState<boolean>(false);
    const [openForm, setOpenForm] = useState(false);
    const [openDeleteModal, setDeleteModal] = useState(false);
    const [enableEdit, setEnableEdit] = useState(false);
    const [pageNo, setPageNo] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);
    const [statusModal, setStatusModal] = useState(false);
    const [statusText, setStatusText] = useState<string>('');
    const [searchTerm, setSearchTerm] = useState<any>(null);
    const [search, setSearch] = useState('');
    const [isBtnEnabled, setIsBtnEnabled] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
    const [prevFormData, setPrevFormData] = useState<object>({});
    const [rowView, setRowView] = useState(false);
    const [selectedRow, setSelectedRow] = useState<any>({});
    const [serviceGroupId, setServiceGroupId] = useState<number>(0);
    const [tab, setTab] = useState<number>(0);
    // const [data] = useState(rows);
    const [formData, setFormData] = useState<FormData>({
        title: '',
        secondaryTitle: '',
        id: 0,
        active: true
    });
    const [searchFormData, setSearchFormData] = useState<SearchFormData>({
      title: '',
      serviceCode: ''
    });
  const [filter, setFilter] = useState<string[]>(['title']);
  const [searchErrorMessages, setSearchErrorMessages] = useState<Partial<SearchFormData>>({});
  const [searchService, setSearchService] = useState<any>({});
  const [loadingService,setLoadingService]=useState<boolean>(false)
  
  const { data: tableData,  isLoading, error,  refetch } = searchTerm ? useGetServiceGroupService({ pageNumber: pageNo, size: pageSize, title: searchTerm }) : useGetServiceGroupService({ pageNumber: pageNo, size: pageSize });
    
  const { data: serviceAssignData, isLoading: serviceAssignIsLoading, error: serviceAssignError, refetch:serviceAssignRefetch } =useGetServiceGroupServiceService({pageNumber: pageNo, size: pageSize,...searchService}, serviceGroupId)

    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const loggedInUserData = useSelector(selectUserInfo);
    const primaryLanguage = loggedInUserData?.primaryLanguage;
    const secondaryLanguage = loggedInUserData?.secondaryLanguage;

    let allServicesAssigned:unknown = serviceAssignData?.pages[0].data?.content.map(service => service.linked).every(linked => linked === true);
    
    useEffect(() => {
      if (!isLoading && !error && !rowView && !openForm && !loadingSearch && !loadingService) { // Only refetch if not already loading or in error state
        refetch();
      }
      if(tableData) {
        setLoading(false);
        if((tableData?.pages[0] as any).status && !(tableData?.pages[0] as any).success) {
          enqueueSnackbar(<AlertMessages statusCode={(tableData?.pages[0] as any)?.status} />, { variant: 'error' })
        }
      }
      if(tab === 0 && enableEdit) {
        setIsBtnEnabled(false);
      }
      if(!serviceAssignIsLoading && !serviceAssignError && serviceGroupId && serviceGroupId !== 0 && tab === 1&&!loadingService) {
        const loadData = async() => {
          setLoadingService(true);
          await serviceAssignRefetch();
          setLoadingService(false);
        }
        loadData();
      }
    }, [pageNo, pageSize, refetch, isLoading, error, tableData, openForm, serviceGroupId, tab]);


    useEffect(() => {
      const getData = setTimeout(async() => {
        if (searchTerm !== null && !isLoading && !error && loadingSearch) {
          await refetch();
          setLoadingSearch(false);
        }
      }, 1000);
      return () => clearTimeout(getData)
    }, [searchTerm, loadingSearch]);

    useEffect(() => {
      const getData = setTimeout(async() => {
        if(searchService&&!serviceAssignIsLoading&&loadingService&&tab===1){
          await serviceAssignRefetch()
          setLoadingService(false);
        }
      }, 300);
      return () => clearTimeout(getData)
    }, [searchService]);

    useEffect(() => {
      if(enableEdit && JSON.stringify(formData) !== JSON.stringify(prevFormData) || openForm && JSON.stringify(formData) !== JSON.stringify(prevFormData)) {
        setIsBtnEnabled(true);
      } else if(enableEdit && JSON.stringify(formData) === JSON.stringify(prevFormData)) {
        setIsBtnEnabled(false);
      }
    }, [enableEdit, formData]);

    useEffect(() => {
      if(enableEdit&&openForm){
        setIsBtnEnabled(false)
      }
    }, [formData]);


    const handleChangePage = (pageNo : number) => {  
      setLoading(true);
      setPageNo(pageNo + 1);
      setPageSize(pageSize);
    }
    const handleChangeRows = (pageSize : number) => {
      setLoading(true);
      setPageSize(pageSize);
      setPageNo(1);
    }


    const [errorMessages, setErrorMessages] = useState<Partial<FormData>>({});

    const openModal = () => {
      if(rowView) {
        setRowView(false);
        setServiceGroupId(0);
        setPageNo(1);
        setTab(0);
      } else {
        setIsBtnEnabled(true);
        setOpenForm(!openForm);
        openModalSettings();
        setShowSecField(false)
        setSearch('');
        setSearchTerm('');
      }
    };
    const openModalSettings = () => {
      setEnableEdit(false);
      setErrorMessages({})
      setIsBtnEnabled(true)
      setFormData({
        title: '',
        secondaryTitle: '',
        id: 0,
        active: true
      });
    }

    const handleSecField = () => {
      setShowSecField((prevValue) => !prevValue);
    }

      const handleChange = (field: string, value: any) => {
        const  val = value;

        setFormData((prevData) => ({
          ...prevData,
          [field]: val,
        }));
      
        if (requiredFields.includes(field) && !val) {
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: 'This field is required.',
          }));
        } else {
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: undefined,
          }));
        }
      };
      
    
      const validateFormData = (data: FormData) => {
        const errors: Partial<FormData> = {};

        if (!data.title?.trim()) {
          errors.title = t('This field is required.');
        }
    
        return errors;
      };

      const { onCreateServiceGroup } = useServiceGroupService({
        onSuccess: (msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          if(response.success) {
            setOpenForm(false);
            refetch();
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('serviceGroup') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
          console.error(err.stack);
        }
      }})

      const { onUpdateServiceGroup } = useUpdateServiceGroupService({
        onSuccess: (data) => {
          const response: any = {};
          Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
          if(response.success) {
            if(!rowView) {
              setOpenForm(false);
              setEnableEdit(false);
              refetch();
            }
            if(rowView) { setPrevFormData(response?.data); setSelectedRow(response?.data); }
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('serviceGroup') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err) => {
          console.error('Login error======', err, enableEdit);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
        id: formData.id,
      });

      const handleSubmit = () => {
        const errors = validateFormData(formData);
        setErrorMessages(errors);
   
        if (Object.keys(errors).length === 0) {
          setIsBtnEnabled(false);
          setLoading(true);
          const serviceGroupData: ServiceGroupRequest = {
            ...formData, 
            title: formData.title,
            secondaryTitle: formData.secondaryTitle,
          };
     
          if (enableEdit) {
            onUpdateServiceGroup(
              serviceGroupData
            );
          } else {
            onCreateServiceGroup(serviceGroupData);
          }
        }
      };

      const onEdit = (row:any) => {
        setEnableEdit(true);
        setIsBtnEnabled(false);
        setFormData(row);
        setPrevFormData(row);
        setOpenForm(true);
        setSearch('');
        setSearchTerm('');
      }

      const onDelete = (row:any) => {
        setFormData(row)
        setDeleteModal(true)
      }

      const { onDeleteServiceGroup } = useDeleteServiceGroupService({
        id: formData.id,
        onSuccess: (msg) => {
          if(msg.success) {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('serviceGroup') + ' ' + t('deleted') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
          }
          refetch(); // This will use the updated pageNo and pageSize
          if(tableData?.pages[0].data.content.length === 1) {
            handleChangePage(pageNo - 1)
          }
        },
        onError: (err) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
      });

      const handleDelete = () => {
        setDeleteModal(false)
        setLoading(true);
        onDeleteServiceGroup()
      }
      const cancelDelete = () => {
        setDeleteModal(false)
      }

      const clearSearch = () => {
        setSearch('');
        setSearchTerm('');
        if(searchTerm) { handleSearch(''); }
      }
      const handleSearch = (search: string) => {
        setLoadingSearch(true);
        setSearchTerm(search);
        setPageNo(1);
        setPageSize(pageSize);
      };
    
      const handleSearchClick = () => {
        const trimmedSearch = search.trim();
        if (trimmedSearch !== '') {
          handleSearch(trimmedSearch);
        }
      };
    
      const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value.trim());
        if (!event.target.value.trim()) handleSearch('');
      };
      
      const onChangeStatus = (row: any) => {
        row.active ? setStatusText('deactivate') : setStatusText('activate');
        setFormData(row);
        setStatusModal(true);
      }
      const cancelChangeStatus = () => {
        setStatusModal(false);
      }
      const submitChangeStatus = () => {
        setStatusModal(false);
        setLoading(true);
        onChangeStatusServiceGroup({});
      }
      const { onChangeStatusServiceGroup } = useChangeStatusServiceGroupService({
        id: formData.id,
        onSuccess: (msg) => {
          if(msg.success) {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('serviceGroup') + ' ' + t(statusText) + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
          }
          refetch();
        },
        onError: (err) => {
          console.error("===", err)
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
        active: formData.active ? "inactive" : "active"
      });

      const showRowView = (row: any) => {
        setEnableEdit(true);
        setOpenForm(false);
        setFormData(row);
        setPrevFormData(row);
        setErrorMessages({});
        setRowView(true);
        setServiceGroupId(row.id);
        setSelectedRow(row);
        setSearch('');
        setSearchTerm('');
      }
      const handleChangeTabs = (tabId: number) => {
        if(tabId === 0) {
          setTab(0);
          setEnableEdit(true);
          setOpenForm(false);
          setFormData(selectedRow);
          setErrorMessages({});
          setIsBtnEnabled(false);
        } else if(tabId === 1) {
          setTab(1);
          setPageNo(1);
          setEnableEdit(false);
          setErrorMessages({});
          clearSearch()
        }
      }
      const assignUnassignSingleEntity = (isChecked: boolean, row: any) => {
        setLoadingService(true);
        if(isChecked) {
          if(tab === 1) {
            onAssignService({ serviceGroupId: serviceGroupId, serviceId: row.id });
          }
        } else {
          if(tab === 1) {
            onUnassignService({ serviceGroupId: serviceGroupId, serviceId: row.id });
          }
        }
      }
      const assignUnassignAllEntities = (isChecked: boolean) => {
        setLoadingService(true);
        if(isChecked) {
          if(tab === 1) {
            onAssignAllServices({serviceGroupId: serviceGroupId});
          }
        } else {
          if(tab === 1) {
            onUnassignAllServices({serviceGroupId: serviceGroupId});
          }
        }
      }
      const { onAssignService } = useAssignServiceService({
        onSuccess: async(msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          if(response.success) {
            if(serviceAssignData?.pages[0].data.content.length === serviceAssignData?.pages[0].data.content.filter(service => service.linked).length) {
              allServicesAssigned = true;
            }
            await serviceAssignRefetch();
            setLoadingService(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('service') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await serviceAssignRefetch();
            setLoadingService(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err:any) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
            console.error(err.stack);
          }
      }})
      const { onUnassignService } = useUnassignServiceService({
        onSuccess: async(data) => {
          const response: any = {};
          Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
          if(response.success) {
            if(serviceAssignData?.pages[0].data.content.length !== serviceAssignData?.pages[0].data.content.filter(service => service.linked).length) {
              allServicesAssigned = false;
            }
            await serviceAssignRefetch();
            setLoadingService(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('service') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await serviceAssignRefetch();
            setLoadingService(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err: any) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
      });
      const { onAssignAllServices } = useAssignAllServiceService({
        onSuccess: async(msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          if(response.success) {
            allServicesAssigned = true;
            await serviceAssignRefetch();
            setLoadingService(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('All') + ' ' + t('services') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await serviceAssignRefetch();
            setLoadingService(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err:any) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
            console.error(err.stack);
          }
      }})
      const { onUnassignAllServices } = useUnassignAllServiceService({
        onSuccess: async(data) => {
          const response: any = {};
          Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
          if(response.success) {
            allServicesAssigned = false;
            await serviceAssignRefetch();
            setLoadingService(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('All') + ' ' + t('services') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await serviceAssignRefetch();
            setLoadingService(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err: any) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
      });

      const clearSearchAssign = () => {
        setFilter(['title']);
        setSearchFormData({
          title: '',
          serviceCode: ''
        });
        setSearchErrorMessages({});
        if(Object.keys(searchService).length) {
          setLoadingService(true);
          setSearchService({})
        }
      }
      const handleChangeFilter = (event: SelectChangeEvent<typeof filter>) => {
        const { target: { value }, } = event;
        setFilter(typeof value === 'string' ? value.split(',') : value,);
        setSearchFormData(prevFormData => 
          Object.fromEntries(Object.keys(prevFormData).map(key => 
            [key, value.includes(key) ? prevFormData[key] : '']
          ))
        );
        if(!value.length && Object.keys(searchService).length) {
          clearSearchAssign();
        }
      };
      const handleChangeSearch = (field: string, value: any) => {
        const val = value;
    
        setSearchFormData((prevData) => ({
          ...prevData,
          [field]: val,
        }));
    
        if (filter.includes(field) && !val) {
          setSearchErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: 'This field is required.',
          }));
        } else {
          setSearchErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: undefined,
          }));
        }
      };
      const validateSearchFormData = (data: SearchFormData) => {
        const errors: Partial<SearchFormData> = {};
    
        filter.forEach(fieldName => {
          if (!data[fieldName]) { 
            errors[fieldName] = t('required');
          }
        });
    
        return errors;
      };
      const handleSubmitSearch = () => {
        const errors = validateSearchFormData(searchFormData);
        setSearchErrorMessages(errors);
    
        if (Object.keys(errors).length === 0) {
          setLoadingService(true);
          const filteredSearch: Record<string, string> = {};
    
          Object.entries(searchFormData).forEach(([key, value]) => {
              if (value) {
                filteredSearch[key] = typeof value === 'string' ? value.trim() : value;
              }
          });
          setSearchService(filteredSearch);
          setPageNo(1);
          setPageSize(pageSize);
        }
      };
      const adjustActions = (actions: any[]) => {
        return checkPrivileges('ROLE_SERVICEGROUP_UPDATE') 
          ? (checkPrivileges('ROLE_SERVICEGROUP_ARCHIVE') 
              ? actions 
              : actions.filter(action => action.label !== 'delete')) 
          : (checkPrivileges('ROLE_SERVICEGROUP_ARCHIVE') 
              ? actions.filter(action => action.label !== 'edit') 
              : []);
      }

    return(
        <Box>
            <ServiceGroupComponent
                openModal={openModal}
                closeModal={openModal}
                handleSecField={handleSecField}
                secondaryLanguage={secondaryLanguage}
                primaryLanguage={primaryLanguage}
                handleSearchClick={handleSearchClick}
                handleInputChange={handleInputChange}
                handleDelete={handleDelete}
                cancelDelete={cancelDelete}
                handleChange={handleChange} 
                handleSubmit={handleSubmit}
                formData={formData}
                handleChangePage={handleChangePage}
                handleChangeRows={handleChangeRows}
                onChangeStatus={onChangeStatus}
                cancelChangeStatus={cancelChangeStatus}
                submitChangeStatus={submitChangeStatus}
                showRowView={showRowView}
                serviceAssignData={serviceAssignData?.pages[0] || {}}
                rowView={rowView}
                handleChangeTabs={handleChangeTabs}
                assignUnassignSingleEntity={assignUnassignSingleEntity}
                assignUnassignAllEntities={assignUnassignAllEntities}
                allServicesAssigned={allServicesAssigned}
                clearSearch={clearSearch}
                columns={checkPrivileges("ROLE_SERVICEGROUP_ACTIVE") ? [
                    { id: 'title', label: 'title', numeric: false },
                    { id: 'active', label: 'status', numeric: false },
                    { id: 'actions', label: 'actions', numeric: false },
                ] : [
                  { id: 'title', label: 'title', numeric: false },
                  { id: 'actions', label: 'actions', numeric: false },
              ]}
                data={tableData?.pages[0] || {}}
                {...{ openForm, openDeleteModal,showSecField, enableEdit, pageNo, pageSize, statusModal, statusText, search,isBtnEnabled,loading,loadingSearch,filter,searchService,loadingService }}
                errorMessages={errorMessages}
                actions1 = {adjustActions([
                  { label: "edit", onClick: onEdit, icon: <EditIcon /> },
                  { label: "delete", onClick: onDelete, icon: <DeleteIcon /> },
                ])}
                searchFormData={searchFormData}
                handleChangeFilter={handleChangeFilter}
                handleChangeSearch={handleChangeSearch}
                handleSubmitSearch={handleSubmitSearch}
                searchErrorMessages={searchErrorMessages}
                clearSearchAssign={clearSearchAssign}
            />
        </Box>
    )
  }