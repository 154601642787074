import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import StationComponent from 'components/Station/index';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useStationService, useDeleteStationService, useGetStationService, useUpdateStationService, useChangeStatusStationService } from 'subModule/src/services/station/useStation';
import { StationRequest } from 'subModule/src/services/station/interface';
import { useGetPriceListDropdownService } from 'subModule/src/services/pricelist/usePriceList';
import { useGetBusinessGroupDropdownService } from 'subModule/src/services/businessgroup/useBusinessGroup';
import { useGetStationStoreService, useAssignStoreService, useUnassignStoreService, useAssignAllStoreService, useUnassignAllStoreService } from 'subModule/src/services/station/useStation';
import { useGetStationWarehouseService, useAssignWarehouseService, useUnassignWarehouseService, useAssignAllWarehouseService, useUnassignAllWarehouseService } from 'subModule/src/services/station/useStation';
import { useGetStationUserService, useAssignUserService, useUnassignUserService, useAssignAllUserService, useUnassignAllUserService } from 'subModule/src/services/station/useStation';
import { useGetStationBayService, useAssignBayService, useUnassignBayService, useAssignAllBayService, useUnassignAllBayService } from 'subModule/src/services/station/useStation';
import { useGetStationBankAccountService, useAssignBankAccountService, useUnassignBankAccountService, useAssignAllBankAccountService, useUnassignAllBankAccountService } from 'subModule/src/services/station/useStation';
import { useSnackbar } from 'notistack';
import AlertMessages from 'common/Alert/AlertMessages';
import { useTranslation } from 'react-i18next';
import { SelectChangeEvent } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectUserInfo } from 'features/user/userSlice';
import { checkPrivileges } from 'Helpers/CheckPrivileges';

interface FormData {
  name: string,
  secondaryName: string,
  checkTyre: boolean,
  applicableToAllBusinessGroup: boolean,
  description: string,
  secondaryDescription: string,
  id: number,
  // stationCategoryId: number,
  // stationCategoryIdError?: string,
  stationCode: string,
  region: string,
  city: string,
  country: string,
  taxNumber: string,
  numberOfTerminal: number,
  startBalanceDefaultAmount: number,
  priceListId: number,
  businessGroupId: number,
  address: string,
  phoneNumber1: string,
  phoneNumber2: string,
  district: string,
  street: string,
  buildingNumber: string,
  postalCode: string,
  location: string,
  latitude: number,
  longitude: number,
  landmark: string,
  email: string,
  website: string,
  googleListingAddress: string,
  plateNumberType: number,
  plateNumberTypeError?: string,
  extraNotes: string,
  timeZone: string,
  language: string,
  termAndCondition: string,
  imgUrl: string,
  active: boolean
}

interface SearchAssignFormData {
  [key: string]: string | number;
}

const requiredFields = ['title', 'stationCode'];

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const phoneRegex = /^[\p{N}+]{5,17}$/u;

export default function Station() {
  const [showSecField, setShowSecField] = useState<boolean>(false);
  const [openForm, setOpenForm] = useState(false);
  const [openDeleteModal, setDeleteModal] = useState(false);
  const [enableEdit, setEnableEdit] = useState(false);
  const [pageNo, setPageNo] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [statusModal, setStatusModal] = useState(false);
  const [statusText, setStatusText] = useState<string>('');
  const [rowView, setRowView] = useState(false);
  const [stationId, setStationId] = useState<number>(0);
  const [tab, setTab] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<any>(null);
  const [isBtnEnabled, setIsBtnEnabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [search, setSearch] = useState('');
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [prevFormData, setPrevFormData] = useState<object>({});
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [searchAssign, setSearchAssign] = useState('');
  const [searchTermAssign, setSearchTermAssign] = useState<any>(null);
  const [loadingSearchAssign, setLoadingSearchAssign] = useState<boolean>(false);
  // const [data] = useState(rows);
  const [formData, setFormData] = useState<FormData>({
    name: '',
    secondaryName: '',
    checkTyre: false,
    applicableToAllBusinessGroup: false,
    description: '',
    secondaryDescription: '',
    id: 0,
    // stationCategoryId: 0,
    stationCode: '',
    region: '',
    city: '',
    country: '',
    taxNumber: '',
    numberOfTerminal: 0,
    startBalanceDefaultAmount: 0,
    priceListId: 0,
    businessGroupId: 0,
    address: '',
    phoneNumber1: '',
    phoneNumber2: '',
    district: '',
    street: '',
    buildingNumber: '',
    postalCode: '',
    location: '',
    latitude: 0,
    longitude: 0,
    landmark: '',
    email: '',
    website: '',
    googleListingAddress: '',
    plateNumberType: 0,
    extraNotes: '',
    timeZone: '',
    language: '',
    termAndCondition: '',
    imgUrl: '',
    active: false
  });
  const [searchAssignFormData, setSearchAssignFormData] = useState<SearchAssignFormData>({
    accountTitle: '',
    bankName: '',
    accountNumber: ''
  });
  const [filterAssign, setFilterAssign] = useState<string[]>(['accountTitle']);
  const [searchAssignErrorMessages, setSearchAssignErrorMessages] = useState<Partial<SearchAssignFormData>>({});
  const [searchMultiAssign, setSearchMultiAssign] = useState<any>({});

  const { data: tableData, isLoading, error, refetch } = searchTerm ? useGetStationService({ pageNumber: pageNo, size: pageSize, name: searchTerm }) : useGetStationService({ pageNumber: pageNo, size: pageSize });

  const { data: priceListData, isLoading: priceListIsLoading, error: priceListError, refetch: priceListRefetch } = useGetPriceListDropdownService({ pageNumber: 1, size: 50 });

  const { data: businessGroupData, isLoading: businessGroupIsLoading, error: businessGroupError, refetch: businessGroupRefetch } = useGetBusinessGroupDropdownService({ pageNumber: 1, size: 50 });

  const { data: storeAssignData, isLoading: storeAssignIsLoading, error: storeAssignError, refetch: storeAssignRefetch } = searchTermAssign ? useGetStationStoreService({ pageNumber: pageNo, size: pageSize, title: searchTermAssign }, stationId) : useGetStationStoreService({ pageNumber: pageNo, size: pageSize }, stationId);

  const { data: warehouseAssignData, isLoading: warehouseAssignIsLoading, error: warehouseAssignError, refetch: warehouseAssignRefetch } = searchTermAssign ? useGetStationWarehouseService({ pageNumber: pageNo, size: pageSize, title: searchTermAssign }, stationId) : useGetStationWarehouseService({ pageNumber: pageNo, size: pageSize }, stationId);

  const { data: userAssignData, isLoading: userAssignIsLoading, error: userAssignError, refetch: userAssignRefetch } = useGetStationUserService({ pageNumber: pageNo, size: pageSize, ...searchMultiAssign }, stationId);

  const { data: bayAssignData, isLoading: bayAssignIsLoading, error: bayAssignError, refetch: bayAssignRefetch } = searchTermAssign ? useGetStationBayService({ pageNumber: pageNo, size: pageSize, title: searchTermAssign }, stationId) : useGetStationBayService({ pageNumber: pageNo, size: pageSize }, stationId);

  const { data: bankAccountAssignData, isLoading: bankAccountAssignIsLoading, error: bankAccountAssignError, refetch: bankAccountAssignRefetch } = useGetStationBankAccountService({ pageNumber: pageNo, size: pageSize, ...searchMultiAssign }, stationId);

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const loggedInUserData = useSelector(selectUserInfo);
  const loggedInUserType = loggedInUserData.type;
  const primaryLanguage = loggedInUserData?.primaryLanguage;
  const secondaryLanguage = loggedInUserData?.secondaryLanguage;
  const userTypesData = [
    { value: 100, label: t('Reseller') },
    { value: 110, label: t('Maintenance Head') },
    { value: 120, label: t('Service Provider') },
    { value: 130, label: t('Station Manager') },
    { value: 140, label: t('Service Manager') },
    { value: 150, label: t('Technician') },
  ];
  const userTypesOption = loggedInUserType === -1 ?
    userTypesData.filter(option => option.value === 100 || option.value === 110)
    : loggedInUserType === 100 ?
      userTypesData.filter(option => option.value === 110)
      : userTypesData.filter(option => option.value > loggedInUserType);

  let allStoresAssigned: unknown = storeAssignData?.pages[0].data?.content.map(store => store.linked).every(linked => linked === true);
  let allWarehousesAssigned: unknown = warehouseAssignData?.pages[0].data?.content.map(warehouse => warehouse.linked).every(linked => linked === true);
  let allUsersAssigned: unknown = userAssignData?.pages[0].data?.content.map(user => user.linked).every(linked => linked === true);
  let allBaysAssigned: unknown = bayAssignData?.pages[0].data?.content.map(bay => bay.linked).every(linked => linked === true);
  let allBankAccountsAssigned: unknown = bankAccountAssignData?.pages[0].data?.content.map(bankaccount => bankaccount.linked).every(linked => linked === true);

  useEffect(() => {
    if (!isLoading && !error && !rowView && !openForm && !loadingSearch) {
      refetch();
    }
    if (tableData) {
      setLoading(false);
      if ((tableData?.pages[0] as any).status && !(tableData?.pages[0] as any).success) {
        enqueueSnackbar(<AlertMessages statusCode={(tableData?.pages[0] as any)?.status} />, { variant: 'error' })
      }
    }
    if (tab === 0 && enableEdit) {
      setIsBtnEnabled(false);
    }
    if (!storeAssignIsLoading && !storeAssignError && stationId && stationId !== 0 && tab === 1) {
      const loadData = async () => {
        setLoadingSearchAssign(true);
        await storeAssignRefetch();
        setLoadingSearchAssign(false);
      }
      loadData();
    }
    if (!warehouseAssignIsLoading && !warehouseAssignError && stationId && stationId !== 0 && tab === 2) {
      const loadData = async () => {
        setLoadingSearchAssign(true);
        await warehouseAssignRefetch();
        setLoadingSearchAssign(false);
      }
      loadData();
    }
    if (!userAssignIsLoading && !userAssignError && stationId && stationId !== 0 && tab === 3) {
      const loadData = async () => {
        setLoadingSearchAssign(true);
        await userAssignRefetch();
        setLoadingSearchAssign(false);
      }
      loadData();
      setFilterAssign(['name']);
      setSearchAssignFormData({
        name: '',
        userName: '',
        type: ''
      });
    }
    if (!bayAssignIsLoading && !bayAssignError && stationId && stationId !== 0 && tab === 4) {
      const loadData = async () => {
        setLoadingSearchAssign(true);
        await bayAssignRefetch();
        setLoadingSearchAssign(false);
      }
      loadData();
    }
    if (!bankAccountAssignIsLoading && !bankAccountAssignError && stationId && stationId !== 0 && tab === 5) {
      const loadData = async () => {
        setLoadingSearchAssign(true);
        await bankAccountAssignRefetch();
        setLoadingSearchAssign(false);
      }
      loadData();
      setFilterAssign(['accountTitle']);
      setSearchAssignFormData({
        accountTitle: '',
        bankName: '',
        accountNumber: ''
      });
    }
  }, [pageNo, pageSize, refetch, isLoading, error, priceListIsLoading, priceListError, businessGroupIsLoading, businessGroupError, stationId, tab, tableData, openForm]);

  useEffect(() => {
    if (enableEdit && JSON.stringify(formData) !== JSON.stringify(prevFormData) || openForm && JSON.stringify(formData) !== JSON.stringify(prevFormData)) {
      setIsBtnEnabled(true);
    } else if (enableEdit && JSON.stringify(formData) === JSON.stringify(prevFormData)) {
      setIsBtnEnabled(false);
    }
  }, [enableEdit, formData]);

  useEffect(() => {
    const getData = setTimeout(async () => {
      if (searchTerm !== null && !isLoading && !error && loadingSearch) {
        await refetch();
        setLoadingSearch(false);
      }
    }, 1000);
    return () => clearTimeout(getData)
  }, [searchTerm, loadingSearch]);

  useEffect(() => {
    const getData = setTimeout(async () => {
      if (searchMultiAssign && loadingSearchAssign && tab === 3) {
        await userAssignRefetch()
        setLoadingSearchAssign(false);
      }
      if (searchMultiAssign && loadingSearchAssign && tab === 5) {
        await bankAccountAssignRefetch();
        setLoadingSearchAssign(false);
      }
    }, 300);
    return () => clearTimeout(getData)
  }, [searchMultiAssign]);

  useEffect(() => {
    const getData = setTimeout(async () => {
      if (searchTermAssign !== null && loadingSearchAssign && tab === 1) {
        await storeAssignRefetch();
        setLoadingSearchAssign(false);
      }
      if (searchTermAssign !== null && loadingSearchAssign && tab === 2) {
        await warehouseAssignRefetch();
        setLoadingSearchAssign(false);
      }
      if (searchTermAssign !== null && loadingSearchAssign && tab === 3) {
        await userAssignRefetch();
        setLoadingSearchAssign(false);
      }
      if (searchTermAssign !== null && loadingSearchAssign && tab === 4) {
        await bayAssignRefetch();
        setLoadingSearchAssign(false);
      }
    }, 1000);
    return () => clearTimeout(getData)
  }, [searchTermAssign, loadingSearchAssign]);

  const handleChangePage = (pageNo: number) => {
    setLoading(true);
    setPageNo(pageNo + 1);
    setPageSize(pageSize);
  }
  const handleChangeRows = (pageSize: number) => {
    setLoading(true);
    setPageSize(pageSize);
    setPageNo(1);
  }

  const [errorMessages, setErrorMessages] = useState<Partial<FormData>>({});

  const openModal = () => {
    if (rowView) {
      setRowView(false);
      setStationId(0);
      setPageNo(1);
      setTab(0);
      setSearchAssign('');
      setSearchTermAssign('');
      setSearchMultiAssign({});
    }
    else {
      setIsBtnEnabled(true);
      setOpenForm(!openForm);
      openModalSettings();
      if (!openForm && !enableEdit) {
        priceListRefetch();
        businessGroupRefetch();
        setSearch('');
        setShowSecField(false)
        setSearchTerm('');
      }
    }
  };
  const openModalSettings = () => {
    setEnableEdit(false);
    setErrorMessages({})
    setFormData({
      name: '',
      secondaryName: '',
      checkTyre: false,
      applicableToAllBusinessGroup: false,
      description: '',
      secondaryDescription: '',
      id: 0,
      // stationCategoryId: 0,
      stationCode: '',
      region: '',
      city: '',
      country: '',
      taxNumber: '',
      numberOfTerminal: 0,
      startBalanceDefaultAmount: 0,
      priceListId: 0,
      businessGroupId: 0,
      address: '',
      phoneNumber1: '',
      phoneNumber2: '',
      district: '',
      street: '',
      buildingNumber: '',
      postalCode: '',
      location: '',
      latitude: 0,
      longitude: 0,
      landmark: '',
      email: '',
      website: '',
      googleListingAddress: '',
      plateNumberType: 0,
      extraNotes: '',
      timeZone: '',
      language: '',
      termAndCondition: '',
      imgUrl: '',
      active: false
    });
  }

  const handleSecField = () => {
    setShowSecField((prevValue) => !prevValue);
  }

  const handleChange = (field: string, value: any) => {
    const val = (field === "stationCategoryId" || field === "priceListId" || field === "businessGroupId" || field === "plateNumberType") ?
      (value ? parseInt(value.value) || 0 : 0)
      : (field === "country") ?
        (value ? value.value : '')
        : (field === "numberOfTerminal") ?
          (value ? parseInt(value) : 0)
          : (field === "startBalanceDefaultAmount" || field === "latitude" || field === "longitude") ?
            (value ? parseFloat(value) : 0)
            : field === "phoneNumber1" ?
              value.length === 0 ? '' : value
              : field === "phoneNumber2" ?
                value.length === 0 ? '' :
                  value.length === 0 ? '' : value
                : value;

    setFormData((prevData) => ({
      ...prevData,
      [field]: val,
    }));

    if (requiredFields.includes(field) && !val) {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        [field]: 'This field is required.',
      }));
    } else {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        [field]: undefined,
      }));
    }
  };


  const validateFormData = (data: FormData) => {
    const errors: Partial<FormData> = {};

    if (!data.name?.trim()) {
      errors.name = t('This field is required.');
    }
    if (!data.stationCode?.trim()) {
      errors.stationCode = t('This field is required.');
    }
    // if (!data.stationCategoryId) {
    //   errors.stationCategoryIdError = t('This field is required.');
    // }
    if (data.email && !emailRegex?.test(data.email)) {
      errors.email = t('validEmail');
    }
    if (data.phoneNumber1?.length > 0 && !phoneRegex.test(data.phoneNumber1)) {
      errors.phoneNumber1 = t('phoneLength');
    }
    if (data.phoneNumber2?.length > 0 && !phoneRegex.test(data.phoneNumber2)) {
      errors.phoneNumber2 = t('phoneLength');
    }

    return errors;
  };

  const { onCreateStation, } = useStationService({
    onSuccess: (msg) => {
      const response: any = {};
      Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
      if (response.success) {
        setOpenForm(false);
        refetch();
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('station') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
      }
    },
    onError: (err: any) => {
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
      if (err instanceof Error) {
        console.error(err.stack);
      }
    }
  })

  const { onUpdateStation } = useUpdateStationService({
    onSuccess: (data) => {
      const response: any = {};
      Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
      if (response.success) {
        if (!rowView) {
          setOpenForm(false);
          setEnableEdit(false);
          refetch();
        }
        if (rowView) { setPrevFormData(response?.data); setSelectedRow(response?.data); }
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('station') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
      }
    },
    onError: (err: any) => {
      console.log("err====,  ", err);
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
    },
    id: formData.id,
  });

  const handleSubmit = () => {
    const errors = validateFormData(formData);
    setErrorMessages(errors);

    if (Object.keys(errors).length === 0) {
      setIsBtnEnabled(false);
      setLoading(true);
      const stationData: StationRequest = {
        id: formData.id,
        name: formData.name,
        secondaryName: formData.secondaryName,
        checkTyre: formData.checkTyre,
        applicableToAllBusinessGroup: formData.applicableToAllBusinessGroup,
        description: formData.description,
        secondaryDescription: formData.secondaryDescription,
        // stationCategoryId: formData.stationCategoryId,
        stationCode: formData.stationCode,
        region: formData.region,
        city: formData.city,
        country: formData.country,
        taxNumber: formData.taxNumber,
        numberOfTerminal: formData.numberOfTerminal,
        startBalanceDefaultAmount: formData.startBalanceDefaultAmount,
        priceListId: formData.priceListId,
        businessGroupId: formData.businessGroupId,
        address: formData.address,
        phoneNumber1: formData.phoneNumber1,
        phoneNumber2: formData.phoneNumber2,
        district: formData.district,
        street: formData.street,
        buildingNumber: formData.buildingNumber,
        postalCode: formData.postalCode,
        location: formData.location,
        latitude: formData.latitude,
        longitude: formData.longitude,
        landmark: formData.landmark,
        email: formData.email,
        website: formData.website,
        googleListingAddress: formData.googleListingAddress,
        plateNumberType: formData.plateNumberType,
        extraNotes: formData.extraNotes,
        timeZone: formData.timeZone,
        language: formData.language,
        termAndCondition: formData.termAndCondition,
        imgUrl: formData.imgUrl,
        active: formData.active
      };

      if (enableEdit) {
        onUpdateStation(
          stationData
        );
      } else {
        onCreateStation(stationData);
      }
    }
  };

  const onEdit = (row: any) => {
    setEnableEdit(true);
    setIsBtnEnabled(false);
    setFormData(row);
    setPrevFormData(row);
    setOpenForm(true);
    priceListRefetch();
    businessGroupRefetch();
    setSearch('');
    setSearchTerm('');
  }

  const onDelete = (row: any) => {
    setFormData(row)
    setDeleteModal(true)
  }

  const { onDeleteStation } = useDeleteStationService({
    id: formData.id,
    onSuccess: (msg) => {
      if (msg.success) {
        enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('station') + ' ' + t('deleted') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
      }
      refetch();
      if (tableData?.pages[0].data.content.length === 1) {
        handleChangePage(pageNo - 1)
      }
    },
    onError: (err: any) => {
      console.log("err====,  ", err);
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
    },
  });

  const handleDelete = () => {
    setDeleteModal(false)
    setLoading(true);
    onDeleteStation()
  }
  const cancelDelete = () => {
    setDeleteModal(false)
  }

  const clearSearch = () => {
    if (!rowView) {
      setSearch('');
      setSearchTerm('');
      if (searchTerm) { handleSearch(''); }
    } else {
      setSearchAssign('');
      setSearchTermAssign('');
      if (searchTermAssign) { handleSearch(''); }
    }
  }
  const handleSearch = (search: string) => {
    if (!rowView) {
      setLoadingSearch(true);
      setSearchTerm(search);
      setPageNo(1);
      setPageSize(pageSize);
    } else {
      setLoadingSearchAssign(true);
      if (tab == 3 || tab == 5) {
        setSearchMultiAssign(search);
      } else {
        setSearchTermAssign(search);
      }
      setPageNo(1);
      setPageSize(pageSize);
    }
  };

  const handleSearchClick = () => {
    if (!rowView) {
      const trimmedSearch = search.trim();
      if (trimmedSearch !== '') {
        handleSearch(trimmedSearch);
      }
    } else {
      const trimmedSearch = searchAssign.trim();
      if (trimmedSearch !== '') {
        handleSearch(trimmedSearch);
      }
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!rowView) {
      setSearch(event.target.value.trim());
      if (!event.target.value.trim()) handleSearch('');
    } else {
      setSearchAssign(event.target.value.trim());
      if (!event.target.value.trim()) handleSearch('');
    }
  };


  const onChangeStatus = (row: any) => {
    row.active ? setStatusText('deactivate') : setStatusText('activate');
    setFormData(row);
    setStatusModal(true);
  }
  const cancelChangeStatus = () => {
    setStatusModal(false);
  }
  const submitChangeStatus = () => {
    setStatusModal(false);
    setLoading(true);
    onChangeStatusStation({});
  }
  const { onChangeStatusStation } = useChangeStatusStationService({
    id: formData.id,
    onSuccess: (msg) => {
      if (msg.success) {
        enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('station') + ' ' + t(statusText) + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
      }
      refetch();
    },
    onError: (err) => {
      console.error("===", err)
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
    },
    active: formData.active ? "inactive" : "active"
  });
  const showRowView = (row: any) => {
    setEnableEdit(true);
    setOpenForm(false);
    setFormData(row);
    setPrevFormData(row);
    setErrorMessages({});
    priceListRefetch();
    businessGroupRefetch();
    setRowView(true);
    setStationId(row.id);
    setSelectedRow(row);
    setSearch('');
    setSearchTerm('');
  }
  const handleChangeTabs = (tabId: number) => {
    setSearchAssign('');
    setSearchTermAssign('');
    setSearchMultiAssign({});
    if (tabId === 0) {
      setTab(0);
      setEnableEdit(true);
      setOpenForm(false);
      setFormData(selectedRow);
      setErrorMessages({});
      priceListRefetch();
      businessGroupRefetch();
      setIsBtnEnabled(false);
    } else if (tabId === 1) {
      setTab(1);
      setPageNo(1);
      setEnableEdit(false);
      setErrorMessages({});
    } else if (tabId === 2) {
      setTab(2);
      setPageNo(1);
      setEnableEdit(false);
      setErrorMessages({});
    } else if (tabId === 3) {
      setTab(3);
      setPageNo(1);
      setEnableEdit(false);
      setErrorMessages({});
      clearSearchAssign();
    } else if (tabId === 4) {
      setTab(4);
      setPageNo(1);
      setEnableEdit(false);
      setErrorMessages({});
    } else if (tabId === 5) {
      setTab(5);
      setPageNo(1);
      setEnableEdit(false);
      setErrorMessages({});
      clearSearchAssign();
    }
  }
  const assignUnassignSingleEntity = (isChecked: boolean, row: any) => {
    setLoadingSearchAssign(true);
    if (isChecked) {
      if (tab === 1) {
        onAssignStore({ stationId: stationId, storeId: row.id });
      } else if (tab === 2) {
        onAssignWarehouse({ stationId: stationId, warehouseId: row.id });
      } else if (tab === 3) {
        onAssignUser({ stationId: stationId, userId: row.id });
      } else if (tab === 4) {
        onAssignBay({ stationId: stationId, bayId: row.id });
      } else if (tab === 5) {
        onAssignBankAccount({ stationId: stationId, bankaccountId: row.id });
      }
    } else {
      if (tab === 1) {
        onUnassignStore({ stationId: stationId, storeId: row.id });
      } else if (tab === 2) {
        onUnassignWarehouse({ stationId: stationId, warehouseId: row.id });
      } else if (tab === 3) {
        onUnassignUser({ stationId: stationId, userId: row.id });
      } else if (tab === 4) {
        onUnassignBay({ stationId: stationId, bayId: row.id });
      } else if (tab === 5) {
        onUnassignBankAccount({ stationId: stationId, bankaccountId: row.id });
      }
    }
  }
  const assignUnassignAllEntities = (isChecked: boolean) => {
    setLoadingSearchAssign(true);
    if (isChecked) {
      if (tab === 1) {
        onAssignAllStores({ stationId: stationId });
      } else if (tab === 2) {
        onAssignAllWarehouses({ stationId: stationId });
      } else if (tab === 3) {
        onAssignAllUsers({ stationId: stationId });
      } else if (tab === 4) {
        onAssignAllBays({ stationId: stationId });
      } else if (tab === 5) {
        onAssignAllBankAccounts({ stationId: stationId });
      }
    } else {
      if (tab === 1) {
        onUnassignAllStores({ stationId: stationId });
      } else if (tab === 2) {
        onUnassignAllWarehouses({ stationId: stationId });
      } else if (tab === 3) {
        onUnassignAllUsers({ stationId: stationId });
      } else if (tab === 4) {
        onUnassignAllBays({ stationId: stationId });
      } else if (tab === 5) {
        onUnassignAllBankAccounts({ stationId: stationId });
      }
    }
  }
  const { onAssignStore } = useAssignStoreService({
    onSuccess: async (msg) => {
      const response: any = {};
      Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
      if (response.success) {
        if (storeAssignData?.pages[0].data.content.length === storeAssignData?.pages[0].data.content.filter(store => store.linked).length) {
          allStoresAssigned = true;
        }
        await storeAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('store') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        await storeAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
      }
    },
    onError: (err: any) => {
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
      if (err instanceof Error) {
        console.error(err.stack);
      }
    }
  })
  const { onUnassignStore } = useUnassignStoreService({
    onSuccess: async (data) => {
      const response: any = {};
      Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
      if (response.success) {
        if (storeAssignData?.pages[0].data.content.length !== storeAssignData?.pages[0].data.content.filter(store => store.linked).length) {
          allStoresAssigned = false;
        }
        await storeAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('store') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        await storeAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
      }
    },
    onError: (err: any) => {
      console.log("err====,  ", err);
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
    },
  });
  const { onAssignAllStores } = useAssignAllStoreService({
    onSuccess: async (msg) => {
      const response: any = {};
      Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
      if (response.success) {
        allStoresAssigned = true;
        await storeAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('All') + ' ' + t('stores') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        await storeAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
      }
    },
    onError: (err: any) => {
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
      if (err instanceof Error) {
        console.error(err.stack);
      }
    }
  })
  const { onUnassignAllStores } = useUnassignAllStoreService({
    onSuccess: async (data) => {
      const response: any = {};
      Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
      if (response.success) {
        allStoresAssigned = false;
        await storeAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('All') + ' ' + t('stores') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        await storeAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
      }
    },
    onError: (err: any) => {
      console.log("err====,  ", err);
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
    },
  });
  const { onAssignWarehouse } = useAssignWarehouseService({
    onSuccess: async (msg) => {
      const response: any = {};
      Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
      if (response.success) {
        if (warehouseAssignData?.pages[0].data.content.length === warehouseAssignData?.pages[0].data.content.filter(warehouse => warehouse.linked).length) {
          allWarehousesAssigned = true;
        }
        await warehouseAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('warehouse') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        await warehouseAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
      }
    },
    onError: (err: any) => {
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
      if (err instanceof Error) {
        console.error(err.stack);
      }
    }
  })
  const { onUnassignWarehouse } = useUnassignWarehouseService({
    onSuccess: async (data) => {
      const response: any = {};
      Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
      if (response.success) {
        if (warehouseAssignData?.pages[0].data.content.length !== warehouseAssignData?.pages[0].data.content.filter(warehouse => warehouse.linked).length) {
          allWarehousesAssigned = false;
        }
        await warehouseAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('warehouse') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        await warehouseAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
      }
    },
    onError: (err: any) => {
      console.log("err====,  ", err);
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
    },
  });
  const { onAssignAllWarehouses } = useAssignAllWarehouseService({
    onSuccess: async (msg) => {
      const response: any = {};
      Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
      if (response.success) {
        allWarehousesAssigned = true;
        await warehouseAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('All') + ' ' + t('warehouses') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        await warehouseAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
      }
    },
    onError: (err: any) => {
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
      if (err instanceof Error) {
        console.error(err.stack);
      }
    }
  })
  const { onUnassignAllWarehouses } = useUnassignAllWarehouseService({
    onSuccess: async (data) => {
      const response: any = {};
      Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
      if (response.success) {
        allWarehousesAssigned = false;
        await warehouseAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('All') + ' ' + t('warehouses') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        await warehouseAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
      }
    },
    onError: (err: any) => {
      console.log("err====,  ", err);
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
    },
  });
  const { onAssignUser } = useAssignUserService({
    onSuccess: async (msg) => {
      const response: any = {};
      Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
      if (response.success) {
        if (userAssignData?.pages[0].data.content.length === userAssignData?.pages[0].data.content.filter(user => user.linked).length) {
          allUsersAssigned = true;
        }
        await userAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('user') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        await userAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
      }
    },
    onError: (err: any) => {
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
      if (err instanceof Error) {
        console.error(err.stack);
      }
    }
  })
  const { onUnassignUser } = useUnassignUserService({
    onSuccess: async (data) => {
      const response: any = {};
      Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
      if (response.success) {
        if (userAssignData?.pages[0].data.content.length !== userAssignData?.pages[0].data.content.filter(user => user.linked).length) {
          allUsersAssigned = false;
        }
        await userAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('user') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        await userAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
      }
    },
    onError: (err: any) => {
      console.log("err====,  ", err);
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
    },
  });
  const { onAssignAllUsers } = useAssignAllUserService({
    onSuccess: async (msg) => {
      const response: any = {};
      Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
      if (response.success) {
        allUsersAssigned = true;
        await userAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('All') + ' ' + t('users') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        await userAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
      }
    },
    onError: (err: any) => {
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
      if (err instanceof Error) {
        console.error(err.stack);
      }
    }
  })
  const { onUnassignAllUsers } = useUnassignAllUserService({
    onSuccess: async (data) => {
      const response: any = {};
      Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
      if (response.success) {
        allUsersAssigned = false;
        await userAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('All') + ' ' + t('users') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        await userAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
      }
    },
    onError: (err: any) => {
      console.log("err====,  ", err);
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
    },
  });
  const { onAssignBay } = useAssignBayService({
    onSuccess: async (msg) => {
      const response: any = {};
      Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
      if (response.success) {
        if (bayAssignData?.pages[0].data.content.length === bayAssignData?.pages[0].data.content.filter(bay => bay.linked).length) {
          allBaysAssigned = true;
        }
        await bayAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('bay') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        await bayAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
      }
    },
    onError: (err: any) => {
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
      if (err instanceof Error) {
        console.error(err.stack);
      }
    }
  })
  const { onUnassignBay } = useUnassignBayService({
    onSuccess: async (data) => {
      const response: any = {};
      Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
      if (response.success) {
        if (bayAssignData?.pages[0].data.content.length !== bayAssignData?.pages[0].data.content.filter(bay => bay.linked).length) {
          allBaysAssigned = false;
        }
        await bayAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('bay') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        await bayAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
      }
    },
    onError: (err: any) => {
      console.log("err====,  ", err);
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
    },
  });
  const { onAssignAllBays } = useAssignAllBayService({
    onSuccess: async (msg) => {
      const response: any = {};
      Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
      if (response.success) {
        allBaysAssigned = true;
        await bayAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('All') + ' ' + t('bays') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        await bayAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
      }
    },
    onError: (err: any) => {
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
      if (err instanceof Error) {
        console.error(err.stack);
      }
    }
  })
  const { onUnassignAllBays } = useUnassignAllBayService({
    onSuccess: async (data) => {
      const response: any = {};
      Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
      if (response.success) {
        allBaysAssigned = false;
        await bayAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('All') + ' ' + ' ' + t('bays') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        await bayAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
      }
    },
    onError: (err: any) => {
      console.log("err====,  ", err);
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
    },
  });
  const { onAssignBankAccount } = useAssignBankAccountService({
    onSuccess: async (msg) => {
      const response: any = {};
      Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
      if (response.success) {
        if (bankAccountAssignData?.pages[0].data.content.length === bankAccountAssignData?.pages[0].data.content.filter(bankaccount => bankaccount.linked).length) {
          allBankAccountsAssigned = true;
        }
        await bankAccountAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('bankaccount') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        await bankAccountAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
      }
    },
    onError: (err: any) => {
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
      if (err instanceof Error) {
        console.error(err.stack);
      }
    }
  })
  const { onUnassignBankAccount } = useUnassignBankAccountService({
    onSuccess: async (data) => {
      const response: any = {};
      Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
      if (response.success) {
        if (bankAccountAssignData?.pages[0].data.content.length !== bankAccountAssignData?.pages[0].data.content.filter(bankaccount => bankaccount.linked).length) {
          allBankAccountsAssigned = false;
        }
        await bankAccountAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('bankaccount') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        await bankAccountAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
      }
    },
    onError: (err: any) => {
      console.log("err====,  ", err);
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
    },
  });
  const { onAssignAllBankAccounts } = useAssignAllBankAccountService({
    onSuccess: async (msg) => {
      const response: any = {};
      Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
      if (response.success) {
        allBankAccountsAssigned = true;
        await bankAccountAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('All') + ' ' + t('bankaccounts') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        await bankAccountAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
      }
    },
    onError: (err: any) => {
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
      if (err instanceof Error) {
        console.error(err.stack);
      }
    }
  })
  const { onUnassignAllBankAccounts } = useUnassignAllBankAccountService({
    onSuccess: async (data) => {
      const response: any = {};
      Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
      if (response.success) {
        allBankAccountsAssigned = false;
        await bankAccountAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('All') + ' ' + t('bankaccounts') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        await bankAccountAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
      }
    },
    onError: (err: any) => {
      console.log("err====,  ", err);
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
    },
  });

  // child Search
  const clearSearchAssign = () => {
    setFilterAssign(tab === 3 ? ['name'] : ['accountTitle']);
    setSearchAssignFormData(tab === 3 ? {
      name: '',
      userName: '',
      type: ''
    } : {
      accountTitle: '',
      bankName: '',
      accountNumber: ''
    });
    setSearchAssignErrorMessages({});
    if (Object.keys(searchMultiAssign).length) {
      setLoadingSearchAssign(true);
      setSearchMultiAssign({});
    }
  }
  const handleChangeAssignFilter = (event: SelectChangeEvent<typeof filterAssign>) => {
    const { target: { value }, } = event;
    setFilterAssign(typeof value === 'string' ? value.split(',') : value,);
    setSearchAssignFormData(prevFormData =>
      Object.fromEntries(Object.keys(prevFormData).map(key =>
        [key, value.includes(key) ? prevFormData[key] : '']
      ))
    );
    if (!value.length && Object.keys(searchMultiAssign).length) {
      clearSearchAssign();
    }
  };
  const handleChangeSearchAssign = (field: string, value: any) => {
    const val = field === 'type' ? (value ? parseInt(value.value) || 0 : 0) : value;

    setSearchAssignFormData((prevData) => ({
      ...prevData,
      [field]: val,
    }));

    if (filterAssign.includes(field) && !val) {
      setSearchAssignErrorMessages((prevErrors) => ({
        ...prevErrors,
        [field]: 'This field is required.',
      }));
    } else {
      setSearchAssignErrorMessages((prevErrors) => ({
        ...prevErrors,
        [field]: undefined,
      }));
    }
  };
  const validateSearchAssignFormData = (data: SearchAssignFormData) => {
    const errors: Partial<SearchAssignFormData> = {};

    filterAssign.forEach(fieldName => {
      if (!data[fieldName]) {
        errors[fieldName] = t('required');
      }
    });

    return errors;
  };
  const handleSubmitSearchAssign = () => {
    const errors = validateSearchAssignFormData(searchAssignFormData);
    setSearchAssignErrorMessages(errors);

    if (Object.keys(errors).length === 0) {
      setLoadingSearchAssign(true);
      const filteredSearch: Record<string, string | number> = {};

      Object.entries(searchAssignFormData).forEach(([key, value]) => {
        if (value) {
          filteredSearch[key] = typeof value === 'string' ? value.trim() : value;
        }
      });
      setSearchMultiAssign(filteredSearch);
      setPageNo(1);
      setPageSize(pageSize);
    }
  };
  const adjustActions = (actions: any[]) => {
    return checkPrivileges('ROLE_STATION_UPDATE')
      ? (checkPrivileges('ROLE_STATION_ARCHIVE')
        ? actions
        : actions.filter(action => action.label !== 'delete'))
      : (checkPrivileges('ROLE_STATION_ARCHIVE')
        ? actions.filter(action => action.label !== 'edit')
        : []);
  }

  return (
    <Box>
      <StationComponent
        openModal={openModal}
        closeModal={openModal}
        handleSecField={handleSecField}
        secondaryLanguage={secondaryLanguage}
        primaryLanguage={primaryLanguage}
        handleSearchClick={handleSearchClick}
        handleInputChange={handleInputChange}
        handleDelete={handleDelete}
        cancelDelete={cancelDelete}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        formData={formData}
        handleChangePage={handleChangePage}
        handleChangeRows={handleChangeRows}
        onChangeStatus={onChangeStatus}
        cancelChangeStatus={cancelChangeStatus}
        submitChangeStatus={submitChangeStatus}
        showRowView={showRowView}
        rowView={rowView}
        handleChangeTabs={handleChangeTabs}
        assignUnassignSingleEntity={assignUnassignSingleEntity}
        assignUnassignAllEntities={assignUnassignAllEntities}
        allStoresAssigned={allStoresAssigned}
        allWarehousesAssigned={allWarehousesAssigned}
        allUsersAssigned={allUsersAssigned}
        allBaysAssigned={allBaysAssigned}
        allBankAccountsAssigned={allBankAccountsAssigned}
        columns={checkPrivileges("ROLE_STATION_ACTIVE") ? [
          { id: 'id', label: 'Id', numeric: false },
          { id: 'name', label: 'name', numeric: false },
          { id: 'stationCode', label: 'stationCode', numeric: false },
          { id: 'phoneNumber1', label: 'phoneNumber', numeric: false },
          { id: 'email', label: 'email', numeric: false },
          { id: 'city', label: 'city', numeric: false },
          { id: 'country', label: 'country', numeric: false },
          { id: 'active', label: 'status', numeric: false },
          { id: 'actions', label: 'actions', numeric: false },
        ] : [
          { id: 'id', label: 'Id', numeric: false },
          { id: 'name', label: 'name', numeric: false },
          { id: 'stationCode', label: 'stationCode', numeric: false },
          { id: 'phoneNumber1', label: 'phoneNumber', numeric: false },
          { id: 'email', label: 'email', numeric: false },
          { id: 'city', label: 'city', numeric: false },
          { id: 'country', label: 'country', numeric: false },
          { id: 'actions', label: 'actions', numeric: false },
        ]}
        data={tableData?.pages[0] || {}}
        priceListData={priceListData?.pages[0] || {}}
        businessGroupData={businessGroupData?.pages[0] || {}}
        storeAssignData={storeAssignData?.pages[0] || {}}
        warehouseAssignData={warehouseAssignData?.pages[0] || {}}
        userAssignData={userAssignData?.pages[0] || {}}
        bayAssignData={bayAssignData?.pages[0] || {}}
        bankAccountAssignData={bankAccountAssignData?.pages[0] || {}}
        clearSearch={clearSearch}
        {...{ openForm, openDeleteModal, enableEdit, showSecField, pageNo, pageSize, statusModal, statusText, isBtnEnabled, loading, search, loadingSearch, searchAssign, loadingSearchAssign, tab, filterAssign, searchMultiAssign, userTypesOption }}
        errorMessages={errorMessages}
        actions1={adjustActions([
          { label: "edit", onClick: onEdit, icon: <EditIcon /> },
          { label: "delete", onClick: onDelete, icon: <DeleteIcon /> },
        ])}
        searchAssignFormData={searchAssignFormData}
        handleChangeAssignFilter={handleChangeAssignFilter}
        handleChangeSearchAssign={handleChangeSearchAssign}
        handleSubmitSearchAssign={handleSubmitSearchAssign}
        searchAssignErrorMessages={searchAssignErrorMessages}
        clearSearchAssign={clearSearchAssign}

      />
    </Box>
  )
}