import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import ItemCategoryComponent from 'components/ItemCatagories/Index';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useItemCategoryService,useDeleteItemCategoryService,useGetItemCategoryService,useUpdateItemCategoryService,useChangeStatusItemCategoryService } from 'subModule/src/services/itemCatagories/useItemCatagoreis';
import { ItemCategoryRequest } from 'subModule/src/services/itemCatagories/interface';
import { useSnackbar } from 'notistack';
import AlertMessages from 'common/Alert/AlertMessages';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUserInfo } from 'features/user/userSlice';
import { checkPrivileges } from 'Helpers/CheckPrivileges';

interface FormData {
  unitTitle: string,
  secondaryUnitTitle: string,
  id: number,
  categoryCode: string,
  default: boolean,
  active: boolean
}

  const requiredFields = ['categoryCode','unitTitle'];

  export default function ItemCategory () {
    const [showSecField, setShowSecField] = useState<boolean>(false);
    const [openForm, setOpenForm] = useState(false);
    const [openDeleteModal, setDeleteModal] = useState(false);
    const [enableEdit, setEnableEdit] = useState(false);
    const [pageNo, setPageNo] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);
    const [statusModal, setStatusModal] = useState(false);
    const [statusText, setStatusText] = useState<string>('');
    const [searchTerm, setSearchTerm] = useState<any>(null);
    const [search, setSearch] = useState('');
    const [isBtnEnabled, setIsBtnEnabled] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
    const [prevFormData, setPrevFormData] = useState<object>({});
    // const [data] = useState(rows);
    const [formData, setFormData] = useState<FormData>({
      unitTitle: '',
      secondaryUnitTitle: '',
        id: 0,
        categoryCode: "",
        default: false,
        active:false
    });

    const { data: tableData,  isLoading, error,  refetch } =searchTerm? useGetItemCategoryService({pageNumber : pageNo,size: pageSize,unitTitle:searchTerm}):useGetItemCategoryService({pageNumber : pageNo,size: pageSize})

    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const loggedInUserData = useSelector(selectUserInfo);
    const primaryLanguage = loggedInUserData?.primaryLanguage;
    const secondaryLanguage = loggedInUserData?.secondaryLanguage;

    useEffect(() => {
      if (!isLoading && !error && !openForm &&!loadingSearch) { // Only refetch if not already loading or in error state
        refetch();
      }
      if(tableData) {
        setLoading(false);
        if((tableData?.pages[0] as any).status && !(tableData?.pages[0] as any).success) {
          enqueueSnackbar(<AlertMessages statusCode={(tableData?.pages[0] as any)?.status} />, { variant: 'error' })
        }
      }
    }, [pageNo, pageSize, refetch, isLoading, error, tableData, openForm]);

    useEffect(() => {
      if(enableEdit && JSON.stringify(formData) !== JSON.stringify(prevFormData) || openForm && JSON.stringify(formData) !== JSON.stringify(prevFormData)) {
        setIsBtnEnabled(true);
      } else if(enableEdit && JSON.stringify(formData) === JSON.stringify(prevFormData)) {
        setIsBtnEnabled(false);
      }
    }, [enableEdit, formData]);

    useEffect(() => {
      const getData = setTimeout(async() => {
      if (searchTerm !== null && !isLoading && !error && loadingSearch) { 
      await refetch();
        setLoadingSearch(false);
      }
    }, 1000)
    return () => clearTimeout(getData)
    }, [searchTerm, isLoading, error, refetch, loadingSearch]);
    
    const handleChangePage = (pageNo : number) => {  
      setLoading(true);
      setPageNo(pageNo + 1);
      setPageSize(pageSize);
    }
    const handleChangeRows = (pageSize : number) => {
      setLoading(true);
      setPageSize(pageSize);
      setPageNo(1);
    }

    const [errorMessages, setErrorMessages] = useState<Partial<FormData>>({});

    const openModal = () => {
        setOpenForm(!openForm);
        setIsBtnEnabled(true);
        setEnableEdit(false);
        setErrorMessages({});
        setSearch("");
        setSearchTerm("");
        setShowSecField(false)
        setFormData({
          unitTitle: '',
          secondaryUnitTitle: '',
            id: 0,
            categoryCode: "",
            default: false,
            active:false
        });
      };

      const handleSecField = () => {
        setShowSecField((prevValue) => !prevValue);
      }

      const handleChange = (field: string, value: any) => {
        const  val = field === "businessId" ? (value ? parseInt(value.value) || 0 : 0) : value;
        setFormData((prevData) => ({
          ...prevData,
          [field]: val,
        }));
      
        if (requiredFields.includes(field) && !val) {
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: 'This field is required.',
          }));
        } else {
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: undefined,
          }));
        }
        if(enableEdit) {
          setIsBtnEnabled(true);
        }
      };
      
    
      const validateFormData = (data: FormData) => {
        const errors: Partial<FormData> = {};

        if (!data.categoryCode?.trim()) {
          errors.categoryCode = t('This field is required.');
        }
        if (!data.unitTitle?.trim()) {
          errors.unitTitle = t('This field is required.');
        }
    
        return errors;
      };

      const { onCreateItemCategory  } = useItemCategoryService({
        onSuccess: (msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          setOpenForm(false);
          if(response.success) {
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('itemCategory') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
          refetch(); // This will use the updated pageNo and pageSize

        },
        onError: (err) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
          console.error(err.stack);
        }
      }})

      const {onUpdateItemCategory } = useUpdateItemCategoryService({
        onSuccess: (data) => {
          const response: any = {};
          Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
          setEnableEdit(false);
          setOpenForm(false);
          if(response.success) {
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('itemCategory') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
          refetch(); // This will use the updated pageNo and pageSize

        },
        onError: (err) => {
          console.error('Login error======', err, enableEdit);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
        id :formData.id,
      }
      );

      const handleSubmit = () => {
        const errors = validateFormData(formData);
        setErrorMessages(errors);
      
        if (Object.keys(errors).length === 0) {
          setIsBtnEnabled(false);
          setLoading(true);
          const ItemCategoryData: ItemCategoryRequest = {
            ...formData, 
            unitTitle: formData.unitTitle,
            secondaryUnitTitle: formData.secondaryUnitTitle,
            id:formData.id,
            categoryCode:formData.categoryCode,
            default:formData.default,
            active:formData.active
          };
     
          if (enableEdit) {
            onUpdateItemCategory(
                ItemCategoryData
            );
          } else {
            onCreateItemCategory(ItemCategoryData);
          }
        }
      };

      const onEdit = (row:any) => {
        setEnableEdit(true);
        setFormData(row);
        setPrevFormData(row);
        setIsBtnEnabled(false);
        setOpenForm(true);
        setSearch("");
        setSearchTerm("");
      }

      const onDelete = (row:any) => {
        setFormData(row)
        setDeleteModal(true)
      }

      const { onDeleteItemCategory } = useDeleteItemCategoryService({
        id: formData.id,
        onSuccess: (msg) => {
          if(msg.success) {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('itemCategory') + ' ' + t('deleted') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
          }
          refetch(); // This will use the updated pageNo and pageSize
          if(tableData?.pages[0].data.content.length === 1) {
            handleChangePage(pageNo - 1)
          }
        },
        onError: (err) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
      });

      const handleDelete = () => {
        setDeleteModal(false)
        setLoading(true);
        onDeleteItemCategory()
      }
      const cancelDelete = () => {
        setDeleteModal(false)
      }

      const clearSearch = () => {
        setSearch('');
        setSearchTerm('');
        if(searchTerm) { handleSearch(''); }
      }
      const handleSearch = (search: string) => {
        setLoadingSearch(true);
        setSearchTerm(search);
        setPageNo(1);
        setPageSize(pageSize);
      };
    
      const handleSearchClick = () => {
        const trimmedSearch = search.trim();
        if (trimmedSearch !== '') {
          handleSearch(trimmedSearch);
        }
      };
    
      const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value.trim());
        if (!event.target.value.trim()) handleSearch('');
      };

      const onChangeStatus = (row: any) => {
        row.active ? setStatusText('deactivate') : setStatusText('activate');
        setFormData(row);
        setStatusModal(true);
      }
      const cancelChangeStatus = () => {
        setStatusModal(false);
      }
      const submitChangeStatus = () => {
        setStatusModal(false);
        setLoading(true);
        onChangeStatusItemCategory({});
      }
      const { onChangeStatusItemCategory } = useChangeStatusItemCategoryService({
        id: formData.id,
        onSuccess: (msg) => {
          if(msg.success) {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('itemCategory') + ' ' + t(statusText) + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
          }
          refetch();
        },
        onError: (err: any) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
        active: formData.active ? "inactive" : "active"
      });
      const showRowView = (row: any) => {
        onEdit(row);
      }
      const adjustActions = (actions: any[]) => {
        return checkPrivileges('ROLE_ITEMCATEGORY_UPDATE') 
          ? (checkPrivileges('ROLE_ITEMCATEGORY_ARCHIVE') 
              ? actions 
              : actions.filter(action => action.label !== 'delete')) 
          : (checkPrivileges('ROLE_ITEMCATEGORY_ARCHIVE') 
              ? actions.filter(action => action.label !== 'edit') 
              : []);
      }

    return(
        <Box>
            <ItemCategoryComponent
                openModal={openModal}
                closeModal={openModal}
                handleSecField={handleSecField}
                secondaryLanguage={secondaryLanguage}
                primaryLanguage={primaryLanguage}
                handleSearchClick={handleSearchClick}
                handleInputChange={handleInputChange}
                handleDelete={handleDelete}
                cancelDelete={cancelDelete}
                handleChange={handleChange} 
                handleSubmit={handleSubmit}
                formData={formData}
                handleChangePage={handleChangePage}
                handleChangeRows={handleChangeRows}
                onChangeStatus={onChangeStatus}
                cancelChangeStatus={cancelChangeStatus}
                submitChangeStatus={submitChangeStatus}
                showRowView={showRowView}
                clearSearch={clearSearch}
                columns={checkPrivileges("ROLE_ITEMCATEGORY_ACTIVE") ? [
                    { id: 'unitTitle', label: 'title', numeric: false },
                    { id: 'categoryCode', label: 'categoryCode', numeric: false },
                    { id: 'active', label: 'status', numeric: false },
                    { id: 'actions', label: 'actions', numeric: false },
                ] : [
                  { id: 'unitTitle', label: 'title', numeric: false },
                  { id: 'categoryCode', label: 'categoryCode', numeric: false },
                  { id: 'actions', label: 'actions', numeric: false },
              ]}
                data={tableData?.pages[0] || {}}
                {...{ openForm, openDeleteModal, enableEdit, showSecField,pageNo, pageSize, statusModal, statusText, search,isBtnEnabled,loading,loadingSearch   }}
                errorMessages={errorMessages}
                actions1 = {adjustActions([
                  { label: "edit", onClick: onEdit, icon: <EditIcon /> },
                  { label: "delete", onClick: onDelete, icon: <DeleteIcon /> },
                ])}
            />
        </Box>
    )
  }