import React, { useEffect, useState } from 'react';
import { Checkbox } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Box from '@mui/material/Box';
import Loader from 'layout/Loader';
import { useTranslation } from 'react-i18next';

interface PrivilegeData {
  id: number;
  active: boolean;
  erole: string;
  parentId: number | null;
  accessLimit: any;
  linked: boolean;
}

interface Props {
  privilegeData: PrivilegeData[];
  assignUnassignSingleEntity: (isChecked: boolean, row: any, scroll: number, accessLimit?: number | null, group?: boolean) => void;
  loading: boolean;
  scrollPosition: number;
  assignPrivilege?: boolean;
  unassignPrivilege?: boolean;
}

const Privileges: React.FC<Props> = ({
  privilegeData, assignUnassignSingleEntity, loading, scrollPosition, assignPrivilege, unassignPrivilege
}) => {
  const [accessLimit, setAccessLimit] = useState<number | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (!loading) { window.scrollTo(0, scrollPosition) }
  }, [loading]);

  const handleParentCheckboxChange = (parent: any, isChecked: boolean) => {
    setAccessLimit(null);
    let groups: any[] = [];
    groups = [parent, ...privilegeData
      .filter((item) => item.parentId === parent.id)
      .map((item) => item)
    ];

    groups.map((group) => {
      if ((group as any).parentId === null) {
        assignUnassignSingleEntity(!(group as any).linked, group, window.scrollY, accessLimit, true);
      } else if ((group as any).parentId !== null) {
        if (isChecked) {
          assignUnassignSingleEntity(true, group, window.scrollY, accessLimit, true);
        } else {
          assignUnassignSingleEntity(false, group, window.scrollY, accessLimit, true);
        }
      }
    });
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Box>
          {privilegeData
            .filter((item) => item.parentId === null)
            .map((parentItem) => (
              <Accordion key={parentItem.id} defaultExpanded>
                <AccordionSummary
                  expandIcon={<span><KeyboardArrowDownIcon /></span>}
                  sx={{
                    padding: '0 5px',
                    minHeight: '20px !important',
                    '& .MuiAccordionSummary-content': {
                      alignItems: 'center',
                      margin: '3px 0',
                    },
                    '& .Mui-expanded': {
                      margin: '0 !important',
                    }
                  }}
                >
                  <Checkbox
                    checked={parentItem.linked && privilegeData.filter((item) => item.parentId === parentItem.id).every((childItem) => childItem.linked)}
                    className='text-primary-color'
                    onChange={(event) => handleParentCheckboxChange(parentItem, event.target.checked)}
                    disabled={(assignPrivilege && !parentItem.linked) ? false
                      : (unassignPrivilege && parentItem.linked) ? false
                        : true
                    }
                    sx={{
                      opacity: 1,
                      '&.Mui-disabled': {
                        opacity: 0.3
                      }
                    }}
                  />
                  <Typography className='font-Saira font-[600] text-[14px] text-txt-color'>
                    {parentItem.erole !== 'ROLE_JOBCARD_DISCOUNT_UPDATE' && parentItem.erole !== 'ROLE_WORKORDER_DISCOUNT_UPDATE' ? t(parentItem.erole.split('_')[1]) : t(parentItem.erole)}
                  </Typography>
                </AccordionSummary>
                {parentItem.erole === 'ROLE_JOBCARD_DISCOUNT_UPDATE' || parentItem.erole === 'ROLE_WORKORDER_DISCOUNT_UPDATE' ?
                  <div className='flex w-100 items-center font-Saira'>
                    <label className='pl-[16px] pe-[10px] font-bold leading-[21px] text-[14px]'>{t('limit')} : </label>
                    <input type="number" id="formfield"
                      min={0}
                      max={100}
                      className='w-[200px]'
                      defaultValue={parentItem.accessLimit}
                      onChange={(e) => setAccessLimit(
                        ((e as any).target.value < 0) ? 0
                          : ((e as any).target.value > 100) ? 100
                            : (e as any).target.value
                      )}
                    />
                    <span className='ps-[5px] leading-[21px] text-[14px]'>%</span>
                  </div>
                  : ''
                }
                <AccordionDetails className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-[10px]'>
                  {parentItem.erole !== 'ROLE_JOBCARD_DISCOUNT_UPDATE' && parentItem.erole !== 'ROLE_WORKORDER_DISCOUNT_UPDATE' ? <Box key={parentItem.id} className='flex text-left items-center'>
                    <Checkbox
                      checked={parentItem.linked}
                      className='text-primary-color'
                      disabled={(assignPrivilege && !parentItem.linked) ? false
                        : (unassignPrivilege && parentItem.linked) ? false
                          : true
                      }
                      onClick={(event) => { assignUnassignSingleEntity((event.target as HTMLInputElement).checked, parentItem, window.scrollY, null, false) }}
                      sx={{
                        opacity: 1,
                        '&.Mui-disabled': {
                          opacity: 0.3
                        }
                      }}
                    />
                    <Typography className='font-Saira font-[500] text-[14px] text-txt-color'>{t(parentItem.erole)}</Typography>
                  </Box> : ''}
                  {privilegeData
                    .filter((item) => item.parentId === parentItem.id)
                    .map((childItem) => (
                      <Box key={childItem.id} className='flex text-left items-center'>
                        <Checkbox
                          checked={childItem.linked}
                          className='text-primary-color pt-[0px]'
                          onClick={(event) => { assignUnassignSingleEntity((event.target as HTMLInputElement).checked, childItem, window.scrollY, null, false) }}
                          disabled={(assignPrivilege && !childItem.linked) ? false
                            : (unassignPrivilege && childItem.linked) ? false
                              : true
                          }
                          sx={{
                            opacity: 1,
                            paddingBottom: 0,
                            '&.Mui-disabled': {
                              opacity: 0.3
                            },
                          }}
                        />
                        <Typography className='font-Saira font-[500] text-[14px] text-txt-color'>{t(childItem.erole)}{childItem.accessLimit ? ' - Limit : ' + childItem.accessLimit : ''}</Typography>
                      </Box>
                    ))}
                </AccordionDetails>
              </Accordion>
            ))}
        </Box>
      )}
    </>
  );
};

export default Privileges;