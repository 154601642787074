import { Box, Grid, Typography } from "@mui/material";
import BackendPaginationTable from "common/Table/TableWithPagination";
import { ReactComponent as Store  } from "assets/SideBarIcons/store.svg";
import ComponentHeaderTwo from "common/ComponentHeaderTwo/ComponentHeaderTwo";
import StoreModalComponent from "./StoreModal";
import FormDialog from "common/Modal/Modal";
import { useTranslation } from 'react-i18next';
import StyledInput from "common/SearchField/Search";
import VerticalTabs from 'common/ComponentSidebar/ComponentSideBar';
import Loader from "layout/Loader";
import { checkPrivileges } from "Helpers/CheckPrivileges";
import StoreItemSearchHeader from "./StoreItemsSearchHeader";

interface StoreComponentProps {
  handleSecField: () => void;
  showSecField: boolean;
  primaryLanguage:string;
  secondaryLanguage:string;
  openModal: () => void;
  closeModal: () => void;
  handleChange: (field: string, value: any) => void;
  handleChangePage: (field: number) => void;
  handleChangeRows: (field: number) => void;
  handleSubmit: () => void;
  handleDelete:  () => void;
  cancelDelete:  () => void;
  search: string;
  handleSearchClick: () => void;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeStatus: (row: any) => void;
  cancelChangeStatus: () => void;
  submitChangeStatus: () => void;
  formData: any;
  openForm: boolean;
  columns: any[]; 
  data: any;
  // stationsData: any;
  errorMessages: any,
  actions1: { label: string; onClick: ( value: any) => void; icon?: React.ReactNode }[];
  openDeleteModal: boolean;
  statusModal: boolean;
  statusText?: string;
  enableEdit?:boolean;
  pageNo:number;
  pageSize:number;
  itemAssignData: any;
  showRowView: (row: any) => void;
  rowView: boolean;
  handleChangeTabs: (tabId: number) => void;
  assignUnassignSingleEntity: (isChecked: boolean, row: any) => void;
  assignUnassignAllEntities: (isChecked: boolean) => void;
  allItemsAssigned: unknown;
  isBtnEnabled: boolean;
  loading: boolean;
  loadingSearch: boolean;
  loadingSearchAssign: boolean;
  searchAssignFormData: any;
  filterAssign: string[];
  searchMultiAssign: any;
  handleChangeAssignFilter: (event: any) => void;
  handleChangeSearchAssign: (field: string, value: any) => void;
  handleSubmitSearchAssign: () => void;
  searchAssignErrorMessages: any;
  clearSearchAssign: () => void;
  tab: number;
  clearSearch: () => void;
}

const StoreComponent: React.FC <StoreComponentProps> = ({
  showSecField,
  handleSecField,
  primaryLanguage,
  secondaryLanguage,
  openModal,
  closeModal,
  handleChange,
  handleSubmit,
  formData,
  openForm,
  columns,
  data,
  // stationsData,
  errorMessages,
  actions1,
  openDeleteModal,
  statusModal,

  handleDelete,
  cancelDelete,
  enableEdit,
  handleSearchClick,
  handleInputChange,
  search,
  isBtnEnabled,
  loading,
  showRowView,
  loadingSearch,
  handleChangePage,
  handleChangeRows,
  onChangeStatus,
  cancelChangeStatus,
  submitChangeStatus,
  pageNo,
  pageSize,
  itemAssignData,
  rowView,
  handleChangeTabs,
  assignUnassignSingleEntity,
  assignUnassignAllEntities,
  allItemsAssigned,
  loadingSearchAssign,
  searchAssignFormData,
  filterAssign,
  searchMultiAssign,
  handleChangeAssignFilter,
  handleChangeSearchAssign,
  handleSubmitSearchAssign,
  searchAssignErrorMessages,
  clearSearchAssign,
  tab,
  clearSearch
}) => {
  const { t } = useTranslation();

  const itemColumns = [
    { id: 'title', label: 'name', numeric: false },
    { id: 'itemCode', label: 'itemCode', numeric: false },
  ]

  const childTabContent = (columns:any, data:any, tableData:any, allEntitiesAssigned:unknown, assignPrivilege: string, unassignPrivilege: string) => {
    return (
      <BackendPaginationTable 
        columns={columns}
        data={data}
        pageNo={pageNo}
        pageSize={pageSize}
        tableData={tableData}
        showCheckbox={true}
        showActions={true}
        enableSorting={true}
        showHeader={true}
        showSearch={true}
        showFilter={true}
        showCreateButton={false}
        actions={[]}
        handleChangePage={handleChangePage}
        handleChangeRows={handleChangeRows}
        assignUnassignSingleEntity={assignUnassignSingleEntity}
        assignUnassignAllEntities={assignUnassignAllEntities}
        allEntitiesAssigned={allEntitiesAssigned}
        loadingSearch={loadingSearchAssign}
        assignPrivilege={checkPrivileges(assignPrivilege)}
        unassignPrivilege={checkPrivileges(unassignPrivilege)}
        headerComponent={tab === 1 ? <StoreItemSearchHeader 
            searchAssignFormData={searchAssignFormData}
            handleChangeAssignFilter={handleChangeAssignFilter}
            handleChangeSearchAssign={handleChangeSearchAssign}
            handleSubmitSearchAssign={handleSubmitSearchAssign}
            searchAssignErrorMessages={searchAssignErrorMessages}
            clearSearchAssign={clearSearchAssign}
            searchAssign={searchMultiAssign}
            {...{filterAssign}}
          /> : (
          <Grid container spacing={2}>  
            <Grid item xs={12} md={6} lg={4}>
              <StyledInput
                fullWidth
                placeholder={t("title")}
                handleSearchClick={handleSearchClick}
                handleChange={handleInputChange}
                value={''}
              />
            </Grid>
          </Grid>
        )}
      />
    );
  }

  const tabs = [
    { 
      id: 0, title: `edit`, 
      child: <StoreModalComponent
      handleSecField={handleSecField}
      showSecField={showSecField}
      secondaryLanguage={secondaryLanguage}
      primaryLanguage={primaryLanguage}
        enableEdit={enableEdit}
        openModal={openModal}
        closeModal={closeModal}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        formData={formData}
        // stationsData={stationsData.data?.content || []}
        openForm={true}
        errorMessages={errorMessages} 
        isBtnEnabled={isBtnEnabled}
      />
    },
    { id: 1, title: `items`, child: childTabContent(itemColumns, itemAssignData.data?.content || [], itemAssignData?.data || {}, allItemsAssigned, 'ROLE_STORE_ITEM_ASSIGN', 'ROLE_STORE_ITEM_UNASSIGN')},
  ];
  const disableTabs = tabs.map(tab => tab.id).filter(tabId => {
    switch (tabId) {
      case 0:
        return !checkPrivileges('ROLE_STORE_UPDATE');
      case 1:
        return !checkPrivileges('ROLE_STORE_ITEM_ASSIGN') && !checkPrivileges('ROLE_STORE_ITEM_UNASSIGN');
      default:
        return false;
    }
  });

    return(

        <Box>
            <ComponentHeaderTwo
              showBtn={true}
              showSaveBtn={openForm}
              onClick={openModal}
              onSubmit={handleSubmit}
              heading={(openForm && !enableEdit) ? "addStores" : (enableEdit && openForm || rowView) ? "editStores" : t('stores')}
              icon={<Store height={28} width={28} />}
              btnText={openForm || rowView ? "back" : "create"}
              btnType={openForm || rowView ? "back" : "create"}
              isBtnEnabled={isBtnEnabled}
              showCreateBtn={checkPrivileges("ROLE_STORE_CREATE")}
              // btnType="create"
            />

            {openForm ? 
              <StoreModalComponent
                enableEdit={enableEdit}
                openModal={openModal}
                closeModal={closeModal}
                handleSecField={handleSecField}
                showSecField={showSecField}
                secondaryLanguage={secondaryLanguage}
                primaryLanguage={primaryLanguage}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                formData={formData}
                // stationsData={stationsData.data?.content || []}
                openForm={openForm}
                errorMessages={errorMessages}
                isBtnEnabled={isBtnEnabled}
              />
            : rowView ?
              <VerticalTabs tabs={tabs} selectedIndex={0} handleChangeTabs={handleChangeTabs} disableTabNo={disableTabs} />
              : <Box>
              {loading ? <Loader /> :
                <BackendPaginationTable
                  columns={actions1.length ? columns : columns.filter(action => action.id !== 'actions')}
                  data={data.data?.content || []}
                  pageNo={pageNo}
                  pageSize={pageSize}
                  tableData={data?.data || {}}
                  showCheckbox={false}
                  showActions={true}
                  enableSorting={true}
                  showHeader={true}
                  showSearch={true}
                  showFilter={true}
                  showCreateButton={true}
                  actions={actions1}
                  handleChangePage={handleChangePage}
                  handleChangeRows={handleChangeRows}
                  onChangeStatus={onChangeStatus}
                  rowClick={disableTabs.length === tabs.length ? false : true}
                  showRowView={showRowView}
                  loadingSearch={loadingSearch}
                  headerComponent={
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={7} lg={5}>
                        <StyledInput
                          fullWidth
                          placeholder={t("storeName")}
                          handleSearchClick={handleSearchClick}
                          handleChange={handleInputChange}
                          value={search}
                          clearSearch={clearSearch}
                        />
                      </Grid>
                    </Grid>
                  }
                />
              }
            </Box>
                  }

      {openDeleteModal && 
            <FormDialog
                open={openDeleteModal}
                onClose={cancelDelete}
                title={t('delete') + ' ' + t('store')}
                // icon={<div><Home/></div>}
                content={  
                    <Grid container >
                  <Grid item  xs={12} sm={12} md={12} lg={12}  >
                  <Typography>
                    {t('Are you sure you want to delete?')}
                  </Typography>
                  </Grid>
                  </Grid>
                  }
                  saveFunction={handleDelete}
                submitButtonText="Delete"
              />}
        {statusModal && 
          <FormDialog
            open={statusModal}
            onClose={cancelChangeStatus}
            title="Change Status"
            // icon={<div><Home/></div>}
            content={  
              <Grid container >
                <Grid item  xs={12} sm={12} md={12} lg={12}  >
                  <Typography>
                  {t(`areYouSure`)}?
                  </Typography>
                </Grid>
              </Grid>
            }
            saveFunction={submitChangeStatus}
            submitButtonText="OK"
          />
        }


        </Box>

    )
}

export default StoreComponent;