// InventoryProfileService.tsx
import { useInfiniteQuery } from '@tanstack/react-query';
import { InventoryProfileService } from '../../core/services';
import { PageSize } from '../../type';

export const useGetInventoryService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-inventory-data'],
      queryFn: async ({ pageParam }) => {
        return InventoryProfileService.getPurchaseOrder({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          poNumber: initialParams.poNumber,
        });
      },
      enabled: false,
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      retry: 1,
      retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useGetPurchaseInvoiceService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-purchase-documents-data'],
      queryFn: async ({ pageParam }) => {
        return InventoryProfileService.getPurchaseInvoices({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          invoiceNumber: initialParams.invoiceNumber,
        });
      },
      enabled: false,
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      retry: 1,
      retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useGetStockReceivedService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-stock-received-data'],
      queryFn: async ({ pageParam }) => {
        return InventoryProfileService.getStockReceipt({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          stockNumber: initialParams.stockNumber,
        });
      },
      enabled: false,
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      retry: 1,
      retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useGetCreditNoteService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-credit-note-data'],
      queryFn: async ({ pageParam }) => {
        return InventoryProfileService.getCreditNote({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          pCDNNumber: initialParams.pCDNNumber,
        });
      },
      enabled: false,
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      retry: 1,
      retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useGeUnstockService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-unstock-data'],
      queryFn: async ({ pageParam }) => {
        return InventoryProfileService.getUnstock({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          unStockNumber: initialParams.unStockNumber,
        });
      },
      enabled: false,
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      retry: 1,
      retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useGetStockTransferService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-stock-transfer-data'],
      queryFn: async ({ pageParam }) => {
        return InventoryProfileService.getStockTransfer({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          sourceStoreId: initialParams.sourceStoreId,
          destinationStoreId: initialParams.destinationStoreId,
        });
      },
      enabled: false,
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      retry: 1,
      retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useGetStockAdjustmentService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-stock-adjustment-data'],
      queryFn: async ({ pageParam }) => {
        return InventoryProfileService.getStockAdjustment({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          storeId: initialParams.storeId,
          itemCode: initialParams.itemCode,
        });
      },
      enabled: false,
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      retry: 1,
      retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};
