import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import PriceListComponent from 'components/PriceList';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useChangeStatusPriceListService, usePriceListService, useDeletePriceListService, useGetPriceListService, useUpdatePriceListService, useGetPriceListPackageService,  useGetPriceListServiceService, useGetPriceListItemService, useUnassignPriceListItemService, useAssignPriceListItemService, useUnassignPriceListServiceService, useAssignPriceListServiceService, useAssignPriceListPackageService, useUnassignPriceListPackageService, useUpdatePriceListItemService, useUpdatePriceListServiceService, useUpdatePriceListPackageService} from 'subModule/src/services/pricelist/usePriceList';
import { AssignItemRequest, AssignPackageRequest, AssignServiceRequest, PriceListRequest } from 'subModule/src/services/pricelist/interface';
import { useSnackbar } from 'notistack';
import AlertMessages from 'common/Alert/AlertMessages';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUserInfo } from 'features/user/userSlice';
import { checkPrivileges } from 'Helpers/CheckPrivileges';

interface FormData {
  title: string,
  secondaryTitle: string,
  haveExpiry: boolean,
  startDate: string,
  endDate: string,
  isDefault: boolean;
  id: number,
  active: boolean
}
interface PackageFormData {
  packageId: number;
  price: number;
  priceError?: string;
  pricelistId: number;
}

interface ServiceFormData {
  serviceId: number;
  price: number;
  priceError?: string;
  pricelistId: number;
}

interface ItemFormData {
  itemId: number;
  price: number;
  priceError?: string;
  pricelistId: number;
}

  const requiredFields = ['title', 'startDate', 'endDate'];

  const packageRequiredFields = ['price'];

  const serviceRequiredFields = ['price'];

  const itemRequiredFields = ['price'];

  export default function PriceList () {
    const [showSecField, setShowSecField] = useState<boolean>(false);
    const [openForm, setOpenForm] = useState(false);
    const [openDeleteModal, setDeleteModal] = useState(false);
    const [enableEdit, setEnableEdit] = useState(false);
    const [pageNo, setPageNo] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);
    const [statusModal, setStatusModal] = useState(false);
    const [statusText, setStatusText] = useState<string>('');
    const [searchTerm, setSearchTerm] = useState<any>(null);
    const [search, setSearch] = useState('');
    const [isBtnEnabled, setIsBtnEnabled] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
    const [rowView, setRowView] = useState(false);
    const [pricelistId, setPricelistId] = useState<number>(0);
    const [tab, setTab] = useState<number>(0);
    const [selectedRow, setSelectedRow] = useState<any>({});
    // const [data] = useState(rows);
    const [prevFormData, setPrevFormData] = useState<object>({});
    const [searchAssign, setSearchAssign] = useState('');
    const [searchTermAssign, setSearchTermAssign] = useState<any>(null);
    const [loadingSearchAssign, setLoadingSearchAssign] = useState<boolean>(false);
    const [formData, setFormData] = useState<FormData>({
      title: '',
      secondaryTitle: '',
      haveExpiry: false,
      startDate: '',
      endDate: '',
      isDefault: false,
      id: 0,
      active: true
    });
    const [packageFormData, setPackageFormData] = useState<PackageFormData>({
      packageId: 0,
      price: 0,
      pricelistId: 0,
    });
    const [serviceFormData, setServiceFormData] = useState<ServiceFormData>({
      serviceId: 0,
      price: 0,
      pricelistId: 0,
    });
    const [itemFormData, setItemFormData] = useState<ItemFormData>({
      itemId: 0,
      price: 0,
      pricelistId: 0,
    });

    const { data: tableData,  isLoading, error,  refetch } = searchTerm ? useGetPriceListService({ pageNumber: pageNo, size: pageSize, title: searchTerm }) : useGetPriceListService({ pageNumber: pageNo, size: pageSize });
    
    const { data: packageAssignData, isLoading: packageAssignIsLoading, error: packageAssignError, refetch: packageAssignRefetch } = searchTermAssign ? useGetPriceListPackageService({pageNumber: pageNo, size: pageSize, title: searchTermAssign}, pricelistId) : useGetPriceListPackageService({pageNumber: pageNo, size: pageSize}, pricelistId);

    const { data: serviceAssignData, isLoading: serviceAssignIsLoading, error: serviceAssignError, refetch: serviceAssignRefetch } = searchTermAssign ? useGetPriceListServiceService({pageNumber: pageNo, size: pageSize, title: searchTermAssign}, pricelistId) : useGetPriceListServiceService({pageNumber: pageNo, size: pageSize}, pricelistId);

    const { data: itemAssignData, isLoading: itemAssignIsLoading, error: itemAssignError, refetch: itemAssignRefetch } = searchTermAssign ? useGetPriceListItemService({pageNumber: pageNo, size: pageSize, title: searchTermAssign}, pricelistId) : useGetPriceListItemService({pageNumber: pageNo, size: pageSize}, pricelistId);

    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const loggedInUserData = useSelector(selectUserInfo);
    const primaryLanguage = loggedInUserData?.primaryLanguage;
    const secondaryLanguage = loggedInUserData?.secondaryLanguage;
    
    useEffect(() => {
      if (!isLoading && !error && !rowView && !openForm && !loadingSearch) { // Only refetch if not already loading or in error state
        refetch();
      }
      if(tableData) {
        setLoading(false);
        if((tableData?.pages[0] as any).status && !(tableData?.pages[0] as any).success) {
          enqueueSnackbar(<AlertMessages statusCode={(tableData?.pages[0] as any)?.status} />, { variant: 'error' })
        }
      }
      if(tab === 0 && enableEdit) {
        setIsBtnEnabled(false);
      }
      if(!packageAssignIsLoading && !packageAssignError && pricelistId && pricelistId !== 0 && tab === 1) {
        const loadData = async() => {
          setLoadingSearchAssign(true);
          await packageAssignRefetch();
          setLoadingSearchAssign(false);
        }
        loadData();
      }
      if(!serviceAssignIsLoading && !serviceAssignError && pricelistId && pricelistId !== 0 && tab === 2) {
        const loadData = async() => {
          setLoadingSearchAssign(true);
          await serviceAssignRefetch();
          setLoadingSearchAssign(false);
        }
        loadData();
      }
      if(!itemAssignIsLoading && !itemAssignError && pricelistId && pricelistId !== 0 && tab === 3) {
        const loadData = async() => {
          setLoadingSearchAssign(true);
          await itemAssignRefetch();
          setLoadingSearchAssign(false);
        }
        loadData();
      }
    }, [pageNo, pageSize, refetch, isLoading, error, tableData, openForm, pricelistId, tab]);
    useEffect(() => {
      if(enableEdit && JSON.stringify(formData) !== JSON.stringify(prevFormData) || openForm && JSON.stringify(formData) !== JSON.stringify(prevFormData)) {
        setIsBtnEnabled(true);
      } else if(enableEdit && JSON.stringify(formData) === JSON.stringify(prevFormData)) {
        setIsBtnEnabled(false);
      }
    }, [enableEdit, formData]);

    useEffect(() => {
      if(enableEdit&&openForm){
        setIsBtnEnabled(false)
      }
    }, [formData]);

    useEffect(() => {
      const getData = setTimeout(async() => {
        if (searchTerm !== null && !isLoading && !error && loadingSearch) {
          await refetch();
          setLoadingSearch(false);
        }
      }, 1000);
      return () => clearTimeout(getData)
    }, [searchTerm, loadingSearch]);
    
    useEffect(() => {
      const getData = setTimeout(async() => {
        if (searchTermAssign !== null && loadingSearchAssign && tab === 1) {
          await packageAssignRefetch();
          setLoadingSearchAssign(false);
        }
        if (searchTermAssign !== null && loadingSearchAssign && tab === 2) {
          await serviceAssignRefetch();
          setLoadingSearchAssign(false);
        }
        if (searchTermAssign !== null && loadingSearchAssign && tab === 3) {
          await itemAssignRefetch();
          setLoadingSearchAssign(false);
        }
      }, 1000);
      return () => clearTimeout(getData)
    }, [searchTermAssign, loadingSearchAssign]);
    
    const handleChangePage = (pageNo : number) => {  
      setLoading(true);
      setPageNo(pageNo + 1);
      setPageSize(pageSize);

    }
    const handleChangeRows = (pageSize : number) => {
      setLoading(true);
      setPageSize(pageSize);
      setPageNo(1);
    }

    const [errorMessages, setErrorMessages] = useState<Partial<FormData>>({});

    const [packageErrorMessages, setPackageErrorMessages] = useState<Partial<PackageFormData>>({});

    const [serviceErrorMessages, setServiceErrorMessages] = useState<Partial<ServiceFormData>>({});

    const [itemErrorMessages, setItemErrorMessages] = useState<Partial<ItemFormData>>({});

    const openModal = () => {
      if(rowView) {
        setRowView(false);
        setPricelistId(0);
        setPageNo(1);
        setTab(0);
        setSearchAssign('');
        setSearchTermAssign('');
      } else {
        setOpenForm(!openForm);
        setIsBtnEnabled(true);
        setSearch('');
        setShowSecField(false)
        setSearchTerm('');
        openModalSettings();
      }
    };
    const openModalSettings = () => {
      setEnableEdit(false);
      setErrorMessages({});
      setIsBtnEnabled(true)
      setFormData({
        title: '',
        secondaryTitle: '',
        haveExpiry: false,
        startDate: '',
        endDate: '',
        isDefault: false,
        id: 0,
        active: true
      });
      setSearch('');
      setSearchTerm('');
    }

    const handleSecField = () => {
      setShowSecField((prevValue) => !prevValue);
    }

      const handleChange = (field: string, value: any) => {
        const  val = (field === "haveExpiry" || field === "isDefault") ? 
            value ? Boolean(value) : false 
          : (field === "startDate" || field === "endDate") ?
            new Date(value).toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }).split('/').reverse().join('-')
          : value;
        if(field === "haveExpiry" && !value) {
          setFormData({...formData, endDate: ''});
        }

        setFormData((prevData) => ({
          ...prevData,
          [field]: val,
        }));
      
        if (requiredFields.includes(field) && !val) {
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: 'This field is required.',
          }));
        } else {
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: undefined,
          }));
        }
      };
      
    
      const validateFormData = (data: FormData) => {
        const errors: Partial<FormData> = {};

        if (!data.title?.trim()) {
          errors.title = t('This field is required.');
        }
        if (!data.startDate || data.startDate === "Invalid Date") {
            errors.startDate = t('This field is required.');
        }
        if (data.haveExpiry && (!data.endDate || data.endDate === "Invalid Date")) {
            errors.endDate = t('This field is required.');
        }
        if (data.startDate && data.endDate && new Date(formData.startDate) >= new Date(formData.endDate)) {
          errors.startDate = t('startDateOrder');
          errors.endDate = t('endDateOrder');
        }
    
        return errors;
      };

      const { onCreatePriceList } = usePriceListService({
        onSuccess: (msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          if(response.success) {
            setOpenForm(false);
            refetch();
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('priceList') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
          console.error(err.stack);
        }
      }})

      const { onUpdatePriceList } = useUpdatePriceListService({
        onSuccess: (data) => {
          const response: any = {};
          Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
          if(response.success) {
            if(!rowView) {
              setEnableEdit(false);
              setOpenForm(false);
              refetch();
            }
            if(rowView) { setPrevFormData(response?.data); setSelectedRow(response?.data); }
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('priceList') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
        id: formData.id,
      });

      const handleSubmit = () => {
        const errors = validateFormData(formData);
    
        setErrorMessages(errors);
    
        if (Object.keys(errors).length === 0) {
            setIsBtnEnabled(false);
            setLoading(true);
            const priceListData: PriceListRequest = {
                ...formData,
                title: formData.title,
                secondaryTitle: formData.secondaryTitle,
                haveExpiry: formData.haveExpiry,
                startDate: formData.startDate,
                endDate: formData.endDate,
                isDefault: formData.isDefault,
            };
    
            if (enableEdit) {
                onUpdatePriceList(priceListData);
            } else {
                onCreatePriceList(priceListData);
            }
        }
    };

      const onEdit = (row:any) => {
        setEnableEdit(true);
        setFormData(row);
        setPrevFormData(row);
        setOpenForm(true);
        setIsBtnEnabled(false);
        setSearch('');
        setSearchTerm('');
      }

      const onDelete = (row:any) => {
        setFormData(row)
        setDeleteModal(true)
      }

      const { onDeletePriceList } = useDeletePriceListService({
        id: formData.id,
        onSuccess: (msg) => {
          if(msg.success) {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('priceList') + ' ' + t('deleted') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
          }
          refetch(); // This will use the updated pageNo and pageSize
          if(tableData?.pages[0].data.content.length === 1) {
            handleChangePage(pageNo - 1)
          }
        },
        onError: (err) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
      });

      const handleDelete = () => {
        setDeleteModal(false);
        setLoading(true);
        onDeletePriceList();
      }
      const cancelDelete = () => {
        setDeleteModal(false)
      }

      const clearSearch = () => {
        if(!rowView) {
          setSearch('');
          setSearchTerm('');
          if(searchTerm) { handleSearch(''); }
        } else {
          setSearchAssign('');
          setSearchTermAssign('');
          if(searchTermAssign) { handleSearch(''); }
        }
      }
      const handleSearch = (search: string) => {
        if(!rowView) {
          setLoadingSearch(true);
          setSearchTerm(search);
          setPageNo(1);
          setPageSize(pageSize);
        } else {
          setLoadingSearchAssign(true);
          setSearchTermAssign(search);
          setPageNo(1);
          setPageSize(pageSize);
        }
      };
    
      const handleSearchClick = () => {
        if(!rowView) {
          const trimmedSearch = search.trim();
          if (trimmedSearch !== '') {
            handleSearch(trimmedSearch);
          }
        } else {
          const trimmedSearch = searchAssign.trim();
          if (trimmedSearch !== '') {
            handleSearch(trimmedSearch);
          }
        }
      };

      const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(!rowView) {
          setSearch(event.target.value?.trim());
          if (!event.target.value?.trim()) handleSearch('');
        } else {
          setSearchAssign(event.target.value.trim());
          if (!event.target.value.trim()) handleSearch('');
        }
      };

      const onChangeStatus = (row: any) => {
        row.active ? setStatusText('deactivate') : setStatusText('activate');
        setFormData(row);
        setStatusModal(true);
      }
      const cancelChangeStatus = () => {
        setStatusModal(false);
      }
      const submitChangeStatus = () => {
        setStatusModal(false);
        setLoading(true);
        onChangeStatusPriceList({});
      }
      const { onChangeStatusPriceList } = useChangeStatusPriceListService({
        id: formData.id,
        onSuccess: (msg) => {
          if(msg.success) {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('priceList') + ' ' + t(statusText) + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
          }
          refetch();
        },
        onError: (err) => {
          console.error("===", err)
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
        active: formData.active ? "inactive" : "active"
      });
      const showRowView = (row: any) => {
        setEnableEdit(true);
        setOpenForm(false);
        setFormData(row);
        setPrevFormData(row);
        setErrorMessages({});
        setRowView(true);
        setPricelistId(row.id);
        setSelectedRow(row);
        setSearch('');
        setSearchTerm('');
      }
      const handleChangePackage = (field: string, value: any) => {
        const  val = field === "price" ? (value >= 0 ? value : 0) : value;
        setPackageFormData((prevData) => ({
          ...prevData,
          [field]: val,
        }));
      
        if (packageRequiredFields.includes(field) && !val) {
          setPackageErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: 'This field is required.',
          }));
        } else {
          setPackageErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: undefined,
          }));
        }
      };
      const validatePackageFormData = (data: PackageFormData) => {
        const errors: Partial<PackageFormData> = {};

        if (!data.price) {
          errors.priceError = t('required');
        }

        return errors;
      };
      const handleChangeService = (field: string, value: any) => {
        const  val = field === "price" ? (value >= 0 ? value : 0) : value;
        setServiceFormData((prevData) => ({
          ...prevData,
          [field]: val,
        }));
      
        if (serviceRequiredFields.includes(field) && !val) {
          setServiceErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: 'This field is required.',
          }));
        } else {
          setServiceErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: undefined,
          }));
        }
      };
      const validateServiceFormData = (data: ServiceFormData) => {
        const errors: Partial<ServiceFormData> = {};

        if (!data.price) {
          errors.priceError = t('required');
        }

        return errors;
      };
      const handleChangeItem = (field: string, value: any) => {
        const  val = field === "price" ? (value >= 0 ? value : 0) : value;
        setItemFormData((prevData) => ({
          ...prevData,
          [field]: val,
        }));
      
        if (itemRequiredFields.includes(field) && !val) {
          setItemErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: 'This field is required.',
          }));
        } else {
          setItemErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: undefined,
          }));
        }
      };
      const validateItemFormData = (data: ItemFormData) => {
        const errors: Partial<ItemFormData> = {};

        if (!data.price) {
          errors.priceError = t('required');
        }

        return errors;
      };
      const handleChangeTabs = (tabId: number) => {
        setSearchAssign('');
        setSearchTermAssign('');
        if(tabId === 0) {
          setTab(0);
          setEnableEdit(true);
          setOpenForm(false);
          setFormData(selectedRow);
          setErrorMessages({});
          setIsBtnEnabled(false);
        } else if(tabId === 1) {
          setTab(1);
          setPageNo(1);
          setEnableEdit(false);
          setPackageFormData({
            packageId: 0,
            price: 0,
            pricelistId: 0,
          });
        } else if(tabId === 2) {
          setTab(2);
          setPageNo(1);
          setEnableEdit(false);
          setServiceFormData({
            serviceId: 0,
            price: 0,
            pricelistId: 0,
          });
        } else if(tabId === 3) {
          setTab(3);
          setPageNo(1);
          setEnableEdit(false);
          setItemFormData({
            itemId: 0,
            price: 0,
            pricelistId: 0,
          });
        }
      }
      const assignUnassignSingleEntity = (isChecked: boolean, row: any) => {
        if(isChecked) {
          if(tab === 1) {
            const errors = validatePackageFormData(packageFormData);
            setPackageErrorMessages(errors);
            
            if (Object.keys(errors).length === 0) {
              setLoadingSearchAssign(true);
              const PriceListsPackageData: AssignPackageRequest = {
                ...packageFormData,
                packageId: row.id,
                price: +packageFormData.price,
                pricelistId: pricelistId,
              };
              
              onAssignPackage(PriceListsPackageData);
            }
          } else if(tab === 2) {
            const errors = validateServiceFormData(serviceFormData);
            setServiceErrorMessages(errors);
            
            if (Object.keys(errors).length === 0) {
              setLoadingSearchAssign(true);
              const PriceListsServiceData: AssignServiceRequest = {
                ...serviceFormData,
                serviceId: row.id,
                price: +serviceFormData.price,
                pricelistId: pricelistId,
              };
     
              onAssignService(PriceListsServiceData);
            }
          } else if(tab === 3) {
            const errors = validateItemFormData(itemFormData);
            setItemErrorMessages(errors);
            
            if (Object.keys(errors).length === 0) {
              setLoadingSearchAssign(true);
              const PriceListsItemData: AssignItemRequest = {
                ...itemFormData,
                itemId: row.id,
                price: +itemFormData.price,
                pricelistId: pricelistId,
              };
     
              onAssignItem(PriceListsItemData);
            }
          }
        } else {
          setLoadingSearchAssign(true);
          if(tab === 1) {
            onUnassignPackage({ pricelistId: pricelistId, packageId: row.id });
          } else if(tab === 2) {
            onUnassignService({ pricelistId: pricelistId, serviceId: row.id });
          } else if(tab === 3) {
            onUnassignItem({ pricelistId: pricelistId, itemId: row.id });
          }
        }
      }
      const { onAssignPackage } = useAssignPriceListPackageService({
        onSuccess: async(msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          if(response.success) {
            await packageAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Package') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await packageAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
          setPackageFormData({
            packageId: 0,
            price: 0,
            pricelistId: 0,
          });
        },
        onError: (err:any) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
            console.error(err.stack);
          }
          setPackageFormData({
            packageId: 0,
            price: 0,
            pricelistId: 0,
          });
      }})
      const { onUnassignPackage } = useUnassignPriceListPackageService({
        onSuccess: async(data) => {
          const response: any = {};
          Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
          if(response.success) {
            await packageAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Package') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await packageAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
          setPackageFormData({
            packageId: 0,
            price: 0,
            pricelistId: 0,
          });
        },
        onError: (err: any) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          setPackageFormData({
            packageId: 0,
            price: 0,
            pricelistId: 0,
          });
        },
      });
      const { onAssignService } = useAssignPriceListServiceService({
        onSuccess: async(msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          if(response.success) {
            await serviceAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Service') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await serviceAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
          setServiceFormData({
            serviceId: 0,
            price: 0,
            pricelistId: 0,
          });
        },
        onError: (err:any) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
            console.error(err.stack);
          }
          setServiceFormData({
            serviceId: 0,
            price: 0,
            pricelistId: 0,
          });
      }})
      const { onUnassignService } = useUnassignPriceListServiceService({
        onSuccess: async(data) => {
          const response: any = {};
          Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
          if(response.success) {
            await serviceAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Service') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await serviceAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
          setServiceFormData({
            serviceId: 0,
            price: 0,
            pricelistId: 0,
          });
        },
        onError: (err: any) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          setServiceFormData({
            serviceId: 0,
            price: 0,
            pricelistId: 0,
          });
        },
      });
      const { onAssignItem } = useAssignPriceListItemService({
        onSuccess: async(msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          if(response.success) {
            await itemAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Item') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await itemAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
          setItemFormData({
            itemId: 0,
            price: 0,
            pricelistId: 0,
          });
        },
        onError: (err:any) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
            console.error(err.stack);
          }
          setItemFormData({
            itemId: 0,
            price: 0,
            pricelistId: 0,
          });
      }})
      const { onUnassignItem } = useUnassignPriceListItemService({
        onSuccess: async(data) => {
          const response: any = {};
          Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
          if(response.success) {
            await itemAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Item') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await itemAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
          setItemFormData({
            itemId: 0,
            price: 0,
            pricelistId: 0,
          });
        },
        onError: (err: any) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          setItemFormData({
            itemId: 0,
            price: 0,
            pricelistId: 0,
          });
        },
      });
      const handleBlurItem = (row: any) => {
        if(row.linked && row.plPrice && itemFormData.price) {
          const PriceListsItemData: AssignItemRequest = {
            ...itemFormData,
            itemId: row.id,
            price: +itemFormData.price,
            pricelistId: pricelistId,
          };

          onUpdatePriceListItem(PriceListsItemData);
        }
      }
      const { onUpdatePriceListItem } = useUpdatePriceListItemService({
        onSuccess: (msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          if(response.success) {
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('price') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
          itemAssignRefetch();
          setItemFormData({
            itemId: 0,
            price: 0,
            pricelistId: 0,
          });
        },
        onError: (err:any) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
            console.error(err.stack);
          }
          setItemFormData({
            itemId: 0,
            price: 0,
            pricelistId: 0,
          });
      }});
      const handleBlurService = (row: any) => {
        if(row.linked && row.plPrice && serviceFormData.price) {
          const PriceListsServiceData: AssignServiceRequest = {
            ...serviceFormData,
            serviceId: row.id,
            price: +serviceFormData.price,
            pricelistId: pricelistId,
          };

          onUpdatePriceListService(PriceListsServiceData);
        }
      }
      const { onUpdatePriceListService } = useUpdatePriceListServiceService({
        onSuccess: (msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          if(response.success) {
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('price') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
          serviceAssignRefetch();
          setServiceFormData({
            serviceId: 0,
            price: 0,
            pricelistId: 0,
          });
        },
        onError: (err:any) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
            console.error(err.stack);
          }
          setServiceFormData({
            serviceId: 0,
            price: 0,
            pricelistId: 0,
          });
      }});
      const handleBlurPackage = (row: any) => {
        if(row.linked && row.plPrice && packageFormData.price) {
          const PriceListsPackageData: AssignPackageRequest = {
            ...packageFormData,
            packageId: row.id,
            price: +packageFormData.price,
            pricelistId: pricelistId,
          };

          onUpdatePriceListPackage(PriceListsPackageData);
        }
      }
      const { onUpdatePriceListPackage } = useUpdatePriceListPackageService({
        onSuccess: (msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          if(response.success) {
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('price') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
          packageAssignRefetch();
          setPackageFormData({
            packageId: 0,
            price: 0,
            pricelistId: 0,
          });
        },
        onError: (err:any) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
            console.error(err.stack);
          }
          setPackageFormData({
            packageId: 0,
            price: 0,
            pricelistId: 0,
          });
      }});
      const adjustActions = (actions: any[]) => {
        return checkPrivileges('ROLE_PRICELIST_UPDATE') 
          ? (checkPrivileges('ROLE_PRICELIST_ARCHIVE') 
              ? actions 
              : actions.filter(action => action.label !== 'delete')) 
          : (checkPrivileges('ROLE_PRICELIST_ARCHIVE') 
              ? actions.filter(action => action.label !== 'edit') 
              : []);
      }

    return(
        <Box>
            <PriceListComponent
                openModal={openModal}
                closeModal={openModal}
                handleSecField={handleSecField}
                secondaryLanguage={secondaryLanguage}
                primaryLanguage={primaryLanguage}
                handleSearchClick={handleSearchClick}
                handleInputChange={handleInputChange}
                handleDelete={handleDelete}
                cancelDelete={cancelDelete}
                handleChange={handleChange} 
                handleSubmit={handleSubmit}
                formData={formData}
                handleChangePage={handleChangePage}
                handleChangeRows={handleChangeRows}
                onChangeStatus={onChangeStatus}
                cancelChangeStatus={cancelChangeStatus}
                submitChangeStatus={submitChangeStatus}
                showRowView={showRowView}
                packageAssignData={packageAssignData?.pages[0] || {}}
                packageFormData={packageFormData}
                handleChangePackage={handleChangePackage}
                packageErrorMessages={packageErrorMessages}
                handleChangeTabs={handleChangeTabs}
                assignUnassignSingleEntity={assignUnassignSingleEntity}
                serviceFormData={serviceFormData}
                handleChangeService={handleChangeService}
                serviceErrorMessages={serviceErrorMessages}
                serviceAssignData={serviceAssignData?.pages[0] || {}}
                itemFormData={itemFormData}
                handleChangeItem={handleChangeItem}
                itemErrorMessages={itemErrorMessages}
                itemAssignData={itemAssignData?.pages[0] || {}}
                handleBlurItem={handleBlurItem}
                handleBlurService={handleBlurService}
                handleBlurPackage={handleBlurPackage}
                clearSearch={clearSearch}
                columns={checkPrivileges("ROLE_PRICELIST_ACTIVE") ? [
                    { id: 'title', label: 'title', numeric: false },
                    { id: 'startDate', label: 'startDate', numeric: false },
                    { id: 'endDate', label: 'endDate', numeric: false },
                    { id: 'haveExpiry', label: 'haveExpiry', numeric: false },
                    { id: 'active', label: 'status', numeric: false },
                    { id: 'actions', label: 'actions', numeric: false },
                ] : [
                  { id: 'title', label: 'title', numeric: false },
                  { id: 'startDate', label: 'startDate', numeric: false },
                  { id: 'endDate', label: 'endDate', numeric: false },
                  { id: 'haveExpiry', label: 'haveExpiry', numeric: false },
                  { id: 'actions', label: 'actions', numeric: false },
              ]}
                data={tableData?.pages[0] || {}}
                {...{ openForm, openDeleteModal, enableEdit,showSecField, pageNo, pageSize, statusModal, statusText, search, isBtnEnabled, rowView, loading, loadingSearch, searchAssign, loadingSearchAssign }}
                errorMessages={errorMessages}
                actions1 = {adjustActions([
                  { label: "edit", onClick: onEdit, icon: <EditIcon /> },
                  { label: "delete", onClick: onDelete, icon: <DeleteIcon /> },
                ])}
            />
        </Box>
    )
  }