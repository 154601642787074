// BusinessInvoice.tsx
import { BaseService } from '../../core/BaseService';
import { PaginationParams } from '../../type';
import {
  AssignUnassignAllRequest,
  AssignUnassignAllResponse,
  AssignUnassignInvoiceRequest,
  AssignUnassignResponse,
  GetBusinessInvoiceResponse,
} from './interface';

export class AccountSettingsProfile extends BaseService {
  async getBusinessInvoice(params: PaginationParams & { businessId: number }) {
    return this.get<GetBusinessInvoiceResponse>(`${this.apiUrl}/api/invoicetemplates/bybusinessid`, params);
  }

  async assignInvoice(req: AssignUnassignInvoiceRequest) {
    return this.post<AssignUnassignResponse, AssignUnassignInvoiceRequest>(
      `${this.apiUrl}/api/businesses/template/assign`,
      req
    );
  }

  async unassignInvoice(req: AssignUnassignInvoiceRequest) {
    return this.put<AssignUnassignResponse, AssignUnassignInvoiceRequest>(
      `${this.apiUrl}/api/businesses/template/unassign`,
      req
    );
  }

  async assignAllInvoices(req: AssignUnassignAllRequest) {
    return this.post<AssignUnassignAllResponse, AssignUnassignAllRequest>(
      `${this.apiUrl}/api/businesses/template/assignall`,
      req
    );
  }

  async unassignAllInvoices(req: AssignUnassignAllRequest) {
    return this.put<AssignUnassignAllResponse, AssignUnassignAllRequest>(
      `${this.apiUrl}/api/businesses/template/unassignall`,
      req
    );
  }
}
