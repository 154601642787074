import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import VendorComponent from 'components/Vendor/index';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useVendorService,useChangeStatusVendorService,useDeleteVendorService,useGetVendorService,useUpdateVendorService } from 'subModule/src/services/vendor/useVendor';
import { VendorRequest } from 'subModule/src/services/vendor/interface';
import { useSnackbar } from 'notistack';
import AlertMessages from 'common/Alert/AlertMessages';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUserInfo } from 'features/user/userSlice';
import { checkPrivileges } from 'Helpers/CheckPrivileges';

interface FormData {
    title: string,
    secondaryTitle: string,
    id: number,
    phoneNumber: string,
    email: string,
    address: string,
    taxNumber: string,
    active: boolean
}

  const requiredFields = ['title', 'email'];

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const phoneRegex = /^[\p{N}+]{5,17}$/u;

  export default function Vendor () {
    const [showSecField, setShowSecField] = useState<boolean>(false);
    const [openForm, setOpenForm] = useState(false);
    const [openDeleteModal, setDeleteModal] = useState(false);
    const [enableEdit, setEnableEdit] = useState(false);
    const [pageNo, setPageNo] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);
    const [statusModal, setStatusModal] = useState(false);
    const [statusText, setStatusText] = useState<string>('');
    const [searchTerm, setSearchTerm] = useState<any>(null);
    const [search, setSearch] = useState('');
    const [isBtnEnabled, setIsBtnEnabled] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
    // const [data] = useState(rows);
    const [prevFormData, setPrevFormData] = useState<object>({});
    const [formData, setFormData] = useState<FormData>({
        title: '',
        secondaryTitle: '',
        id: 0,
        phoneNumber: '',
        email: '',
        address: '',
        taxNumber: '',
        active: true
    });

    const { data: tableData,  isLoading, error,  refetch } = searchTerm ? useGetVendorService({ pageNumber: pageNo, size: pageSize, title: searchTerm }) : useGetVendorService({ pageNumber: pageNo, size: pageSize });
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const loggedInUserData = useSelector(selectUserInfo);
    const primaryLanguage = loggedInUserData?.primaryLanguage;
    const secondaryLanguage = loggedInUserData?.secondaryLanguage;

    useEffect(() => {
      if (!isLoading && !error && !openForm && !loadingSearch) { // Only refetch if not already loading or in error state
        refetch();
      }
      if(tableData) {
        setLoading(false);
        if((tableData?.pages[0] as any).status && !(tableData?.pages[0] as any).success) {
          enqueueSnackbar(<AlertMessages statusCode={(tableData?.pages[0] as any)?.status} />, { variant: 'error' })
        }
      }
    }, [pageNo, pageSize, refetch, isLoading, error, tableData, openForm]);
    useEffect(() => {
      if(enableEdit && JSON.stringify(formData) !== JSON.stringify(prevFormData) || openForm && JSON.stringify(formData) !== JSON.stringify(prevFormData)) {
        setIsBtnEnabled(true);
      } else if(enableEdit && JSON.stringify(formData) === JSON.stringify(prevFormData)) {
        setIsBtnEnabled(false);
      }
    }, [enableEdit, formData]);

    useEffect(() => {
      const getData = setTimeout(async () => {
        if (searchTerm !== null && !isLoading && !error && loadingSearch) {
          await refetch();
          setLoadingSearch(false);
        }
      }, 1000);
      return () => clearTimeout(getData)
    }, [searchTerm, loadingSearch]);
    
    const handleChangePage = (pageNo : number) => {
      setLoading(true);  
      setPageNo(pageNo+1)
      setPageSize(pageSize)

    }
    const handleChangeRows = (pageSize : number) => {
      setLoading(true);
      setPageSize(pageSize)
      setPageNo(1)
    }

    const [errorMessages, setErrorMessages] = useState<Partial<FormData>>({});

    const openModal = () => {
        setOpenForm(!openForm);
        setIsBtnEnabled(true);
        setEnableEdit(false);
        setErrorMessages({})
        setShowSecField(false)
        setFormData({
            title: '',
            secondaryTitle: '',
            id: 0,
            phoneNumber: '',
            email: '',
            address: '',
            taxNumber: '',
            active: true
        });
        setSearch('');
        setSearchTerm('');
      };

      const handleSecField = () => {
        setShowSecField((prevValue) => !prevValue);
      }

      const handleChange = (field: string, value: any) => {
        const  val = field === "phoneNumber" ? (value.length === 0 ? '' : value) : value;

        setFormData((prevData) => ({
          ...prevData,
          [field]: val,
        }));
      
        if (requiredFields.includes(field) && !val) {
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: 'This field is required.',
          }));
        } else {
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: undefined,
          }));
        }
        if(enableEdit) {
          setIsBtnEnabled(true);
        }
      };
      
    
      const validateFormData = (data: FormData) => {
        const errors: Partial<FormData> = {};

        if (!data.title?.trim()) {
          errors.title = t('This field is required.');
        }
        if (!emailRegex?.test(data.email)) {
            errors.email = t('validEmail');
        }
        if (data.phoneNumber.length > 0 && !phoneRegex.test(data.phoneNumber)) {
          errors.phoneNumber = t('phoneLength');
        }
    
        return errors;
      };

      const { onCreateVendor,  } = useVendorService({
        onSuccess: (msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          setOpenForm(false);
          if(response.success) {
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('vendor') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
          refetch(); // This will use the updated pageNo and pageSize
        },
        onError: (err:any) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
            console.error(err.stack);
          }
      }})

      const {onUpdateVendor } = useUpdateVendorService({
        onSuccess: (data) => {
          const response: any = {};
          Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
          setEnableEdit(false);
          setOpenForm(false);
          if(response.success) {
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('vendor') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
          refetch(); // This will use the updated pageNo and pageSize

        },
        onError: (err: any) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
        id: formData.id,
      }
      );

      const handleSubmit = () => {
        const errors = validateFormData(formData);
        setErrorMessages(errors);
   
        if (Object.keys(errors).length === 0) {
          setIsBtnEnabled(false);
          setLoading(true);
          const vendorData: VendorRequest = {
            ...formData, 
            title: formData.title,
            secondaryTitle: formData.secondaryTitle,
            phoneNumber: formData.phoneNumber,
            email: formData.email,
            address: formData.address,
            taxNumber: formData.taxNumber,
          };
     
          if (enableEdit) {
            onUpdateVendor(
                vendorData
            );
          } else {
            onCreateVendor(vendorData);
          }
        }
      };

      const onEdit = (row:any) => {
        setEnableEdit(true);
        setFormData(row);
        setPrevFormData(row);
        setOpenForm(true);
        setIsBtnEnabled(false);
        setSearch('');
        setSearchTerm('');
      }

      const onDelete = (row:any) => {
        setFormData(row)
        setDeleteModal(true)
      }

      const { onDeleteVendor } = useDeleteVendorService({
        id: formData.id,
        onSuccess: (msg) => {
          if(msg.success) {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('vendor') + ' ' + t('deleted') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
          }
          refetch(); // This will use the updated pageNo and pageSize
          if(tableData?.pages[0].data.content.length === 1) {
            handleChangePage(pageNo - 1)
          }
        },
        onError: (err: any) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
      });

      const handleDelete = () => {
        setDeleteModal(false);
        setLoading(true);
        onDeleteVendor();
      }
      const cancelDelete = () => {
        setDeleteModal(false)
      }

      const clearSearch = () => {
        setSearch('');
        setSearchTerm('');
        if(searchTerm) { handleSearch(''); }
      }
      const handleSearch = (search: string) => {
        setLoadingSearch(true);
        setSearchTerm(search);
        setPageNo(1);
        setPageSize(pageSize);
      };
    
      const handleSearchClick = () => {
        const trimmedSearch = search.trim();
        if (trimmedSearch !== '') {
          handleSearch(trimmedSearch);
        }
      };
    
      const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value.trim());
        if (!event.target.value.trim()) handleSearch('');
      };
      const onChangeStatus = (row: any) => {
        row.active ? setStatusText('deactivate') : setStatusText('activate');
        setFormData(row);
        setStatusModal(true);
      }
      const cancelChangeStatus = () => {
        setStatusModal(false);
      }
      const submitChangeStatus = () => {
        setStatusModal(false);
        setLoading(true);
        onChangeStatusVendor({});
      }
      const { onChangeStatusVendor } = useChangeStatusVendorService({
        id: formData.id,
        onSuccess: (msg) => {
          if(msg.success) {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('vendor') + ' ' + t(statusText) + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
          }
          refetch();
        },
        onError: (err) => {
          console.error("===", err)
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
        active: formData.active ? "inactive" : "active"
      });
      const showRowView = (row: any) => {
        onEdit(row);
      }
      const adjustActions = (actions: any[]) => {
        return checkPrivileges('ROLE_VENDOR_UPDATE') 
          ? (checkPrivileges('ROLE_VENDOR_ARCHIVE') 
              ? actions 
              : actions.filter(action => action.label !== 'delete')) 
          : (checkPrivileges('ROLE_VENDOR_ARCHIVE') 
              ? actions.filter(action => action.label !== 'edit') 
              : []);
      }

    return(
        <Box>
            <VendorComponent
                openModal={openModal}
                closeModal={openModal}
                handleSecField={handleSecField}
                secondaryLanguage={secondaryLanguage}
                primaryLanguage={primaryLanguage}
                handleSearchClick={handleSearchClick}
                handleInputChange={handleInputChange}
                handleDelete={handleDelete}
                cancelDelete={cancelDelete}
                handleChange={handleChange} 
                handleSubmit={handleSubmit}
                formData={formData}
                handleChangePage={handleChangePage}
                handleChangeRows={handleChangeRows}
                onChangeStatus={onChangeStatus}
                cancelChangeStatus={cancelChangeStatus}
                submitChangeStatus={submitChangeStatus}
                showRowView={showRowView}
                clearSearch={clearSearch}
                columns={checkPrivileges("ROLE_VENDOR_ACTIVE") ? [
                    { id: 'title', label: 'title', numeric: false },
                    { id: 'email', label: 'email', numeric: false },
                    { id: 'phoneNumber', label: 'phoneNumber', numeric: false },
                    { id: 'active', label: 'status', numeric: false },
                    { id: 'actions', label: 'actions', numeric: false },
                ] : [
                  { id: 'title', label: 'title', numeric: false },
                  { id: 'email', label: 'email', numeric: false },
                  { id: 'phoneNumber', label: 'phoneNumber', numeric: false },
                  { id: 'actions', label: 'actions', numeric: false },
              ]}
                data={tableData?.pages[0] || {}}
                {...{ openForm, openDeleteModal, enableEdit,showSecField, pageNo, pageSize, statusModal, statusText,search, isBtnEnabled, loading,loadingSearch }}
                errorMessages={errorMessages}
                actions1 = {adjustActions([
                { label: "edit", onClick: onEdit, icon: <EditIcon /> },
                { label: "delete", onClick: onDelete, icon: <DeleteIcon /> },
                ])}
            />
        </Box>
    )
  }