import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import ButtonNew from '../Button/Button';

interface SimpleModalProps {
  visable: boolean;
  notVisableEnternalAddBtn?: boolean;
  notVisableBackbtn?: boolean;
  padding?: boolean;
  title?: string;
  btnclosetext?: string;
  content: React.ReactNode;
  isButtonVisable?: boolean;
  isVisableBtn?: boolean;
  buttonText?: string;
  attBtnNotshow?: boolean;
  attributeChangesMessage?: string;
  showPort?: boolean;
  showPortList?: () => void;
  formSubmit?: () => void;
  modalControle?: () => void;
  entityPopup?: boolean;
}

const modalCloseBtnStyle = {
  position: 'absolute' as const,
  right: 15,
  top: 15,
};

const SimpleModal: React.FC<SimpleModalProps> = ({
  visable,
  notVisableEnternalAddBtn,
  notVisableBackbtn,
  padding,
  title,
  btnclosetext,
  content,
  isButtonVisable,
  isVisableBtn,
  buttonText,
  attBtnNotshow,
  attributeChangesMessage,
  showPort,
  showPortList,
  formSubmit,
  modalControle,
  entityPopup
}) => {
  const [open, setOpen] = useState(visable);

  const handleClose = () => {
    setOpen(false);
    if (modalControle) {
      modalControle();
    }
  };
  
  return (
    <div style={{ display: open ? 'block' : 'none', width: '100%' }}>
      <Paper
        style={{
          boxShadow: 'none' ,
          // boxShadow: notVisableEnternalAddBtn ? 'none' : undefined,
          padding: padding === false ? 0 : "20px 16px 45px 16px",
          color: 'rgba(0,0,0,0.54)',
          position: 'relative',
          backgroundColor: entityPopup ? "#fff" : "#F1F6F9"
        }}
      >
        {/* {!notVisableEnternalAddBtn && title && <h1 className='mr-[5px]  ml-0 float-left font-Saira font-[700] text-[18px] text-txt-color'>{t(title)}</h1>} */}
        {!notVisableBackbtn && (
          <Button style={modalCloseBtnStyle} size="small" variant="outlined" onClick={handleClose}>
            {btnclosetext}
          </Button>
        )}
        <div>{content}</div>
        {isButtonVisable && (
          <div
            className="clearfix"
            style={{
            //   borderTop: !notVisableEnternalAddBtn && '2px outset',
              borderTop: !notVisableEnternalAddBtn ? undefined : '2px outset',
              paddingTop: 20,
            }}
          >
            {attBtnNotshow ? (
              <div>
                <Button
                  variant="contained"
                  disabled={!isVisableBtn}
                  onClick={formSubmit}
                >
                  {buttonText}
                </Button>
              </div>
            ) : (
              <>
                {title === 'Attributes' ? null : (
                  <div style={{ display: 'inline' }}>
                    <span>{attributeChangesMessage}</span>
                  </div>
                )}
                {showPort && (
                  <Button onClick={showPortList} style={{ marginLeft: 20 }}>
                   deviceModel
                  </Button>
                )}
                
                {!notVisableEnternalAddBtn && (
                  <div  style={{ float: 'right' }}>
                  <ButtonNew
                    type={!isVisableBtn ? "disabled" : "create"}
                    text={buttonText}
                    // variant="contained"
                    disabled={!isVisableBtn}
                    onClick={formSubmit}
                  >
                  </ButtonNew>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </Paper>
    </div>
  );
};

export default SimpleModal;
