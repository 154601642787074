import SimpleModal from 'common/Form/Form';
import StyledField from 'common/TextField/TexField1';
import { Grid,Tooltip } from '@mui/material';
import DynamicSelect from 'common/Select/Select';
import { ReactComponent as ModalBtn } from "assets/Modal/ModalBtn.svg";
// import CommonCheckbox from 'common/CheckBox/Checkbox';
import { checkPrivileges } from 'Helpers/CheckPrivileges';
import { useTranslation } from 'react-i18next';

interface ModelModalComponentProps {
  openModal: () => void;
  closeModal: () => void;
  handleChange: (field: string, value: any) => void;
  handleSubmit: () => void;
  onOpenMakePopup: () => void;
  onOpenModelPopup: () => void;
  formData: any;
  makesData: any;
  handleSecField: (field: string) => void;
  showModelEngineSecField: boolean;
  primaryLanguage: string;
  secondaryLanguage: string;
  modelsData: any;
  openForm: boolean;
  errorMessages: { [key: string]: string };
  enableEdit?: boolean;
  isBtnEnabled: boolean;
  loadNext: (field: string, scroll: any) => void;
  loadPrev: (field: string, scroll: any) => void;
  handleDropdownSearch: (field: string, search: string) => void;
  loadingDropdownSearch: boolean;
  clearModel: boolean;
}

const ModelModalComponent: React.FC<ModelModalComponentProps> = ({
  closeModal,
  handleChange,
  handleSubmit,
  onOpenMakePopup,
  onOpenModelPopup,
  formData,
  makesData,
  modelsData,
  openForm,
  enableEdit,
  errorMessages,
  isBtnEnabled,
  loadNext,
  loadPrev,
  showModelEngineSecField,
  handleSecField,
  primaryLanguage,
  secondaryLanguage,
  handleDropdownSearch,
  loadingDropdownSearch,
  clearModel
}) => {

  const { t } = useTranslation();

  const makeOptions = makesData.map((item: { id: number, title: string }) => ({
    value: item.id,
    label: item.title
  }));
  const modelOptions = formData.makeId ? modelsData.map((item: { id: number, title: string }) => ({
    value: item.id,
    label: item.title
  })) : [];

  return (
    <div>
      <SimpleModal
        visable={openForm}
        title={enableEdit ? "updateModelEngines" : "addModelEngines"}
        isButtonVisable={true}
        isVisableBtn={isBtnEnabled}
        btnclosetext="Back"
        buttonText={enableEdit ? "update" : "submit"}
        attBtnNotshow={false}
        notVisableBackbtn={true}
        showPortList={handleSubmit} // Assuming this is the Submit button
        modalControle={closeModal}
        // attributeChangesMessage="Attribute changes message"
        // showPort={true}
        formSubmit={handleSubmit}
        content={
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3} className='grid' style={{ gridTemplateColumns: '85% 10%' }}>
              <DynamicSelect
                defaultValue={enableEdit ? makeOptions.find((option: any) => option.value === formData?.makeId) : null}
                value={makeOptions.find((option: any) => option.value === formData?.makeId)}
                isDisabled={false}
                isLoading={loadingDropdownSearch}
                isClearable={true}
                isRtl={false}
                // fullWidth={false}
                isSearchable={true}
                name="makeId"
                options={makeOptions}
                onInputChange={(e) => handleDropdownSearch('makeId', e)}
                // isMulti
                isRequired={true}
                onChange={(e) => handleChange('makeId', e)}
                error={errorMessages?.makeIdError}
                label="make"
                onMenuScrollToBottom={(e) => { loadNext('makeId', e); }}
                onMenuScrollToTop={(e) => { loadPrev('makeId', e); }}
              />
              {checkPrivileges("ROLE_MAKE_CREATE") && <Tooltip title={t('addMake')}><ModalBtn
                onClick={onOpenMakePopup}
                className='mt-5 mx-2 mb-2 cursor-pointer h-[38px]'
              /></Tooltip>}
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} className='grid' style={{ gridTemplateColumns: '85% 10%' }}>
              <DynamicSelect
                defaultValue={enableEdit ? modelOptions.find((option: any) => option.value === formData?.modelId) : null}
                value={formData.makeId && !clearModel ? modelOptions.find((option: any) => option.value === formData?.modelId) : 0}
                isDisabled={false}
                isLoading={loadingDropdownSearch}
                isClearable={true}
                isRtl={false}
                // fullWidth={false}
                isSearchable={true}
                name="modelId"
                options={modelOptions}
                onInputChange={(e) => handleDropdownSearch('modelId', e)}
                // isMulti
                isRequired={true}
                onChange={(e) => handleChange('modelId', e)}
                error={errorMessages?.modelIdError}
                label="Model"
                onMenuScrollToBottom={(e) => { loadNext('modelId', e); }}
                onMenuScrollToTop={(e) => { loadPrev('modelId', e); }}
              />
            {checkPrivileges("ROLE_MODEL_CREATE") && <Tooltip title={t('addModels')}><ModalBtn
                onClick={onOpenModelPopup}
                className='mt-5 mx-2 mb-2 cursor-pointer h-[38px]'
              /></Tooltip>}
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}  >
              <StyledField
                label={`${!primaryLanguage ? t('primary') : ''} ${t('title')} ${primaryLanguage ? ` (${t(primaryLanguage)})` : ''}`}
                placeholder="Write your title here"
                handleChange={(e) => handleChange('title', e.target.value)}
                required={true}
                fullWidth
                search='language'
                handleSecField={() => handleSecField("modelEngineTitle")}
                error={errorMessages?.title}
                value={formData?.title || ''}
              />
              {showModelEngineSecField &&
              <Grid item xs={12} sm={12} md={12} lg={12} >
              <div style={{ position: 'relative' }}>
                <div
                  style={{
                    position: 'absolute',
                    top: -8,
                    left: 0,
                    zIndex: 1,
                    display: showModelEngineSecField ? 'flex' : 'none',
                    backgroundColor: "white",
                    height: '5rem',
                    width: '100%',
                    justifyContent:'center',
                    borderRadius: '8px',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
                  }}
                >
                  <div className='w-full mx-2 mt-1'>
                <StyledField
                  label={`${!secondaryLanguage ? t('secondary') : ''} ${t('title')} ${secondaryLanguage ? ` (${t(secondaryLanguage)})` : ''}`}
                  placeholder="Write your secondaryTitle here"
                  handleChange={(e) => handleChange('secondaryTitle', e.target.value)}
                  required={false}
                  fullWidth
                  // error={errorMessages?.secondaryTitle}
                  value={formData?.secondaryTitle || ''}
                />
                 </div>
                    </div>
                  </div>
                </Grid>
            }
            </Grid>
            
            {/* <Grid item  xs={12} sm={6} md={4} lg={3} className={`flex items-center justify-start`}>
              <CommonCheckbox
                label="isDefault"
                onChange={(e) => handleChange('default', e.target.checked)}
                color="success"
                required={false}
                // error={errorMessages?.default}
                checked={formData?.default}
              />
            </Grid> */}
          </Grid>
        }
      />
    </div>
  );
};

export default ModelModalComponent;