import Box from '@mui/material/Box';
import { useEffect, useState} from 'react';
import BusinessGroupComponent from 'components/BusinessGroup/Index';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useBusinessGroupService,useChangeStatusBusinessGroupService,useDeleteBusinessGroupService, useGetBusinessGroupService,useUpdateBusinessGroupService } from 'subModule/src/services/businessgroup/useBusinessGroup';
import { useGetBusinessGroupStationService,useAssignStationService,useUnassignStationService,useAssignAllStationService,useUnassignAllStationService } from 'subModule/src/services/businessgroup/useBusinessGroup';
import { BusinessGroupRequest } from 'subModule/src/services/businessgroup/interface';
import { useSnackbar } from 'notistack';
import AlertMessages from 'common/Alert/AlertMessages';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUserInfo } from 'features/user/userSlice';
import { checkPrivileges } from 'Helpers/CheckPrivileges';

interface FormData {
  title: string,
  secondaryTitle: string,
  id: number,
  active: boolean
}

  const requiredFields = ['title'];

  export default function BusinessGroup () {
    const [showSecField, setShowSecField] = useState<boolean>(false);
    const [openForm, setOpenForm] = useState(false);
    const [openDeleteModal, setDeleteModal] = useState(false);
    const [enableEdit, setEnableEdit] = useState(false);
    const [pageNo, setPageNo] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);
    const [statusModal, setStatusModal] = useState(false);
    const [statusText, setStatusText] = useState<string>('');
    const [rowView, setRowView] = useState(false);
    const [businessGroupId, setBusinessGroupId] = useState<number>(0);
    const [tab, setTab] = useState<number>(0);
    const [searchTerm, setSearchTerm] = useState<any>(null);
    const [selectedRow, setSelectedRow] = useState<any>({});
    const [isBtnEnabled, setIsBtnEnabled] = useState<boolean>(false);
    const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [search, setSearch] = useState('');
    const [loadingSearchAssign, setLoadingSearchAssign] = useState<boolean>(false);
    // const [data] = useState(rows);
    const [prevFormData, setPrevFormData] = useState<object>({});
    const [formData, setFormData] = useState<FormData>({
      title: '',
      secondaryTitle: '',
      id: 0,
      active: true
    });

    const { data: tableData,  isLoading, error,  refetch } = searchTerm ? useGetBusinessGroupService({ pageNumber: pageNo, size: pageSize, title: searchTerm }) : useGetBusinessGroupService({ pageNumber: pageNo, size: pageSize });
    
    const { data: stationAssignData, isLoading: stationAssignIsLoading, error: stationAssignError, refetch: stationAssignRefetch } = useGetBusinessGroupStationService({pageNumber: pageNo, size: pageSize}, businessGroupId);

    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const loggedInUserData = useSelector(selectUserInfo);
    const primaryLanguage = loggedInUserData?.primaryLanguage;
    const secondaryLanguage = loggedInUserData?.secondaryLanguage;

    let allStationsAssigned:unknown = stationAssignData?.pages[0].data?.content.map(station => station.linked).every(linked => linked === true);

    useEffect(() => {
      if ( !isLoading && !error && !rowView && !openForm && !loadingSearch) {
        refetch();
      }
      if (tableData) {
        setLoading(false);
        if ((tableData?.pages[0] as any).status && !(tableData?.pages[0] as any).success) {
          enqueueSnackbar(<AlertMessages statusCode={(tableData?.pages[0] as any)?.status} />, { variant: 'error' })
        }
      }
      if(tab === 0 && enableEdit) {
        setIsBtnEnabled(false);
      }
      if(!stationAssignIsLoading && !stationAssignError && businessGroupId && businessGroupId !== 0 && tab === 1) {
        const loadData = async() => {
          setLoadingSearchAssign(true);
          await stationAssignRefetch();
          setLoadingSearchAssign(false);
        }
        loadData();
      }
      if(tableData) {
        setLoading(false);
        if((tableData?.pages[0] as any).status && !(tableData?.pages[0] as any).success) {
          enqueueSnackbar(<AlertMessages statusCode={(tableData?.pages[0] as any)?.status} />, { variant: 'error' })
        }
      }
    }, [pageNo, pageSize, refetch, isLoading, error, businessGroupId, tab, tableData, openForm]);

    useEffect(() => {
      if(enableEdit && JSON.stringify(formData) !== JSON.stringify(prevFormData) || openForm && JSON.stringify(formData) !== JSON.stringify(prevFormData)) {
        setIsBtnEnabled(true);
      } else if(enableEdit && JSON.stringify(formData) === JSON.stringify(prevFormData)) {
        setIsBtnEnabled(false);
      }
    }, [enableEdit, formData]);

    useEffect(() => {
      const getData = setTimeout(async() => {
        if (searchTerm !== null && !isLoading && !error && loadingSearch) {
          await refetch();
          setLoadingSearch(false);
        }
      }, 1000);
      return () => clearTimeout(getData)
    }, [searchTerm, loadingSearch]);
    
    const handleChangePage = (pageNo : number) => { 
      setLoading(true); 
      setPageNo(pageNo+1);
      setPageSize(pageSize);
    }
    const handleChangeRows = (pageSize : number) =>{
      setLoading(true);
      setPageSize(pageSize);
      setPageNo(1);
    }

    const [errorMessages, setErrorMessages] = useState<Partial<FormData>>({});

    const openModal = () => {
      if(rowView) {
        setRowView(false);
        setBusinessGroupId(0);
        setPageNo(1);
        setTab(0);
      } else {
        setIsBtnEnabled(true);
        setOpenForm(!openForm);
        openModalSettings();
        setSearch('');
        setShowSecField(false)
        setSearchTerm('');
      }
    };
    const openModalSettings = () => {
      setEnableEdit(false);
        setErrorMessages({})
        setFormData({
          title: '',
          secondaryTitle: '',
          id: 0,
          active: true
      });
    }

    const handleSecField = () => {
      setShowSecField((prevValue) => !prevValue);
    }

      const handleChange = (field: string, value: any) => {
        const  val = field === "businessId" ? (value ? parseInt(value.value) || 0 : 0) : value;
        setFormData((prevData) => ({
          ...prevData,
          [field]: val,
        }));
      
        if (requiredFields.includes(field) && !val) {
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: 'This field is required.',
          }));
        } else {
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: undefined,
          }));
        }
      };
      
    
      const validateFormData = (data: FormData) => {
        const errors: Partial<FormData> = {};

        if (!data.title?.trim()) {
          errors.title = t('This field is required.');
        }
    
        return errors;
      };

      const { onCreateBusinessGroup  } = useBusinessGroupService({
        onSuccess: (msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          if(response.success) {
            setOpenForm(false);
            refetch();
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('businessGroup') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
          console.error(err.stack);
        }
      }})

      const {onUpdateBusinessGroup } = useUpdateBusinessGroupService({
        onSuccess: (data) => {
          const response: any = {};
          Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
          if(response.success) {
            if(!rowView) {
              setOpenForm(false);
              setEnableEdit(false);
              refetch();
            }
            if(rowView) { setPrevFormData(response?.data); setSelectedRow(response?.data); }
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('businessGroup') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err) => {
          console.error('Login error======', err, enableEdit);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
        id :formData.id,
      }
      );

      const handleSubmit = () => {
        const errors = validateFormData(formData);
        setErrorMessages(errors);
    
        if (Object.keys(errors).length === 0) {
          setIsBtnEnabled(false);
          setLoading(true);
          const BusinessGroupData: BusinessGroupRequest = {
            ...formData, 
            title: formData.title,
            secondaryTitle: formData.secondaryTitle,
            id: formData.id,
            active: formData.active
          };

          if (enableEdit) {
            onUpdateBusinessGroup(
                BusinessGroupData
            );
          } else {
            onCreateBusinessGroup(BusinessGroupData);
          }
        }
      };

      const onEdit = (row:any) => {
        setEnableEdit(true);
        setIsBtnEnabled(false);
        setFormData(row);
        setPrevFormData(row);
        setOpenForm(true);
        setSearch('');
        setSearchTerm('');
      }

      const onDelete = (row:any) => {
        setFormData(row);
        setDeleteModal(true);
      }

      const { onDeleteBusinessGroup } = useDeleteBusinessGroupService({
        id: formData.id,
        onSuccess: (msg) => {
          if(msg.success) {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('businessGroup') + ' ' + t('deleted') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
          }
          refetch(); // This will use the updated pageNo and pageSize
          if(tableData?.pages[0].data.content.length === 1) {
            handleChangePage(pageNo - 1)
          }
        },
        onError: (err) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
      });

      const handleDelete = () => {
        setDeleteModal(false);
        setLoading(true);
        onDeleteBusinessGroup();
      }
      const cancelDelete = () => {
        setDeleteModal(false)
      }

      const clearSearch = () => {
        setSearch('');
        setSearchTerm('');
        if(searchTerm) { handleSearch(''); }
      }
      const handleSearch = (search: string) => {
        setLoadingSearch(true);
        setSearchTerm(search);
        setPageNo(1);
        setPageSize(pageSize);
      };
    
      const handleSearchClick = () => {
        const trimmedSearch = search.trim();
        if (trimmedSearch !== '') {
          handleSearch(trimmedSearch);
        }
      };
    
      const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value?.trim());
        if (!event.target.value?.trim()) handleSearch('');
      };

      const onChangeStatus = (row: any) => {
        row.active ? setStatusText('deactivate') : setStatusText('activate');
        setFormData(row);
        setStatusModal(true);
      }
      const cancelChangeStatus = () => {
        setStatusModal(false);
      }
      const submitChangeStatus = () => {
        setStatusModal(false);
        setLoading(true);
        onChangeStatusBusinessGroup({});
      }
      const { onChangeStatusBusinessGroup } = useChangeStatusBusinessGroupService({
        id: formData.id,
        onSuccess: (msg) => {
          if(msg.success) {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('businessGroup') + ' ' + t(statusText) + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
          }
          refetch();
        },
        onError: (err: any) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
        active: formData.active ? "inactive" : "active"
      });
      const showRowView = (row: any) => {
        onEdit(row);
        setEnableEdit(true);
        setIsBtnEnabled(false);
        setOpenForm(false);
        setFormData(row);
        setErrorMessages({});
        setRowView(true);
        setBusinessGroupId(row.id);
        setSelectedRow(row);
        setSearch('');
        setSearchTerm('');
      }
      const handleChangeTabs = (tabId: number) => {
        if(tabId === 0) {
          setTab(0);
          setEnableEdit(true);
          setOpenForm(false);
          setFormData(selectedRow);
          setErrorMessages({});
        } else if(tabId === 1) {
          setTab(1);
          setPageNo(1);
          setEnableEdit(false);
          setErrorMessages({});
        } else if(tabId === 2) {
          setTab(2);
          setPageNo(1);
          setEnableEdit(false);
          setErrorMessages({});
        }
      }
      const assignUnassignSingleEntity = (isChecked: boolean, row: any) => {
        setLoadingSearchAssign(true);
        if(isChecked) {
          if(tab === 1) {
            onAssignStation({ businessGroupId: businessGroupId, stationId: row.id });
          }
        } else {
          if(tab === 1) {
            onUnassignStation({ businessGroupId: businessGroupId, stationId: row.id });
          }
        }
      }
      const assignUnassignAllEntities = (isChecked: boolean) => {
        setLoadingSearchAssign(true);
        if(isChecked) {
          if(tab === 1) {
            onAssignAllStations({businessGroupId: businessGroupId});
          }
        } else {
          if(tab === 1) {
            onUnassignAllStations({businessGroupId: businessGroupId});
          }
        }
      }
      const { onAssignStation } = useAssignStationService({
        onSuccess: async(msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          if(response.success) {
            if(stationAssignData?.pages[0].data.content.length === stationAssignData?.pages[0].data.content.filter(station => station.linked).length) {
              allStationsAssigned = true;
            }
            await stationAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('stations') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await stationAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err:any) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
            console.error(err.stack);
          }
      }})
      const { onUnassignStation } = useUnassignStationService({
        onSuccess: async(data) => {
          const response: any = {};
          Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
          if(response.success) {
            if(stationAssignData?.pages[0].data.content.length !== stationAssignData?.pages[0].data.content.filter(station => station.linked).length) {
              allStationsAssigned = false;
            }
            await stationAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('service') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await stationAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err: any) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
      });
      const { onAssignAllStations } = useAssignAllStationService({
        onSuccess: async(msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          if(response.success) {
            allStationsAssigned = true;
            await stationAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('All') + ' ' + t('stations') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await stationAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err:any) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
            console.error(err.stack);
          }
      }})
      const { onUnassignAllStations } = useUnassignAllStationService({
        onSuccess: async(data) => {
          const response: any = {};
          Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
          if(response.success) {
            allStationsAssigned = false;
            await stationAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('All') + ' ' + t('stations') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await stationAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err: any) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
      });
      const adjustActions = (actions: any[]) => {
        return checkPrivileges('ROLE_BUSINESSGROUP_UPDATE') 
          ? (checkPrivileges('ROLE_BUSINESSGROUP_ARCHIVE') 
              ? actions 
              : actions.filter(action => action.label !== 'delete')) 
          : (checkPrivileges('ROLE_BUSINESSGROUP_ARCHIVE') 
              ? actions.filter(action => action.label !== 'edit') 
              : []);
      }

    return(
        <Box>
            <BusinessGroupComponent
                openModal={openModal}
                closeModal={openModal}
                handleSecField={handleSecField}
                secondaryLanguage={secondaryLanguage}
                primaryLanguage={primaryLanguage}
                handleSearchClick={handleSearchClick}
                handleInputChange={handleInputChange}
                handleDelete={handleDelete}
                cancelDelete={cancelDelete}
                handleChange={handleChange} 
                handleSubmit={handleSubmit}
                formData={formData}
                handleChangePage={handleChangePage}
                handleChangeRows={handleChangeRows}
                onChangeStatus={onChangeStatus}
                cancelChangeStatus={cancelChangeStatus}
                submitChangeStatus={submitChangeStatus}
                showRowView={showRowView}
                rowView={rowView}
                handleChangeTabs={handleChangeTabs}
                assignUnassignSingleEntity={assignUnassignSingleEntity}
                assignUnassignAllEntities={assignUnassignAllEntities}
                allStationsAssigned={allStationsAssigned}
                clearSearch={clearSearch}
                columns={checkPrivileges("ROLE_BUSINESSGROUP_ACTIVE") ? [
                    { id: 'title', label: 'title', numeric: false },
                    { id: 'active', label: 'status', numeric: false },
                    { id: 'actions', label: 'actions', numeric: false },
                ] : [
                  { id: 'title', label: 'title', numeric: false },
                  { id: 'actions', label: 'actions', numeric: false },
              ]}
                data={tableData?.pages[0] || {}}
                stationAssignData={stationAssignData?.pages[0] || {}}
                {...{ openForm, openDeleteModal, enableEdit,showSecField,  pageNo, pageSize, statusModal, statusText, search, isBtnEnabled, loading, loadingSearch, loadingSearchAssign }}
                errorMessages={errorMessages}
                actions1 = {adjustActions([
                  { label: "edit", onClick: onEdit, icon: <EditIcon /> },
                  { label: "delete", onClick: onDelete, icon: <DeleteIcon /> },
                ])}
            />
        </Box>
    )
  }