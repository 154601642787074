import * as React from 'react';
import { FC } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';

interface SelectMultiFilterProps {
  label: string;
  filterOptions: string[];
  handleChangeFilter: (event: any) => void;
  filter: string[];
  replaceLabel?: boolean;
}

const SelectMultiFilter: FC<SelectMultiFilterProps> = ({
  label,
  filterOptions,
  handleChangeFilter,
  filter,
  replaceLabel
}) => {
  const { t } = useTranslation();
  const [closeMenu, setCloseMenu] = React.useState<boolean>(true);

  const ITEM_HEIGHT = 30;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 10 + 5,
        width: 'auto',
      },
    },
  };
  const handleOpen = () => {
    document.body.style.overflow = 'hidden';
    setCloseMenu(false);
  }
  const handleClose = () => {
    document.body.style.overflow = 'auto';
    setCloseMenu(true);
  }
  // const handleIcon=()=>{
  //   setCloseMenu(false);
  // }
  const translatedFilter = filter.map(value => value === 'userName' ? t('email') : value === 'customerName' ? t('clientName') : value === 'customerPhone' ? t('clientPhone') : value === 'customerName' ? t('clientName') : value === 'customerPhone' ? t('clientPhone') : value === 'title' && replaceLabel ? t('name') : value === 'sourceStoreId' ? t('from') + ' ' + t('store') : value === 'destinationStoreId' ? t('to') + ' ' + t('store') : value === 'storeId' ? t('store') : t(value)).join(', ');
  return (
    <div>
      <FormControl sx={{ 
        width: '100%', 
        '& .MuiInputLabel-shrink': {
          color: 'rgba(0, 0, 0, 0.6) !important'
        },
      }}>
        <InputLabel id="demo-multiple-checkbox-label" className={`font-Saira font-[500] text-[13px] leading-[17px] absolute top-[-2px]`}>{t(label)}</InputLabel>
        <Tooltip className='text-start' title={!closeMenu ? '' : translatedFilter}>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={filter}
            onChange={handleChangeFilter}
            onOpen={handleOpen}
            onClose={handleClose}
            input={<OutlinedInput label={t(label)} />}
            // IconComponent={() => (
            //   <KeyboardArrowDownIcon sx={{fontSize:"2.1rem",backgroundColor:"rgb(216, 209, 224)", borderRadius: '4px', }} onClick={handleIcon}/> // Change the color here
            // )}
            renderValue={(selected) => selected.map(value => value === 'userName' ? t('email') : value === 'customerName' ? t('clientName') : value === 'customerPhone' ? t('clientPhone') : value === 'title' && replaceLabel ? t('name') : value === 'sourceStoreId' ? t('from') + ' ' + t('store') : value === 'destinationStoreId' ? t('to') + ' ' + t('store') : value === 'storeId' ? t('store') : t(value)).join(', ')}
            MenuProps={MenuProps}
            className={`font-Saira mt-[1px] italic font-[600] text-[13px] h-9 leading-[17px]`}
            sx={{ 
              color: 'rgba(0, 0, 0, 0.6) !important',
              '& #demo-multiple-checkbox': {
                background: '#fff',
                padding: '8px 30px 8px 10px',
                borderRadius: '8px',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderRadius: '8px',
              },
              // '& .Mui-selected': {
              //   backgroundColor: 'rgba(76, 0, 85, 0.15) !important'
              // },
              // '& :hover .MuiMenuItem-root': {
              //   backgroundColor: 'rgba(76, 0, 85, 0.15) !important'
              // },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
               borderColor: 'black !important',
               borderWidth:"1px !important"
              },
            }}
     
          >
            {filterOptions.map((name) => (
              <MenuItem key={name} value={name} sx={{ padding: '20px 15px', borderBottom: '1px solid #DFD2E1' }}>
                <ListItemText primary={name === 'userName' ? t('email') : name === 'customerName' ? t('clientName') : name === 'customerPhone' ? t('clientPhone') : name === 'title' && replaceLabel ? t('name') : name === 'sourceStoreId' ? t('from') + ' ' + t('store') : name === 'destinationStoreId' ? t('to') + ' ' + t('store') : name === 'storeId' ? t('store') : t(name)} sx={{
                    '& .MuiListItemText-primary': {
                      fontFamily: 'Saira',
                      fontSize: '14px',
                      fontStyle:'italic',
                      lineHeight: '18px',
                      fontWeight: 500,
                      letterSpacing: 0,
                      color: 'rgba(0, 0, 0, 0.6)',
                    },
                  }} 
                />
                {filter.indexOf(name) > -1 ? 
                  <CheckOutlinedIcon className='text-primary-color text-[24px] absolute right-[10px]' />
                : null}
              </MenuItem>
            ))}
          </Select>
        </Tooltip>
      </FormControl>
    </div>
  );
}

export default SelectMultiFilter;