import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import ClientComponent from 'components/Client/Index';
import EditIcon from '@mui/icons-material/Edit';
import { useEffect, useState } from 'react';
import { ClientRequest } from 'subModule/src/services/client/interface';
import { useClientService,useDeleteClientService,useGetClientsService,useUpdateClientService,useChangeStatusClientService } from 'subModule/src/services/client/useClient';
// import { useGetRoleDropdownService } from 'subModule/src/services/role/useRole';
import { useUpdatePasswordService } from 'subModule/src/services/user/useUser';
import { UpdatePasswordRequest } from 'subModule/src/services/user/interface';
import { useSnackbar } from 'notistack';
import AlertMessages from 'common/Alert/AlertMessages';
import { useTranslation } from 'react-i18next';
import { SelectChangeEvent } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectUserInfo } from 'features/user/userSlice';
import { checkPrivileges } from 'Helpers/CheckPrivileges';


interface FormData {
  id:number,
  name: string,
  secondaryName: string,
  roleId: number,
  // roleIdError?:string,
  type: number,
  typeError?: string,
  username: string,
  email: string,
  password: string,
  phone: string,
  address: string,
  creditLimit: number,
  creditPeriod: number,
  city: string,
  country: string,
  postalCode: string,
  active: boolean,
  business: object,
  emailValid: boolean,
  account: {
    id: number,
    contractNumber: string,
    title: string,
    secondaryTitle: string,
    email: string,
    phone: string,
    creditLimit: number,
    creditLimitError?: string,
    creditPeriod: number,
    creditPeriodError?: string,
    companyType: number,
    companyTypeError?: string,
    contractStart: string,
    contractEnd: string,
    address: string,
    taxNumber: string,
  }
}
interface UpdatePasswordFormData {
  newPassword: string,
  confirmNewPassword: string,
}

interface SearchFormData {
  [key: string]: string;
}

const requiredFields = ['name', 'username', 'password'];

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const phoneRegex = /^[\p{N}+]{5,17}$/u;

const updatePasswordRequiredFields = ['newPassword', 'confirmNewPassword'];

export default function Client () {
  const [showSecAccountField, setShowSecAccountField] = useState<boolean>(false);
  const [showSecUserField, setShowSecUserField] = useState<boolean>(false);
    const [openForm, setOpenForm] = useState(false);
    const [openDeleteModal, setDeleteModal] = useState(false);
    const [enableEdit, setEnableEdit] = useState(false);
    const [pageNo, setPageNo] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);
    const [statusModal, setStatusModal] = useState(false);
    const [statusText, setStatusText] = useState<string>('');
    const [isBtnEnabled, setIsBtnEnabled] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
    const [filter, setFilter] = useState<string[]>(['name']);
    const [search, setSearch] = useState<any>({});
    // const [dropdown, setDropdown] = useState<string>('roleId');
    // const [rolesPageParams, setRolesPageParams] = useState<any>({ number: 1, size: 20 });
    const [prevFormData, setPrevFormData] = useState<object>({});
    const [rowView, setRowView] = useState(false);
    const [tab, setTab] = useState<number>(0);
    const [selectedRow, setSelectedRow] = useState<any>({});
      const [formData, setFormData] = useState<FormData>({
        id: 0,
        name: "",
        secondaryName: "",
        roleId: 0,
        type: 0,
        username: "",
        email: "",
        password: "",
        phone: "",
        address: "",
        creditLimit: 0,
        creditPeriod: 0,
        city: "",
        country: "",
        postalCode: "",
        active: false,
        business: {},
        emailValid: false,
        account: {
          id: 0,
          contractNumber: "",
          title: "",
          secondaryTitle: "",
          email: "",
          phone: "",
          creditLimit: 0,
          creditPeriod: 0,
          companyType: 0,
          contractStart: "",
          contractEnd: "",
          address: "",
          taxNumber: ""
        }
      });
      const [updatePasswordFormData, setUpdatePasswordFormData] = useState<UpdatePasswordFormData>({
        newPassword: '',
        confirmNewPassword: ''
      });

      const [searchFormData, setSearchFormData] = useState<SearchFormData>({
        name:'',
        userName:'',
        accountTitle:'', 
        contractNumber:'',
      });
    const { data: tableData,  isLoading, error,  refetch } = useGetClientsService({ pageNumber: pageNo, size: pageSize, ...search });
    
    // const { data: rolesData, isLoading: rolesIsLoading, error: rolesError, refetch: rolesRefetch } = useGetRoleDropdownService({pageNumber: rolesPageParams.number, size: rolesPageParams.size, title: search});

    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const loggedInUserData = useSelector(selectUserInfo);
    const primaryLanguage = loggedInUserData?.primaryLanguage;
    const secondaryLanguage = loggedInUserData?.secondaryLanguage;

    useEffect(() => {
      if (!isLoading && !error && !rowView && !openForm && !loadingSearch) { // Only refetch if not already loading or in error state
        refetch();
      }
      if(tableData) {
        setLoading(false);
        if((tableData?.pages[0] as any).status && !(tableData?.pages[0] as any).success) {
          enqueueSnackbar(<AlertMessages statusCode={(tableData?.pages[0] as any)?.status} />, { variant: 'error' })
        }
      }
    }, [pageNo, pageSize, refetch, isLoading, error, tableData, openForm, rowView]);
    useEffect(() => {
      if(enableEdit && JSON.stringify(formData) !== JSON.stringify(prevFormData) || openForm && JSON.stringify(formData) !== JSON.stringify(prevFormData)) {
        setIsBtnEnabled(true);
      } else if(enableEdit && JSON.stringify(formData) === JSON.stringify(prevFormData)) {
        setIsBtnEnabled(false);
      }
    }, [enableEdit, formData]);

    useEffect(() => {
      const getData = setTimeout(async() => {
        if (search && !isLoading && !error && loadingSearch) {
          await refetch();
          setLoadingSearch(false);
        }
      }, 1000)
      return () => clearTimeout(getData)
    }, [search]);

    // useEffect(() => {
    //   if(rolesPageParams && openForm && dropdown === 'roleId' && !rolesIsLoading && !rolesError) {
    //     rolesRefetch();
    //   }
    // }, [rolesPageParams])
    
    // const loadNext = (field: string, scroll: any) => {
    //   if(scroll.deltaY > 90 || scroll.touches[0].clientY > 90) {
    //     if(field === 'roleId') {
    //       if(!(rolesData?.pages[0]?.data as any).last) {
    //         setDropdown('roleId');
    //         setRolesPageParams({ number: rolesPageParams.number + 1, size: rolesPageParams.size });
    //       }
    //     }
    //   }
    // }
    // const loadPrev = (field: string, scroll: any) => {
    //   if(scroll.deltaY < 10 || scroll.touches[0].identifier === 0) {
    //     if(field === 'makeId') {
    //       if(!(rolesData?.pages[0]?.data as any).first) {
    //         setDropdown('roleId');
    //         setRolesPageParams({ number: rolesPageParams.number - 1, size: rolesPageParams.size });
    //       }
    //     }
    //   }
    // }
    
    const handleChangePage = (pageNo : number) => { 
      setLoading(true);
      setPageNo(pageNo + 1);
      setPageSize(pageSize);

    }
    const handleChangeRows = (pageSize : number) => {
      setLoading(true);
      setPageSize(pageSize);
      setPageNo(1);
    }

    const [errorMessages, setErrorMessages] = useState<{ user: Partial<FormData> ; account: Partial<FormData['account']> }>({
      user: {},
      account: {}
    });
    const [updatePasswordErrorMessages, setUpdatePasswordErrorMessages] = useState<Partial<UpdatePasswordFormData>>({});

    const [searchErrorMessages, setSearchErrorMessages] = useState<Partial<SearchFormData>>({});

    const openModal = () => {
      if (rowView) {
        setRowView(false);
        setPageNo(1);
        setTab(0);
      } else {
        setOpenForm(!openForm);
        setIsBtnEnabled(true);
        openModalSettings();
      }
    }
    const openModalSettings = () => {
      setEnableEdit(false);
      setShowSecAccountField(false);
      setShowSecUserField(false);
      setErrorMessages({user: {}, account: {}});
      setFormData({
        id: 0,
        name: "",
        secondaryName: "",
        roleId: 0,
        type: 0,
        username: "",
        email: "",
        password: "",
        phone: "",
        address: "",
        creditLimit: 0,
        creditPeriod: 0,
        city: "",
        country: "",
        postalCode: "",
        active: false,
        business: {},
        emailValid: false,
        account: {
          id: 0,
          contractNumber: "",
          title: "",
          secondaryTitle: "",
          email: "",
          phone: "",
          creditLimit: 0,
          creditPeriod: 0,
          companyType: 0,
          contractStart: "",
          contractEnd: "",
          address: "",
          taxNumber: ""
        }
      });
      if(!openForm && !enableEdit) {
        // rolesRefetch();
        // setRolesPageParams({ number: 1, size: 20 });
        clearSearch();
      }
    }

      const handleSecField = (field: string) => {
        if (field === 'account') {
          setShowSecAccountField((prevValue) => !prevValue);
        } else if (field === 'user') {
          setShowSecUserField((prevValue) => !prevValue);
        }
      }

      const handleChange = (field: string, value: any, section: 'user' | 'account') => {
        const val = (field === "roleId" || field === "type" || field === "companyType") ? 
          (value ? parseInt(value.value) || 1 : 0) 
          : field === "creditLimit" || field === "creditPeriod" ?
            (value >=0 ? value : 0)
          : field === "phone" ?
            value.length === 0 ? '' : value
          : (field === "contractStart" || field === "contractEnd") ?
            new Date(value).toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })?.split('/').reverse().join('-')
          : value;
      
        if (field === 'type' && val === 170) {
          setFormData((prevData) => ({
            ...prevData,
            [field]: val,
          }));
        } else {
          section === 'account' ? 
            setFormData((prevData) => ({
              ...prevData,
              [section]: {
                ...prevData[section],
                [field]: val,
              }
            }))
          : setFormData((prevData) => ({
              ...prevData,
              [field]: val,
          }));
        }
      
        if (requiredFields.includes(field) && !val) {
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            [section]: {
              ...prevErrors[section],
              [field]: 'This field is required.',
            }
          }));
        } else {
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            [section]: {
              ...prevErrors[section],
              [field]: undefined,
            }
          }));
        }
      
        if (enableEdit) {
          setIsBtnEnabled(true);
        }
      };

    
      const validateFormData = (data: FormData) => {
        const errorsUser: Partial<FormData> = {};
        const errorsAccount: Partial<FormData['account']> = {};
        
        if (!data.type) {
          errorsUser.typeError = t('This field is required.');
        }
        // if (!data.roleId) {
        //   errorsUser.roleIdError = t('This field is required.');
        // }
        if (!data.username?.trim()) {
          errorsUser.username = t('This field is required.');
        }
        if (!emailRegex?.test(data.username)) {
          errorsUser.username = t('validEmail');
        }
        if (!data.password?.trim() || data.password.length < 8) {
          errorsUser.password = t('minPasswordLength');
        }
        if (!data.name?.trim()) {
          errorsUser.name = t('This field is required.');
        }
        if (data.phone && !phoneRegex.test(data.phone)) {
          errorsUser.phone =  t('phoneLength');
        }
    
        // Validations for account data
        if ((data.type === 160 || data.type === 0) && !data.account?.title?.trim()) {
          errorsAccount.title = t('This field is required.');
        }
        if ((data.type === 160 || data.type === 0) && (!data.account?.email?.trim() || !emailRegex.test(data.account?.email))) {
          errorsAccount.email = t('validEmail');
        }
        if ((data.type === 160 || data.type === 0) && data.account?.phone && !phoneRegex.test(data.account?.phone)) {
          errorsAccount.phone = t('phoneLength');
        }
        if (data.account?.contractStart && data.account?.contractStart === "Invalid Date") {
          errorsAccount.contractStart = t('Invalid Date');
        }
        if (data.account?.contractEnd && data.account?.contractEnd === "Invalid Date") {
          errorsAccount.contractEnd = t('Invalid Date');
        }
        if ((data.type === 160 || data.type === 0) && (formData.account?.contractStart && formData.account?.contractEnd && new Date(formData.account?.contractStart) >= new Date(formData.account?.contractEnd))) {
          errorsAccount.contractStart =t('contractStartDateOrder');
        }
    
        return { errorsUser, errorsAccount };
      };

      const { onCreateClient } = useClientService({
        onSuccess: (msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          if(response.success) {
            setOpenForm(false);
            refetch();
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('created') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={response.status}  text={t('email')} />, { variant: 'error' });
          }
        },
        onError: (err) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
          console.error(err.stack);
        }
      }})

      const { onUpdateClient } = useUpdateClientService({
        onSuccess: (data) => {
          const response: any = {};
          Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
          if(response.success) {
            if (!rowView) {
              setEnableEdit(false);
              setOpenForm(false);
              refetch();
            }
            if(rowView) { setPrevFormData(response?.data); setSelectedRow(response?.data); }
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={response.status}  text={t('email')} />, { variant: 'error' });
          }
        },
        onError: (err) => {
          console.error('Login error======', err, enableEdit);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
        id: formData.id,
      }
      );
    
      const handleSubmit = () => {
        const errors = validateFormData(formData);
        setErrorMessages({user: { ...errors.errorsUser }, account: { ...errors.errorsAccount }});

        if (Object.keys(errors.errorsAccount).length === 0 && Object.keys(errors.errorsUser).length === 0) {
          setIsBtnEnabled(false);
          setLoading(true);
          const clientData: ClientRequest = (formData.type === 0 || formData.type === 160) ? {
            ...formData, 
            id: formData.id,
            name: formData.name,
            secondaryName: formData.secondaryName,
            type: formData.type,
            roleId: formData.roleId,
            username: formData.username,
            email: formData.username,
            password: formData.password,
            phone: formData.phone,
            address: formData.address,
            creditLimit: formData.creditLimit,
            creditPeriod: formData.creditPeriod,
            city: formData.city,
            country: formData.country,
            postalCode: formData.postalCode,
            active: formData.active,
            business: formData.business,
            emailValid: formData.emailValid,
            account: {
              id: formData.account.id,
              contractNumber: formData.account.contractNumber,
              title: formData.account.title,
              secondaryTitle: formData.account.secondaryTitle,
              email: formData.account.email,
              phone: formData.account.phone,
              creditLimit: formData.account.creditLimit,
              creditPeriod: formData.account.creditPeriod,
              companyType: formData.account.companyType,
              contractStart: formData.account.contractStart,
              contractEnd: formData.account.contractEnd,
              address: formData.account.address,
              taxNumber: formData.account.taxNumber,
            }
          } : {
            id: formData.id,
            name: formData.name,
            secondaryName: formData.secondaryName,
            type: formData.type,
            roleId: formData.roleId,
            username: formData.username,
            email: formData.username,
            password: formData.password,
            phone: formData.phone,
            address: formData.address,
            creditLimit: formData.creditLimit,
            creditPeriod: formData.creditPeriod,
            city: formData.city,
            country: formData.country,
            postalCode: formData.postalCode,
            active: formData.active,
            business: formData.business,
            emailValid: formData.emailValid,
          };
     
          onCreateClient(clientData);
        } else if(Object.keys(errors.errorsAccount).length === 0 && Object.keys(errors.errorsUser).length === 1 && enableEdit && 'password' in errors.errorsUser) {
          setIsBtnEnabled(false);
          setLoading(true);
          const clientData: ClientRequest = (formData.type === 0 || formData.type === 160) ? {
            ...formData, 
            id: formData.id,
            name: formData.name,
            secondaryName: formData.secondaryName,
            type: formData.type,
            roleId: formData.roleId,
            username: formData.username,
            email: formData.username,
            password: formData.password,
            phone: formData.phone,
            address: formData.address,
            creditLimit: formData.creditLimit,
            creditPeriod: formData.creditPeriod,
            city: formData.city,
            country: formData.country,
            postalCode: formData.postalCode,
            active: formData.active,
            business: formData.business,
            emailValid: formData.emailValid,
            account: {
              id: formData.account.id,
                contractNumber: formData.account.contractNumber,
                title: formData.account.title,
                secondaryTitle: formData.account.secondaryTitle,
                email: formData.account.email,
                phone: formData.account.phone,
                creditLimit: formData.account.creditLimit,
                creditPeriod: formData.account.creditPeriod,
                companyType: formData.account.companyType,
                contractStart: formData.account.contractStart,
                contractEnd: formData.account.contractEnd,
                address: formData.account.address,
                taxNumber: formData.account.taxNumber,
            }
          } : {
            id: formData.id,
            name: formData.name,
            secondaryName: formData.secondaryName,
            type: formData.type,
            roleId: formData.roleId,
            username: formData.username,
            email: formData.username,
            password: formData.password,
            phone: formData.phone,
            address: formData.address,
            creditLimit: formData.creditLimit,
            creditPeriod: formData.creditPeriod,
            city: formData.city,
            country: formData.country,
            postalCode: formData.postalCode,
            active: formData.active,
            business: formData.business,
            emailValid: formData.emailValid,
          };
          onUpdateClient(clientData);
        }
      };
  
      const onEdit = (row:any) => {
        setEnableEdit(true);
        if(emailRegex.test(row.username)) {
          setFormData(row);
          setPrevFormData(row);
        } else {
          setFormData({...row, username: ''});
          setPrevFormData({...row, username: ''});
        }
        setOpenForm(true);
        setIsBtnEnabled(false);
        // setRolesPageParams({ number: 1, size: 20 });
        // rolesRefetch();
        clearSearch();
      }

      const onDelete = (row:any) => {
        setFormData(row);
        setDeleteModal(true);
      }
      const { onDeleteClient } = useDeleteClientService({
        id: formData.id,
        onSuccess: (msg) => {
          if(msg.success) {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('deleted') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
          }
          refetch(); // This will use the updated pageNo and pageSize
          if(tableData?.pages[0].data.content.length === 1) {
            handleChangePage(pageNo - 1)
          }
        },
        onError: (err) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
      });

      const handleDelete = () => {
        setDeleteModal(false);
        setLoading(true);
        onDeleteClient();
      }

      const cancelDelete = () => {
        setDeleteModal(false)
      }


      const clearSearch = () => {
        setFilter(['name']);
        setSearchFormData({
          name:'',
          userName:'',
          accountTitle:'', 
          contractNumber:'', 
        });
        setSearchErrorMessages({});
        if(Object.keys(search).length) {
          setLoadingSearch(true);
          setSearch({});
        }
      } 
      const handleChangeFilter = (event: SelectChangeEvent<typeof filter>) => {
        const { target: { value }, } = event;
        setFilter(typeof value === 'string' ? value.split(',') : value,);
        setSearchFormData(prevFormData => 
          Object.fromEntries(Object.keys(prevFormData).map(key => 
            [key, value.includes(key) ? prevFormData[key] : '']
          ))
        );
        if(!value.length && Object.keys(search).length) {
          clearSearch();
        }
      };
      const handleChangeSearch = (field: string, value: any) => {
        const val = value;
    
        setSearchFormData((prevData) => ({
          ...prevData,
          [field]: val,
        }));
    
        if (filter.includes(field) && !val) {
          setSearchErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: 'This field is required.',
          }));
        } else {
          setSearchErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: undefined,
          }));
        }
      };
      const validateSearchFormData = (data: SearchFormData) => {
        const errors: Partial<SearchFormData> = {};
    
        filter.forEach(fieldName => {
          if (!data[fieldName]) { 
            errors[fieldName] = t('required');
          }
        });
    
        return errors;
      };
      const handleSubmitSearch = () => {
        const errors = validateSearchFormData(searchFormData);
        setSearchErrorMessages(errors);
    
        if (Object.keys(errors).length === 0) {
          setLoadingSearch(true);
          const filteredSearch: Record<string, string> = {};
    
          Object.entries(searchFormData).forEach(([key, value]) => {
              if (value) {
                filteredSearch[key] = typeof value === 'string' ? value.trim() : value;
              }
          });
    
          setSearch(filteredSearch);
          setPageNo(1);
          setPageSize(pageSize);
        }
      };

      const onChangeStatus = (row: any) => {
        row.active ? setStatusText('deactivate') : setStatusText('activate');
        setFormData(row);
        setStatusModal(true);
      }
      const cancelChangeStatus = () => {
        setStatusModal(false);
      }
    
      const submitChangeStatus = () => {
        setStatusModal(false);
        setLoading(true);
        onChangeStatusClient({});
      }
      const { onChangeStatusClient } = useChangeStatusClientService({
        id: formData.id,
        onSuccess: (msg) => {
          if(msg.success) {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t(statusText) + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
          }
          refetch();
        },
        onError: (err: any) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
        active: formData.active ? "inactive" : "active"
      });
      const showRowView = (row: any) => {
        setEnableEdit(true);
        setOpenForm(false);
        setIsBtnEnabled(false);
        if(emailRegex.test(row.username)) {
          setFormData(row);
          setPrevFormData(row);
        } else {
          setFormData({...row, username: ''});
          setPrevFormData({...row, username: ''});
        }
        setErrorMessages({user: {}, account: {}});
        // rolesRefetch();
        setRowView(true);
        setSelectedRow(row);
        setSearch('');
        clearSearch();
      }
      const handleChangeTabs = (tabId: number) => {
        if (tabId === 0) {
          setTab(0);
          setEnableEdit(true);
          setOpenForm(false);
          setFormData(selectedRow);
          setErrorMessages({user: {}, account: {}});
          // rolesRefetch();
        } else if (tabId === 1) {
          setTab(1);
          setPageNo(1);
          setEnableEdit(false);
          setUpdatePasswordErrorMessages({});
          setUpdatePasswordFormData({
            newPassword: '',
            confirmNewPassword: ''
          });
        }
      }
      const handleUpdatePassword = (field: string, value: any) => {
        const  val = value;

        setUpdatePasswordFormData((prevData) => ({
          ...prevData,
          [field]: val,
        }));
      
        if (updatePasswordRequiredFields.includes(field) && !val) {
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: 'This field is required.',
          }));
        } else {
          setUpdatePasswordErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: undefined,
          }));
        }
      };
      const validateFormDataUpdatePassword = (data: UpdatePasswordFormData) => {
        const errors: Partial<UpdatePasswordFormData> = {};
        
        if (!data.newPassword?.trim() || data.newPassword?.length < 8) {
          errors.newPassword = t('minPasswordLength');
        }
        
        if (!data.confirmNewPassword?.trim() || data.newPassword !== data.confirmNewPassword) {
          errors.confirmNewPassword = t('confirmPasswordNewPassword');
        }
    
        return errors;
      };
      const handleSubmitUpdatePassword = () => {
        const errors = validateFormDataUpdatePassword(updatePasswordFormData);
        setUpdatePasswordErrorMessages(errors);

        if (Object.keys(errors).length === 0) {
          setIsBtnEnabled(false);
          setLoading(true);
          const updatePasswordData: UpdatePasswordRequest = {
            email: formData.email,
            newPassword: updatePasswordFormData.newPassword,
          };
          onUpdatePassword(updatePasswordData);
        }
      };
      const { onUpdatePassword } = useUpdatePasswordService({
        onSuccess: (data) => {
          const response: any = {};
          Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
          if(response.success) {
            setUpdatePasswordFormData({
              newPassword: '',
              confirmNewPassword: ''
            });
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('password') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err) => {
          console.error('Login error======', err, enableEdit);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
      });
      const adjustActions = (actions: any[]) => {
        return checkPrivileges('ROLE_CLIENT_UPDATE') 
          ? (checkPrivileges('ROLE_CLIENT_ARCHIVE') 
              ? actions 
              : actions.filter(action => action.label !== 'delete')) 
          : (checkPrivileges('ROLE_CLIENT_ARCHIVE') 
              ? actions.filter(action => action.label !== 'edit') 
              : []);
      }

    return(
      <Box>
        <ClientComponent
        handleSecField={handleSecField}
        secondaryLanguage={secondaryLanguage}
        primaryLanguage={primaryLanguage}
          openModal={openModal}
          closeModal={openModal}
          handleDelete={handleDelete}
          cancelDelete={cancelDelete}
          handleChange={handleChange} 
          handleSubmit={handleSubmit}
          formData={formData}
          // rolesData={rolesData?.pages[0] || {}}
          handleChangePage={handleChangePage}
          handleChangeRows={handleChangeRows}
          onChangeStatus={onChangeStatus}
          cancelChangeStatus={cancelChangeStatus}
          submitChangeStatus={submitChangeStatus}
          showRowView={showRowView}
          // loadNext={loadNext}
          // loadPrev={loadPrev}
          handleChangeTabs={handleChangeTabs}
          handleUpdatePassword={handleUpdatePassword}
          updatePasswordFormData={updatePasswordFormData}
          updatePasswordErrorMessages={updatePasswordErrorMessages}
          handleSubmitUpdatePassword={handleSubmitUpdatePassword}
          columns={checkPrivileges("ROLE_CLIENT_ACTIVE") ? [
            { id: 'name', label: 'name', numeric: false },
            { id: 'userNameClient', label: 'email', numeric: false },
            { id: 'accountTitleClient', label: "accountTitle", numeric: false },
            { id: 'accountContractNumber', label: "contractNumber", numeric: false },
            { id: 'typeTitle', label: "type", numeric: false },
            { id: 'active', label: 'status', numeric: false },
            { id: 'actions', label: 'actions', numeric: false },
          ] : [
            { id: 'name', label: 'name', numeric: false },
            { id: 'userNameClient', label: 'email', numeric: false },
            { id: 'accountTitleClient', label: "accountTitle", numeric: false },
            { id: 'accountContractNumber', label: "contractNumber", numeric: false },
            { id: 'typeTitle', label: "type", numeric: false },
            { id: 'actions', label: 'actions', numeric: false },
          ]}
          data={tableData?.pages[0] || {}}
          {...{ openForm,openDeleteModal , showSecAccountField, showSecUserField, enableEdit, pageNo, pageSize, statusModal, statusText, isBtnEnabled, loading, filter, search, loadingSearch, rowView, tab}}
          errorMessages={errorMessages}
          searchFormData={searchFormData}
          handleChangeFilter={handleChangeFilter}
          handleChangeSearch={handleChangeSearch}
          handleSubmitSearch={handleSubmitSearch}
          searchErrorMessages={searchErrorMessages}
          clearSearch={clearSearch}
          actions1 = {adjustActions([
            { label: "edit", onClick: onEdit, icon: <EditIcon /> },
            { label: "delete", onClick: onDelete, icon: <DeleteIcon /> },
          ])}
        />
      </Box>
    )
}