import { Box, Grid, Typography } from "@mui/material";
import BackendPaginationTable from "common/Table/TableWithPagination";
import { ReactComponent as Payment } from "assets/SideBarIcons/money-transfer.svg";
import ComponentHeaderTwo from "common/ComponentHeaderTwo/ComponentHeaderTwo";
import PaymentModalComponent from "./PaymentModal";
import FormDialog from "common/Modal/Modal";
import { useTranslation } from 'react-i18next';
// import StyledInput from "common/SearchField/Search";
import Loader from "layout/Loader";
import { checkPrivileges } from "Helpers/CheckPrivileges";
import PaymentSearchHeader from "./PaymentSearchHeader";

interface PaymentComponentProps {
  openModal: () => void;
  closeModal: () => void;
  handleChange: (field: string, value: any) => void;
  handleChangePage: (field: number) => void;
  handleChangeRows: (field: number) => void;
  handleSubmit: () => void;
  handleDelete: () => void;
  cancelDelete: () => void;
  formData: any;
  openForm: boolean;
  columns: any[];
  data: any;
  errorMessages: any,
  actions1: { label: string; onClick: (value: any) => void; icon?: React.ReactNode }[];
  openDeleteModal: boolean;
  enableEdit?: boolean;
  pageNo: number;
  pageSize: number;
  isBtnEnabled: boolean;
  loading: boolean;
  showRowView: (row: any) => void;
  loadNext: (field: string, scroll: any) => void;
  loadPrev: (field: string, scroll: any) => void;
  handleDropdownSearch: (field: string, search: string) => void;
  loadingDropdownSearch: boolean;
  clientsData: any;
  paymentsCreditAmount: any;
  paymentsInvoiceData: any;
  assignUnassignInvoices: (isChecked: boolean, row: any) => void;
  searchFormData: any;
  handleChangeFilter: (event: any) => void;
  handleChangeSearch: (field: string, value: any) => void;
  handleSubmitSearch: () => void;
  searchErrorMessages: any;
  filter: string[];
  clearSearch: () => void;
  search: any;
  loadingSearch: boolean;
  clearSearchInvoice: () => void;
  handleSearchClick: () => void;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  searchInvoice: string; 
  loadingSearchInvoice: boolean;
  paymentModesData: any;
}

const PaymentComponent: React.FC<PaymentComponentProps> = ({
  openModal,
  closeModal,
  handleChange,
  handleSubmit,
  formData,
  openForm,
  columns,
  data,
  errorMessages,
  actions1,
  openDeleteModal,
  handleDelete,
  cancelDelete,
  enableEdit,
  handleChangePage,
  handleChangeRows,
  pageNo,
  pageSize,
  isBtnEnabled,
  loading,
  showRowView,
  loadNext,
  loadPrev,
  handleDropdownSearch,
  loadingDropdownSearch,
  clientsData,
  paymentsCreditAmount,
  paymentsInvoiceData,
  assignUnassignInvoices,
  searchFormData,
  handleChangeFilter,
  handleChangeSearch,
  handleSubmitSearch,
  searchErrorMessages,
  filter,
  clearSearch,
  search,
  loadingSearch,
  clearSearchInvoice,
  handleSearchClick,
  handleInputChange,
  searchInvoice,
  loadingSearchInvoice,
  paymentModesData
}) => {
  const { t } = useTranslation();
  const invoicesColumns = [
    { id: 'invoiceNo', label: 'invoiceNo', numeric: false },
    { id: 'balanceAmount', label: 'balanceAmount', numeric: false },
    { id: 'totalAmount', label: 'totalAmount', numeric: false },
  ]

  return (
    <Box>
      <ComponentHeaderTwo
        showBtn={true}
        showSaveBtn={openForm}
        onClick={openModal}
        onSubmit={handleSubmit}
        heading={(openForm && !enableEdit) ? "addPayment" : (enableEdit && openForm) ? "editPayment" : t('Payments')}
        icon={<Payment height={28} width={28} />}
        btnText={openForm ? "back" : "create"}
        btnType={openForm ? "back" : "create"}
        isBtnEnabled={isBtnEnabled}
        showCreateBtn={checkPrivileges("ROLE_PAYMENT_CREATE")}
      // btnType="create"
      />

      {openForm ?
        <PaymentModalComponent
            enableEdit={enableEdit}
            openModal={openModal}
            closeModal={closeModal}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            formData={formData}
            openForm={openForm}
            errorMessages={errorMessages}
            isBtnEnabled={isBtnEnabled}
            loadNext={loadNext}
            loadPrev={loadPrev}
            handleDropdownSearch={handleDropdownSearch}
            clientsData={clientsData.data?.content || []}
            paymentsCreditAmount={paymentsCreditAmount}
            paymentsInvoiceData={paymentsInvoiceData}
            handleChangePage={handleChangePage}
            handleChangeRows={handleChangeRows}
            assignUnassignInvoices={assignUnassignInvoices}
            clearSearchInvoice={clearSearchInvoice}
            handleSearchClick={handleSearchClick}
            handleInputChange={handleInputChange}
            paymentModesData={paymentModesData.data?.content || []}
            {...{loadingDropdownSearch, invoicesColumns, pageNo, pageSize, searchInvoice, loadingSearchInvoice}}
        />
        : <Box>
          {loading ? <Loader /> :
            <BackendPaginationTable
              columns={actions1.length ? columns : columns.filter(action => action.id !== 'actions')}
              data={data.data?.content || []}
              pageNo={pageNo}
              pageSize={pageSize}
              tableData={data?.data || {}}
              showCheckbox={false}
              showActions={true}
              enableSorting={true}
              showHeader={true}
              showSearch={true}
              showFilter={true}
              showCreateButton={true}
              actions={actions1}
              handleChangePage={handleChangePage}
              handleChangeRows={handleChangeRows}
              rowClick={checkPrivileges("ROLE_PAYMENT_UPDATE")}
              showRowView={showRowView}
              loadingSearch={loadingSearch}
              headerComponent={
                <PaymentSearchHeader 
                  searchFormData={searchFormData}
                  handleChangeFilter={handleChangeFilter}
                  handleChangeSearch={handleChangeSearch}
                  handleSubmitSearch={handleSubmitSearch}
                  searchErrorMessages={searchErrorMessages}
                  clearSearch={clearSearch}
                  {...{filter, search}}
                />
              }
            />
          }
        </Box>
      }

      {openDeleteModal &&
        <FormDialog
          open={openDeleteModal}
          onClose={cancelDelete}
          title={t('delete') + ' ' + t('Payment')}
          // icon={<div><Home/></div>}
          content={
            <Grid container >
              <Grid item xs={12} sm={12} md={12} lg={12}  >
                <Typography>
                  {t('Are you sure you want to delete?')}
                </Typography>
              </Grid>
            </Grid>
          }
          saveFunction={handleDelete}
          submitButtonText="Delete"
        />}
    </Box>

  )
}

export default PaymentComponent;