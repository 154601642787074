import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import ModelComponent from 'components/Model';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useGetMakeDropdownService } from 'subModule/src/services/make/useMake';
import { ModelRequest } from 'subModule/src/services/model/interface';
import { MakeRequest } from 'subModule/src/services/make/interface';
import { useModelService,useDeleteModelService,useGetModelService,useUpdateModelService,useChangeStatusModelService, useGetModelServiceService, useUnassignServiceService, useAssignServiceService, useAssignAllServiceService, useUnassignAllServiceService } from 'subModule/src/services/model/useModel';
import { useMakeService } from 'subModule/src/services/make/useMake';
import { useSnackbar } from 'notistack';
import AlertMessages from 'common/Alert/AlertMessages';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUserInfo } from 'features/user/userSlice';
import { checkPrivileges } from 'Helpers/CheckPrivileges';

interface FormData {
  title: string,
  secondaryTitle: string,
  makeId: number,
  makeIdError?: string,
  id: number,
  default: boolean,
  active: boolean
}
interface MakeFormData {
  title: string,
  secondaryTitle: string,
  id: number,
  default: boolean,
  active: boolean
}

  const requiredFields = ['title', 'makeId'];

  const makeRequiredFields = ['title'];

  export default function Model () {
    const [showModelSecField, setShowModelSecField] = useState<boolean>(false);
    const [showModelMakeSecField, setShowModelMakeSecField] = useState<boolean>(false);
    const [openForm, setOpenForm] = useState(false);
    const [openDeleteModal, setDeleteModal] = useState(false);
    const [enableEdit, setEnableEdit] = useState(false);
    const [pageNo, setPageNo] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);
    const [statusModal, setStatusModal] = useState(false);
    const [statusText, setStatusText] = useState<string>('');
    const [searchTerm, setSearchTerm] = useState<any>(null);
    const [openMakePopup, setOpenMakePopup] = useState(false);
    const [search, setSearch] = useState('');
    const [isBtnEnabled, setIsBtnEnabled] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(true);
    // const [data] = useState(rows);
    const [prevFormData, setPrevFormData] = useState<object>({});
    const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
    const [makesPageParams, setMakesPageParams] = useState<any>({ number: 1, size: 1000 });
    const [dropdownSearchTerm, setDropdownSearchTerm] = useState<any>('');
    const [dropdown, setDropdown] = useState<string>('makeId');
    const [loadingDropdownSearch, setLoadingDropdownSearch] = useState<boolean>(false);
    const [initialRender, setInitialRender] = useState<boolean>(true);
    const [rowView, setRowView] = useState(false);
    const [modelId, setModelId] = useState<number>(0);
    const [tab, setTab] = useState<number>(0);
    const [selectedRow, setSelectedRow] = useState<any>({});
    const [searchAssign, setSearchAssign] = useState('');
    const [searchTermAssign, setSearchTermAssign] = useState<any>(null);
    const [loadingSearchAssign, setLoadingSearchAssign] = useState<boolean>(false);
    const [formData, setFormData] = useState<FormData>({
        title: '',
        secondaryTitle: '',
        id: 0,
        makeId: 0,
        default: false,
        active: true
    });
    const [makeformData, setMakeFormData] = useState<MakeFormData>({
      title: '',
      secondaryTitle: '',
      id: 0,
      default: false,
      active: true
    });

    const { data: tableData,  isLoading, error,  refetch } = searchTerm ? useGetModelService({ pageNumber: pageNo, size: pageSize, title: searchTerm }) : useGetModelService({ pageNumber: pageNo, size: pageSize });

    const { data: makesData, isLoading: makesIsLoading, error: makesError, refetch: makesRefetch } = useGetMakeDropdownService({ pageNumber: makesPageParams.number, size: makesPageParams.size, title: dropdownSearchTerm });
    
    const { data: serviceAssignData, isLoading: serviceAssignIsLoading, error: serviceAssignError, refetch: serviceAssignRefetch } = searchTermAssign ? useGetModelServiceService({ pageNumber: pageNo, size: pageSize, title: searchTermAssign }, modelId) : useGetModelServiceService({ pageNumber: pageNo, size: pageSize }, modelId);
    
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const loggedInUserData = useSelector(selectUserInfo);
    const primaryLanguage = loggedInUserData?.primaryLanguage;
    const secondaryLanguage = loggedInUserData?.secondaryLanguage;

    let allServicesAssigned: unknown = serviceAssignData?.pages[0].data?.content.map(service => service.linked).every(linked => linked === true);

    useEffect(() => {
      if (!isLoading && !error && !rowView && !openForm && !loadingSearch) { // Only refetch if not already loading or in error state
        refetch();
      }
      if(tableData) {
        setLoading(false);
        if((tableData?.pages[0] as any).status && !(tableData?.pages[0] as any).success) {
          enqueueSnackbar(<AlertMessages statusCode={(tableData?.pages[0] as any)?.status} />, { variant: 'error' })
        }
      }
      if(tab === 0 && enableEdit) {
        setIsBtnEnabled(false);
      }
      if (!serviceAssignIsLoading && !serviceAssignError && modelId && modelId !== 0 && tab === 1) {
        const loadData = async() => {
          setLoadingSearchAssign(true);
          await serviceAssignRefetch();
          setLoadingSearchAssign(false);
        }
        loadData();
      }
    }, [pageNo, pageSize, refetch, isLoading, error, modelId, tab, tableData, openForm]);
    useEffect(() => {
      if(enableEdit && JSON.stringify(formData) !== JSON.stringify(prevFormData) || openForm && JSON.stringify(formData) !== JSON.stringify(prevFormData)) {
        setIsBtnEnabled(true);
      } else if(enableEdit && JSON.stringify(formData) === JSON.stringify(prevFormData)) {
        setIsBtnEnabled(false);
      }
    }, [enableEdit, formData]);

    useEffect(() => {
      const getData = setTimeout(async() => {
        if (searchTerm !== null && !isLoading && !error && loadingSearch) {
          await refetch();
          setLoadingSearch(false);
        }
      }, 1000);
      return () => clearTimeout(getData)
    }, [searchTerm, loadingSearch]);
  
    useEffect(() => {
      const getData = setTimeout(async() => {
        if (searchTermAssign !== null && loadingSearchAssign && tab === 1) {
          await serviceAssignRefetch();
          setLoadingSearchAssign(false);
        }
      }, 1000);
      return () => clearTimeout(getData)
    }, [searchTermAssign, loadingSearchAssign]);

    useEffect(() => {
      const getData = setTimeout(async() => {
        if ((dropdownSearchTerm || dropdownSearchTerm === '') && !makesIsLoading && !makesError && openForm && !initialRender) {
          if(dropdown === 'makeId') {
            setLoadingDropdownSearch(true);
            setMakesPageParams({ number: 1, size: 1000 });
            await makesRefetch();
            setLoadingDropdownSearch(false);
          }
        }
      }, 500)
    return () => clearTimeout(getData)
    }, [dropdownSearchTerm, makesIsLoading, makesError, openForm]);

    useEffect(() => {
      if(makesPageParams && openForm && dropdown === 'makeId') {
        makesRefetch();
      }
    }, [makesPageParams])

    const handleDropdownSearch = (field:string, search: string) => {
      if(field === 'makeId') {
        setInitialRender(false);
        setDropdownSearchTerm(search);
        setDropdown('makeId');
      }
    }
    const loadNext = (field: string, scroll: any) => {
      if(scroll.deltaY > 90 || scroll.touches[0].clientY > 90) {
        if(field === 'makeId') {
          if(!(makesData?.pages[0]?.data as any).last) {
            setDropdown('makeId');
            setMakesPageParams({ number: makesPageParams.number + 1, size: makesPageParams.size });
          }
        }
      }
    }
    const loadPrev = (field: string, scroll: any) => {
      if(scroll.deltaY < 10 || scroll.touches[0].identifier === 0) {
        if(field === 'makeId') {
          if(!(makesData?.pages[0]?.data as any).first) {
            setDropdown('makeId');
            setMakesPageParams({ number: makesPageParams.number - 1, size: makesPageParams.size });
          }
        }
      }
    }
    
    const handleChangePage = (pageNo : number) => {  
      setLoading(true);
      setPageNo(pageNo + 1);
      setPageSize(pageSize);

    }
    const handleChangeRows = (pageSize : number) => {
      setLoading(true);
      setPageSize(pageSize);
      setPageNo(1);
    }

    const [errorMessages, setErrorMessages] = useState<Partial<FormData>>({});

    const [makeErrorMessages, setMakeErrorMessages] = useState<Partial<MakeFormData>>({});

    const openModal = () => {
      if (rowView) {
        setRowView(false);
        setModelId(0);
        setPageNo(1);
        setTab(0);
        setSearchAssign('');
        setSearchTermAssign('');
      } else {
          setOpenForm(!openForm);
          setIsBtnEnabled(true);
          openModalSettings();
          setShowModelSecField(false)
          setShowModelMakeSecField(false)
          setSearch('');
          setSearchTerm('');
        }
      };

      const openModalSettings = () => {
        setEnableEdit(false);
        setErrorMessages({});
        setSearchTerm('');
        setDropdown('makeId');
        setDropdownSearchTerm('');
        setInitialRender(true);
        setFormData({
            title: '',
            secondaryTitle: '',
            id: 0,
            makeId: 0,
            default: false,
            active: true
        });
        if(!openForm && !enableEdit) {
          makesRefetch();
          setMakesPageParams({ number: 1, size: 1000 });
          setSearch('');
          setSearchTerm('');
        }
      }

      const handleSecField = () => {
        setShowModelSecField((prevValue) => !prevValue);
        setShowModelMakeSecField((prevValue) => !prevValue);
      }

      const handleChange = (field: string, value: any) => {
        const  val = field === "makeId" ? (value ? parseInt(value.value) || 0 : 0) 
          : (field === "default") ? value ? Boolean(value) : false
          : value;

        setFormData((prevData) => ({
          ...prevData,
          [field]: val,
        }));
      
        if (requiredFields.includes(field) && !val) {
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: 'This field is required.',
          }));
        } else {
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: undefined,
          }));
        }
      };
      
    
      const validateFormData = (data: FormData) => {
        const errors: Partial<FormData> = {};

        if (!data.makeId) {
          errors.makeIdError = t('This field is required.');
        }
        if (!data.title?.trim()) {
          errors.title = t('This field is required.');
        }
    
        return errors;
      };

      const { onCreateModel,  } = useModelService({
        onSuccess: (msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          if(response.success) {
            setOpenForm(false);
            refetch();
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('model') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
          console.error(err.stack);
        }
      }})

      const { onUpdateModel } = useUpdateModelService({
        onSuccess: (data) => {
          const response: any = {};
          Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
          if(response.success) {
            if(!rowView) {
              setOpenForm(false);
              setEnableEdit(false);
              refetch();
            }
            if(rowView) { setPrevFormData(response?.data); setSelectedRow(response?.data); }
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('model') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err) => {
          console.error('Login error======', err, enableEdit);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
        id: formData.id,
      });

      const handleSubmit = () => {
        const errors = validateFormData(formData);
        setErrorMessages(errors);
   
        if (Object.keys(errors).length === 0) {
          setIsBtnEnabled(false);
          setLoading(true);
          const modelData: ModelRequest = {
            ...formData, 
            title: formData.title,
            secondaryTitle: formData.secondaryTitle,
            makeId: formData.makeId,
            default:formData.default,
            active:formData.active
          };
     
          if (enableEdit) {
            onUpdateModel(
                modelData
            );
          } else {
            onCreateModel(modelData);
          }
        }
      };

      const onEdit = (row:any) => {
        setEnableEdit(true);
        setFormData(row);
        setPrevFormData(row);
        setOpenForm(true);
        makesRefetch();
        setIsBtnEnabled(false);
        setSearch('');
        setSearchTerm('');
        setMakesPageParams({ number: 1, size: 1000 });
        setInitialRender(true);
        setDropdownSearchTerm('');
      }

      const onDelete = (row:any) => {
        setFormData(row)
        setDeleteModal(true)
      }

      const { onDeleteModel } = useDeleteModelService({
        id: formData.id,
        onSuccess: (msg) => {
          if(msg.success) {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('model') + ' ' + t('deleted') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
          }
          refetch();
          if(tableData?.pages[0].data.content.length === 1) {
            handleChangePage(pageNo - 1)
          }
        },
        onError: (err) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
      });

      const handleDelete = () => {
        setDeleteModal(false);
        setLoading(true);
        onDeleteModel();
      }
      const cancelDelete = () => {
        setDeleteModal(false)
      }

      const clearSearch = () => {
        if(!rowView) {
          setSearch('');
          setSearchTerm('');
          if(searchTerm) { handleSearch(''); }
        } else {
          setSearchAssign('');
          setSearchTermAssign('');
          if(searchTermAssign) { handleSearch(''); }
        }
      }
      const handleSearch = (search: string) => {
        if(!rowView) {
          setLoadingSearch(true);
          setSearchTerm(search);
          setPageNo(1);
          setPageSize(pageSize);
        } else {
          setLoadingSearchAssign(true);
          setSearchTermAssign(search);
          setPageNo(1);
          setPageSize(pageSize);
        }
      };
    
      const handleSearchClick = () => {
        if(!rowView) {
          const trimmedSearch = search.trim();
          if (trimmedSearch !== '') {
            handleSearch(trimmedSearch);
          }
        } else {
          const trimmedSearch = searchAssign.trim();
          if (trimmedSearch !== '') {
            handleSearch(trimmedSearch);
          }
        }
      };
    
      const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(!rowView) {
          setSearch(event.target.value.trim());
          if (!event.target.value.trim()) handleSearch('');
        } else {
          setSearchAssign(event.target.value.trim());
          if (!event.target.value.trim()) handleSearch('');
        }
      };
      const onChangeStatus = (row: any) => {
        row.active ? setStatusText('deactivate') : setStatusText('activate');
        setFormData(row);
        setStatusModal(true);
      }
      const cancelChangeStatus = () => {
        setStatusModal(false);
      }
      const submitChangeStatus = () => {
        setStatusModal(false);
        setLoading(true);
        onChangeStatusModel({});
      }
      const { onChangeStatusModel } = useChangeStatusModelService({
        id: formData.id,
        onSuccess: (msg) => {
          if(msg.success) {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('model') + ' ' + t(statusText) + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
          }
          refetch();
        },
        onError: (err: any) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
        active: formData.active ? "inactive" : "active"
      });
      const onOpenMakePopup = () => {
        setOpenMakePopup(!openMakePopup);
        setMakeErrorMessages({})
        setMakeFormData({
            title: '',
            secondaryTitle: '',
            id: 0,
            default: false,
            active: true
        });
      }
      const closeMakePopup = () => {
        setOpenMakePopup(false);
      }
      const handleChangeMake = (field: string, value: any) => {
        const  val = (field === "default") ? value ? Boolean(value) : false : value;

        setMakeFormData((prevData) => ({
          ...prevData,
          [field]: val,
        }));
      
        if (makeRequiredFields.includes(field) && !val) {
          setMakeErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: 'This field is required.',
          }));
        } else {
          setMakeErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: undefined,
          }));
        }
      };
      const validateMakeFormData = (data: MakeFormData) => {
        const errors: Partial<MakeFormData> = {};

        if (!data.title?.trim()) {
          errors.title = t('This field is required.');
        }
    
        return errors;
      };
      const submitMake = () => {
        const errors = validateMakeFormData(makeformData);
        setMakeErrorMessages(errors);
   
        if (Object.keys(errors).length === 0) {
          const makeData: MakeRequest = {
            ...makeformData, 
            title: makeformData.title,
            secondaryTitle: makeformData.secondaryTitle,
          };
          onCreateMake(makeData);
        }
      }
      const { onCreateMake } = useMakeService({
        onSuccess: (msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          if(response.success) {
            setOpenMakePopup(false);
            makesRefetch();
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('make') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
          console.error(err.stack);
        }
      }})
      const showRowView = (row: any) => {
        setEnableEdit(true);
        setOpenForm(false);
        setFormData(row);
        setPrevFormData(row);
        makesRefetch();
        setErrorMessages({});
        setRowView(true);
        setModelId(row.id);
        setSelectedRow(row);
        setSearch('');
        setSearchTerm('');
        setMakesPageParams({ number: 1, size: 1000 });
        setInitialRender(true);
        setDropdownSearchTerm('');
      }
      const handleChangeTabs = (tabId: number) => {
        setSearchAssign('');
        setSearchTermAssign('');
        if (tabId === 0) {
          setTab(0);
          setEnableEdit(true);
          setOpenForm(false);
          setFormData(selectedRow);
          setErrorMessages({});
          setIsBtnEnabled(false);
          setDropdown('makeId');
          makesRefetch();
          setDropdownSearchTerm('');
          setInitialRender(true);
        } else if (tabId === 1) {
          setTab(1);
          setPageNo(1);
          setEnableEdit(false);
          setErrorMessages({});
        }
      }
      const assignUnassignSingleEntity = (isChecked: boolean, row: any) => {
        setLoadingSearchAssign(true);
        if (isChecked) {
          if (tab === 1) {
            onAssignService({ modelId: modelId, serviceId: row.id });
          }
        } else {
          if (tab === 1) {
            onUnassignService({ modelId: modelId, serviceId: row.id });
          }
        }
      }
      const assignUnassignAllEntities = (isChecked: boolean) => {
        setLoadingSearchAssign(true);
        if (isChecked) {
          if (tab === 1) {
            onAssignAllServices({ modelId: modelId });
          }
        } else {
          if (tab === 1) {
            onUnassignAllServices({ modelId: modelId });
          }
        }
      }
      const { onAssignService } = useAssignServiceService({
        onSuccess: async(msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          if (response.success) {
            if (serviceAssignData?.pages[0].data.content.length === serviceAssignData?.pages[0].data.content.filter(service => service.linked).length) {
              allServicesAssigned = true;
            }
            await serviceAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('service') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await serviceAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err: any) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
            console.error(err.stack);
          }
        }
      })
      const { onUnassignService } = useUnassignServiceService({
        onSuccess: async(data) => {
          const response: any = {};
          Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
          if (response.success) {
            if (serviceAssignData?.pages[0].data.content.length !== serviceAssignData?.pages[0].data.content.filter(service => service.linked).length) {
              allServicesAssigned = false;
            }
            await serviceAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('service') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await serviceAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err: any) => {
          console.log("err====,  ", err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
      });
      const { onAssignAllServices } = useAssignAllServiceService({
        onSuccess: async(msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          if (response.success) {
            allServicesAssigned = true;
            await serviceAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('All') + ' ' + t('services') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await serviceAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err: any) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
            console.error(err.stack);
          }
        }
      })
      const { onUnassignAllServices } = useUnassignAllServiceService({
        onSuccess: async(data) => {
          const response: any = {};
          Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
          if (response.success) {
            allServicesAssigned = false;
            await serviceAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('All')  + ' ' +  t('services') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await serviceAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err: any) => {
          console.log("err====,  ", err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
      });
      const adjustActions = (actions: any[]) => {
        return checkPrivileges('ROLE_MODEL_UPDATE') 
          ? (checkPrivileges('ROLE_MODEL_ARCHIVE') 
              ? actions 
              : actions.filter(action => action.label !== 'delete')) 
          : (checkPrivileges('ROLE_MODEL_ARCHIVE') 
              ? actions.filter(action => action.label !== 'edit') 
              : []);
      }

    return(
        <Box>
            <ModelComponent
              handleSecField={handleSecField}
              secondaryLanguage={secondaryLanguage}
              primaryLanguage={primaryLanguage}
                openModal={openModal}
                closeModal={openModal}
                handleSearchClick={handleSearchClick}
                handleInputChange={handleInputChange}
                handleDelete={handleDelete}
                cancelDelete={cancelDelete}
                handleChange={handleChange} 
                handleSubmit={handleSubmit}
                formData={formData}
                handleChangePage={handleChangePage}
                handleChangeRows={handleChangeRows}
                onChangeStatus={onChangeStatus}
                cancelChangeStatus={cancelChangeStatus}
                submitChangeStatus={submitChangeStatus}
                onOpenMakePopup={onOpenMakePopup}
                closeMakePopup={closeMakePopup}
                submitMake={submitMake}
                handleChangeMake={handleChangeMake}
                makeformData={makeformData}
                showRowView={showRowView}
                loadNext={loadNext}
                loadPrev={loadPrev}
                handleDropdownSearch={handleDropdownSearch}
                handleChangeTabs={handleChangeTabs}
                assignUnassignSingleEntity={assignUnassignSingleEntity}
                assignUnassignAllEntities={assignUnassignAllEntities}
                allServicesAssigned={allServicesAssigned}
                clearSearch={clearSearch}
                columns={checkPrivileges("ROLE_MODEL_ACTIVE") ? [
                    { id: 'title', label: 'title', numeric: false },
                    { id: 'makeTitle', label: 'make', numeric: false },
                    { id: 'active', label: 'status', numeric: false },
                    { id: 'actions', label: 'actions', numeric: false },
                ] : [
                  { id: 'title', label: 'title', numeric: false },
                  { id: 'makeTitle', label: 'make', numeric: false },
                  { id: 'actions', label: 'actions', numeric: false },
              ]}
                data={tableData?.pages[0] || {}}
                makesData={makesData?.pages[0] || {}}
                serviceAssignData={serviceAssignData?.pages[0] || {}}
                // tableDataMake={tableDataMake?.pages[0] || {}}
                {...{ openForm, openDeleteModal, enableEdit, pageNo, pageSize , statusModal, statusText, openMakePopup, search, isBtnEnabled, loading, loadingSearch, loadingDropdownSearch, searchAssign, loadingSearchAssign, rowView,showModelSecField,showModelMakeSecField}}
                errorMessages={errorMessages}
                makeErrorMessages={makeErrorMessages}
                actions1 = {adjustActions([
                { label: "edit", onClick: onEdit, icon: <EditIcon /> },
                { label: "delete", onClick: onDelete, icon: <DeleteIcon /> },
                ])}
            />
        </Box>
    )
  }