import SimpleModal from 'common/Form/Form';
import StyledField from 'common/TextField/TexField1';
import StyledInputArea from 'common/TextArea/TextArea';
import {  Grid } from '@mui/material';
import BasicDatePicker from 'common/Pickers/Pickers';
import DynamicSelect from 'common/Select/Select';
import { useTranslation } from 'react-i18next';
import React from 'react';
import CommonCheckbox from 'common/CheckBox/Checkbox';

interface ClientModalProps {
  openModal: () => void;
  closeModal: () => void;
  handleSecField: (field: string) => void;
  showSecAccountField: boolean;
  showSecUserField: boolean;
  primaryLanguage: string;
  secondaryLanguage: string;
  handleChange: (field: string, value: any, section: 'user' | 'account') => void;
  handleSubmit: () => void;
  formData: any;
  // rolesData: any;
  openForm: boolean;
  errorMessages:{ [key: string]: string };
  enableEdit?: boolean;
  isBtnEnabled: boolean;
  // loadNext: (field: string, scroll: any) => void;
  // loadPrev: (field: string, scroll: any) => void;
}

const ClientModal : React.FC<ClientModalProps> = ({
    closeModal,
    handleChange,
    handleSubmit,
    formData,
    showSecAccountField,
    showSecUserField,
    handleSecField,
    primaryLanguage,
    secondaryLanguage,
    // rolesData,
    openForm,
    enableEdit,
    errorMessages,
    isBtnEnabled,
    // loadNext,
    // loadPrev
}) => {
  const {t} = useTranslation();
  const typeOptions = [
    { value: 160, label: t('Client') },
    { value: 170, label: t('Customer') },
  ]
  const companyTypeOptions = [
    { value: 1000, label: t('Individual') },
    { value: 1100, label: t('Corporate') },
    { value: 1200, label: t('Government') },
  ]
  // const roleOptions = rolesData.map((item: { id: number, title: string }) => ({ 
  //   value: item.id, 
  //   label: item.title 
  // }));
  const handleSearch = () => {
    // Handle search logic here
  }

    return(
        <div>
      <SimpleModal
        visable={openForm}
        // title={enableEdit ? "updateClient" : "addClient"}
        isButtonVisable={true}
        isVisableBtn={isBtnEnabled}
        btnclosetext="Back"
        buttonText={enableEdit ? "update":"submit"}
        attBtnNotshow={false}
        notVisableBackbtn={true}
        showPortList={handleSubmit} // Assuming this is the Submit button
        modalControle={closeModal}
        // attributeChangesMessage="Attribute changes message"
        // showPort={true}
        formSubmit={handleSubmit}
        content={
          <Grid container>
            <Grid container rowGap={1}>
              {!enableEdit &&
                <Grid container columnSpacing={2} rowGap={2}>
                  <Grid item  xs={12} sm={6} md={4} lg={3}>
                    <DynamicSelect
                      defaultValue={enableEdit ? typeOptions.find((option: any) => option.value === formData?.type) : null}
                      value={typeOptions.find((option: any) => option.value === formData?.type)}
                      isDisabled={false}
                      isLoading={false}
                      isClearable={true}
                      isRtl={false}
                      // fullWidth={false}
                      isSearchable={true}
                      name="companyType"
                      options={typeOptions}
                      // onScroll={handleScroll}
                      onInputChange={handleSearch}
                      // isMulti
                      isRequired={true}
                      onChange={(e) => handleChange('type', e, 'user')}
                      error={(errorMessages.user as any).typeError}
                      label="clientType"
                    />
                  </Grid>
                </Grid>
              }
              {(formData?.type === 0 || formData?.type === 160) &&
                <>
                  <Grid item  xs={12} sm={12} md={12} lg={12} className='my-[5px]'>
                    <h1 className='flex font-Saira font-[700] text-[18px] text-primary-color'>{t('accountDetails')}</h1>
                  </Grid>
                  <Grid container columnSpacing={2} rowGap={2}>
                    <Grid item  xs={12} sm={6} md={4} lg={3}  >
                      <StyledField
                        label={`${!primaryLanguage ? t('primary') : ''} ${t('name')} ${primaryLanguage ? ` (${t(primaryLanguage)})` : ''}`}
                        placeholder="Write your Title here"
                        handleChange={(e) => handleChange('title', e.target.value, 'account')}
                        required={true}
                        search='language'
                        handleSecField={() => handleSecField('account')}
                        fullWidth
                        error={(errorMessages.account as any).title}
                        value={formData?.account?.title || ''}
                      />
                      {showSecAccountField && 
                        <div style={{ position: 'relative' }}>
                          <div
                            style={{
                              position: 'absolute',
                              top: -8,
                              left: 0,
                              zIndex: 1,
                              display: showSecAccountField ? 'flex' : 'none',
                              backgroundColor: "white",
                              height: '5rem',
                              width: '100%',
                              justifyContent:'center',
                              borderRadius: '8px',
                              boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
                            }}
                          >
                            <div className='w-full mx-2 mt-1'>
                              <StyledField
                                label={`${!secondaryLanguage ? t('secondary') : ''} ${t('name')} ${secondaryLanguage ? ` (${t(secondaryLanguage)})` : ''}`}
                                placeholder="Write your Secondary Title here"
                                handleChange={(e) => handleChange('secondaryTitle', e.target.value, 'account')}
                                required={false}
                                fullWidth
                                // error={errorMessages?.secondaryTitle}
                                value={formData?.account.secondaryTitle || ''}
                              />
                            </div>
                          </div>
                        </div>
                      }
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}  >
                      <StyledField
                        fullWidth={true}
                        label="VAT Number"
                        placeholder="Write tax number here"
                        handleChange={(e) => handleChange('taxNumber', e.target.value, 'account')}
                        required={false}
                        // error={errorMessages?.taxNumber}
                        value={formData?.account.taxNumber || ''}
                      />
                    </Grid>
                    <Grid item  xs={12} sm={6} md={4} lg={3}  >
                      <StyledField
                        label="contractNumber"
                        // placeholder="Write contract number here"
                        handleChange={(e) => handleChange('contractNumber', e.target.value, 'account')}
                        required={false}
                        fullWidth
                        // error={(errorMessages.account as any).contractNumber}
                        value={formData?.account?.contractNumber || ''}
                      />
                    </Grid>
                    <Grid item  xs={12} sm={6} md={4} lg={3} >
                      <BasicDatePicker
                        type = "datePicker"
                        label="contractStartDate"
                        fullWidth
                        onChange={(e) => handleChange('contractStart', e, 'account')}
                        required={false}
                        error={(errorMessages.account as any).contractStart}
                        value={enableEdit ? formData?.account?.contractStart?.split('T')[0] : null}
                      />
                    </Grid>
                    <Grid item  xs={12} sm={6} md={4} lg={3} >
                      <BasicDatePicker
                        type = "datePicker"
                        label="contractEndDate"
                        fullWidth
                        onChange={(e) => handleChange('contractEnd', e, 'account')}
                        required={false}
                        error={(errorMessages.account as any).contractEnd}
                        value={enableEdit ? formData?.account?.contractEnd?.split('T')[0] : null}
                      />
                    </Grid>
                    <Grid item  xs={12} sm={6} md={4} lg={3}  >
                      <StyledField
                        type="email"
                        label="email"
                        placeholder="Write your Email here"
                        handleChange={(e) => handleChange('email', e.target.value, 'account')}
                        required={true}
                        fullWidth
                        error={(errorMessages.account as any).email}
                        value={formData?.account?.email || ''}
                      />
                    </Grid>
                    <Grid item  xs={12} sm={6} md={4} lg={3}  >
                        <StyledField
                            fullWidth={true}
                            label="phoneNumber"
                            placeholder="Write your Phone No. here"
                            handleChange={(e) => handleChange('phone', e.target.value, 'account')}
                            required={false}
                            error={(errorMessages.account as any).phone}
                            value={formData?.account?.phone || ''}
                        />
                    </Grid>
                    <Grid item  xs={12} sm={6} md={4} lg={3}  >
                        <StyledField
                            type="number"
                            fullWidth={true}
                            label="creditLimit"
                            placeholder="Enter Credit Limit"
                            handleChange={(e) => handleChange('creditLimit', e.target.value, 'account')}
                            required={false}
                            // error={(errorMessages.account as any).creditLimitError}
                            value={formData?.account?.creditLimit || ''}
                        />
                    </Grid>
                    <Grid item  xs={12} sm={6} md={4} lg={3}  >
                        <StyledField
                            type="number"
                            fullWidth={true}
                            label="creditPeriod"
                            placeholder="Enter Credit Period"
                            handleChange={(e) => handleChange('creditPeriod', e.target.value, 'account')}
                            required={false}
                            // error={(errorMessages.account as any).creditPeriodError}
                            value={formData?.account?.creditPeriod || ''}
                        />
                    </Grid>
                    <Grid item  xs={12} sm={6} md={4} lg={3}>
                      <DynamicSelect
                        defaultValue={enableEdit ? companyTypeOptions.find((option: any) => option.value === formData?.account?.companyType) : null}
                        value={companyTypeOptions.find((option: any) => option.value === formData?.account?.companyType)}
                        isDisabled={false}
                        isLoading={false}
                        isClearable={true}
                        isRtl={false}
                        // fullWidth={false}
                        isSearchable={true}
                        name="companyType"
                        options={companyTypeOptions}
                        // onScroll={handleScroll}
                        onInputChange={handleSearch}
                        // isMulti
                        isRequired={false}
                        onChange={(e) => handleChange('companyType', e, 'user')}
                        // error={(errorMessages as any).companyTypeError}
                        label="companyType"
                      />
                    </Grid>
                    <Grid item  xs={12} sm={6} md={4} lg={3}  >
                        <StyledInputArea
                            fullWidth={true}
                            label="address"
                            // placeholder="Write your Address here"
                            rowsNo={1}
                            handleChange={(e) => handleChange('address', e.target.value, 'account')}
                            required={false}
                            // error={errorMessages?.address}
                            value={formData?.account?.address || ''}
                        />
                    </Grid>
                  </Grid>
                </>
              }
            </Grid>

              {/* <----------------------UserDetails-------------------> */}
              <Grid container spacing={2}  className='mt-[0px]'>
                <Grid item  xs={12} sm={12} md={12} lg={12} className='py-3 px-4'>
                  <h1 className='flex font-Saira font-[700] text-[18px] text-primary-color'>{t('userDetails')}</h1>
                </Grid>
              
                <Grid item xs={12} sm={6} md={4} lg={3}  >
                  <StyledField
                    label={`${!primaryLanguage ? t('primary') : ''} ${t('name')} ${primaryLanguage ? ` (${t(primaryLanguage)})` : ''}`}
                    handleChange={(e) => handleChange('name', e.target.value, 'user')}
                    required={true}
                    search='language'
                    handleSecField={() => handleSecField('user')}
                    fullWidth
                    error={(errorMessages.user as any).name}
                    value={formData?.name || ''}
                  />
                  {showSecUserField && 
                    <div style={{ position: 'relative' }}>
                      <div
                        style={{
                          position: 'absolute',
                          top: -8,
                          left: 0,
                          zIndex: 1,
                          display: showSecUserField ? 'flex' : 'none',
                          backgroundColor: "white",
                          height: '5rem',
                          width: '100%',
                          justifyContent:'center',
                          borderRadius: '8px',
                          boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
                        }}
                      >
                        <div className='w-full mx-2 mt-1'>
                          <StyledField
                            label={`${!secondaryLanguage ? t('secondary') : ''} ${t('name')} ${secondaryLanguage ? ` (${t(secondaryLanguage)})` : ''}`}
                            handleChange={(e) => handleChange('secondaryName', e.target.value, 'user')}
                            required={false}
                            fullWidth
                            // error={errorMessages.user?.secondaryName}
                            value={formData?.secondaryName || ''}
                          />
                        </div>
                      </div>
                    </div>
                  }
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}  >
                  <StyledField
                    label="email"
                    placeholder="Write your name here"
                    handleChange={(e) => handleChange('username', e.target.value, 'user')}
                    required={true}
                    fullWidth
                    error={(errorMessages.user as any).username}
                    value={formData?.username || ''}
                  />
                </Grid>
                {enableEdit ? null :
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <StyledField
                      type="password"
                      label="password"
                      placeholder="Write your password here"
                      handleChange={(e) => handleChange('password', e.target.value, 'user')}
                      required={true}
                      fullWidth
                      error={(errorMessages.user as any).password}
                      value={formData?.password || ''}
                      search="showPassword"
                    />
                  </Grid>
                }
                {/* <Grid item xs={12} sm={6} md={4} lg={3}  >
                  <DynamicSelect
                    defaultValue={enableEdit ? roleOptions.find((option: any) => option.value === formData?.roleId) : null}
                    value={roleOptions.find((option: any) => option.value === formData?.roleId)}
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    // fullWidth={false}
                    isSearchable={true}
                    name="roleId"
                    options={roleOptions}
                    // onScroll={handleScroll}
                    onInputChange={handleSearch}
                    // isMulti
                    isRequired={true}
                    onChange={(e) => handleChange('roleId', e, 'user')}
                    error={(errorMessages.user as any).roleIdError}
                    label="role"
                    onMenuScrollToBottom={(e) => { loadNext('roleId', e); }}
                    onMenuScrollToTop={(e) => { loadPrev('roleId', e); }}
                  />
                </Grid> */}
                {/* <Grid item xs={12} sm={6} md={4} lg={3}  >
                  <StyledField
                    type="email"
                    label="email"
                    placeholder="Write your Email here"
                    handleChange={(e) => handleChange('email', e.target.value, 'user')}
                    required={false}
                    fullWidth
                    // error={(errorMessages.user as any).email}
                    value={formData?.email || ''}
                  />
                </Grid> */}

                <Grid item xs={12} sm={6} md={4} lg={3}  >
                  <StyledField
                    fullWidth={true}
                    label="phoneNumber"
                    placeholder="Write your Phone No. here"
                    handleChange={(e) => handleChange('phone', e.target.value, 'user')}
                    required={false}
                    error={(errorMessages.user as any).phone}
                    value={formData?.phone || ''}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}  >
                  <StyledField
                    fullWidth={true}
                    label="address"
                    placeholder="Write your Address here"
                    handleChange={(e) => handleChange('address', e.target.value, 'user')}
                    required={false}
                    // error={errorMessages.user?.address}
                    value={formData?.address || ''}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} className={`flex items-center justify-start`}>
                  <CommonCheckbox
                    label="Valid Email"
                    onChange={(e) => handleChange('emailValid', e.target.checked, 'user')}
                    color="success"
                    required={false}
                    // error={errorMessages?.emailValidError}
                    checked={formData?.emailValid}
                  />
                </Grid>
              </Grid>
          </Grid>
        }
      />
        </div>
    )
}
export default ClientModal;