import { Box, Grid, Typography } from "@mui/material"
import AccountSettingsComponent from "components/AccountSettings";
import {  useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import AlertMessages from 'common/Alert/AlertMessages';
import { useChangePasswordService} from 'subModule/src/services/user/useUser';
import { ChangePasswordRequest} from 'subModule/src/services/user/interface';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { clearUserInfo, selectUserInfo } from 'features/user/userSlice';
import { useGetItemGroupDropdownService } from "subModule/src/services/itemgroup/useItemGroup";
import { useGetPriceListDropdownService } from "subModule/src/services/pricelist/usePriceList";
import { useGetTaxProcedureDropdownService } from "subModule/src/services/taxProcedure/useTaxProcedure";
import { BusinessRequest } from "subModule/src/services/business/interface";
import { useUpdateBusinessService } from "subModule/src/services/business/useBusiness";
import { useAuthLogoutService } from "subModule/src/services/userProfile/useProfile";
import { useNavigate } from "react-router-dom";
import FormDialog from "common/Modal/Modal";
import { useAssignBusinessInvoice, useGetBusinessInvoiceService, useUnassignBusinessInvoice } from "subModule/src/services/accountsettings/useAccountSettings";

interface ChangePasswordFormData {
  password: string,
  newPassword: string,
  confirmNewPassword: string,
}

interface BusinessData {
  id: number,
  title: string,
  secondaryTitle: string,
  primaryLanguage: string,
  secondaryLanguage: string,
  whiteLabel: boolean,
  sellBelowStock: boolean,
  scrapItemGroupId: number,
  scrapItemGroupIdError?: string,
  active: boolean,
  priceListId: number,
  currency: string,
  // priceListIdError?: string,
  taxProcedureId: number,
}

const businessRequiredFields = ['title', 'scrapItemGroupId'];

const changePasswordRequiredFields = ['password', 'newPassword', 'confirmNewPassword'];

export default function AccountSettings() {
  const [showSecField, setShowSecField] = useState<boolean>(false);
  const [enableEdit, setEnableEdit] = useState(false);
  const [isBtnEnabled, setIsBtnEnabled] = useState<boolean>(true);
  const [scrapGroupItemsPageParams, setScrapGroupItemsPageParams] = useState<any>({ number: 1, size: 50 });
  const [dropdownSearchTerm, setDropdownSearchTerm] = useState<any>('');
  const [dropdown, setDropdown] = useState<string>('scrapItemGroupId');
  const [loadingDropdownSearch, setLoadingDropdownSearch] = useState<boolean>(false);
  const [initialRender, setInitialRender] = useState<boolean>(true);
  const [tab, setTab] = useState<number>(0);
  const [prevBusinessData, setPrevBusinessData] = useState<object>({});
  const [updateBusinessModal, setUpdateBusinessModal] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [pageNo, setPageNo] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [loadingInvoices, setLoadingInvoices] = useState<boolean>(true);
  const [changePasswordFormData, setChangePasswordFormData] = useState<ChangePasswordFormData>({
    password: '',
    newPassword: '',
    confirmNewPassword: ''
  });

  const { data: scrapItemGroupsData, isLoading: scrapItemGroupsIsLoading, error: scrapItemGroupsError, refetch: scrapItemGroupsRefetch } = useGetItemGroupDropdownService({ pageNumber: scrapGroupItemsPageParams.number, size: scrapGroupItemsPageParams.size, title: dropdownSearchTerm });

  const { data: priceListData, refetch: priceListRefetch } = useGetPriceListDropdownService({ pageNumber: 1, size: 50 });

  const { data: taxProceduresData, refetch: taxProceduresRefetch } = useGetTaxProcedureDropdownService({ pageNumber: 1, size: 50 });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  
  const loggedInUserData = useSelector(selectUserInfo);
  const loggedInUserEmail = loggedInUserData?.email;
  const [loggedInUserBusinessData, setLoggedInUserBusinessData] = useState<BusinessData>({
    id: loggedInUserData.id,
    title: loggedInUserData.title,
    secondaryTitle: loggedInUserData.secondaryTitle,
    primaryLanguage: loggedInUserData.primaryLanguage,
    secondaryLanguage: loggedInUserData.secondaryLanguage,
    whiteLabel: loggedInUserData.whiteLabel,
    sellBelowStock: loggedInUserData.sellBelowStock,
    scrapItemGroupId: loggedInUserData.scrapItemGroupId,
    active: loggedInUserData.active,
    currency: loggedInUserData.currency,
    priceListId: loggedInUserData.priceListId,
    taxProcedureId: loggedInUserData.taxProcedureId,
  });

  const { data: invoiceAssignData, refetch: invoiceAssignRefetch } = useGetBusinessInvoiceService({ pageNumber: pageNo, size: pageSize }, loggedInUserData.id);

  let allInvoicesAssigned: unknown = invoiceAssignData?.pages[0].data?.content.map(invoice => invoice.linked).every(linked => linked === true);
  
  useEffect(() => {
    if(tab === 1 && enableEdit) {
      setIsBtnEnabled(false);
    }
    if(tab === 2) {
      const loadData = async() => {
        setLoadingInvoices(true);
        invoiceAssignRefetch();
        setLoadingInvoices(false);
      }
      loadData();
    }
  }, [tab, pageNo, pageSize, invoiceAssignData]);

  useEffect(() => {
    if (enableEdit && JSON.stringify(loggedInUserBusinessData) !== JSON.stringify(prevBusinessData)) {
      setIsBtnEnabled(true);
    } else if (enableEdit && JSON.stringify(loggedInUserBusinessData) === JSON.stringify(prevBusinessData)) {
      setIsBtnEnabled(false);
    }
  }, [enableEdit, loggedInUserBusinessData]);

  useEffect(() => {
    const getData = setTimeout(async () => {
      if ((dropdownSearchTerm || dropdownSearchTerm === '') && !scrapItemGroupsIsLoading && !scrapItemGroupsError && enableEdit && !initialRender) {
        if (dropdown === 'scrapItemGroupId') {
          setLoadingDropdownSearch(true);
          setScrapGroupItemsPageParams({ number: 1, size: 50 });
          await scrapItemGroupsRefetch();
          setLoadingDropdownSearch(false);
        }
      }
    }, 500)
    return () => clearTimeout(getData)
  }, [dropdownSearchTerm, scrapItemGroupsIsLoading, scrapItemGroupsError, enableEdit]);

  useEffect(() => {
    if (scrapGroupItemsPageParams && enableEdit && dropdown === 'scrapItemGroupId') {
      scrapItemGroupsRefetch();
    }
  }, [scrapGroupItemsPageParams]);

  const handleChangePage = (pageNo : number) => {  
    setLoadingInvoices(true);
    setPageNo(pageNo + 1);
    setPageSize(pageSize);
  }
  const handleChangeRows = (pageSize : number) => {
    setLoadingInvoices(true);
    setPageSize(pageSize);
    setPageNo(1);
  }

  const handleDropdownSearch = (field: string, search: string) => {
    if (field === 'scrapItemGroupId') {
      setInitialRender(false);
      setDropdownSearchTerm(search);
      setDropdown('scrapItemGroupId');
    }
  }
  const loadNext = (field: string, scroll: any) => {
    if (scroll.deltaY > 90 || scroll.touches[0].clientY > 90) {
      if (field === 'scrapItemGroupId') {
        if (!(scrapItemGroupsData?.pages[0]?.data as any).last) {
          setDropdown('scrapItemGroupId');
          setScrapGroupItemsPageParams({ number: scrapGroupItemsPageParams.number + 1, size: scrapGroupItemsPageParams.size });
        }
      }
    }
  }
  const loadPrev = (field: string, scroll: any) => {
    if (scroll.deltaY < 10 || scroll.touches[0].identifier === 0) {
      if (field === 'scrapItemGroupId') {
        if (!(scrapItemGroupsData?.pages[0]?.data as any).first) {
          setDropdown('scrapItemGroupId');
          setScrapGroupItemsPageParams({ number: scrapGroupItemsPageParams.number - 1, size: scrapGroupItemsPageParams.size });
        }
      }
    }
  }

  const [errorMessages, setErrorMessages] = useState<Partial<BusinessData>>({});

  const [changePasswordErrorMessages, setChangePasswordErrorMessages] = useState<Partial<ChangePasswordFormData>>({});

  const handleChangePassword = (field: string, value: any) => {
    const  val = value;

    setChangePasswordFormData((prevData) => ({
      ...prevData,
      [field]: val,
    }));
  
    if (changePasswordRequiredFields.includes(field) && !val) {
      setChangePasswordErrorMessages((prevErrors) => ({
        ...prevErrors,
        [field]: 'This field is required.',
      }));
    } else {
      setChangePasswordErrorMessages((prevErrors) => ({
        ...prevErrors,
        [field]: undefined,
      }));
    }
  };
  const validateFormDataChangePassword = (data: ChangePasswordFormData) => {
    const errors: Partial<ChangePasswordFormData> = {};

    if (!data.password?.trim() || data.password?.length < 8) {
      errors.password = t('minPasswordLength');
    } 
    
    if (!data.newPassword?.trim() || data.newPassword?.length < 8) {
      errors.newPassword = t('minPasswordLength');
    } else if (data.password === data.newPassword) {
      errors.newPassword = t('currentPasswordNewPasword');
    }
    
    if (!data.confirmNewPassword?.trim() || data.newPassword !== data.confirmNewPassword) {
      errors.confirmNewPassword = t('confirmPasswordNewPassword');
    }

    return errors;
  };
  const handleSubmitChangePassword = () => {
    const errors = validateFormDataChangePassword(changePasswordFormData);
    setChangePasswordErrorMessages(errors);

    if (Object.keys(errors).length === 0) {
      setIsBtnEnabled(true);
      const changePasswordData: ChangePasswordRequest = {
        email: loggedInUserEmail,
        password: changePasswordFormData.password,
        newPassword: changePasswordFormData.newPassword,
      };
      onChangePassword(changePasswordData);
    }
  };

  const { onChangePassword } = useChangePasswordService({
    onSuccess: (data) => {
      const response: any = {};
      Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
      if(response.success) {
        setChangePasswordFormData({
          password: '',
          newPassword: '',
          confirmNewPassword: ''
        });
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('password') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
      }
    },
    onError: () => {
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
    },
  });
  const handleChangeTabs = (tabId: number) => {
    if (tabId === 0) {
      setTab(0);
      setEnableEdit(false);
      setChangePasswordFormData({
        password: '',
        newPassword: '',
        confirmNewPassword: ''
      });
      setChangePasswordErrorMessages({});
      setIsBtnEnabled(true);
    } else if (tabId === 1) {
      setTab(1);
      setEnableEdit(true);
      setLoading(true);
      setErrorMessages({});
      setLoggedInUserBusinessData({
        id: loggedInUserData.id,
        title: loggedInUserData.title,
        secondaryTitle: loggedInUserData.secondaryTitle,
        primaryLanguage: loggedInUserData.primaryLanguage,
        secondaryLanguage: loggedInUserData.secondaryLanguage,
        whiteLabel: loggedInUserData.whiteLabel,
        sellBelowStock: loggedInUserData.sellBelowStock,
        scrapItemGroupId: loggedInUserData.scrapItemGroupId,
        active: loggedInUserData.active,
        currency: loggedInUserData.currency,
        priceListId: loggedInUserData.priceListId,
        taxProcedureId: loggedInUserData.taxProcedureId,
      });
      setPrevBusinessData({
        id: loggedInUserData.id,
        title: loggedInUserData.title,
        secondaryTitle: loggedInUserData.secondaryTitle,
        primaryLanguage: loggedInUserData.primaryLanguage,
        secondaryLanguage: loggedInUserData.secondaryLanguage,
        whiteLabel: loggedInUserData.whiteLabel,
        sellBelowStock: loggedInUserData.sellBelowStock,
        scrapItemGroupId: loggedInUserData.scrapItemGroupId,
        active: loggedInUserData.active,
        currency: loggedInUserData.currency,
        priceListId: loggedInUserData.priceListId,
        taxProcedureId: loggedInUserData.taxProcedureId,
      });
      const getData = async() => {
        scrapItemGroupsRefetch();
        priceListRefetch();
        await taxProceduresRefetch();
        setLoading(false);
      };
      getData();
    } else if (tabId === 2) {
      setLoadingInvoices(true);
      setTab(2);
      setEnableEdit(true);
      setErrorMessages({});
      setPageNo(1);
      setPageSize(20);
    }
  }
  const handleSecField = () => {
    setShowSecField((prevValue) => !prevValue);
  }

  const handleChangeBusiness = (field: string, value: any) => {
    const val = (field === "whiteLabel" || field === 'sellBelowStock') ? value ? Boolean(value) : false
      : (field === "priceListId" || field === "scrapItemGroupId" || field === "taxProcedureId") ? (value ? parseInt(value.value) || 0 : 0) 
      : (field === "currency" || field === "primaryLanguage" || field === "secondaryLanguage" ) ?
        (value ? value.value || '' : '')
        : value;

    setLoggedInUserBusinessData((prevData) => ({
      ...prevData,
      [field]: val,
    }));

    if (businessRequiredFields.includes(field) && !val) {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        [field]: 'This field is required.',
      }));
    } else {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        [field]: undefined,
      }));
    }
  };


  const validateBusinessData = (data: BusinessData) => {
    const errors: Partial<BusinessData> = {};

    if (!data.title?.trim()) {
      errors.title = t('This field is required.');
    }
    // if (!data.priceListId) {
    //   errors.priceListIdError = 'Default Price list is required.';
    // }
    if (!data.scrapItemGroupId) {
      errors.scrapItemGroupIdError = t('This field is required.');
    }

    return errors;
  };
  const handleSubmitBusiness = () => {
    const errors = validateBusinessData(loggedInUserBusinessData);
    setErrorMessages(errors);

    if (Object.keys(errors).length === 0) {
      setUpdateBusinessModal(true);
    }
  };
  const { onUpdateBusiness } = useUpdateBusinessService({
    onSuccess: (data) => {
      const response: any = {};
      Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
      if (response.success) {
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('business') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
        localStorage.getItem('ACCESS_TOKEN') ? onLogOut() : window.location.href = '/login';
      } else {
        enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
      }
    },
    onError: (err: any) => {
      console.log("err====,  ", err);
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
    },
    id: loggedInUserBusinessData.id,
  });
  const updateBusinessSettings = () => {
    setUpdateBusinessModal(false);
    setIsBtnEnabled(false);
    const businessData: BusinessRequest = {
      ...loggedInUserBusinessData,
      title: loggedInUserBusinessData.title,
      secondaryTitle: loggedInUserBusinessData.secondaryTitle,
      primaryLanguage: loggedInUserBusinessData.primaryLanguage,
      secondaryLanguage: loggedInUserBusinessData.secondaryLanguage,
      whiteLabel: loggedInUserBusinessData.whiteLabel,
      sellBelowStock: loggedInUserBusinessData.sellBelowStock,
      scrapItemGroupId: loggedInUserBusinessData.scrapItemGroupId,
      priceListId: loggedInUserBusinessData.priceListId,
      currency: loggedInUserBusinessData.currency,
      taxProcedureId: loggedInUserBusinessData.taxProcedureId,
    };

    onUpdateBusiness(businessData);
  }
  const cancelUpdateBusinessSettings = () => {
    setUpdateBusinessModal(false);
  }
  const { onLogOut } = useAuthLogoutService({
    onSuccess: (msg) => {
      const response: any = {};
      Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
      if(response.success) {
        dispatch(clearUserInfo());
        ['userInfo','selectedSubItem', 'ACCESS_TOKEN'].forEach(item => localStorage.removeItem(item));
        navigate('/login');   
      } else {
        enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
      }
    },
    onError: (err) => {
      console.error('Login error======', err);
      if (err instanceof Error) {
        console.error(err.stack);
      }
    },
  });
  const assignUnassignSingleEntity = (isChecked: boolean, row: any) => {
    setLoadingInvoices(true);
    if (isChecked) {
      if (tab === 2) {
        onAssignService({ businessId: loggedInUserData.id, templateId: row.id });
      }
    } else {
      if (tab === 2) {
        onUnassignService({ businessId: loggedInUserData.id, templateId: row.id });
      }
    }
  }
  // const assignUnassignAllEntities = (isChecked: boolean) => {
  //   setLoadingInvoices(true);
  //   if (isChecked) {
  //     if (tab === 2) {
  //       onAssignAllServices({ businessId: loggedInUserData.id });
  //     }
  //   } else {
  //     if (tab === 2) {
  //       onUnassignAllServices({ businessId: loggedInUserData.id });
  //     }
  //   }
  // }
  const { onAssignService } = useAssignBusinessInvoice({
    onSuccess: async(msg) => {
      const response: any = {};
      Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
      if (response.success) {
        if (invoiceAssignData?.pages[0].data.content.length === invoiceAssignData?.pages[0].data.content.filter(service => service.linked).length) {
          allInvoicesAssigned = true;
        }
        await invoiceAssignRefetch();
        setLoadingInvoices(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('invoice') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        await invoiceAssignRefetch();
        setLoadingInvoices(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
      }
    },
    onError: (err: any) => {
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
      if (err instanceof Error) {
        console.error(err.stack);
      }
    }
  })
  const { onUnassignService } = useUnassignBusinessInvoice({
    onSuccess: async(data) => {
      const response: any = {};
      Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
      if (response.success) {
        if (invoiceAssignData?.pages[0].data.content.length !== invoiceAssignData?.pages[0].data.content.filter(service => service.linked).length) {
          allInvoicesAssigned = false;
        }
        await invoiceAssignRefetch();
        setLoadingInvoices(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('invoice') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        await invoiceAssignRefetch();
        setLoadingInvoices(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
      }
    },
    onError: (err: any) => {
      console.log("err====,  ", err);
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
    },
  });
  // const { onAssignAllServices } = useAssignAllBusinessInvoice({
  //   onSuccess: async(msg) => {
  //     const response: any = {};
  //     Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
  //     if (response.success) {
  //       allInvoicesAssigned = true;
  //       await invoiceAssignRefetch();
  //       setLoadingInvoices(false);
  //       enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('All') + ' ' + t('invoices') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
  //     } else {
  //       await invoiceAssignRefetch();
  //       setLoadingInvoices(false);
  //       enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
  //     }
  //   },
  //   onError: (err: any) => {
  //     enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
  //     if (err instanceof Error) {
  //       console.error(err.stack);
  //     }
  //   }
  // })
  // const { onUnassignAllServices } = useUnassignAllBusinessInvoice({
  //   onSuccess: async(data) => {
  //     const response: any = {};
  //     Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
  //     if (response.success) {
  //       allInvoicesAssigned = false;
  //       await invoiceAssignRefetch();
  //       setLoadingInvoices(false);
  //       enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('All')  + ' ' +  t('invoices') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
  //     } else {
  //       await invoiceAssignRefetch();
  //       setLoadingInvoices(false);
  //       enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
  //     }
  //   },
  //   onError: (err: any) => {
  //     console.log("err====,  ", err);
  //     enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
  //   },
  // });

    return (
        <Box>
          <AccountSettingsComponent
            handleChangePassword={handleChangePassword}
            changePasswordFormData={changePasswordFormData}
            changePasswordErrorMessages={changePasswordErrorMessages}
            handleSubmitChangePassword={handleSubmitChangePassword}
            handleChangeTabs={handleChangeTabs}
            showSecField={showSecField}
            loggedInUserBusinessData={loggedInUserBusinessData}
            scrapItemGroupsData={scrapItemGroupsData?.pages[0] || {}}
            priceListData={priceListData?.pages[0] || {}}
            taxProceduresData={taxProceduresData?.pages[0] || {}}
            handleDropdownSearch={handleDropdownSearch}
            loadNext={loadNext}
            loadPrev={loadPrev}
            handleSecField={handleSecField}
            handleChangeBusiness={handleChangeBusiness}
            handleSubmitBusiness={handleSubmitBusiness}
            invoiceAssignData={invoiceAssignData?.pages[0] || {}}
            handleChangePage={handleChangePage}
            handleChangeRows={handleChangeRows}
            assignUnassignSingleEntity={assignUnassignSingleEntity}
            // assignUnassignAllEntities={assignUnassignAllEntities}
            allInvoicesAssigned={allInvoicesAssigned}
            {...{ isBtnEnabled, loadingDropdownSearch, errorMessages, enableEdit, loading, pageNo, pageSize, loadingInvoices }}
          />
          {updateBusinessModal && <FormDialog
            open={updateBusinessModal}
            onClose={cancelUpdateBusinessSettings}
            title={t('update') + ' ' + t('business') + ' ' +  t('settings')}
            // icon={<div><Home/></div>}
            content={
              <Grid container >
                <Grid item xs={12} sm={12} md={12} lg={12}  >
                  <Typography>{t('applyBusinessSettingsMessage')}</Typography>
                </Grid>
              </Grid>
            }
            saveFunction={updateBusinessSettings}
            submitButtonText="OK"
          />}
        </Box>
    )

}