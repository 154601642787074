import { useTranslation } from 'react-i18next';

type AlertMessagesProps = {
    statusCode?: number;
    text?: string;
};

const getMessage = (statusCode?: number, text?: string): string => {
    switch (statusCode) {
      case 200:
        return `${text}`;
      case 201:
        return `${text}`;
      case 202:
        return `${text}`;
      case 400:
        return "Bad Request";
      case 401:
        return "Unauthorized";
      case 403:
        return "Access Denied";
      case 404:
        return "Not Found";
      case 422:
        return "Owner cannot be updated";
      case 452:
        return "Invalid current password";
      case 500:
        return "Something went wrong";
      case 409:
        return `${text} number already exists`;
      case 4091:
        return "Username already exists";
      case 4092:
        return "Email already exists";
      case 4093:
        return "Invalid credentials";
      default:
        return text || "Something went wrong";
    }
  };
  
  const AlertMessages: React.FC<AlertMessagesProps> = ({ statusCode, text }) => {
    const { t } = useTranslation();
    const message = getMessage(statusCode, text);
    return <>{t(message)}</>;
  };
  
  export default AlertMessages;