import { Box, Grid, Typography } from "@mui/material";
import BackendPaginationTable from "common/Table/TableWithPagination";
import { ReactComponent as Business} from '../../assets/SideBarIcons/business.svg';
import ComponentHeaderTwo from "common/ComponentHeaderTwo/ComponentHeaderTwo";
import BusinessGroupModal from "./BusinessGroupModal";
import FormDialog from "common/Modal/Modal";
import { useTranslation } from 'react-i18next';
import StyledInput from "common/SearchField/Search";
import VerticalTabs from 'common/ComponentSidebar/ComponentSideBar';
import Loader from "layout/Loader";
import { checkPrivileges } from "Helpers/CheckPrivileges";

interface BusinessGroupComponentProps {
  openModal: () => void;
  closeModal: () => void;
  handleChange: (field: string, value: any) => void;
  handleChangePage: (field: number) => void;
  handleChangeRows: (field: number) => void;
  handleSubmit: () => void;
  handleDelete:  () => void;
  handleSecField: () => void;
  showSecField: boolean;
  primaryLanguage:string;
  secondaryLanguage:string;
  cancelDelete:  () => void;
  search: string;
  handleSearchClick: () => void;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeStatus: (row: any) => void;
  cancelChangeStatus: () => void;
  submitChangeStatus: () => void;
  formData: any;
  openForm: boolean;
  columns: any[]; 
  data: any; 
  loadingSearch: boolean;
  errorMessages: any,
  actions1: { label: string; onClick: ( value: any) => void; icon?: React.ReactNode }[];
  openDeleteModal: boolean;
  enableEdit?:boolean;
  pageNo:number;
  pageSize:number;
  statusModal: boolean;
  statusText?: string;
  stationAssignData: any;
  showRowView: (row: any) => void;
  rowView: boolean;
  isBtnEnabled: boolean;
  handleChangeTabs: (tabId: number) => void;
  assignUnassignSingleEntity: (isChecked: boolean, row: any) => void;
  assignUnassignAllEntities: (isChecked: boolean) => void;
  allStationsAssigned: unknown;
  loading: boolean;
  loadingSearchAssign: boolean;
  clearSearch: () => void;
}

const BusinessGroupComponent: React.FC <BusinessGroupComponentProps> = ({
  openModal,
  closeModal,
  handleChange,
  handleSubmit,
  formData,
  openForm,
  columns,
  showSecField,
  handleSecField,
  primaryLanguage,
  secondaryLanguage,
  data,
  errorMessages,
  actions1,
  openDeleteModal,
  handleDelete,
  cancelDelete,
  enableEdit,
  handleSearchClick,
  handleInputChange,
  search,
  handleChangePage,
  handleChangeRows,
  onChangeStatus,
  loadingSearch,
  cancelChangeStatus,
  submitChangeStatus,
  pageNo,
  statusModal,

  pageSize,
  stationAssignData,
  showRowView,
  isBtnEnabled,
  rowView,
  handleChangeTabs,
  assignUnassignSingleEntity,
  assignUnassignAllEntities,
  allStationsAssigned,
  loading,
  loadingSearchAssign,
  clearSearch
}) => {
  const { t } = useTranslation();

  const stationColumns = [
    { id: 'name', label: 'name', numeric: false },
    { id: 'stationCode', label: 'branchCode', numeric: false },
    { id: 'phoneNumber1', label: 'phoneNumber', numeric: false },
    { id: 'email', label: 'email', numeric: false },
  ]
  const childTabContent = (columns:any, data:any, tableData:any, allEntitiesAssigned:unknown, assignPrivilege: string, unassignPrivilege: string) => {
    return (
      <BackendPaginationTable 
        columns={columns}
        data={data}
        pageNo={pageNo}
        pageSize={pageSize}
        tableData={tableData}
        showCheckbox={true}
        showActions={true}
        enableSorting={true}
        showHeader={true}
        showSearch={false}
        showFilter={true}
        showCreateButton={false}
        actions={[]}
        handleChangePage={handleChangePage}
        handleChangeRows={handleChangeRows}
        assignUnassignSingleEntity={assignUnassignSingleEntity}
        assignUnassignAllEntities={assignUnassignAllEntities}
        allEntitiesAssigned={allEntitiesAssigned}
        loadingSearch={loadingSearchAssign}
        assignPrivilege={checkPrivileges(assignPrivilege)}
        unassignPrivilege={checkPrivileges(unassignPrivilege)}
        headerComponent={(
          <Grid container spacing={2}>  
            <Grid item md={3}>
              <StyledInput
                fullWidth
                placeholder="search"
                handleSearchClick={handleSearchClick}
                handleChange={handleInputChange}
                value={search}
              />
            </Grid>
          </Grid>
        )}
      />
    );
  }
  const tabs = [
    { 
      id: 0, title: `edit`, 
      child: <BusinessGroupModal
        enableEdit={enableEdit}
        openModal={openModal}
        handleSecField={handleSecField}
        showSecField={showSecField}
        secondaryLanguage={secondaryLanguage}
        primaryLanguage={primaryLanguage}
        closeModal={closeModal}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        formData={formData}
        openForm={true}
        errorMessages={errorMessages}
        isBtnEnabled={isBtnEnabled}
      />
    },
    { id: 1, title: `stations`, child: childTabContent(stationColumns, stationAssignData.data?.content || [], stationAssignData?.data || {}, allStationsAssigned, 'ROLE_BUSINESSGROUP_STATION_ASSIGN', 'ROLE_BUSINESSGROUP_STATION_UNASSIGN')},
  ];
  const disableTabs = tabs.map(tab => tab.id).filter(tabId => {
    switch (tabId) {
      case 0:
        return !checkPrivileges('ROLE_BUSINESSGROUP_UPDATE');
      case 1:
        return !checkPrivileges('ROLE_BUSINESSGROUP_STATION_ASSIGN') && !checkPrivileges('ROLE_BUSINESSGROUP_STATION_UNASSIGN');
      default:
        return false;
    }
  });

    return(

        <Box>
          <ComponentHeaderTwo
            showBtn={true}
            showSaveBtn={openForm}
            onClick={openModal}
            onSubmit={handleSubmit}
            heading={(openForm && !enableEdit) ? "addBusinessGroups" : (enableEdit && openForm || rowView) ? "editBusinessGroups" : t('businessesGroup')}
            icon={<Business height={28} width={28} />}
            btnText={openForm || rowView ? "back" : "create"}
            btnType={openForm || rowView ? "back" : "create"}
            isBtnEnabled={isBtnEnabled}
            showCreateBtn={checkPrivileges("ROLE_BUSINESSGROUP_CREATE")}
            // btnType="create"
          />

            {openForm ? 
              <BusinessGroupModal
                enableEdit={enableEdit}
                openModal={openModal}
                handleSecField={handleSecField}
                showSecField={showSecField}
                secondaryLanguage={secondaryLanguage}
                primaryLanguage={primaryLanguage}
                closeModal={closeModal}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                formData={formData}
                openForm={openForm}
                errorMessages={errorMessages}
                isBtnEnabled={isBtnEnabled}
              />
            :
             rowView ?
              <VerticalTabs tabs={tabs} selectedIndex={0} handleChangeTabs={handleChangeTabs} disableTabNo={disableTabs} />
              : 
              <Box>
              {loading ? <Loader /> :
                <BackendPaginationTable
                  columns={actions1.length ? columns : columns.filter(action => action.id !== 'actions')}
                  data={data.data?.content || []}
                  pageNo={pageNo}
                  pageSize={pageSize}
                  tableData={data?.data || {}}
                  showCheckbox={false}
                  showActions={true}
                  enableSorting={true}
                  showHeader={true}
                  showSearch={true}
                  showFilter={true}
                  showCreateButton={true}
                  actions={actions1}
                  handleChangePage={handleChangePage}
                  handleChangeRows={handleChangeRows}
                  onChangeStatus={onChangeStatus}
                  rowClick={checkPrivileges("ROLE_BUSINESSGROUP_UPDATE")}
                  showRowView={showRowView}
                  loadingSearch={loadingSearch}
                  headerComponent={
                  <Grid container spacing={2}>  
                    <Grid item xs={12} md={7} lg={5}>
                      <StyledInput
                        fullWidth
                        placeholder={t("title")}
                        handleSearchClick={handleSearchClick}
                        handleChange={handleInputChange}
                        value={search}
                        clearSearch={clearSearch}
                      />
                    </Grid>
                  </Grid>
                  }
                />
              }
            </Box>
            }

      {openDeleteModal && 
            <FormDialog
                open={openDeleteModal}
                onClose={cancelDelete}
                title={t('delete') + ' ' + t('businessGroup')}
                // icon={<div><Home/></div>}
                content={  
                    <Grid container >
                  <Grid item  xs={12} sm={12} md={12} lg={12}  >
                  <Typography>
                    {t('Are you sure you want to delete?')}
                  </Typography>
                  </Grid>
                  </Grid>
                  }
                  saveFunction={handleDelete}
                submitButtonText="Delete"
              />}

         {statusModal && 
          <FormDialog
            open={statusModal}
            onClose={cancelChangeStatus}
            title="Change Status"
            // icon={<div><Home/></div>}
            content={  
              <Grid container >
                <Grid item  xs={12} sm={12} md={12} lg={12}  >
                  <Typography>
                  {t(`areYouSure`)}?
                  </Typography>
                </Grid>
              </Grid>
            }
            saveFunction={submitChangeStatus}
            submitButtonText="OK"
          />
        }


        </Box>

    )
}

export default BusinessGroupComponent;