// ItemGroupService.tsx
import { BaseService } from '../../core/BaseService';
import { ChangeStatusRequest, PaginationParams } from '../../type';
import {
  AssignUnassignAllRequest,
  AssignUnassignAllResponse,
  AssignUnassignItemRequest,
  AssignUnassignResponse,
  ChangeStatusItemGroupResponse,
  DeleteItemGroupResponse,
  GetItemGroupByIdResponse,
  GetItemGroupDropdownResponse,
  GetItemGroupItemResponse,
  GetItemGroupResponse,
  ItemGroupRequest,
  ItemGroupResponse,
} from './interface';

export class ItemGroupProfile extends BaseService {
  async createItemGroup(req: ItemGroupRequest) {
    return this.post<ItemGroupResponse, ItemGroupRequest>(`${this.apiUrl}/api/itemgroups`, req);
  }

  async getItemGroup(params: PaginationParams) {
    return this.get<GetItemGroupResponse>(`${this.apiUrl}/api/itemgroups/paged`, params);
  }

  async getItemGroupDropdown(params: PaginationParams) {
    return this.get<GetItemGroupDropdownResponse>(`${this.apiUrl}/api/itemgroups/get`, params);
  }

  async updateItemGroup(id: number, req: ItemGroupRequest) {
    return this.put<ItemGroupResponse, ItemGroupRequest>(`${this.apiUrl}/api/itemgroups/${id}`, req);
  }

  async deleteItemGroup(id: number) {
    return this.delete<DeleteItemGroupResponse>(`${this.apiUrl}/api/itemgroups/${id}`);
  }

  async changeStatusItemGroup(id: number, active: string, req: ChangeStatusRequest) {
    return this.put<ChangeStatusItemGroupResponse, ChangeStatusRequest>(
      `${this.apiUrl}/api/itemgroups/${active}/${id}`,
      req
    );
  }

  async getItemGroupItem(params: PaginationParams & { itemGroupId: number }) {
    return this.get<GetItemGroupItemResponse>(`${this.apiUrl}/api/items/byitemgroupid`, params);
  }

  async assignItem(req: AssignUnassignItemRequest) {
    return this.post<AssignUnassignResponse, AssignUnassignItemRequest>(
      `${this.apiUrl}/api/itemgroups/item/assign`,
      req
    );
  }

  async unassignItem(req: AssignUnassignItemRequest) {
    return this.put<AssignUnassignResponse, AssignUnassignItemRequest>(
      `${this.apiUrl}/api/itemgroups/item/unassign`,
      req
    );
  }

  async assignAllItems(req: AssignUnassignAllRequest) {
    return this.post<AssignUnassignAllResponse, AssignUnassignAllRequest>(
      `${this.apiUrl}/api/itemgroups/item/assignall`,
      req
    );
  }

  async unassignAllItems(req: AssignUnassignAllRequest) {
    return this.put<AssignUnassignAllResponse, AssignUnassignAllRequest>(
      `${this.apiUrl}/api/itemgroups/item/unassignall`,
      req
    );
  }

  async getItemGroupById(id: number | undefined) {
    return this.get<GetItemGroupByIdResponse>(`${this.apiUrl}/api/itemgroups/${id}`);
  }
}
