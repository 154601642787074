import { Box, Button, Divider, Grid, Step, StepLabel, Stepper, Typography } from "@mui/material"
import { useForm } from "react-hook-form"
import { useEffect, useState } from "react";
import FormDialog from "common/Modal/Modal";
import AddIcon from '@mui/icons-material/Add';
import { InventoryItemsTable } from "components/Inventory/InventoryItemsTable";
import { useSnackbar } from "notistack";
import { useGetItemsService } from "subModule/src/services/items/useItems";
import { selectUserInfo } from "features/user/userSlice";
import { useSelector } from "react-redux";
import AlertMessages from "common/Alert/AlertMessages";
import { useNavigate, useParams } from "react-router-dom";
import { InventoryProfileService } from "subModule/src/core/services";
import { GetCreditNoteResponse, GetUnstockResponse, NewPurchaseOrderResponse, SaveCreditNote } from "subModule/src/services/inventory/interface";
import Loader from "layout/Loader";
import { useTranslation } from "react-i18next";
import { DeleteData } from "subModule/src/type";

interface FormData {
    [key: string]: any;
}
interface UnstockProps {
    steps: string[];
    setCurrentSteps?: any;
}

const Unstock: React.FC<UnstockProps> = ({ steps, setCurrentSteps }) => {
    const params = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const loggedInUserCurrency = useSelector(selectUserInfo)?.currency;
    const { enqueueSnackbar } = useSnackbar();
    const { control, register, handleSubmit, setValue, formState: { errors }, watch, resetField} = useForm<FormData>({defaultValues: {
        unStockItem: [],
    }});
    const { data: itemsData, refetch: itemsRefetch } = useGetItemsService({ pageNumber: 1, size: 8000 });
    const [currentStep, setCurrentStep] = useState<number>(steps.indexOf('Unstock'));
    const [confirm, setConfirm] = useState<boolean>(false);
    const [unStockItem, setunStockItem] = useState<any[]>([]);
    const [entity, setEntity] = useState<FormData>({});
    const [loading, setLoading] = useState<boolean>(true);
    const [creditnote, setUnstock] = useState<SaveCreditNote>();
    const [IsDataAvailable, setIsDataAvailable] = useState<boolean>(false);
    const [checkStatus, setCheckStatus] = useState<number | null>(1);

    useEffect(() => {
        itemsRefetch();
        if(params?.id) {
            getUnstockByPOID();
        }
    }, [params]);
    const getUnstockByPOID = () => {
        InventoryProfileService.getUnstockByPOID(params?.id).then((res: GetUnstockResponse) => {
            if(!res.data) {
                setIsDataAvailable(false);
                getCreditNoteByPOID();
            } else {
                setIsDataAvailable(true);
                setLoading(false);
                setCheckStatus((res.data as any)?.status);
                setUnstock((res.data as any));
                setValue('poId', params?.id);
                setValue('storeId', res.data?.storeId);
                setunStockItem(res.data.unStockItem);
                setValue('unStockItem', res.data.unStockItem);
            }
        }).catch((err:any) => { console.error("===", err); setLoading(false); });
    }
    const getCreditNoteByPOID = () => {
        InventoryProfileService.getCreditNoteByPOID(params?.id).then((res: GetCreditNoteResponse) => {
            if(res.success) {
                setUnstock((res.data as any));
                setunStockItem((res.data as any)?.pcdnoteItemResponse);
                setValue('unStockItem', res.data.pcdnoteItemResponse);
                setLoading(false);
            } else {
                enqueueSnackbar(<AlertMessages text={res.message || "Something went wrong"} />, { variant: 'error' });
                setLoading(false);
            }
        }).catch((err:any) => { console.error("===", err); setLoading(false); });
    }
    const columns = [
        { id: 'itemId' },
        { id: 'itemCode'},
        { id: 'unitId' },
        { id: 'itemName', label: t('Item') + ' *', numeric: false, disablePadding: false, type: 'text' },
        { id: 'quantity', label: t('Quantity'), numeric: false, disablePadding: false, type: 'number' },
        { id: 'unitTitle' },
    ];
    const DeleteItem = (row: any, typeName: string) => {
        if(typeName === "unStockItem" && IsDataAvailable) {
            setLoading(true);
            InventoryProfileService.deleteEntityItem((row as any).id, 'unstock/item').then((response: DeleteData) => {
                if(response.success)  enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('deleted') + ' ' + t('successfully')} />, { variant: 'success' });
                else enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                setLoading(false);
                getUnstockByPOID();
            }).catch((err:any) => { 
                console.error("===", err);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
                setLoading(false);
            });
        } else if(typeName === "unStockItem" && !IsDataAvailable) {
            setunStockItem((prevRows) => prevRows.filter((rows) => rows.id !== row.id));
        }
    }
    const addNewItem = (typeName: string) => {
        if(typeName === "unStockItem") {
            const newItem = columns.reduce((acc, column) => ({ ...acc, [column.id]: column.type === 'number' ? 0 : '' }), {});
            setunStockItem((prevRows) => [...prevRows, newItem]);
        }
    };
    const handleItem = (row: any, typeName: string, action: string) => {
        if(typeName === "unStockItem" && IsDataAvailable) {
            if(action === 'create') {
                InventoryProfileService.createUnstockItem({
                    ...row, poId: params?.id, purchaseCreditDebitNoteId: creditnote?.id, storeId: creditnote?.storeId
                }).then((response: GetUnstockResponse) => {
                    if(response.success)  enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Debit Note Item') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
                    else enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                    getUnstockByPOID();
                }).catch((err:any) => { 
                    console.error("===", err);
                    enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
                    getUnstockByPOID();
                });
            } else {
                InventoryProfileService.updateUnstockItem({
                    ...row, poId: params?.id, purchaseCreditDebitNoteId: creditnote?.id
                }).then((response: GetUnstockResponse) => {
                    if(response.success)  enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Debit Note Item') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
                    else enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                    getUnstockByPOID();
                }).catch((err:any) => { 
                    console.error("===", err);
                    enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
                    getUnstockByPOID();
                });
            }
        }
    }

    const handleInputChange = (index: number, columnId: string, value: any, typeName: string, type: string) => {
        if(typeName === "unStockItem") {
            const updatedItems = [...unStockItem];
            updatedItems[index][columnId] = type === 'number' ? parseFloat(value) : value;
            setunStockItem(updatedItems);
        }
    };
    const onSubmit = (data: any) => {
        if(Object.values(data)?.length) {
            if(checkStatus === 1) {
                const filteredItems = unStockItem.filter((item: any) => item?.itemName);
                if(filteredItems.length === 0) {
                    enqueueSnackbar(<AlertMessages text="Please add at least one item" />, { variant: 'error' });
                    return;
                }
                if(IsDataAvailable) {
                    setConfirm(true);
                    setEntity({...data});
                } else {
                    setLoading(true);
                    InventoryProfileService.saveUnstock(data.unStockItem).then((response: GetUnstockResponse) => {
                        if(response.success)  enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Unstock') + ' ' + t('saved') + ' ' + t('successfully')} />, { variant: 'success' });
                        else enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                        getUnstockByPOID();
                        setLoading(false);
                    }).catch((err:any) => { 
                        console.error("===", err);
                        setLoading(false);
                        enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
                    });
                }
            }
        }
    };

    return (
        loading ? <Loader /> : <Grid container component="form" id="form" className="px-[8px] items-baseline"  rowGap={2} columnGap={4} onSubmit={handleSubmit(onSubmit)}>
            <Grid container className="items-center">
                <Grid item xs={12} sm={6} className="text-start">
                    <Typography className="font-Saira text-[12px] font-semibold text-primary-color">{t('Unstock')}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} className="hide-on-print">
                    <div className="flex gap-2 justify-end">
                        {checkStatus === 1 && <Button type="submit" className="primary" variant="contained" disableElevation onClick={() => onSubmit(handleSubmit(onSubmit))}>{IsDataAvailable ? (checkStatus === 1 ? t('Authorize') : '') : t('save')}</Button>}
                        {/*  <Button className="secondary" variant="contained" disableElevation>{t('email')}</Button> */}
                        <Button className="secondary" variant="contained" disableElevation onClick={() => window.print()}>{t('Print')}</Button>
                        <Button className="secondary" variant="contained" disableElevation onClick={() => navigate('/inventory')}>{t('Back to list')}</Button>
                    </div>
                </Grid>
            </Grid>
            <Grid item xs={12}><Divider className="bg-[#AEB3C4] hide-on-print" /></Grid>
            {!IsDataAvailable ? <Grid container className="bg-primary-color py-[12px] px-[18px] rounded-[8px] mb-[16px] items-center">
                <Grid item xs={4}>
                    <Typography className="font-Saira text-[16px] font-bold text-[#fff] text-left">{t('Unstock')}</Typography>
                    <Typography className="font-Saira text-[14px] text-[#fff] text-left"><span className="font-bold">{t('Debit Note')} # </span> <span>{creditnote?.creditDebitNoteNumber || ''}</span></Typography>
                    <Typography className="font-Saira text-[14px] text-[#fff] text-left"><span className="font-bold">{t('Date')}</span> <span>{creditnote?.creditDebitDate?.split('T')[0] || ''}</span></Typography>
                </Grid>
                <Grid item xs={8}>
                    <div className="flex justify-end gap-10">
                        <Typography className="font-Saira text-[12px] text-[#fff] text-center"><span className="font-bold">{t('referenceNo')}</span> <br /><span className="font-medium">{creditnote?.referenceNumber || ''}</span></Typography>
                        <Typography className="font-Saira text-[12px] text-[#fff] text-center"><span className="font-bold">{t('Amount')}</span> <br /><span className="font-medium">{new Intl.NumberFormat('en-US', {}).format((creditnote as SaveCreditNote)?.amount || 0) || 0}</span></Typography>
                    </div>
                </Grid>
            </Grid> : null}
            <Grid container><Grid item xs={12}>
                <NewPurchaseSteps form={{ control, register, handleSubmit, setValue, errors, watch, resetField }} addNewItem={addNewItem} handleInputChange={handleInputChange} items={itemsData?.pages[0]?.data?.content || []} DeleteItem={DeleteItem} handleItem={handleItem} {...{currentStep, setCurrentStep, columns, unStockItem, loggedInUserCurrency, params, IsDataAvailable, steps, creditnote, checkStatus, setCurrentSteps, t}} />
            </Grid></Grid>
            <Grid item xs={12} className="hide-on-print"><Divider className="bg-[#AEB3C4] mt-[8px]" /></Grid>
            <Grid container className="items-center hide-on-print">
                <Grid item xs={12} sm={6} className="text-start"></Grid>
                <Grid item xs={12} sm={6}>
                    <div className="flex gap-2 justify-end">
                        {checkStatus === 1 && <Button type="submit" className="primary" variant="contained" disableElevation onClick={() => onSubmit(handleSubmit(onSubmit))}>{IsDataAvailable ? (checkStatus === 1 ? t('Authorize') : '') : t('save')}</Button>}
                        {/*  <Button className="secondary" variant="contained" disableElevation>{t('email')}</Button> */}
                        <Button className="secondary" variant="contained" disableElevation onClick={() => window.print()}>{t('Print')}</Button>
                        <Button className="secondary" variant="contained" disableElevation onClick={() => navigate('/inventory')}>{t('Back to list')}</Button>
                    </div>
                </Grid>
            </Grid>
            {confirm &&
                <FormDialog
                  open={confirm}
                  onClose={(event, reason) => event && reason !== 'backdropClick' ? setConfirm(false) : ''}
                  title={t('Authorize') + ' ' + t(steps[currentStep])}
                  saveFunction={() => {
                    delete entity.typeName;
                    entity.unStockItem.every((row:any) => delete row.total);
                    setConfirm(false);
                    InventoryProfileService.changeStatusEntity({typeName: 'unstock', entityId: creditnote?.id, status: 2}).then((response: NewPurchaseOrderResponse) => {
                        if(response.success) {
                            setConfirm(false);
                            setCheckStatus(2);
                            getUnstockByPOID();
                            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Status') + ' ' + t('changed') + ' ' + t('successfully')} />, { variant: 'success' });
                        } else {
                            setConfirm(false);
                            enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                        }
                    }).catch((err:any) => { 
                        console.error("===", err);
                        setConfirm(false);
                        enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
                    });
                }}
                  content={  
                    <><Typography>{t('Are you sure?')}</Typography></>
                  }
                  submitButtonText="OK"
                />
              }
        </Grid>
    )
}
interface NewPurchaseStepsProps {
    form: FormData;
    addNewItem: (typeName: string) => void;
    handleInputChange: (index: number, columnId: string, value: any, typeName: string, type: string) => void;
    currentStep: number;
    setCurrentStep: any;
    setCurrentSteps: any;
    columns: any[];
    unStockItem: any[];
    items: any[];
    loggedInUserCurrency?: string;
    DeleteItem: (row: any, typeName: string) => void;
    params?: any;
    handleItem?: (row: any, typeName: string, action: string) => void;
    IsDataAvailable?: boolean;
    steps: string[];
    creditnote?: any;
    checkStatus?: number | null;
    t?:any;
}

export const NewPurchaseSteps: React.FC<NewPurchaseStepsProps> = ({ form, addNewItem, handleInputChange, currentStep, columns, unStockItem, items, loggedInUserCurrency, DeleteItem, params, handleItem, IsDataAvailable, steps, checkStatus, setCurrentStep, setCurrentSteps, t }) => {
    return (<>
        <Stepper className='hide-on-print' activeStep={currentStep} connector={<></>}>
            {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {};
                const labelProps: { optional?: React.ReactNode; } = {};
                return (
                    <Step key={index} {...stepProps} onClick={() => {
                        if(document.querySelector(`.MuiStep-root:nth-child(${index + 1})`)?.classList.contains('Mui-completed')) {
                            setCurrentStep(steps.indexOf(label));
                            setCurrentSteps(steps.indexOf(label));
                        }
                    }} className={`${document.querySelector(`.MuiStep-root:nth-child(${index + 1})`)?.classList.contains('Mui-completed') ? 'cursor-pointer' : ''}`}>
                        <StepLabel {...labelProps}>{t(label)}</StepLabel>
                    </Step>
                );
            })}
        </Stepper>
        <Box className="bg-white py-[12px] px-[18px] rounded-[8px] mt-[16px]">
            <Typography className="font-Saira text-[12px] font-semibold text-[#171826] text-left">{t(steps[currentStep])}</Typography>
            <InventoryItemsTable form={form} columns={columns} handleInputChange={handleInputChange} rows={unStockItem} typeName="unStockItem" items={items} currency={loggedInUserCurrency} DeleteItem={DeleteItem} handleItem={handleItem} {...{params}} checkStatus={checkStatus} viewDelete={IsDataAvailable ? false : true} />
            <Grid item xs={12} sm={6} className="hide-on-print">
                <div className="flex gap-2 justify-start">
                    <Button className="secondary flex gap-1" variant="contained" disableElevation disabled={checkStatus === 2} onClick={() => addNewItem('unStockItem')}><AddIcon className="w-[20px] h-[20px]" /> {t('Add More Items')}</Button>
                </div>
            </Grid>
        </Box>
    </>);
}

export default Unstock;
