import { Box, Grid, Typography } from "@mui/material";
import BackendPaginationTable from "common/Table/TableWithPagination";
import { ReactComponent as ClientGroupIcon } from "assets/SideBarIcons/customer-group.svg";
import ComponentHeaderTwo from "common/ComponentHeaderTwo/ComponentHeaderTwo";
import ClientGroupModalComponent from "./ClientGroupModal";
import FormDialog from "common/Modal/Modal";
import { useTranslation } from 'react-i18next';
import StyledInput from "common/SearchField/Search";
import Loader from "layout/Loader";
import VerticalTabs from "common/ComponentSidebar/ComponentSideBar";
import { checkPrivileges } from "Helpers/CheckPrivileges";
import ClientGroupClientSearchHeader from "./ClientGroupClientSearchHeader";

interface ClientGroupComponentProps {
  openModal: () => void;
  closeModal: () => void;
  handleChange: (field: string, value: any) => void;
  handleChangePage: (field: number) => void;
  handleChangeRows: (field: number) => void;
  handleSubmit: () => void;
  handleDelete: () => void;
  cancelDelete: () => void;
  handleSecField: () => void;
  showSecField: boolean;
  primaryLanguage:string;
  secondaryLanguage:string;
  search: string;
  handleSearchClick: () => void;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeStatus: (row: any) => void;
  cancelChangeStatus: () => void;
  submitChangeStatus: () => void;
  statusModal: boolean;
  statusText?: string;
  formData: any;
  openForm: boolean;
  columns: any[];
  data: any;
  errorMessages: any,
  actions1: { label: string; onClick: (value: any) => void; icon?: React.ReactNode }[];
  openDeleteModal: boolean;
  enableEdit?: boolean;
  pageNo: number;
  pageSize: number;
  showRowView: (row: any) => void;
  isBtnEnabled: boolean;
  loading: boolean;
  loadingSearch: boolean;
  rowView: boolean;
  handleChangeTabs: (tabId: number) => void;
  assignUnassignSingleEntity: (isChecked: boolean, row: any) => void;
  assignUnassignAllEntities: (isChecked: boolean) => void;
  allClientsAssigned: unknown;
  clientAssignData: any;
  loadingSearchAssign: boolean;
  searchAssignFormData: any;
  filterAssign: string[];
  searchMultiAssign: any;
  handleChangeAssignFilter: (event: any) => void;
  handleChangeSearchAssign: (field: string, value: any) => void;
  handleSubmitSearchAssign: () => void;
  searchAssignErrorMessages: any;
  clearSearchAssign: () => void;
  tab: number;
  clearSearch: () => void;
}

const ClientGroupComponent: React.FC<ClientGroupComponentProps> = ({
  openModal,
  closeModal,
  handleChange,
  handleSubmit,
  formData,
  openForm,
  columns,
  data,
  errorMessages,
  actions1,
  openDeleteModal,
  handleDelete,
  cancelDelete,
  enableEdit,
  showSecField,
  handleSecField,
  primaryLanguage,
  secondaryLanguage,
  handleSearchClick,
  handleInputChange,
  search,
  handleChangePage,
  handleChangeRows,
  loadingSearch,
  onChangeStatus,
  cancelChangeStatus,
  submitChangeStatus,
  pageNo,
  statusModal,

  pageSize,
  showRowView,
  isBtnEnabled,
  loading,
  rowView,
  handleChangeTabs,
  assignUnassignSingleEntity,
  assignUnassignAllEntities,
  allClientsAssigned,
  loadingSearchAssign,
  clientAssignData,
  searchAssignFormData,
  filterAssign,
  searchMultiAssign,
  handleChangeAssignFilter,
  handleChangeSearchAssign,
  handleSubmitSearchAssign,
  searchAssignErrorMessages,
  clearSearchAssign,
  tab,
  clearSearch
}) => {
  const { t } = useTranslation();
  const clientColumns = [
    { id: 'name', label: 'name', numeric: false },
    { id: 'username', label: 'email', numeric: false },
  ]
  const childTabContent = (columns:any, data:any, tableData:any, allEntitiesAssigned:unknown, assignPrivilege: string, unassignPrivilege: string) => {
    return (
      <BackendPaginationTable 
        columns={columns}
        data={data}
        pageNo={pageNo}
        pageSize={pageSize}
        tableData={tableData}
        showCheckbox={true}
        showActions={true}
        enableSorting={true}
        showHeader={true}
        showSearch={true}
        showFilter={true}
        showCreateButton={false}
        actions={[]}
        handleChangePage={handleChangePage}
        handleChangeRows={handleChangeRows}
        assignUnassignSingleEntity={assignUnassignSingleEntity}
        assignUnassignAllEntities={assignUnassignAllEntities}
        allEntitiesAssigned={allEntitiesAssigned}
        loadingSearch={loadingSearchAssign}
        assignPrivilege={checkPrivileges(assignPrivilege)}
        unassignPrivilege={checkPrivileges(unassignPrivilege)}
        headerComponent={tab === 1 ? <ClientGroupClientSearchHeader 
          searchAssignFormData={searchAssignFormData}
          handleChangeAssignFilter={handleChangeAssignFilter}
          handleChangeSearchAssign={handleChangeSearchAssign}
          handleSubmitSearchAssign={handleSubmitSearchAssign}
          searchAssignErrorMessages={searchAssignErrorMessages}
          clearSearchAssign={clearSearchAssign}
          searchAssign={searchMultiAssign}
          {...{filterAssign}}
        /> : (
          <Grid container spacing={2}>  
            <Grid item md={3}>
              <StyledInput
                fullWidth
                placeholder={t("name")}
                handleSearchClick={handleSearchClick}
                handleChange={handleInputChange}
                value={search}
              />
            </Grid>
          </Grid>
        )}
      />
    );
  }
  const tabs = [
    { 
      id: 0, title: `Edit`, 
        child: <ClientGroupModalComponent
        enableEdit={enableEdit}
        handleSecField={handleSecField}
        showSecField={showSecField}
        secondaryLanguage={secondaryLanguage}
        primaryLanguage={primaryLanguage}
        openModal={openModal}
        closeModal={closeModal}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        formData={formData}
        openForm={true}
        errorMessages={errorMessages}
        isBtnEnabled={isBtnEnabled}
      />
    },
    { id: 1, title: 'Clients', child: childTabContent(clientColumns, clientAssignData.data?.content || [], clientAssignData?.data || {}, allClientsAssigned, 'ROLE_CLIENTGROUP_CLIENT_ASSIGN', 'ROLE_CLIENTGROUP_CLIENT_UNASSIGN')},
  ];
  const disableTabs = tabs.map(tab => tab.id).filter(tabId => {
    switch (tabId) {
      case 0:
        return !checkPrivileges('ROLE_CLIENTGROUP_UPDATE');
      case 1:
        return !checkPrivileges('ROLE_CLIENTGROUP_CLIENT_ASSIGN') && !checkPrivileges('ROLE_CLIENTGROUP_CLIENT_UNASSIGN');
      default:
        return false;
    }
  });

  return (

    <Box>
      <ComponentHeaderTwo
        showBtn={true}
        showSaveBtn={openForm}
        onClick={openModal}
        onSubmit={handleSubmit}
        heading={(openForm && !enableEdit) ? "addClientGroup" : (enableEdit && openForm || rowView) ? "editclientGroup" : t('clientGroup')}
        icon={<ClientGroupIcon height={28} width={28} />}
        btnText={openForm || rowView ? "back" : "create"}
        btnType={openForm || rowView ? "back" : "create"}
        isBtnEnabled={isBtnEnabled}
        showCreateBtn={checkPrivileges("ROLE_CLIENTGROUP_CREATE")}
        // btnType="create"
      />

      {openForm ?
        <ClientGroupModalComponent
          enableEdit={enableEdit}
          openModal={openModal}
          handleSecField={handleSecField}
          showSecField={showSecField}
          secondaryLanguage={secondaryLanguage}
          primaryLanguage={primaryLanguage}
          closeModal={closeModal}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          formData={formData}
          openForm={openForm}
          errorMessages={errorMessages}
          isBtnEnabled={isBtnEnabled}
          />
          : rowView ?
            <VerticalTabs tabs={tabs} selectedIndex={0} handleChangeTabs={handleChangeTabs} disableTabNo={disableTabs} />
            : <Box>
                {loading ? <Loader /> :
                  <BackendPaginationTable
                    columns={columns}
                    data={data.data?.content || []}
                    pageNo={pageNo}
                    pageSize={pageSize}
                    tableData={data?.data || {}}
                    showCheckbox={false}
                    showActions={true}
                    enableSorting={true}
                    showHeader={true}
                    showSearch={true}
                    showFilter={true}
                    showCreateButton={true}
                    actions={actions1}
                    handleChangePage={handleChangePage}
                    handleChangeRows={handleChangeRows}
                    onChangeStatus={onChangeStatus}
                    rowClick={disableTabs.length === tabs.length ? false : true}
                    showRowView={showRowView}
                    loadingSearch={loadingSearch}
                    headerComponent={
                      <Grid container spacing={2}>  
                        <Grid item xs={12} md={7} lg={5}>
                          <StyledInput
                            fullWidth
                            placeholder={t("title")}
                            handleSearchClick={handleSearchClick}
                            handleChange={handleInputChange}
                            value={search}
                            clearSearch={clearSearch}
                          />
                        </Grid>
                      </Grid>
                    }
                  />
                }
              </Box>
              }

      {openDeleteModal &&
        <FormDialog
          open={openDeleteModal}
          onClose={cancelDelete}
          title={t('delete') + ' ' + t('clientGroup')}
          // icon={<div><Home/></div>}
          content={
            <Grid container >
              <Grid item xs={12} sm={12} md={12} lg={12}  >
                <Typography>
                  {t('Are you sure you want to delete?')}
                </Typography>
              </Grid>
            </Grid>
          }
          saveFunction={handleDelete}
          submitButtonText="Delete"
        />}

      {statusModal &&
        <FormDialog
          open={statusModal}
          onClose={cancelChangeStatus}
          title="Change Status"
          // icon={<div><Home/></div>}
          content={
            <Grid container >
              <Grid item xs={12} sm={12} md={12} lg={12}  >
                <Typography>
                  {t(`areYouSure`)}?
                </Typography>
              </Grid>
            </Grid>
          }
          saveFunction={submitChangeStatus}
          submitButtonText="OK"
        />
      }




    </Box>

  )
}

export default ClientGroupComponent;