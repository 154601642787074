import { Box, Grid, Typography } from "@mui/material";
import BackendPaginationTable from "common/Table/TableWithPagination";
import { ReactComponent as Vehicle  } from "../../assets/SideBarIcons/vehicle.svg";
import ComponentHeaderTwo from "common/ComponentHeaderTwo/ComponentHeaderTwo";
import VehicleModalComponent from "./VehicleModal";
import FormDialog from "common/Modal/Modal";
import { useTranslation } from 'react-i18next';
import Loader from "layout/Loader";
import VehicleSearchHeader from "./VehicleSearchHeader";
import { checkPrivileges } from "Helpers/CheckPrivileges";
import SearchHeaderMenu from "common/Misc/SearchHeaderMenu";

interface VehicleComponentProps {
  openModal: () => void;
  closeModal: () => void;
  handleChange: (field: string, value: any) => void;
  handleChangePage: (field: number) => void;
  handleChangeRows: (field: number) => void;
  handleSubmit: () => void;
  handleDelete:  () => void;
  cancelDelete:  () => void;
  onChangeStatus: (row: any) => void;
  cancelChangeStatus: () => void;
  submitChangeStatus: () => void;
  statusModal: boolean;
  statusText?: string;
  formData: any;
  openForm: boolean;
  columns: any[]; 
  primaryLanguage:string;
  secondaryLanguage:string;
  data: any; 
  clientsData: any;
  makesData: any;
  modelsData: any;
  modelEnginesData: any;
  vehicleTypesData: any;
  errorMessages: any,
  actions1: { label: string; onClick: ( value: any) => void; icon?: React.ReactNode }[];
  openDeleteModal: boolean;
  enableEdit?:boolean;
  pageNo:number;
  pageSize:number;
  isBtnEnabled: boolean;
  loadingSearch: boolean;
  loading: boolean;
  showRowView: (row: any) => void;
  loadNext: (field: string, scroll: any) => void;
  loadPrev: (field: string, scroll: any) => void;
  handleDropdownSearch: (field: string, search: string) => void;
  loadingDropdownSearch: boolean;
  clearModel: boolean;
  clearModelEngine: boolean;
  searchFormData: any;
  handleChangeFilter: (event: any) => void;
  handleChangeSearch: (field: string, value: any) => void;
  handleSubmitSearch: () => void;
  searchErrorMessages: any;
  filter: string[];
  clearSearch: () => void;
  search: any;
  refetch: () => void;
}

const VehicleComponent: React.FC <VehicleComponentProps> = ({
  openModal,
  closeModal,
  handleChange,
  handleSubmit,
  formData,
  openForm,
  columns,
  data,
  clientsData,
  primaryLanguage,
  secondaryLanguage,
  makesData,
  modelsData,
  modelEnginesData,
  vehicleTypesData,
  errorMessages,
  actions1,
  openDeleteModal,
  handleDelete,
  cancelDelete,
  enableEdit,
  handleChangePage,
  handleChangeRows,
  onChangeStatus,
  cancelChangeStatus,
  submitChangeStatus,
  statusModal,
  pageNo,
  pageSize,
  isBtnEnabled,
  loadingSearch,
  loading,
  showRowView,
  loadNext,
  loadPrev,
  handleDropdownSearch,
  loadingDropdownSearch,
  clearModel,
  clearModelEngine,
  searchFormData,
  handleChangeFilter,
  handleChangeSearch,
  handleSubmitSearch,
  searchErrorMessages,
  filter,
  clearSearch,
  search,
  refetch
}) => {
  const { t } = useTranslation();
    return(
        <Box>
          <ComponentHeaderTwo
            showBtn={true}
            showSaveBtn={openForm}
            onClick={openModal}
            onSubmit={handleSubmit}
            heading={(openForm && !enableEdit) ? "addVehicles" : (enableEdit&&openForm) ? "editVehicles" : t('vehicles')}
            icon={<Vehicle height={32} width={32} />}
            btnText={openForm ? "back" : "create"}
            btnType={openForm ? "back" : "create"}
            isBtnEnabled={isBtnEnabled}
            showCreateBtn={checkPrivileges("ROLE_VEHICLE_CREATE")}
            // btnType="create"
          />
          <div style={{ height: '10px' }}></div>
          {openForm ? 
            <VehicleModalComponent
              secondaryLanguage={secondaryLanguage}
              primaryLanguage={primaryLanguage}
              enableEdit={enableEdit}
              openModal={openModal}
              closeModal={closeModal}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              formData={formData}
              clientsData={clientsData.data?.content || []}
              makesData={makesData.data?.content || []}
              modelsData={modelsData.data?.content || []}
              modelEnginesData={modelEnginesData.data?.content || []}
              vehicleTypesData={vehicleTypesData.data?.content || []}
              openForm={openForm}
              errorMessages={errorMessages}
              isBtnEnabled={isBtnEnabled}
              loadNext={loadNext}
              loadPrev={loadPrev}
              handleDropdownSearch={handleDropdownSearch}
              {...{loadingDropdownSearch, clearModel, clearModelEngine, t}}
            />
           :  <Box>
                {loading ? <Loader /> :
                  <BackendPaginationTable
                    columns={actions1.length ? columns : columns.filter(action => action.id !== 'actions')}
                    data={data.data?.content || []}
                    pageNo={pageNo}
                    pageSize={pageSize}
                    tableData={data?.data || {}}
                    showCheckbox={false}
                    showActions={true}
                    enableSorting={true}
                    showHeader={true}
                    showSearch={true}
                    showFilter={true}
                    showCreateButton={true}
                    actions={actions1}
                    handleChangePage={handleChangePage}
                    handleChangeRows={handleChangeRows}
                    onChangeStatus={onChangeStatus}
                    rowClick={checkPrivileges("ROLE_VEHICLE_UPDATE")}
                    showRowView={showRowView}
                    loadingSearch={loadingSearch}
                    headerComponent={<>
                        <VehicleSearchHeader 
                          searchFormData={searchFormData}
                          handleChangeFilter={handleChangeFilter}
                          handleChangeSearch={handleChangeSearch}
                          handleSubmitSearch={handleSubmitSearch}
                          searchErrorMessages={searchErrorMessages}
                          clearSearch={clearSearch}
                          {...{filter, search}}
                        />
                        {checkPrivileges('ROLE_VEHICLE_IMPORT') ? <SearchHeaderMenu typeName="vehicles" isMulti={true} refetch={refetch} /> : null}
                      </>
                    }
                  />
                }
              </Box>
            }

      {openDeleteModal && 
            <FormDialog
                open={openDeleteModal}
                onClose={cancelDelete}
                title={t('delete') + ' ' + t('vehicle')}
                // icon={<div><Home/></div>}
                content={  
                    <Grid container >
                  <Grid item  xs={12} sm={12} md={12} lg={12}  >
                  <Typography>
                    {t('Are you sure you want to delete?')}
                  </Typography>
                  </Grid>
                  </Grid>
                  }
                  saveFunction={handleDelete}
                submitButtonText="Delete"
              />}
{statusModal && 
          <FormDialog
            open={statusModal}
            onClose={cancelChangeStatus}
            title="Change Status"
            // icon={<div><Home/></div>}
            content={  
              <Grid container >
                <Grid item  xs={12} sm={12} md={12} lg={12}  >
                  <Typography>
                  {t(`areYouSure`)}?
                  </Typography>
                </Grid>
              </Grid>
            }
            saveFunction={submitChangeStatus}
            submitButtonText="OK"
          />
        }

        </Box>

    )
}

export default VehicleComponent;