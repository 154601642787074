// ActivityLogService.tsx
import { BaseService } from '../../core/BaseService';
import { PaginationParams } from '../../type';
import { GetActionsListResponse, GetActivityLogResponse } from './interface';

export class ActivityLogProfile extends BaseService {
  async getActivityLogs(params: PaginationParams) {
    return this.get<GetActivityLogResponse>(`${this.apiUrl}/api/requestlogs/paged`, params);
  }

  async getActionsList() {
    return this.get<GetActionsListResponse>(`${this.apiUrl}/api/requestlogs/actionslist`);
  }
}
