import SimpleModal from 'common/Form/Form';
import StyledField from 'common/TextField/TexField1';
import { Grid } from '@mui/material';
import BasicDatePicker from 'common/Pickers/Pickers';
import CommonCheckbox from 'common/CheckBox/Checkbox';
import { useTranslation } from 'react-i18next';

interface PriceListModalComponentProps {
  handleSecField: () => void;
  showSecField: boolean;
  primaryLanguage: string;
  secondaryLanguage: string;
  openModal: () => void;
  closeModal: () => void;
  handleChange: (field: string, value: any) => void;
  handleSubmit: () => void;
  formData: any;
  openForm: boolean;
  errorMessages: { [key: string]: string };
  enableEdit?: boolean;
  isBtnEnabled: boolean;
}

const PriceListModalComponent: React.FC<PriceListModalComponentProps> = ({
  closeModal,
  showSecField,
  handleSecField,
  primaryLanguage,
  secondaryLanguage,
  handleChange,
  handleSubmit,
  formData,
  openForm,
  enableEdit,
  errorMessages,
  isBtnEnabled
}) => {

  const { t } = useTranslation();

  return (
    <div>
      <SimpleModal
        visable={openForm}
        // title={enableEdit ? "updatePriceLists" : "addPriceLists"}
        isButtonVisable={true}
        isVisableBtn={isBtnEnabled}
        btnclosetext="Back"
        buttonText={enableEdit ? 'update' : 'submit'}
        attBtnNotshow={false}
        notVisableBackbtn={true}
        showPortList={handleSubmit} // Assuming this is the Submit button
        modalControle={closeModal}
        // attributeChangesMessage="Attribute changes message"
        // showPort={true}
        formSubmit={handleSubmit}
        content={
          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}  >
              <StyledField
                label={`${!primaryLanguage ? t('primary') : ''} ${t('title')} ${primaryLanguage ? ` (${t(primaryLanguage)})` : ''}`}
                search='language'
                handleSecField={handleSecField}
                placeholder="Write your title here"
                handleChange={(e) => handleChange('title', e.target.value)}
                required={true}
                fullWidth
                error={errorMessages?.title}
                value={formData?.title || ''}
              />
              {showSecField &&
                <Grid item xs={12} sm={12} md={12} lg={12} >
                  <div style={{ position: 'relative' }}>
                    <div
                      style={{
                        position: 'absolute',
                        top: -8,
                        left: 0,
                        zIndex: 1,
                        display: showSecField ? 'flex' : 'none',
                        backgroundColor: "white",
                        height: '5rem',
                        width: '100%',
                        justifyContent: 'center',
                        borderRadius: '8px',
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
                      }}
                    >
                      <div className='w-full mx-2 mt-1'>
                        <StyledField
                          label={`${!secondaryLanguage ? t('secondary') : ''} ${t('title')} ${secondaryLanguage ? ` (${t(secondaryLanguage)})` : ''}`}
                          placeholder="Write your secondaryTitle here"
                          handleChange={(e) => handleChange('secondaryTitle', e.target.value)}
                          required={false}
                          fullWidth
                          // error={errorMessages?.secondaryTitle}
                          value={formData?.secondaryTitle || ''}
                        />
                      </div>
                    </div>
                  </div>
                </Grid>
              }
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} >
              <BasicDatePicker
                type="datePicker"
                label="selectStartDate"
                fullWidth
                onChange={(e) => handleChange('startDate', e)}
                required={true}
                error={errorMessages?.startDate}
                value={enableEdit ? formData?.startDate?.split('T')[0] : null}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} >
              <BasicDatePicker
                type="datePicker"
                label="selectEndDate"
                fullWidth
                onChange={(e) => handleChange('endDate', e)}
                required={formData?.haveExpiry}
                error={errorMessages?.endDate}
                value={formData?.haveExpiry ? formData?.endDate?.split('T')[0] : ''}
                disable={!formData?.haveExpiry}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} className={`flex items-center justify-start`}>
              <CommonCheckbox
                label="haveExpiry"
                onChange={(e) => handleChange('haveExpiry', e.target.checked)}
                color="success"
                required={false}
                // error={errorMessages?.haveExpiryError}
                checked={formData?.haveExpiry}
              />
            </Grid>
            {/* <Grid item  xs={12} sm={6} md={4} lg={3} className={`flex items-center justify-start`}>
                <CommonCheckbox
                    label="isDefault"
                    onChange={(e) => handleChange('isDefault', e.target.checked)}
                    color="success"
                    required={false}
                    // error={errorMessages?.isDefaultError}
                    checked={formData?.isDefault}
                />
            </Grid> */}
          </Grid>
        }
      />
    </div>
  );
};

export default PriceListModalComponent;