import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import PackagesComponent from 'components/Packages/Index';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useChangeStatusPackagesService,usePackagesService,useDeletePackagesService,useGetPackagesService,useUpdatePackagesService, useGetPackagePriceListService, useAssignPackagePriceListService, useUnassignPackagePriceListService } from 'subModule/src/services/packages/usePackages';
import { useGetPackageServiceService, useAssignServiceService, useUnassignServiceService, useAssignAllServiceService, useUnassignAllServiceService } from 'subModule/src/services/packages/usePackages';
import { useGetPackageItemService, useAssignItemService, useUnassignItemService, useAssignAllItemService, useUnassignAllItemService } from 'subModule/src/services/packages/usePackages';
import { useGetPackageStationService, useAssignStationService, useUnassignStationService, useAssignAllStationService, useUnassignAllStationService } from 'subModule/src/services/packages/usePackages';
import { useGetPackageModelService, useAssignModelService, useUnassignModelService, useAssignAllModelService, useUnassignAllModelService } from 'subModule/src/services/packages/usePackages';
import { useGetDefaultTaxProcedureService, useGetTaxProcedureDropdownService } from 'subModule/src/services/taxProcedure/useTaxProcedure';
import { useGetItemGroupDropdownService } from 'subModule/src/services/itemgroup/useItemGroup';
import { AssignPriceListRequest, PackagesRequest } from 'subModule/src/services/packages/interface';
import { useSnackbar } from 'notistack';
import AlertMessages from 'common/Alert/AlertMessages';
import { useTranslation } from 'react-i18next';
import { SelectChangeEvent } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectUserInfo } from 'features/user/userSlice';
import { checkPrivileges } from 'Helpers/CheckPrivileges';
import { useGetPackageGroupService } from 'subModule/src/services/packagegroup/usePackageGroup';

interface FormData {
  title: string,
  secondaryTitle: string,
  id: number,
  packageCode: string,
  description: string,
  extraNotes: string,
  taxProcedureId: number,
  taxProcedureIdError?: string,
  itemGroup: number,
  itemGroupId: number,
  itemGroupIdError?: string,
  sortOrder: number;
  pricingType: number;
  price: number;
  priceError?: string,
  priceWithTax?: number,
  isTaxable: boolean;
  applyToAllStations: boolean,
  isDefault: boolean;
  active: boolean;
  packageGroupId: number;
}
interface PriceListFormData {
  packageId: number;
  price: number;
  priceError?: string;
  pricelistId: number;
}
interface SearchAssignFormData {
  [key: string]: string;
}

  let requiredFields = ['title', 'price', 'taxProcedureId', 'packageCode'];

  const priceListRequiredFields = ['price'];

  export default function Packages () {
    const [showSecField, setShowSecField] = useState<boolean>(false);
    const [openForm, setOpenForm] = useState(false);
    const [openDeleteModal, setDeleteModal] = useState(false);
    const [enableEdit, setEnableEdit] = useState(false);
    const [pageNo, setPageNo] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);
    const [statusModal, setStatusModal] = useState(false);
    const [statusText, setStatusText] = useState<string>('');
    const [rowView, setRowView] = useState(false);
    const [packageId, setPackageId] = useState<number>(0);
    const [tab, setTab] = useState<number>(0);
    const [selectedRow, setSelectedRow] = useState<any>({});
    const [isBtnEnabled, setIsBtnEnabled] = useState<boolean>(false);
    const [prevFormData, setPrevFormData] = useState<object>({});
    const [loading, setLoading] = useState<boolean>(true);
    const [searchTerm, setSearchTerm] = useState<any>(null);
    const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
    const [search, setSearch] = useState('');
    const [searchAssign, setSearchAssign] = useState('');
    const [searchTermAssign, setSearchTermAssign] = useState<any>(null);
    const [loadingSearchAssign, setLoadingSearchAssign] = useState<boolean>(false);
    const [itemGroupsPageParams, setItemGroupsPageParams] = useState<any>({ number: 1, size: 50 });
    const [dropdownSearchTerm, setDropdownSearchTerm] = useState<any>('');
    const [dropdown, setDropdown] = useState<string>('itemGroupId');
    const [loadingDropdownSearch, setLoadingDropdownSearch] = useState<boolean>(false);
    const [initialRender, setInitialRender] = useState<boolean>(true);
    // const [data] = useState(rows);
    const [formData, setFormData] = useState<FormData>({
        title: '',
        secondaryTitle: '',
        id: 0,
        packageCode: '',
        description: '',
        extraNotes: '',
        taxProcedureId: 0,
        itemGroup: 0,
        itemGroupId: 0,
        sortOrder: 0,
        pricingType: 2,
        price: 0,
        priceWithTax: 0,
        isTaxable: false,
        applyToAllStations: true,
        isDefault: false,
        active: false,
        packageGroupId: 0
    });
    const [priceListFormData, setPriceListFormData] = useState<PriceListFormData>({
      packageId: 0,
      price: 0,
      pricelistId: 0,
    });
    const [searchAssignFormData, setSearchAssignFormData] = useState<SearchAssignFormData>({
      name: '',
      phone : '',
      email: ''
    });
    const [filterAssign, setFilterAssign] = useState<string[]>(['name']);
    const [searchAssignErrorMessages, setSearchAssignErrorMessages] = useState<Partial<SearchAssignFormData>>({});
    const [searchMultiAssign, setSearchMultiAssign] = useState<any>({});

    const { data: tableData,  isLoading, error,  refetch } = searchTerm ? useGetPackagesService({pageNumber: pageNo, size: pageSize, title: searchTerm}) : useGetPackagesService({pageNumber: pageNo, size: pageSize});
    
    const { data: taxProceduresData, isLoading: taxProceduresIsLoading, error: taxProceduresError, refetch: taxProceduresRefetch } = useGetTaxProcedureDropdownService({ pageNumber: 1, size: 50 });

    const { data: itemGroupsData, isLoading: itemGroupsIsLoading, error: itemGroupsError, refetch: itemGroupsRefetch } = useGetItemGroupDropdownService({ pageNumber: itemGroupsPageParams.number, size: itemGroupsPageParams.size, title: dropdownSearchTerm });
    
    const { data: serviceAssignData, isLoading: serviceAssignIsLoading, error: serviceAssignError, refetch: serviceAssignRefetch } = useGetPackageServiceService({pageNumber: pageNo, size: pageSize, ...searchMultiAssign}, packageId);

    const { data: itemAssignData, isLoading: itemAssignIsLoading, error: itemAssignError, refetch: itemAssignRefetch } = searchTermAssign ? 
      useGetPackageItemService({pageNumber: pageNo, size: pageSize, title: searchTermAssign}, packageId, formData?.itemGroup === 1 && formData?.itemGroupId ? formData?.itemGroupId : null) 
    : useGetPackageItemService({pageNumber: pageNo, size: pageSize}, packageId, formData?.itemGroup === 1 && formData?.itemGroupId ? formData?.itemGroupId : null);

    const { data: stationAssignData, isLoading: stationAssignIsLoading, error: stationAssignError, refetch: stationAssignRefetch } = useGetPackageStationService({pageNumber: pageNo, size: pageSize, ...searchMultiAssign}, packageId);

    const { data: modelAssignData, isLoading: modelAssignIsLoading, error: modelAssignError, refetch: modelAssignRefetch } = searchTermAssign ? useGetPackageModelService({pageNumber: pageNo, size: pageSize, title: searchTermAssign}, packageId) : useGetPackageModelService({pageNumber: pageNo, size: pageSize}, packageId);

    const { data: priceListAssignData, isLoading: priceListAssignIsLoading, error: priceListAssignError, refetch: priceListAssignRefetch } = searchTermAssign ? useGetPackagePriceListService({pageNumber: pageNo, size: pageSize, title: searchTermAssign}, packageId) : useGetPackagePriceListService({pageNumber: pageNo, size: pageSize}, packageId);

    const { data: defaultTaxProceduresData, refetch: defaultTaxProceduresRefetch } = useGetDefaultTaxProcedureService(4);

    const { data: packageGroupsData, refetch: packageGroupsRefetch } = useGetPackageGroupService({ pageNumber: 1, size: 500 });
    
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const loggedInUserData = useSelector(selectUserInfo);
    const primaryLanguage = loggedInUserData?.primaryLanguage;
    const secondaryLanguage = loggedInUserData?.secondaryLanguage;
    const loggedInUserCurrency = loggedInUserData?.currency;
    const defaultPriceList = loggedInUserData?.priceListTitle;
    const defaultPriceListId = loggedInUserData?.priceListId;

    let allServicesAssigned:unknown = serviceAssignData?.pages[0].data?.content.map(service => service.linked).every(linked => linked === true);
    let allItemsAssigned:unknown = itemAssignData?.pages[0].data?.content.map(item => item.linked).every(linked => linked === true);
    let allStationsAssigned:unknown = stationAssignData?.pages[0].data?.content.map(station => station.linked).every(linked => linked === true);
    let allModelsAssigned:unknown = modelAssignData?.pages[0].data?.content.map(model => model.linked).every(linked => linked === true);

    useEffect(() => {
      if ( !isLoading && !error && !rowView && !openForm && !loadingSearch) {
        refetch();
      }
      if (tableData) {
        setLoading(false);
        if ((tableData?.pages[0] as any).status && !(tableData?.pages[0] as any).success) {
          enqueueSnackbar(<AlertMessages statusCode={(tableData?.pages[0] as any)?.status} />, { variant: 'error' })
        }
      }
      if(tab === 0 && enableEdit) {
        setIsBtnEnabled(false);
      }
      if(!serviceAssignIsLoading && !serviceAssignError && packageId && packageId !== 0 && tab === 1) {
        const loadData = async() => {
          setLoadingSearchAssign(true);
          await serviceAssignRefetch();
          setLoadingSearchAssign(false);
        }
        loadData();
        setFilterAssign(['title']);
        setSearchAssignFormData({
          title: '',
          serviceCode: '',
        });
      }
      if(!itemAssignIsLoading && !itemAssignError && packageId && packageId !== 0 && tab === 2) {
        const loadData = async() => {
          setLoadingSearchAssign(true);
          await itemAssignRefetch();
          setLoadingSearchAssign(false);
        }
        loadData();
      }
      if(!stationAssignIsLoading && !stationAssignError && packageId && packageId !== 0 && tab === 3) {
        const loadData = async() => {
          setLoadingSearchAssign(true);
          await stationAssignRefetch();
          setLoadingSearchAssign(false);
        }
        loadData();
        setFilterAssign(['name']);
        setSearchAssignFormData({
          name: '',
          phone : '',
          email: ''
        });
      }
      if(!modelAssignIsLoading && !modelAssignError && packageId && packageId !== 0 && tab === 4) {
        const loadData = async() => {
          setLoadingSearchAssign(true);
          await modelAssignRefetch();
          setLoadingSearchAssign(false);
        }
        loadData();
      }
      if(!priceListAssignIsLoading && !priceListAssignError && packageId && packageId !== 0 && tab === 5) {
        const loadData = async() => {
          setLoadingSearchAssign(true);
          await priceListAssignRefetch();
          setLoadingSearchAssign(false);
        }
        loadData();
      }
    }, [pageNo, pageSize, refetch, isLoading, error, packageId, taxProceduresIsLoading, taxProceduresError, tab, tableData, openForm]);

    useEffect(() => {
      if (enableEdit && JSON.stringify(formData) !== JSON.stringify(prevFormData) || openForm && JSON.stringify(formData) !== JSON.stringify(prevFormData)) {
        setIsBtnEnabled(true);
      } else if (enableEdit && JSON.stringify(formData) === JSON.stringify(prevFormData)) {
        setIsBtnEnabled(false);
      }
    }, [enableEdit, formData]);
    
    useEffect(() => {
      const getData = setTimeout(async() => {
        if (searchTerm !== null && !isLoading && !error && loadingSearch) {
          await refetch();
          setLoadingSearch(false);
        }
      }, 1000);
      return () => clearTimeout(getData)
    }, [searchTerm, loadingSearch]);

    useEffect(() => {
      const getData = setTimeout(async() => {
        if (searchTermAssign !== null && loadingSearchAssign && tab === 2) {
          await itemAssignRefetch();
          setLoadingSearchAssign(false);
        } else if (searchTermAssign !== null && loadingSearchAssign && tab === 4) {
          await modelAssignRefetch();
          setLoadingSearchAssign(false);
        } else if (searchTermAssign !== null && loadingSearchAssign && tab === 5) {
          await priceListAssignRefetch();
          setLoadingSearchAssign(false);
        }
      }, 1000);
      return () => clearTimeout(getData)
    }, [searchTermAssign, loadingSearchAssign]);

    useEffect(() => {
      const getData = setTimeout(async() => {
        if (searchMultiAssign && loadingSearchAssign && tab === 1) {
          await serviceAssignRefetch();
          setLoadingSearchAssign(false);
        }
        if (searchMultiAssign && loadingSearchAssign && tab === 3) {
          await stationAssignRefetch();
          setLoadingSearchAssign(false);
        }
      }, 1000);
      return () => clearTimeout(getData)
    }, [searchMultiAssign]);

    useEffect(() => {
      const getData = setTimeout(async() => {
        if ((dropdownSearchTerm || dropdownSearchTerm === '') && !itemGroupsIsLoading && !itemGroupsError && openForm && !initialRender) {
          if(dropdown === 'itemGroupId') {
            setLoadingDropdownSearch(true);
            setItemGroupsPageParams({ number: 1, size: 50 });
            await itemGroupsRefetch();
            setLoadingDropdownSearch(false);
          }
        }
      }, 500)
    return () => clearTimeout(getData)
    }, [dropdownSearchTerm, itemGroupsIsLoading, itemGroupsIsLoading, openForm]);

    useEffect(() => {
      if(itemGroupsPageParams && openForm && dropdown === 'itemGroupId') {
        itemGroupsRefetch();
      }
    }, [itemGroupsPageParams])

    const handleDropdownSearch = (field:string, search: string) => {
      if(field === 'itemGroupId') {
        setInitialRender(false);
        setDropdownSearchTerm(search);
        setDropdown('itemGroupId');
      }
    }
    const loadNext = (field: string, scroll: any) => {
      if(scroll.deltaY > 90 || scroll.touches[0].clientY > 90) {
        if(field === 'itemGroupId') {
          if(!(itemGroupsData?.pages[0]?.data as any).last) {
            setDropdown('itemGroupId');
            setItemGroupsPageParams({ number: itemGroupsPageParams.number + 1, size: itemGroupsPageParams.size });
          }
        }
      }
    }
    const loadPrev = (field: string, scroll: any) => {
      if(scroll.deltaY < 10 || scroll.touches[0].identifier === 0) {
        if(field === 'itemGroupId') {
          if(!(itemGroupsData?.pages[0]?.data as any).first) {
            setDropdown('itemGroupId');
            setItemGroupsPageParams({ number: itemGroupsPageParams.number - 1, size: itemGroupsPageParams.size });
          }
        }
      }
    }
    
    const handleChangePage = (pageNo : number) => {  
      setLoading(true);
      setPageNo(pageNo+1);
      setPageSize(pageSize);
    }
    const handleChangeRows = (pageSize : number) => {
      setLoading(true);
      setPageSize(pageSize);
      setPageNo(1);
    }

    const [errorMessages, setErrorMessages] = useState<Partial<FormData>>({});

    const [priceListErrorMessages, setPriceListErrorMessages] = useState<Partial<PriceListFormData>>({});

    const openModal = () => {
        if(rowView) {
          setRowView(false);
          setPackageId(0);
          setPageNo(1);
          setTab(0);
          setSearchAssign('');
          setSearchTermAssign('');
          setSearchMultiAssign({});
          setDropdown('itemGroupId');
          setDropdownSearchTerm('');
          setInitialRender(true);
        } else {
          setIsBtnEnabled(true);
          setShowSecField(false)
          setOpenForm(!openForm);
          setDropdown('itemGroupId');
          setDropdownSearchTerm('');
          setInitialRender(true);
          openModalSettings();
          if(!openForm && !enableEdit) {
            taxProceduresRefetch();
            itemGroupsRefetch();
            defaultTaxProceduresRefetch();
            packageGroupsRefetch();
            setItemGroupsPageParams({ number: 1, size: 50 });
            setSearch('');
            setSearchTerm('');
          }
        }
      };
      const openModalSettings = () => {
        setEnableEdit(false);
        setErrorMessages({})
        setFormData({
          title: '',
          secondaryTitle: '',
          id: 0,
          packageCode: '',
          description: '',
          extraNotes: '',
          taxProcedureId: 0,
          itemGroup: 0,
          itemGroupId: 0,
          sortOrder: 0,
          pricingType: 2,
          price: 0,
          priceWithTax: 0,
          isTaxable: false,
          applyToAllStations: true,
          isDefault: false,
          active: false,
          packageGroupId: 0
        });
      }

      const handleSecField = () => {
        setShowSecField((prevValue) => !prevValue);
      }

      const handleChange = (field: string, value: any) => {
        const  val = (field === "taxProcedureId" || field === "itemGroupId" || field === "itemGroup" || field === 'packageGroupId') ? 
          (value ? parseInt(value.value) || 0 : 0) 
        : (field === "pricingType") ? 
          (value ? parseInt(value.value) || 2 : 2)
        : (field === "sortOrder") ?
          ((value && value > 0 && value < 101) ? parseInt(value) : 0)
        : field === "price" ?
          (value >= 0 ? value : 0)
        : (field === "isDefault" || field === "isTaxable" ||  field === "applyToAllStations") ?
          value ? Boolean(value) : false
        : value;

        if(field === "itemGroup" && val !== 1) {
          setFormData({ ...formData, itemGroupId: 0 });
        }
        setFormData((prevData) => ({
          ...prevData,
          [field]: val,
        }));
        if(field === "isTaxable") {
          if(val) {
            requiredFields = ['title', 'price', 'taxProcedureId', 'packageCode'];
            if(openForm && !enableEdit) {
              setFormData((prevData) => ({
                ...prevData,
                taxProcedureId: (defaultTaxProceduresData as any)?.data?.id,
              }));
            }
          } else {
            requiredFields = ['title', 'price', 'packageCode'];
            setFormData((prevData) => ({
              ...prevData,
              taxProcedureId: 0,
            }));
          }
        }
        if (requiredFields.includes(field) && !val) {
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: 'This field is required.',
          }));
        } else {
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: undefined,
          }));
        }
      };
      
    
      const validateFormData = (data: FormData) => {
        const errors: Partial<FormData> = {};

        if (!data.title?.trim()) {
          errors.title = t('This field is required.');
        }
        if (!data.packageCode?.trim()) {
          errors.packageCode = t('This field is required.');
        }
        if(!data.price) {
          errors.priceError = t('This field is required.')
        }
        if(data.isTaxable && !data.taxProcedureId) {
          errors.taxProcedureIdError = t('This field is required.');
        }
        if(!data.itemGroupId && data.itemGroup === 1) {
          errors.itemGroupIdError = t('This field is required.');
        }
    
        return errors;
      };

      const { onCreatePackages  } = usePackagesService({
        onSuccess: (msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          if(response.success) {
            setOpenForm(false);
            refetch();
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('package') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('packageCode')} />, { variant: 'error' });
          }
        },
        onError: (err) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
          console.error(err.stack);
        }
      }})

      const { onUpdatePackages } = useUpdatePackagesService({
        onSuccess: (data) => {
          const response: any = {};
          Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
          if(response.success) {
            if(!rowView) {
              setOpenForm(false);
              setEnableEdit(false);
              refetch();
            }
            if(rowView) { setPrevFormData(response?.data); setSelectedRow(response?.data); }
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('package') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('packageCode')} />, { variant: 'error' });
          }
        },
        onError: (err) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
        id :formData.id,
      }
      );

      const handleSubmit = () => {
        const errors = validateFormData(formData);
        setErrorMessages(errors);
      
        if (Object.keys(errors).length === 0) {
          setIsBtnEnabled(false);
          setLoading(true);
          const PackagesData: PackagesRequest = {
            ...formData, 
            title: formData.title,
            secondaryTitle: formData.secondaryTitle,
            id: formData.id,
            packageCode:formData.packageCode,
            description: formData.description,
            extraNotes: formData.extraNotes,
            taxProcedureId: formData.taxProcedureId,
            itemGroup: formData.itemGroup,
            itemGroupId:formData.itemGroupId,
            sortOrder: formData.sortOrder,
            pricingType: formData.pricingType,
            price: formData.price,
            isTaxable: formData.isTaxable,
            applyToAllStations: formData.applyToAllStations,
            isDefault: formData.isDefault,
            active:formData.active,
            packageGroupId: formData.packageGroupId
          };
     
          if (enableEdit) {
            onUpdatePackages(
                PackagesData
            );
          } else {
            onCreatePackages(PackagesData);
          }
        }
      };

      const onEdit = (row:any) => {
        setEnableEdit(true);
        setIsBtnEnabled(false);
        setDropdown('itemGroupId');
        setDropdownSearchTerm('');
        setInitialRender(true);
        taxProceduresRefetch();
        itemGroupsRefetch();
        packageGroupsRefetch();
        itemAssignRefetch();
        setFormData(row);
        setPrevFormData(row);
        setOpenForm(true);
        setSearch('');
        setSearchTerm('');
      }

      const onDelete = (row:any) => {
        setFormData(row)
        setDeleteModal(true)
      }

      const { onDeletePackages } = useDeletePackagesService({
        id: formData.id,
        onSuccess: (msg) => {
          if(msg.success) {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('package') + ' ' + t('deleted') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
          }
          refetch(); // This will use the updated pageNo and pageSize
          if(tableData?.pages[0].data.content.length === 1) {
            handleChangePage(pageNo - 1)
          }
        },
        onError: (err) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
      });

      const handleDelete = () => {
        setDeleteModal(false);
        setLoading(true);
        onDeletePackages();
      }
      const cancelDelete = () => {
        setDeleteModal(false)
      }

      const clearSearch = () => {
        if(!rowView) {
          setSearch('');
          setSearchTerm('');
          if(searchTerm) { handleSearch(''); }
        } else {
          setSearchAssign('');
          setSearchTermAssign('');
          if(searchTermAssign) { handleSearch(''); }
        }
      }
      const handleSearch = (search: string) => {
        if(!rowView) {
          setLoadingSearch(true);
          setSearchTerm(search);
          setPageNo(1);
          setPageSize(pageSize);
        } else {
          setLoadingSearchAssign(true);
          if(tab == 3 || tab == 1) {
            setSearchMultiAssign(search);
          } else {
            setSearchTermAssign(search);
          }
          setPageNo(1);
          setPageSize(pageSize);
        }
      };
    
      const handleSearchClick = () => {
        if(!rowView) {
          const trimmedSearch = search.trim();
          if (trimmedSearch !== '') {
            handleSearch(trimmedSearch);
          }
        } else {
          const trimmedSearch = searchAssign.trim();
          if (trimmedSearch !== '') {
            handleSearch(trimmedSearch);
          }
        }
      };
    
      const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(!rowView) {
          setSearch(event.target.value?.trim());
          if (!event.target.value?.trim()) handleSearch('');
        } else {
          setSearchAssign(event.target.value.trim());
          if (!event.target.value.trim()) handleSearch('');
        }
      };
      const onChangeStatus = (row: any) => {
        row.active ? setStatusText('deactivate') : setStatusText('activate');
        setFormData(row);
        setStatusModal(true);
      }
      const cancelChangeStatus = () => {
        setStatusModal(false);
      }
      const submitChangeStatus = () => {
        setStatusModal(false);
        setLoading(true);
        onChangeStatusPackages({});
      }
      const { onChangeStatusPackages } = useChangeStatusPackagesService({
        id: formData.id,
        onSuccess: (msg) => {
          if(msg.success) {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('package') + ' ' + t(statusText) + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
          }
          refetch();
        },
        onError: (err) => {
          console.error("===", err)
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
        active: formData.active ? "inactive" : "active"
      });
      const showRowView = (row: any) => {
        setEnableEdit(true);
        taxProceduresRefetch();
        itemGroupsRefetch();
        packageGroupsRefetch();
        setOpenForm(false);
        setFormData(row);
        setPrevFormData(row);
        setErrorMessages({});
        setRowView(true);
        setPackageId(row.id);
        setSelectedRow(row);
        setSearch('');
        setSearchTerm('');
      }
      const handleChangePriceList = (field: string, value: any) => {
        const  val = field === "price" ? (value >= 0 ? value : 0) : value;
        setPriceListFormData((prevData) => ({
          ...prevData,
          [field]: val,
        }));
      
        if (priceListRequiredFields.includes(field) && !val) {
          setPriceListErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: 'This field is required.',
          }));
        } else {
          setPriceListErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: undefined,
          }));
        }
      };
      const validatePriceListFormData = (data: PriceListFormData) => {
        const errors: Partial<PriceListFormData> = {};

        if (!data.price) {
          errors.priceError = t('required');
        }
    
        return errors;
      };
      const handleChangeTabs = (tabId: number) => {
        setSearchAssign('');
        setSearchTermAssign('');
        setSearchMultiAssign({});
        if(tabId === 0) {
          setTab(0);
          setEnableEdit(true);
          setOpenForm(false);
          setFormData(selectedRow);
          taxProceduresRefetch();
          itemGroupsRefetch();
          packageGroupsRefetch();
          setErrorMessages({});
          setIsBtnEnabled(false);
          setDropdown('itemGroupId');
          setDropdownSearchTerm('');
          setInitialRender(true);
        } else if(tabId === 1) {
          setTab(1);
          setPageNo(1);
          setEnableEdit(false);
          setErrorMessages({});
          clearSearchAssign();
        } else if(tabId === 2) {
          setTab(2);
          setPageNo(1);
          setEnableEdit(false);
          setErrorMessages({});
        } else if(tabId === 3) {
          setTab(3);
          setPageNo(1);
          setEnableEdit(false);
          setErrorMessages({});
          clearSearchAssign();
        } else if(tabId === 4) {
          setTab(4);
          setPageNo(1);
          setEnableEdit(false);
          setErrorMessages({});
        } else if(tabId === 5) {
          setTab(5);
          setPageNo(1);
          setEnableEdit(false);
          setPriceListErrorMessages({});
          setPriceListFormData({
            packageId: 0,
            price: 0,
            pricelistId: 0,
          });
        }
      }
      const assignUnassignSingleEntity = (isChecked: boolean, row: any) => {
        if(isChecked) {
          if(tab === 1) {
            setLoadingSearchAssign(true);
            onAssignService({ packageId: packageId, serviceId: row.id });
          } else if(tab === 2) {
            setLoadingSearchAssign(true);
            onAssignItem({ packageId: packageId, itemId: row.id });
          } else if(tab === 3) {
            setLoadingSearchAssign(true);
            onAssignStation({ packageId: packageId, stationId: row.id });
          } else if(tab === 4) {
            setLoadingSearchAssign(true);
            onAssignModel({ packageId: packageId, modelId: row.id });
          } else if(tab === 5) {
            const errors = validatePriceListFormData(priceListFormData);
            setPriceListErrorMessages(errors);
            
            if (Object.keys(errors).length === 0) {
              setLoadingSearchAssign(true);
              const PackagesPriceListData: AssignPriceListRequest = {
                ...priceListFormData, 
                pricelistId: row.id,
                price: +priceListFormData.price,
                packageId: packageId,
              };
     
              onAssignPriceList(PackagesPriceListData);
            }
          }
        } else {
          if(tab === 1) {
            setLoadingSearchAssign(true);
            onUnassignService({ packageId: packageId, serviceId: row.id });
          } else if(tab === 2) {
            setLoadingSearchAssign(true);
            onUnassignItem({ packageId: packageId, itemId: row.id });
          } else if(tab === 3) {
            setLoadingSearchAssign(true);
            onUnassignStation({ packageId: packageId, stationId: row.id });
          } else if(tab === 4) {
            setLoadingSearchAssign(true);
            onUnassignModel({ packageId: packageId, modelId: row.id });
          } else if(tab === 5) {
            setLoadingSearchAssign(true);
            onUnassignPriceList({ packageId: packageId, pricelistId: row.id });
          }
        }
      }
      const assignUnassignAllEntities = (isChecked: boolean) => {
        setLoadingSearchAssign(true);
        if(isChecked) {
          if(tab === 1) {
            onAssignAllServices({packageId: packageId});
          } else if(tab === 2) {
            onAssignAllItems({packageId: packageId});
          } else if(tab === 3) {
            onAssignAllStations({packageId: packageId});
          } else if(tab === 4) {
            onAssignAllModels({packageId: packageId});
          }
        } else {
          if(tab === 1) {
            onUnassignAllServices({packageId: packageId});
          } else if(tab === 2) {
            onUnassignAllItems({packageId: packageId});
          } else if(tab === 3) {
            onUnassignAllStations({packageId: packageId});
          } else if(tab === 4) {
            onUnassignAllModels({packageId: packageId});
          }
        }
      }
      const { onAssignService } = useAssignServiceService({
        onSuccess: async(msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          if(response.success) {
            if(serviceAssignData?.pages[0].data.content.length === serviceAssignData?.pages[0].data.content.filter(service => service.linked).length) {
              allServicesAssigned = true;
            }
            await serviceAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('services') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await serviceAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err:any) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
            console.error(err.stack);
          }
      }})
      const { onUnassignService } = useUnassignServiceService({
        onSuccess: async(data) => {
          const response: any = {};
          Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
          if(response.success) {
            if(serviceAssignData?.pages[0].data.content.length !== serviceAssignData?.pages[0].data.content.filter(service => service.linked).length) {
              allServicesAssigned = false;
            }
            await serviceAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('service') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await serviceAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err: any) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
      });
      const { onAssignAllServices } = useAssignAllServiceService({
        onSuccess: async(msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          if(response.success) {
            allServicesAssigned = true;
            await serviceAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('All') + ' ' + t('services') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await serviceAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err:any) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
            console.error(err.stack);
          }
      }})
      const { onUnassignAllServices } = useUnassignAllServiceService({
        onSuccess: async(data) => {
          const response: any = {};
          Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
          if(response.success) {
            allServicesAssigned = false;
            await serviceAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('All') + ' ' + t('services') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await serviceAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err: any) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
      });
      const { onAssignItem } = useAssignItemService({
        onSuccess: async(msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          if(response.success) {
            if(itemAssignData?.pages[0].data.content.length === itemAssignData?.pages[0].data.content.filter(item => item.linked).length) {
              allItemsAssigned = true;
            }
            await itemAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('item') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await itemAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err:any) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
            console.error(err.stack);
          }
      }})
      const { onUnassignItem } = useUnassignItemService({
        onSuccess: async(data) => {
          const response: any = {};
          Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
          if(response.success) {
            if(itemAssignData?.pages[0].data.content.length !== itemAssignData?.pages[0].data.content.filter(item => item.linked).length) {
              allItemsAssigned = false;
            }
            await itemAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('item') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await itemAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err: any) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
      });
      const { onAssignAllItems } = useAssignAllItemService({
        onSuccess: async(msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          if(response.success) {
            allItemsAssigned = true;
            await itemAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('All') + ' ' + t('items') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await itemAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err:any) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
            console.error(err.stack);
          }
      }})
      const { onUnassignAllItems } = useUnassignAllItemService({
        onSuccess: async(data) => {
          const response: any = {};
          Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
          if(response.success) {
            allItemsAssigned = false;
            await itemAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('All') + ' ' + t('items') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await itemAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err: any) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
      });
      const { onAssignStation } = useAssignStationService({
        onSuccess: async(msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          if(response.success) {
            if(stationAssignData?.pages[0].data.content.length === stationAssignData?.pages[0].data.content.filter(station => station.linked).length) {
              allStationsAssigned = true;
            }
            await stationAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('station') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await stationAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err:any) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
            console.error(err.stack);
          }
      }})
      const { onUnassignStation } = useUnassignStationService({
        onSuccess: async(data) => {
          const response: any = {};
          Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
          if(response.success) {
            if(stationAssignData?.pages[0].data.content.length !== stationAssignData?.pages[0].data.content.filter(station => station.linked).length) {
              allStationsAssigned = false;
            }
            await stationAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('station') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await stationAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err: any) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
      });
      const { onAssignAllStations } = useAssignAllStationService({
        onSuccess: async(msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          if(response.success) {
            allStationsAssigned = true;
            await stationAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('All') + ' ' + t('stations') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await stationAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err:any) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
            console.error(err.stack);
          }
      }})
      const { onUnassignAllStations } = useUnassignAllStationService({
        onSuccess: async(data) => {
          const response: any = {};
          Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
          if(response.success) {
            allStationsAssigned = false;
            await stationAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('All') + ' ' + t('stations') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await stationAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err: any) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
      });
      const { onAssignModel } = useAssignModelService({
        onSuccess: async(msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          if(response.success) {
            if(modelAssignData?.pages[0].data.content.length === modelAssignData?.pages[0].data.content.filter(model => model.linked).length) {
              allModelsAssigned = true;
            }
            await modelAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('model') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await modelAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err:any) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
            console.error(err.stack);
          }
      }})
      const { onUnassignModel } = useUnassignModelService({
        onSuccess: async(data) => {
          const response: any = {};
          Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
          if(response.success) {
            if(modelAssignData?.pages[0].data.content.length !== modelAssignData?.pages[0].data.content.filter(model => model.linked).length) {
              allModelsAssigned = false;
            }
            await modelAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('model') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await modelAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err: any) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
      });
      const { onAssignAllModels } = useAssignAllModelService({
        onSuccess: async(msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          if(response.success) {
            allModelsAssigned = true;
            await modelAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('All') + ' ' + t('models') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await modelAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err:any) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
            console.error(err.stack);
          }
      }})
      const { onUnassignAllModels } = useUnassignAllModelService({
        onSuccess: async(data) => {
          const response: any = {};
          Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
          if(response.success) {
            allModelsAssigned = false;
            await modelAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('All') + ' ' + t('models') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await modelAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err: any) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
      });
      const { onAssignPriceList } = useAssignPackagePriceListService({
        onSuccess: async(msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          if(response.success) {
            await priceListAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('priceList') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await priceListAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
          setPriceListFormData({
            packageId: 0,
            price: 0,
            pricelistId: 0,
          });
        },
        onError: (err:any) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
            console.error(err.stack);
          }
          setPriceListFormData({
            packageId: 0,
            price: 0,
            pricelistId: 0,
          });
      }})
      const { onUnassignPriceList } = useUnassignPackagePriceListService({
        onSuccess: async(msg) => {
          if(msg.success) {
            await priceListAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('priceList') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await priceListAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
          }
          setPriceListFormData({
            packageId: 0,
            price: 0,
            pricelistId: 0,
          });
        },
        onError: (err) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          setPriceListFormData({
            packageId: 0,
            price: 0,
            pricelistId: 0,
          });
        },
      });

      const clearSearchAssign = () => {
        setFilterAssign(tab === 3 ? ['name'] : ['title']);
        setSearchAssignFormData(tab === 3 ? {
          name: '',
          phone : '',
          email: ''
        } : {
          title: '',
          serviceCode: '',
        });
        setSearchAssignErrorMessages({});
        if(Object.keys(searchMultiAssign).length) {
          setLoadingSearchAssign(true);
          setSearchMultiAssign({});
        }
      }
      const handleChangeAssignFilter = (event: SelectChangeEvent<typeof filterAssign>) => {
        const { target: { value }, } = event;
        setFilterAssign(typeof value === 'string' ? value.split(',') : value,);
        setSearchAssignFormData(prevFormData => 
          Object.fromEntries(Object.keys(prevFormData).map(key => 
            [key, value.includes(key) ? prevFormData[key] : '']
          ))
        );
        if(!value.length && Object.keys(searchMultiAssign).length) {
          clearSearchAssign();
        }
      };
      const handleChangeSearchAssign = (field: string, value: any) => {
        const val = value;
    
        setSearchAssignFormData((prevData) => ({
          ...prevData,
          [field]: val,
        }));
    
        if (filterAssign.includes(field) && !val) {
          setSearchAssignErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: 'This field is required.',
          }));
        } else {
          setSearchAssignErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: undefined,
          }));
        }
      };
      const validateSearchAssignFormData = (data: SearchAssignFormData) => {
        const errors: Partial<SearchAssignFormData> = {};
    
        filterAssign.forEach(fieldName => {
          if (!data[fieldName]?.trim()) { 
            errors[fieldName] = t('required');
          }
        });
    
        return errors;
      };
      const handleSubmitSearchAssign = () => {
        const errors = validateSearchAssignFormData(searchAssignFormData);
        setSearchAssignErrorMessages(errors);
    
        if (Object.keys(errors).length === 0) {
          setLoadingSearchAssign(true);
          const filteredSearch: Record<string, string> = {};
    
          Object.entries(searchAssignFormData).forEach(([key, value]) => {
              if (value) {
                filteredSearch[key] = typeof value === 'string' ? value.trim() : value;
              }
          });
          setSearchMultiAssign(filteredSearch);
          setPageNo(1);
          setPageSize(pageSize);
        }
      };
      const adjustActions = (actions: any[]) => {
        return checkPrivileges('ROLE_PACKAGE_UPDATE') 
          ? (checkPrivileges('ROLE_PACKAGE_ARCHIVE') 
              ? actions 
              : actions.filter(action => action.label !== 'delete')) 
          : (checkPrivileges('ROLE_PACKAGE_ARCHIVE') 
              ? actions.filter(action => action.label !== 'edit') 
              : []);
      }

    return(
        <Box>
            <PackagesComponent
                openModal={openModal}
                closeModal={openModal}
                handleSecField={handleSecField}
                secondaryLanguage={secondaryLanguage}
                primaryLanguage={primaryLanguage}
                handleDelete={handleDelete}
                cancelDelete={cancelDelete}
                handleChange={handleChange} 
                handleSubmit={handleSubmit}
                formData={formData}
                taxProceduresData={taxProceduresData?.pages[0] || {}}
                itemGroupsData={itemGroupsData?.pages[0] || {}}
                packageGroupsData={packageGroupsData?.pages[0] || {}}
                handleChangePage={handleChangePage}
                handleChangeRows={handleChangeRows}
                onChangeStatus={onChangeStatus}
                cancelChangeStatus={cancelChangeStatus}
                submitChangeStatus={submitChangeStatus}
                showRowView={showRowView}
                rowView={rowView}
                handleChangeTabs={handleChangeTabs}
                assignUnassignSingleEntity={assignUnassignSingleEntity}
                assignUnassignAllEntities={assignUnassignAllEntities}
                allServicesAssigned={allServicesAssigned}
                allItemsAssigned={allItemsAssigned}
                allStationsAssigned={allStationsAssigned}
                allModelsAssigned={allModelsAssigned}
                handleSearchClick={handleSearchClick}
                handleInputChange={handleInputChange}
                priceListAssignData={priceListAssignData?.pages[0] || {}}
                priceListFormData={priceListFormData}
                handleChangePriceList={handleChangePriceList}
                priceListErrorMessages={priceListErrorMessages}
                clearSearch={clearSearch}
                loadNext={loadNext}
                loadPrev={loadPrev}
                handleDropdownSearch={handleDropdownSearch}
                refetch={refetch}
                columns={checkPrivileges("ROLE_PACKAGE_ACTIVE") ? [
                    { id: 'title', label: 'title', numeric: false },
                    { id: 'packageCode', label: 'packageCode', numeric: false },
                    { id: 'pricingTypePackages', label: 'pricingType', numeric: false },
                    { id: 'itemGroup', label: t('Item'), numeric: false },
                    { id: 'active', label: 'Status', numeric: false },
                    { id: 'actions', label: 'actions', numeric: false },
                ] : [
                  { id: 'title', label: 'title', numeric: false },
                  { id: 'packageCode', label: 'packageCode', numeric: false },
                  { id: 'pricingTypePackages', label: 'pricingType', numeric: false },
                  { id: 'itemGroup', label: t('Item'), numeric: false },
                  { id: 'actions', label: 'actions', numeric: false },
              ]}
                data={tableData?.pages[0] || {}}
                serviceAssignData={serviceAssignData?.pages[0] || {}}
                itemAssignData={itemAssignData?.pages[0] || {}}
                stationAssignData={stationAssignData?.pages[0] || {}}
                modelAssignData={modelAssignData?.pages[0] || {}}
                {...{ openForm, openDeleteModal, enableEdit,showSecField, pageNo, pageSize, statusModal, statusText, isBtnEnabled, loading, handleSearch, search, loadingSearch, searchAssign, loadingSearchAssign, tab, filterAssign, searchMultiAssign, defaultPriceList, loggedInUserCurrency, loadingDropdownSearch, defaultPriceListId, packageGroupsRefetch}}
                errorMessages={errorMessages}
                searchAssignFormData={searchAssignFormData}
                handleChangeAssignFilter={handleChangeAssignFilter}
                handleChangeSearchAssign={handleChangeSearchAssign}
                handleSubmitSearchAssign={handleSubmitSearchAssign}
                searchAssignErrorMessages={searchAssignErrorMessages}
                clearSearchAssign={clearSearchAssign}
                actions1 = {adjustActions([
                  { label: "edit", onClick: onEdit, icon: <EditIcon /> },
                  { label: "delete", onClick: onDelete, icon: <DeleteIcon /> },
                ])}
            />
        </Box>
    )
  }