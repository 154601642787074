import { Box, Grid, Typography } from "@mui/material";
import BackendPaginationTable from "common/Table/TableWithPagination";
import { ReactComponent as Roles } from "assets/SideBarIcons/roles.svg";
import ComponentHeaderTwo from "common/ComponentHeaderTwo/ComponentHeaderTwo";
import RoleModalComponent from "./RolesModal";
import FormDialog from "common/Modal/Modal";
import { useTranslation } from 'react-i18next';
import StyledInput from "common/SearchField/Search";
import Loader from "layout/Loader";
import VerticalTabs from "common/ComponentSidebar/ComponentSideBar";
import Privileges from './Privileges';
import { checkPrivileges } from "Helpers/CheckPrivileges";

interface RoleComponentProps {
  handleSecField: () => void;
  showSecField: boolean;
  primaryLanguage: string;
  secondaryLanguage: string;
  openModal: () => void;
  closeModal: () => void;
  handleChange: (field: string, value: any) => void;
  handleChangePage: (field: number) => void;
  handleChangeRows: (field: number) => void;
  handleSubmit: () => void;
  handleDelete: () => void;
  cancelDelete: () => void;
  search: string;
  handleSearchClick: () => void;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeStatus: (row: any) => void;
  cancelChangeStatus: () => void;
  submitChangeStatus: () => void;
  formData: any;
  openForm: boolean;
  columns: any[];
  data: any;
  errorMessages: any,
  actions1: { label: string; onClick: (value: any) => void; icon?: React.ReactNode }[];
  openDeleteModal: boolean;
  enableEdit?: boolean;
  pageNo: number;
  pageSize: number;
  statusModal: boolean;
  statusText?: string;
  showRowView: (row: any) => void;
  isBtnEnabled: boolean;
  loading: boolean;
  loadingSearch: boolean;
  rowView: boolean;
  handleChangeTabs: (tabId: number) => void;
  assignUnassignSingleEntity: (isChecked: boolean, row: any, scroll: number, accessLimit?: number | null, group?: boolean) => void;
  privilegeAssignData: any;
  loadingAssign: boolean;
  clearSearch: () => void;
  scrollPosition: number;
}

const RoleComponent: React.FC<RoleComponentProps> = ({
  showSecField,
  handleSecField,
  primaryLanguage,
  secondaryLanguage,
  openModal,
  closeModal,
  handleChange,
  handleSubmit,
  formData,
  openForm,
  columns,
  data,
  errorMessages,
  actions1,
  openDeleteModal,
  handleDelete,
  cancelDelete,
  enableEdit,
  handleSearchClick,
  handleInputChange,
  search,
  handleChangePage,
  handleChangeRows,
  onChangeStatus,
  cancelChangeStatus,
  submitChangeStatus,
  statusModal,

  pageNo,
  pageSize,
  isBtnEnabled,
  loading,
  showRowView,
  loadingSearch,
  rowView,
  handleChangeTabs,
  assignUnassignSingleEntity,
  privilegeAssignData,
  loadingAssign,
  clearSearch,
  scrollPosition
}) => {
  const { t } = useTranslation();

  const tabs = [
    {
      id: 0, title: `Edit`,
      child: <RoleModalComponent
        handleSecField={handleSecField}
        showSecField={showSecField}
        secondaryLanguage={secondaryLanguage}
        primaryLanguage={primaryLanguage}
        enableEdit={enableEdit}
        openModal={openModal}
        closeModal={closeModal}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        formData={formData}
        openForm={true}
        errorMessages={errorMessages}
        isBtnEnabled={isBtnEnabled}
      />
    },
    {
      id: 1, title: `Privileges`, child: <Privileges
        privilegeData={privilegeAssignData.data?.content || []}
        assignUnassignSingleEntity={assignUnassignSingleEntity}
        loading={loadingAssign}
        assignPrivilege={checkPrivileges('ROLE_ROLE_PRIVILEGE_ASSIGN')}
        unassignPrivilege={checkPrivileges('ROLE_ROLE_PRIVILEGE_UNASSIGN')}
        {...{scrollPosition}}
      />
    },
  ];
  const disableTabs = tabs.map(tab => tab.id).filter(tabId => {
    switch (tabId) {
      case 0:
        return !checkPrivileges('ROLE_ROLE_UPDATE');
      case 1:
        return !checkPrivileges('ROLE_ROLE_PRIVILEGE_ASSIGN') && !checkPrivileges('ROLE_ROLE_PRIVILEGE_UNASSIGN');
      default:
        return false;
    }
  });

  return (

    <Box>
      <ComponentHeaderTwo
        showBtn={true}
        showSaveBtn={openForm}
        onClick={openModal}
        onSubmit={handleSubmit}
        heading={(openForm && !enableEdit) ? "addRoles" : (enableEdit && openForm || rowView) ? "editRoles" : t('roles')}
        icon={<Roles height={28} width={28} />}
        btnText={openForm || rowView ? "back" : "create"}
        btnType={openForm || rowView ? "back" : "create"}
        isBtnEnabled={isBtnEnabled}
        showCreateBtn={checkPrivileges("ROLE_ROLE_CREATE")}
      // btnType="create"
      />

      {openForm ?
        <RoleModalComponent
          handleSecField={handleSecField}
          showSecField={showSecField}
          secondaryLanguage={secondaryLanguage}
          primaryLanguage={primaryLanguage}
          enableEdit={enableEdit}
          openModal={openModal}
          closeModal={closeModal}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          formData={formData}
          openForm={openForm}
          errorMessages={errorMessages}
          isBtnEnabled={isBtnEnabled}
        />
        : rowView ?
          <VerticalTabs tabs={tabs} selectedIndex={0} handleChangeTabs={handleChangeTabs} disableTabNo={disableTabs} />
          : <Box>
            {loading ? <Loader /> :
              <BackendPaginationTable
                columns={actions1.length ? columns : columns.filter(action => action.id !== 'actions')}
                data={data.data?.content || []}
                pageNo={pageNo}
                pageSize={pageSize}
                tableData={data?.data || {}}
                showCheckbox={false}
                showActions={true}
                enableSorting={true}
                showHeader={true}
                showSearch={true}
                showFilter={true}
                showCreateButton={true}
                actions={actions1}
                handleChangePage={handleChangePage}
                handleChangeRows={handleChangeRows}
                onChangeStatus={onChangeStatus}
                rowClick={disableTabs.length === tabs.length ? false : true}
                showRowView={showRowView}
                loadingSearch={loadingSearch}
                headerComponent={
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={7} lg={5}>
                      <StyledInput
                        fullWidth
                        placeholder={t("title")}
                        handleSearchClick={handleSearchClick}
                        handleChange={handleInputChange}
                        value={search}
                        clearSearch={clearSearch}
                      />
                    </Grid>
                  </Grid>
                }
              />
            }
          </Box>
      }

      {openDeleteModal &&
        <FormDialog
          open={openDeleteModal}
          onClose={cancelDelete}
          title={t('delete') + ' ' + t('role')}
          // icon={<div><Home/></div>}
          content={
            <Grid container >
              <Grid item xs={12} sm={12} md={12} lg={12}  >
                <Typography>
                  {t('Are you sure you want to delete?')}
                </Typography>
              </Grid>
            </Grid>
          }
          saveFunction={handleDelete}
          submitButtonText="Delete"
        />}

      {statusModal &&
        <FormDialog
          open={statusModal}
          onClose={cancelChangeStatus}
          title="Change Status"
          // icon={<div><Home/></div>}
          content={
            <Grid container >
              <Grid item xs={12} sm={12} md={12} lg={12}  >
                <Typography>
                  {t(`areYouSure`)}?
                </Typography>
              </Grid>
            </Grid>
          }
          saveFunction={submitChangeStatus}
          submitButtonText="OK"
        />
      }



    </Box>

  )
}

export default RoleComponent;