import { Grid, TextField } from "@mui/material"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next";
import { ChecklistProfileService } from "subModule/src/core/services";
import AlertMessages from "common/Alert/AlertMessages";
import { useSnackbar } from "notistack";
import SimpleModal from "common/Form/Form";
import { useNavigate, useParams } from "react-router-dom";
import ComponentHeaderTwo from "common/ComponentHeaderTwo/ComponentHeaderTwo";
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import { checkPrivileges } from "Helpers/CheckPrivileges";
import { useEffect, useState } from "react";
import Loader from "layout/Loader";
import { GetChecklistByIdResponse } from "subModule/src/services/checklist/interface";
import VerticalTabs from "common/ComponentSidebar/ComponentSideBar";

interface FormData { [key: string]: any; }
export default function ChecklistModal() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const { register, handleSubmit, formState: { errors, dirtyFields, isDirty }, reset } = useForm<FormData>({ defaultValues: {} });
    const [loading, setLoading] = useState<boolean>(params?.id ? true : false);
    const [IsBtnEnabled, setIsBtnEnabled] = useState<boolean>(params?.id ? false : true);
    useEffect(() => {
        if (params?.id) {
            if (loading) getByID();
        }
    }, [params]);
    useEffect(() => {
        if (isDirty && params?.id) setIsBtnEnabled(true);
    }, [isDirty, dirtyFields]);
    const getByID = () => {
        ChecklistProfileService.getChecklistById(+(params as any)?.id).then((res: GetChecklistByIdResponse) => {
            if (res.success) {
                reset({ ...res?.data || {} });
                setLoading(false);
            } else {
                enqueueSnackbar(<AlertMessages text={res.message || "Something went wrong"} />, { variant: 'error' });
            }
        }).catch((err: any) => { console.error("===", err); });
    }
    const onSubmit = (data: any) => {
        setIsBtnEnabled(false);
        if (params?.id) {
            ChecklistProfileService.updateChecklist(+params?.id, data).then((response: any) => {
                if (response.success) {
                    enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Checklist') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
                    reset({ ...response?.data || {} });
                } else {
                    enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                    setIsBtnEnabled(true);
                }
            }).catch((err: any) => {
                console.error("===", err);
                setIsBtnEnabled(true);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
            });
        } else {
            ChecklistProfileService.createChecklist(data).then((response: any) => {
                if (response.success) {
                    enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Checklist') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
                    navigate('/checklists');
                } else {
                    setIsBtnEnabled(true);
                    enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                }
            }).catch((err: any) => {
                console.error("===", err);
                setIsBtnEnabled(true);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
            });
        }
    };
    const handleChangeTabs = (tabId: number) => {
        if (tabId === 0) navigate(`checklist/${params?.id}`);
        else if (tabId === 1) navigate(`/checklistitems/${params?.id}`);
    }
    const tabs = [
        { id: 0, title: `edit`, child: <></> },
        { id: 1, title: `${t('ChecklistItem')}`, child: <></> },
    ];
    const disableTabs = tabs.map(tab => tab.id).filter(tabId => {
        switch (tabId) {
            case 0:
                return !checkPrivileges('ROLE_CHECKLIST_UPDATE');
            case 1:
                return !checkPrivileges('ROLE_CHECKLISTITEM_VIEW');
            default:
                return false;
        }
    });

    return (<>
        <ComponentHeaderTwo
            showBtn={true}
            showSaveBtn={true}
            onClick={() => navigate('/checklists')}
            onSubmit={handleSubmit(onSubmit)}
            heading={params?.id ? t('editChecklist') : t('addChecklist')}
            icon={<LocalAtmIcon className="text-black w-[34px] h-[34px]" />}
            btnText={"back"}
            btnType={"back"}
            isBtnEnabled={IsBtnEnabled}
            showCreateBtn={checkPrivileges("ROLE_CHECKLIST_CREATE")}
        />
        {loading ? <Loader /> : <div className={`${params?.id ? 'flex' : ''}`}>
            {params?.id ? <VerticalTabs tabs={tabs} selectedIndex={0} handleChangeTabs={handleChangeTabs} disableTabNo={disableTabs} /> : null}
            <SimpleModal
                visable={true}
                title={params?.id ? "editChecklist" : "addChecklist"}
                isButtonVisable={true}
                isVisableBtn={IsBtnEnabled}
                btnclosetext="Back"
                buttonText={params?.id ? "update" : "submit"}
                attBtnNotshow={false}
                notVisableBackbtn={true}
                showPortList={handleSubmit(onSubmit)}
                formSubmit={handleSubmit(onSubmit)}
                content={
                    <Grid container component="form" id="form" className="items-baseline" spacing={3} onSubmit={handleSubmit(onSubmit)}>
                        <Grid item xs={12} md={3}>
                            <TextField
                                {...register("title", { required: t('This field is required.') })}
                                label={t('title') + ' *'}
                                fullWidth
                                error={!!errors.title}
                                helperText={errors.title?.message as string}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                    </Grid>
                }
            />
        </div>}
    </>)
}