import React, { useEffect, useState } from 'react';
import Select, { Props } from 'react-select';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';

interface DynamicSelectProps extends Props<any> {
  label: string;
  onScroll?: (event: React.UIEvent<HTMLDivElement>) => void;
  isRequired?: boolean;
  error?: string;
  isDisabled?: boolean;
}

const DynamicSelect: React.FC<DynamicSelectProps> = ({ error, label, isRequired, isDisabled, ...props }) => {
  const { t, i18n } = useTranslation();
  const [placeholder, setPlaceholder] = useState(t('select'));
  useEffect(() => {
    setPlaceholder(t('select'));
  }, [i18n.language]);
  const CustomMultiValue = (props: any) => (
    <Tooltip title={props.data.label}>
      <div style={{ display: 'flex', alignItems: 'center', borderRadius: '8px', backgroundColor: '#f2f2f2', marginRight: '4px' }}>
        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          {props.data.label}
        </div>
        <CloseIcon fontSize='small' onClick={(e) => props.removeProps.onClick(e)} style={{ marginLeft: '4px', cursor: 'pointer' }} />
      </div>
    </Tooltip>
  );

  const handleFocusAndBlur = (event: any) => {
    setPlaceholder(event.type === 'focus' ? '' : t('select'));
  };

  const isArabic = i18n.language === "ar";
  const isFieldDisabledStyles: React.CSSProperties = isDisabled ? { pointerEvents: 'none', opacity: 0.5 } : {};

  return (
    <div className='text-left' style={isFieldDisabledStyles}>
      <Typography
        className='font-Saira font-[600] text-[14px] text-txt-color line-clamp-1'
        style={{ textAlign: isArabic ? "right" : 'left' }}
        color={error&&!isDisabled ? 'red !important' : 'textPrimary'}
      >
        {t(label)}
        {isRequired && label ? ' *' : ''}
      </Typography>
      <Select
        {...props}
        className='font-Saira italic font-[500] text-[14px] pr-0'
        menuPlacement="auto"
        menuPortalTarget={document.body}
        styles={{
          control: (provided, state) => ({
            ...provided,
            height: '20px',
            textAlign: 'left',
            borderRadius: '8px',
            borderWidth: state.isFocused ? "1px" : "1px",
            borderColor: state.isFocused ? "black" : error ? '#C4C4C4' : '#C4C4C4',
            backgroundColor: "white",
            boxShadow: state.isFocused ? "none" : state.menuIsOpen ? "none" : "none",
            '&:hover': {
              borderColor: 'black',
            },
          }),
          valueContainer: (provided) => ({
            ...provided,
          }),
          singleValue: (provided) => ({
            ...provided,
            textAlign: 'left',
          }),
          option: (provided, state) => ({
            ...provided,
            textAlign: 'left',
            backgroundColor: state.isSelected ? '#4C0055' : state.isFocused ? '#f2f2f2' : provided.backgroundColor,
            '&:hover': {
              backgroundColor: state.isSelected ? '#4C0055' : 'rgba(76, 0, 85, 0.35)',
              color: "white",
            },
          }),
          dropdownIndicator: (provided) => ({
            ...provided,
            borderRadius:'8px',
            width:'100%',
            height:'100%',
            backgroundColor: 'rgba(76, 0, 85, 0.15)',
            color:'black'
          }),
          indicatorSeparator: (base) => ({
            ...base,
            display: 'none', // Hide the indicator separator
          }),
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        }}
        components={{
          MultiValue: CustomMultiValue,
        }}
        onFocus={(e) => { handleFocusAndBlur(e) }}
        onBlur={(e) => { handleFocusAndBlur(e) }}
        placeholder={t(placeholder)}
        loadingMessage={() => <h3>Loading...</h3>}
        isDisabled={isDisabled}
      />
      {!isDisabled && (
        <>
          <HelperText className="font-Saira italic font-[500] text-[12px] text-red-400" error={Boolean(error)}>{error ? t(error) : null}</HelperText>
          <span className="dropdown" style={{ display: 'block', height: '10px' }}></span>
        </>
      )}
    </div>
  );
};

const HelperText = styled('p')<{ error?: boolean }>`
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  color: ${(props) => (props.error ? 'red' : '')};
  text-align: left;
  position: absolute;
`;

export default DynamicSelect;
