// ChecklistItemProfileService.tsx
import { useInfiniteQuery } from '@tanstack/react-query';
import { ChecklistItemProfileService } from '../../core/services';
import { PageSize } from '../../type';

export const useGetChecklistItemService = (initialParams: PageSize, id?: number) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-checklist-items-data'],
      queryFn: async ({ pageParam }) => {
        return ChecklistItemProfileService.getChecklistItem(
          {
            page: initialParams.pageNumber ?? pageParam,
            size: initialParams.size,
            title: initialParams.title,
          },
          id
        );
      },
      enabled: false,
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      retry: 1,
      retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};
