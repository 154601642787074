// ActivityLogProfileService.tsx
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { ActivityLogProfileService } from '../../core/services';
import { PageSize } from '../../type';
import { GetActionsListResponse } from './interface';

export const useGetActivityLogsService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-jobcards-data'],
      queryFn: async ({ pageParam }) => {
        return ActivityLogProfileService.getActivityLogs({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          itemType: initialParams.itemType,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      enabled: false,
      // retry: 1,
      // retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useGetActionsListService = () => {
  const { data, isLoading, error, refetch } = useQuery<GetActionsListResponse, unknown>({
    queryKey: ['get-actions-list-data'],
    queryFn: async () => {
      return ActivityLogProfileService.getActionsList();
    },
    enabled: false,
  });

  return {
    data,
    isLoading,
    error,
    refetch,
  };
};
