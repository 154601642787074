import SimpleModal from 'common/Form/Form';
import StyledField from 'common/TextField/TexField1';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ClientGroupModalComponentProps {
  openModal: () => void;
  closeModal: () => void;
  handleChange: (field: string, value: any) => void;
  handleSubmit: () => void;
  handleSecField: () => void;
  showSecField: boolean;
  primaryLanguage: string;
  secondaryLanguage: string;
  formData: any;
  openForm: boolean;
  errorMessages: { [key: string]: string };
  enableEdit?: boolean;
  isBtnEnabled: boolean;
}

const ClientGroupModalComponent: React.FC<ClientGroupModalComponentProps> = ({
  closeModal,
  handleChange,
  handleSubmit,
  formData,
  openForm,
  enableEdit,
  errorMessages,
  showSecField,
  handleSecField,
  primaryLanguage,
  secondaryLanguage,
  isBtnEnabled,
}) => {

  const { t } = useTranslation();
  return (
    <div>
      <SimpleModal
        visable={openForm}
        // title={enableEdit ? "updateClientGroup" : "addClientGroup"}
        isButtonVisable={true}
        isVisableBtn={isBtnEnabled}
        btnclosetext="Back"
        buttonText={enableEdit ? "update" : "submit"}
        attBtnNotshow={false}
        notVisableBackbtn={true}
        showPortList={handleSubmit} // Assuming this is the Submit button
        modalControle={closeModal}
        // attributeChangesMessage="Attribute changes message"
        // showPort={true}
        formSubmit={handleSubmit}
        content={
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <StyledField
                label={`${!primaryLanguage ? t('primary') : ''} ${t('title')} ${primaryLanguage ? ` (${t(primaryLanguage)})` : ''}`}
                handleChange={(e) => handleChange('title', e.target.value)}
                required={true}
                search='language'
                handleSecField={handleSecField}
                fullWidth
                error={errorMessages?.title}
                value={formData?.title || ''}
              />
              {showSecField &&
                <Grid item xs={12} sm={12} md={12} lg={12} >
                  <div style={{ position: 'relative' }}>
                    <div
                      style={{
                        position: 'absolute',
                        top: -8,
                        left: 0,
                        zIndex: 1,
                        display: showSecField ? 'flex' : 'none',
                        backgroundColor: "white",
                        height: '5rem',
                        width: '100%',
                        justifyContent: 'center',
                        borderRadius: '8px',
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
                      }}
                    >
                      <div className='w-full mx-2 mt-1'>
                        <StyledField
                          label={`${!secondaryLanguage ? t('secondary') : ''} ${t('title')} ${secondaryLanguage ? ` (${t(secondaryLanguage)})` : ''}`}
                          handleChange={(e) =>
                            handleChange('secondaryTitle', e.target.value)
                          }
                          required={false}
                          fullWidth
                          // error={errorMessages?.secondaryTitle}
                          value={formData?.secondaryTitle || ''}
                        />
                      </div>
                    </div>
                  </div>
                </Grid>
              }
            </Grid>
          </Grid>
        }
      />
    </div>
  );
};

export default ClientGroupModalComponent;
