import { Box, Button, Grid, InputAdornment, TextField } from "@mui/material"
import { Controller, useFieldArray, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next";
import { ChecklistItemProfileService } from "subModule/src/core/services";
import AlertMessages from "common/Alert/AlertMessages";
import { useSnackbar } from "notistack";
import SimpleModal from "common/Form/Form";
import { useNavigate, useParams } from "react-router-dom";
import ComponentHeaderTwo from "common/ComponentHeaderTwo/ComponentHeaderTwo";
import ChecklistIcon from '@mui/icons-material/Checklist';
import { checkPrivileges } from "Helpers/CheckPrivileges";
import { useEffect, useState } from "react";
import Loader from "layout/Loader";
import { GetChecklistItemByIdResponse } from "subModule/src/services/checklistitem/interface";
import VerticalTabs from "common/ComponentSidebar/ComponentSideBar";
import CLHeader from "./CLHeader";
import { ReactComponent as Inspection } from "assets/SideBarIcons/inspection.svg";
import DynamicSelect from "common/Select/Select";
import { CloseOutlined } from "@mui/icons-material";

interface FormData { [key: string]: any;}
export default function ChecklistItemModal() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const { register, control, handleSubmit, formState: { errors, dirtyFields, isDirty }, reset, watch, getValues } = useForm<FormData>({defaultValues: {
        options: []
    }});
    const { fields, append, remove } = useFieldArray({ control, name: 'options', });
    const [loading, setLoading] = useState<boolean>(params?.id ? true : false);
    const [IsBtnEnabled, setIsBtnEnabled] = useState<boolean>(params?.id ? false : true);
    useEffect(() => {
        if(params?.id) {
            if(loading) getByID();
        }
    }, [params]);
    useEffect(() => {
        if (isDirty && params?.id) setIsBtnEnabled(true);
      }, [isDirty, dirtyFields]);
    const getByID = () => {
        ChecklistItemProfileService.getChecklistItemById(+(params as any)?.id).then((res: GetChecklistItemByIdResponse) => {
            if(res.success) {
                reset({ 
                    ...res?.data || {}, 
                    type: { value: res.data?.type, label: res?.data?.type === 'select' ? t('dropdown') : t(res?.data?.type || '') },
                });
                setLoading(false);
            } else {
                enqueueSnackbar(<AlertMessages text={res.message || "Something went wrong"} />, { variant: 'error' });
            }
        }).catch((err:any) => { console.error("===", err); });
    }
    const types = [
        { value: 'radio', label: t('radio') },
        { value: 'select', label: t('dropdown') },
        { value: 'number', label: t('number') },
        { value: 'text', label: t('text') },
        { value: 'checkbox', label: t('checkbox') },
        { value: 'date', label: t('date') },
        { value: 'textarea', label: t('textarea') },
    ];
    const onSubmit = (data: any) => {
        setIsBtnEnabled(false);
        if(params?.id) {
            ChecklistItemProfileService.updateChecklistItem(+params?.id, {
                ...data, type: data.type.value, 
                multiple: data.type.value == 'select' || data.type.value === 'checkbox' || data.type.value === 'radio' ? true : false,
                checkListId: +(params as any)?.clid,
            }).then((response: any) => {
                if(response.success) {
                    enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('ChecklistItem') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
                    navigate(`/checklistitems/${params?.clid}`);
                } else {
                    enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                }
                setIsBtnEnabled(true);
            }).catch((err:any) => { 
                console.error("===", err);
                setIsBtnEnabled(true);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
            });
        } else {
            ChecklistItemProfileService.createChecklistItem({
                ...data, type: data.type.value, 
                multiple: data.type.value == 'select' || data.type.value === 'checkbox' || data.type.value === 'radio' ? true : false,
                checkListId: +(params as any)?.clid,
                value: null,
                active: true
            }).then((response: any) => {
                if(response.success) {
                    enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('ChecklistItem') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
                    navigate(`/checklistitems/${params?.clid}`);
                } else {
                    setIsBtnEnabled(true);
                    enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                }
            }).catch((err:any) => { 
                console.error("===", err);
                setIsBtnEnabled(true);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
            });
        }
    };
    const handleChangeTabs = (tabId: number) => {
        if (tabId === 0)  navigate(`/checklist/${params?.clid}`); 
        else if (tabId === 1) navigate(`/checklistitems/${params?.clid}`);
    }
    const tabs = [
        { id: 0, title: `edit`, child: <></> },
        { id: 1, title: `${t('ChecklistItem')}`, child: <></> },
    ];
    const disableTabs = tabs.map(tab => tab.id).filter(tabId => {
        switch (tabId) {
            case 0:
                return !checkPrivileges('ROLE_CHECKLIST_UPDATE');
            case 1:
                return !checkPrivileges('ROLE_CHECKLISTITEM_VIEW');
            default:
                return false;
        }
    });

    return (<>
        <ComponentHeaderTwo
            showBtn={true}
            showSaveBtn={false}
            onClick={() => navigate(`/checklists`)}
            heading={t('Checklists')}
            icon={<ChecklistIcon sx={{ fill: '#000', width: '30px', height: '30px', marginTop: '3px' }} />}
            btnText={"back"}
            btnType={"back"}
            showCreateBtn={checkPrivileges("ROLE_CHECKLIST_CREATE")}
        />
        {loading ? <Loader /> : <Box className='flex'>
            <VerticalTabs tabs={tabs} selectedIndex={1} handleChangeTabs={handleChangeTabs} disableTabNo={disableTabs} />
            <div className='w-[100%] mt-[10px]'>
                <CLHeader
                    showBtn={false}
                    showSaveBtn={true}
                    onClick={() => navigate(`/checklistitems/${params?.clid}`)}
                    onSubmit={handleSubmit(onSubmit)}
                    heading={params?.id ? t('editChecklistItem') : t('addChecklistItem')}
                    icon={<Inspection height={28} width={28} />}
                    btnText={"back"}
                    btnType={"back"}
                    isBtnEnabled={IsBtnEnabled}
                    showCreateBtn={checkPrivileges("ROLE_CHECKLISTITEM_CREATE")}
                />
                <SimpleModal
                    visable={true}
                    title={params?.id ? "editChecklistItem" : "addChecklistItem"}
                    isButtonVisable={true}
                    isVisableBtn={IsBtnEnabled}
                    btnclosetext="Back"
                    buttonText={params?.id ? "update" : "submit"}
                    attBtnNotshow={false}
                    notVisableBackbtn={true}
                    showPortList={handleSubmit(onSubmit)}
                    formSubmit={handleSubmit(onSubmit)}
                    content={ 
                        <Grid container component="form" id="form" className="items-baseline" rowSpacing={1} columnSpacing={2} onSubmit={handleSubmit(onSubmit)}>
                            <Grid item xs={12} md={3}>
                                <TextField 
                                    {...register("title", { required: t('This field is required.') })} 
                                    label={t('title') + ' *'}
                                    fullWidth
                                    error={!!errors.title} 
                                    helperText={errors.title?.message as string} 
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Controller
                                    name="type"
                                    control={control}
                                    defaultValue={null}
                                    rules={{ required: t("This field is required.") }}
                                    render={({ field }) => (
                                        <DynamicSelect
                                            label={t("type") + ' *'}
                                            {...field}
                                            options={types}
                                            isSearchable
                                            onChange={(selectedOption: any) => { 
                                                field.onChange(selectedOption);
                                                if (selectedOption?.value === 'select' || selectedOption?.value === 'checkbox' || selectedOption?.value === 'radio') {
                                                    reset({ ...getValues(), options: [''] });
                                                } else {
                                                    reset({ ...getValues(), options: [] });
                                                }
                                                if(!IsBtnEnabled) setIsBtnEnabled(true);
                                            }}
                                            error={errors.type?.message as string}
                                        />
                                    )}
                                />
                            </Grid>
                            {(watch('type')?.value === 'select' || watch('type')?.value === 'checkbox' || watch('type')?.value === 'radio') && (<>
                                <Grid container item xs={12} rowSpacing={1} columnSpacing={2}>
                                    {fields.map((field, index) => (
                                        <Grid item xs={12} md={3} key={field.id}>
                                            <TextField
                                                {...register(`options.${index}`, { required: t('This field is required.') })}
                                                label={`${t('option')} ${index + 1}`}
                                                fullWidth
                                                error={!!(errors as any).options?.[index]}
                                                helperText={(errors as any).options?.[index]?.message}
                                                InputProps={{ endAdornment: index !==0 ? <InputAdornment position="end" onClick={() => remove(index)}><CloseOutlined /></InputAdornment> : null, }}
                                            />
                                        </Grid>
                                    ))}
                                    <Grid item xs={12} md={3} className="text-left">
                                        <Button 
                                            onClick={() => append('')} 
                                            className="mt-[30px] font-Saira text-primary-color font-semibold italic text-[13px] border-0 p-0 bg-transparent capitalize"
                                        >{t('addAnotherOption')}</Button>
                                    </Grid>
                                </Grid>
                            </>)}
                        </Grid>
                    }
                />
            </div>
        </Box>}
    </>)
}