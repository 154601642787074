import { Box, Grid, Typography } from "@mui/material";
import BackendPaginationTable from "common/Table/TableWithPagination";
import { ReactComponent as Groups  } from "assets/SideBarIcons/Groups.svg";
import ComponentHeaderTwo from "common/ComponentHeaderTwo/ComponentHeaderTwo";
import ItemGroupModalComponent from "./ItemGroupModal";
import FormDialog from "common/Modal/Modal";
import { useTranslation } from 'react-i18next';
import StyledInput from "common/SearchField/Search";
import Loader from "layout/Loader";
import VerticalTabs from "common/ComponentSidebar/ComponentSideBar";
import ItemGroupItemSearchHeader from "./ItemGroupItemSearchHeader";
import { checkPrivileges } from "Helpers/CheckPrivileges";

interface ItemGroupComponentProps {
  handleSecField: () => void;
  showSecField: boolean;
  primaryLanguage:string;
  secondaryLanguage:string;
  openModal: () => void;
  closeModal: () => void;
  handleChange: (field: string, value: any) => void;
  handleChangePage: (field: number) => void;
  handleChangeRows: (field: number) => void;
  handleSubmit: () => void;
  handleDelete:  () => void;
  cancelDelete:  () => void;
  search: string;
  handleSearchClick: () => void;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  statusModal: boolean;
  statusText?: string;
  onChangeStatus: (row: any) => void;
  cancelChangeStatus: () => void;
  submitChangeStatus: () => void;
  formData: any;
  openForm: boolean;
  columns: any[]; 
  data: any; 
  errorMessages: any,
  actions1: { label: string; onClick: ( value: any) => void; icon?: React.ReactNode }[];
  openDeleteModal: boolean;
  enableEdit?:boolean;
  pageNo:number;
  pageSize:number;
  showRowView: (row: any) => void;
  isBtnEnabled: boolean;
  loading: boolean;
  loadingSearch: boolean;
  rowView: boolean;
  handleChangeTabs: (tabId: number) => void;
  assignUnassignSingleEntity: (isChecked: boolean, row: any) => void;
  assignUnassignAllEntities: (isChecked: boolean) => void;
  allItemsAssigned: unknown;
  itemAssignData: any;
  searchFormData: any;
  handleChangeFilter: (event: any) => void;
  handleChangeSearch: (field: string, value: any) => void;
  handleSubmitSearch: () => void;
  searchErrorMessages: any;
  filter: string[];
  clearSearchAssign: () => void;
  searchItem: any;
  loadingItem:boolean;
  clearSearch: () => void;
}

const ItemGroupComponent: React.FC <ItemGroupComponentProps> = ({
  showSecField,
  handleSecField,
  primaryLanguage,
  secondaryLanguage,
  openModal,
  closeModal,
  handleChange,
  handleSubmit,
  formData,
  openForm,
  columns,
  data,
  errorMessages,
  actions1,
  openDeleteModal,
  handleDelete,
  cancelDelete,
  enableEdit,
  handleSearchClick,
  handleInputChange,
  search,
  handleChangePage,
  handleChangeRows,
  statusModal,

  onChangeStatus,
  cancelChangeStatus,
  submitChangeStatus,
  pageNo,
  pageSize,
  isBtnEnabled,
  loading,
  showRowView,
  loadingSearch,
  rowView,
  handleChangeTabs,
  assignUnassignSingleEntity,
  assignUnassignAllEntities,
  allItemsAssigned,
  itemAssignData,
  searchFormData,
  searchItem,
  handleChangeFilter,
  handleChangeSearch,
  handleSubmitSearch,
  searchErrorMessages,
  clearSearchAssign,
  filter,
  loadingItem,
  clearSearch
}) => {
  const { t } = useTranslation();
  const itemColumns = [
    { id: 'title', label: 'name', numeric: false },
    { id: 'itemCode', label: 'itemCode', numeric: false },
    { id: 'itemCost', label: 'itemCost', numeric: false },
    { id: 'partNo', label: 'partNo', numeric: false },
  ]
  const childTabContent = (columns:any, data:any, tableData:any, allEntitiesAssigned:unknown, assignPrivilege: string, unassignPrivilege: string) => {
    return (
      <BackendPaginationTable 
        columns={columns}
        data={data}
        pageNo={pageNo}
        pageSize={pageSize}
        tableData={tableData}
        showCheckbox={true}
        showActions={true}
        enableSorting={true}
        showHeader={true}
        showSearch={true}
        showFilter={true}
        showCreateButton={false}
        actions={[]}
        handleChangePage={handleChangePage}
        handleChangeRows={handleChangeRows}
        assignUnassignSingleEntity={assignUnassignSingleEntity}
        assignUnassignAllEntities={assignUnassignAllEntities}
        allEntitiesAssigned={allEntitiesAssigned}
        loadingSearch={loadingItem}
        assignPrivilege={checkPrivileges(assignPrivilege)}
        unassignPrivilege={checkPrivileges(unassignPrivilege)}
        headerComponent={
          <ItemGroupItemSearchHeader 
            searchFormData={searchFormData}
            handleChangeFilter={handleChangeFilter}
            handleChangeSearch={handleChangeSearch}
            handleSubmitSearch={handleSubmitSearch}
            searchErrorMessages={searchErrorMessages}
            clearSearch={clearSearchAssign}
            search={searchItem}
            {...{filter}}
          />
        }
      />
    );
  }

  const tabs = [
    { 
      id: 0, title: `Edit`, 
      child: <ItemGroupModalComponent
      handleSecField={handleSecField}
      showSecField={showSecField}
      secondaryLanguage={secondaryLanguage}
      primaryLanguage={primaryLanguage}
        enableEdit={enableEdit}
        openModal={openModal}
        closeModal={closeModal}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        formData={formData}
        openForm={true}
        errorMessages={errorMessages}
        isBtnEnabled={isBtnEnabled}
      />
    },
    { id: 1, title: `items`, child: childTabContent(itemColumns, itemAssignData.data?.content || [], itemAssignData?.data || {}, allItemsAssigned, 'ROLE_ITEMGROUP_ITEM_ASSIGN', 'ROLE_ITEMGROUP_ITEM_UNASSIGN')},
  ];
  const disableTabs = tabs.map(tab => tab.id).filter(tabId => {
    switch (tabId) {
      case 0:
        return !checkPrivileges('ROLE_ITEMGROUP_UPDATE');
      case 1:
        return !checkPrivileges('ROLE_ITEMGROUP_ITEM_ASSIGN') && !checkPrivileges('ROLE_ITEMGROUP_ITEM_UNASSIGN');
      default:
        return false;
    }
  });

    return(

        <Box>
          <ComponentHeaderTwo
            showBtn={true}
            showSaveBtn={openForm}
            onClick={openModal}
            onSubmit={handleSubmit}
            heading={(openForm&&!enableEdit)?"addItemGroup":(enableEdit&&openForm||rowView)?"editItemGroup":t('itemsGroup')}
            icon={<Groups height={30} width={30} />}
            btnText={openForm || rowView ? "back" : "create"}
            btnType={openForm || rowView ? "back" : "create"}
            isBtnEnabled={isBtnEnabled}
            showCreateBtn={checkPrivileges("ROLE_ITEMGROUP_CREATE")}
            // btnType="create"
          />

          {openForm ? 
            <ItemGroupModalComponent
              enableEdit={enableEdit}
              handleSecField={handleSecField}
              showSecField={showSecField}
              secondaryLanguage={secondaryLanguage}
              primaryLanguage={primaryLanguage}
              openModal={openModal}
              closeModal={closeModal}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              formData={formData}
              openForm={openForm}
              errorMessages={errorMessages} 
              isBtnEnabled={isBtnEnabled}
            />
            : rowView ?
              <VerticalTabs tabs={tabs} selectedIndex={0} handleChangeTabs={handleChangeTabs} disableTabNo={disableTabs} />
              : <Box>
                  {loading ? <Loader /> :
                    <BackendPaginationTable
                      columns={actions1.length ? columns : columns.filter(action => action.id !== 'actions')}
                      data={data.data?.content || []}
                      pageNo={pageNo}
                      pageSize={pageSize}
                      tableData={data?.data || {}}
                      showCheckbox={false}
                      showActions={true}
                      enableSorting={true}
                      showHeader={true}
                      showSearch={true}
                      showFilter={true}
                      showCreateButton={true}
                      actions={actions1}
                      handleChangePage={handleChangePage}
                      handleChangeRows={handleChangeRows}
                      onChangeStatus={onChangeStatus}
                      rowClick={disableTabs.length === tabs.length ? false : true}
                      showRowView={showRowView}
                      loadingSearch={loadingSearch}
                      headerComponent={
                      <Grid container spacing={2}>  
                        <Grid item xs={12} md={7} lg={5}>
                          <StyledInput
                            fullWidth
                            placeholder={t("title")}
                            handleSearchClick={handleSearchClick}
                            handleChange={handleInputChange}
                            value={search}
                            clearSearch={clearSearch}
                          />
                        </Grid>
                      </Grid>
                      }
                    />
                  }
                </Box>
              }

      {openDeleteModal && 
        <FormDialog
          open={openDeleteModal}
          onClose={cancelDelete}
          title={t('delete') + ' ' + t('itemGroup')}
          // icon={<div><Home/></div>}
          content={  
            <Grid container >
              <Grid item  xs={12} sm={12} md={12} lg={12}  >
                <Typography>
                  {t('Are you sure you want to delete?')}
                </Typography>
              </Grid>
            </Grid>
          }
          saveFunction={handleDelete}
          submitButtonText="Delete"
        />
      }
      {statusModal && 
          <FormDialog
            open={statusModal}
            onClose={cancelChangeStatus}
            title="Change Status"
            // icon={<div><Home/></div>}
            content={  
              <Grid container >
                <Grid item  xs={12} sm={12} md={12} lg={12}  >
                  <Typography>
                  {t(`areYouSure`)}?
                  </Typography>
                </Grid>
              </Grid>
            }
            saveFunction={submitChangeStatus}
            submitButtonText="OK"
          />
        }
        


        </Box>

    )
}

export default ItemGroupComponent;