import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import StationGroupComponent from 'components/StationGroup/Index';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { StationGroupRequest } from 'subModule/src/services/stationgroup/interface';
import { useStationGroupService,useDeleteStationGroupService,useGetStationGroupService,useUpdateStationGroupService,useChangeStatusStationGroupService } from 'subModule/src/services/stationgroup/useStationGroup';
// import { useGetStationService } from 'subModule/src/services/station/useStation';
import { useGetStationGroupStationService, useAssignStationService, useUnassignStationService, useAssignAllStationService, useUnassignAllStationsService } from 'subModule/src/services/stationgroup/useStationGroup';
import { useSnackbar } from 'notistack';
import AlertMessages from 'common/Alert/AlertMessages';
import { useTranslation } from 'react-i18next';
import { SelectChangeEvent } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectUserInfo } from 'features/user/userSlice';
import { checkPrivileges } from 'Helpers/CheckPrivileges';

interface FormData {
    title: string,
    secondaryTitle: string,
    id: number,
    active: boolean,
    default: boolean
}
interface SearchAssignFormData {
  [key: string]: string;
}

  const requiredFields = ['title'];

  export default function StationGroup () {
    const [showSecField, setShowSecField] = useState<boolean>(false);
    const [openForm, setOpenForm] = useState(false);
    const [openDeleteModal, setDeleteModal] = useState(false);
    const [enableEdit, setEnableEdit] = useState(false);
    const [pageNo, setPageNo] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);
    const [statusModal, setStatusModal] = useState(false);
    const [statusText, setStatusText] = useState<string>('');
    const [rowView, setRowView] = useState(false);
    const [stationGroupId, setstationGroupId] = useState<number>(0);
    const [tab, setTab] = useState<number>(0);
    const [searchTerm, setSearchTerm] = useState<any>(null);
    const [search, setSearch] = useState('');
    const [isBtnEnabled, setIsBtnEnabled] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
    const [prevFormData, setPrevFormData] = useState<object>({});
    const [selectedRow, setSelectedRow] = useState<any>({});
    // const [data] = useState(rows);
    const [formData, setFormData] = useState<FormData>({
        title: '',
        secondaryTitle: '',
        id: 0,
        default:false,
        active: false
    });
    const [searchAssignFormData, setSearchAssignFormData] = useState<SearchAssignFormData>({
      name: '',
      phone: '',
      email: '',
      stationCode: '',
    });
    const [filterAssign, setFilterAssign] = useState<string[]>(['name']);
    const [searchAssignErrorMessages, setSearchAssignErrorMessages] = useState<Partial<SearchAssignFormData>>({});
    const [searchAssign, setSearchAssign] = useState<any>({});
    const [loadingSearchAssign, setLoadingSearchAssign] = useState<boolean>(false);

    const { data: tableData,  isLoading, error,  refetch } = searchTerm ? useGetStationGroupService({ pageNumber: pageNo, size: pageSize, title: searchTerm }) : useGetStationGroupService({ pageNumber: pageNo, size: pageSize });

    const { data: stationAssignData, isLoading: stationAssignIsLoading, error: stationAssignError, refetch: stationAssignRefetch } = useGetStationGroupStationService({pageNumber: pageNo, size: pageSize, ...searchAssign}, stationGroupId);

    // const { data: stationData,  isLoading: stationIsLoading, error: stationError,  refetch: stationRefetch } = useGetStationService({pageNumber : pageNo,size: pageSize});
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const loggedInUserData = useSelector(selectUserInfo);
    const primaryLanguage = loggedInUserData?.primaryLanguage;
    const secondaryLanguage = loggedInUserData?.secondaryLanguage;

    let allStationsAssigned:unknown = stationAssignData?.pages[0].data?.content.map(station => station.linked).every(linked => linked === true);
    
    useEffect(() => {
      if (!isLoading && !error && !rowView && !openForm && !loadingSearch)  { // Only refetch if not already loading or in error state
        refetch();
      }
      if(tableData) {
        setLoading(false);
        if((tableData?.pages[0] as any).status && !(tableData?.pages[0] as any).success) {
          enqueueSnackbar(<AlertMessages statusCode={(tableData?.pages[0] as any)?.status} />, { variant: 'error' })
        }
      }
      if(tab === 0 && enableEdit) {
        setIsBtnEnabled(false);
      }
      if(!stationAssignIsLoading && !stationAssignError && stationGroupId && stationGroupId !== 0 && tab === 1) {
        const loadData = async() => {
          setLoadingSearchAssign(true);
          await stationAssignRefetch();
          setLoadingSearchAssign(false);
        }
        loadData();
      }
    }, [pageNo, pageSize, refetch, isLoading, error,tableData, stationGroupId, tab, openForm]);

    useEffect(() => {
      if(enableEdit && JSON.stringify(formData) !== JSON.stringify(prevFormData) || openForm && JSON.stringify(formData) !== JSON.stringify(prevFormData)) {        
        setIsBtnEnabled(true);
      } else if(enableEdit && JSON.stringify(formData) === JSON.stringify(prevFormData)) {
        setIsBtnEnabled(false);
      }
    }, [enableEdit, formData]);

    useEffect(() => {
      const getData = setTimeout(async() => {
        if (searchTerm !== null && !isLoading && !error && loadingSearch) {
          await refetch();
          setLoadingSearch(false);
        }
      }, 1000);
      return () => clearTimeout(getData)
    }, [searchTerm, loadingSearch]);

    useEffect(() => {
      const getData = setTimeout(async() => {
        if (searchAssign && loadingSearchAssign && tab === 1) {
          await stationAssignRefetch();
          setLoadingSearchAssign(false);
        }
      }, 1000);
      return () => clearTimeout(getData)
    }, [searchAssign]);
    
    const handleChangePage = (pageNo : number) => {  
      setLoading(true);
      setPageNo(pageNo + 1);
      setPageSize(pageSize);
    }
    const handleChangeRows = (pageSize : number) => {
      setLoading(true);
      setPageSize(pageSize);
      setPageNo(1);
    }

    const [errorMessages, setErrorMessages] = useState<Partial<FormData>>({});

    const openModal = () => {
      if(rowView) {
        setRowView(false);
        setstationGroupId(0);
        setPageNo(1);
        setTab(0);
        setSearchAssign({});
      } 
      else {
        setIsBtnEnabled(true);
        setOpenForm(!openForm);
        setShowSecField(false)
        setSearch('');
        setSearchTerm('');
        openModalSettings();
      }
    };
      const openModalSettings = () => {
        setEnableEdit(false);
        setErrorMessages({})
        setFormData({
          title: '',
          secondaryTitle: '',
          id: 0,
          default:false,
          active: false
        });
      }

      const handleSecField = () => {
        setShowSecField((prevValue) => !prevValue);
      }

      const handleChange = (field: string, value: any) => {
        const  val = (field === "stationId" || field === "stationGroupId") ? 
                    (value ? parseInt(value.value) || 0 : 0) 
                    : value;

        setFormData((prevData) => ({
          ...prevData,
          [field]: val,
        }));
      
        if (requiredFields.includes(field) && !val) {
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: 'This field is required.',
          }));
        } else {
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: undefined,
          }));
        }
        if(enableEdit) {
          setIsBtnEnabled(true);
        }
      };
      
    
      const validateFormData = (data: FormData) => {
        const errors: Partial<FormData> = {};

        if (!data.title?.trim()) {
          errors.title = t('This field is required.');
        }
    
        return errors;
      };

      const { onCreateStationGroup,  } = useStationGroupService({
        onSuccess: (msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          if(response.success) {
            setOpenForm(false);
            refetch();
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('stationGroup') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err:any) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
            console.error(err.stack);
          }
      }})

      const {onUpdateStationGroup } = useUpdateStationGroupService({
        onSuccess: (data) => {
          const response: any = {};
          Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
          if(!rowView) {
            setOpenForm(false);
            setEnableEdit(false);
            refetch();
          }
          if(rowView) { setPrevFormData(response?.data); setSelectedRow(response?.data); }
          if(response.success) {
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('stationGroup') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err: any) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
        id: formData.id,
      }
      );

      const handleSubmit = () => {
        const errors = validateFormData(formData);
        setErrorMessages(errors);
   
        if (Object.keys(errors).length === 0) {
          setIsBtnEnabled(false);
          setLoading(true);
          const stationGroupData: StationGroupRequest = {
            ...formData, 
            title: formData.title,
            secondaryTitle: formData.secondaryTitle,
            id: formData.id,
            default:formData.default,
            active: formData.active
          };
     
          if (enableEdit) {
            onUpdateStationGroup(
              stationGroupData
            );
          } else {
            onCreateStationGroup(stationGroupData);
          }
        }
      };

      const onEdit = (row: any) => {
        setEnableEdit(true);
        setFormData(row);
        setPrevFormData(row);
        setIsBtnEnabled(false);
        setOpenForm(true);
        setSearch('');
        setSearchTerm('');
      };

      const onDelete = (row:any) => {
        setFormData(row)
        setDeleteModal(true)
      }

      const { onDeleteStationGroup } = useDeleteStationGroupService({
        id: formData.id,
        onSuccess: (msg) => {
          if(msg.success) {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('stationGroup') + ' ' + t('deleted') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
          }
          refetch(); // This will use the updated pageNo and pageSize
          if(tableData?.pages[0].data.content.length === 1) {
            handleChangePage(pageNo - 1)
          }
          // stationRefetch()
        },
        onError: (err: any) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
      });

      const handleDelete = () => {
        setDeleteModal(false)
        setLoading(true);
        onDeleteStationGroup()
      }
      const cancelDelete = () => {
        setDeleteModal(false)
      }

      const clearSearch = () => {
        setSearch('');
        setSearchTerm('');
        if(searchTerm) { handleSearch(''); }
      }
      const handleSearch = (search: string) => {
        if(!rowView) {
          setLoadingSearch(true);
          setSearchTerm(search);
          setPageNo(1);
          setPageSize(pageSize);
        } else {
          setLoadingSearchAssign(true);
          setSearchAssign(search);
          setPageNo(1);
          setPageSize(pageSize);
        }
      };
    
      const handleSearchClick = () => {
        if(!rowView) {
          const trimmedSearch = search.trim();
          if (trimmedSearch !== '') {
            handleSearch(trimmedSearch);
          }
        } else {
          const trimmedSearch = searchAssign.trim();
          if (trimmedSearch !== '') {
            handleSearch(trimmedSearch);
          }
        }
      };
    
      const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(!rowView) {
          setSearch(event.target.value.trim());
          if (!event.target.value.trim()) handleSearch('');
        } else {
          setSearchAssign(event.target.value.trim());
          if (!event.target.value.trim()) handleSearch('');
        }
      };


      const onChangeStatus = (row: any) => {
        row.active ? setStatusText('deactivate') : setStatusText('activate');
        setFormData(row);
        setStatusModal(true);
      }
      const cancelChangeStatus = () => {
        setStatusModal(false);
      }
      const submitChangeStatus = () => {
        setStatusModal(false);
        setLoading(true);
        onChangeStatusStationGroup({});
      }
      const { onChangeStatusStationGroup } = useChangeStatusStationGroupService({
        id: formData.id,
        onSuccess: (msg) => {
          if(msg.success) {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('stationGroup') + ' ' + t(statusText) + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
          }
          refetch(); // This will use the updated pageNo and pageSize
          // stationRefetch();
        },
        onError: (err) => {
          console.error("===", err)
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
        active: formData.active ? "inactive" : "active"
      });
      const showRowView = (row: any) => {
        setEnableEdit(true);
        setOpenForm(false);
        setFormData(row);
        setPrevFormData(row);
        setErrorMessages({});
        setRowView(true);
        setstationGroupId(row.id);
        setSelectedRow(row);
        setSearch('');
        setSearchTerm('');
        clearSearchAssign();
      }

      const handleChangeTabs = (tabId: number) => {
        setSearchAssign({});
        if(tabId === 0) {
          setTab(0);
          setEnableEdit(true);
          setOpenForm(false);
          setFormData(selectedRow);
          setErrorMessages({});
          // priceListRefetch();
          setIsBtnEnabled(false);
          // businessGroupRefetch();
        } else if(tabId === 1) {
          setTab(1);
          setPageNo(1);
          setEnableEdit(false);
          setErrorMessages({});
          clearSearchAssign();
        } 
      }
      const assignUnassignSingleEntity = (isChecked: boolean, row: any) => {
        setLoadingSearchAssign(true);
        if(isChecked) {
          if(tab === 1) {
            onAssignStation({ stationGroupId: stationGroupId, stationId: row.id });
          }
        } else {
          if(tab === 1) {
            onUnassignStation({ stationGroupId: stationGroupId, stationId: row.id });
          }
        }
      }
      const assignUnassignAllEntities = (isChecked: boolean) => {
        setLoadingSearchAssign(true);
        if(isChecked) {
          if(tab === 1) {
            onAssignAllStations({stationGroupId: stationGroupId});
          }
        } else {
          if(tab === 1) {
            onUnassignAllStations({stationGroupId: stationGroupId});
          }
        }
      }

      const { onAssignStation } = useAssignStationService({
        onSuccess: async(msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          if(response.success) {
            if(stationAssignData?.pages[0].data.content.length === stationAssignData?.pages[0].data.content.filter(station => station.linked).length) {
              allStationsAssigned = true;
            }
            await stationAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('station') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await stationAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err:any) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
            console.error(err.stack);
          }
      }})
      const { onUnassignStation } = useUnassignStationService({
        onSuccess: async(data) => {
          const response: any = {};
          Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
          if(response.success) {
            if(stationAssignData?.pages[0].data.content.length !== stationAssignData?.pages[0].data.content.filter(station => station.linked).length) {
              allStationsAssigned = false;
            }
            await stationAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('station') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await stationAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err: any) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
      });
      const { onAssignAllStations } = useAssignAllStationService({
        onSuccess: async(msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          if(response.success) {
            allStationsAssigned = true;
            await stationAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('All') + ' ' + t('stations') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await stationAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err:any) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
            console.error(err.stack);
          }
      }})
      const { onUnassignAllStations } = useUnassignAllStationsService({
        onSuccess: async(data) => {
          const response: any = {};
          Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
          if(response.success) {
            allStationsAssigned = false;
            await stationAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('All') + ' ' + t('stations') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await stationAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err: any) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
      });

      const clearSearchAssign = () => {
        setFilterAssign(['name']);
        setSearchAssignFormData({
          name: '',
          phone: '',
          email: '',
          stationCode: '',
        });
        setSearchAssignErrorMessages({});
        if(Object.keys(searchAssign).length) {
          setLoadingSearchAssign(true);
          setSearchAssign({});
        }
      }
      const handleChangeAssignFilter = (event: SelectChangeEvent<typeof filterAssign>) => {
        const { target: { value }, } = event;
        setFilterAssign(typeof value === 'string' ? value.split(',') : value,);
        setSearchAssignFormData(prevFormData => 
          Object.fromEntries(Object.keys(prevFormData).map(key => 
            [key, value.includes(key) ? prevFormData[key] : '']
          ))
        );
        if(!value.length && Object.keys(searchAssign).length) {
          clearSearchAssign();
        }
      };
      const handleChangeSearchAssign = (field: string, value: any) => {
        const val = value;
    
        setSearchAssignFormData((prevData) => ({
          ...prevData,
          [field]: val,
        }));
    
        if (filterAssign.includes(field) && !val) {
          setSearchAssignErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: 'This field is required.',
          }));
        } else {
          setSearchAssignErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: undefined,
          }));
        }
      };
      const validateSearchAssignFormData = (data: SearchAssignFormData) => {
        const errors: Partial<SearchAssignFormData> = {};
    
        filterAssign.forEach(fieldName => {
          if (!data[fieldName]?.trim()) { 
            errors[fieldName] = t('required');
          }
        });
    
        return errors;
      };
      const handleSubmitSearchAssign = () => {
        const errors = validateSearchAssignFormData(searchAssignFormData);
        setSearchAssignErrorMessages(errors);
    
        if (Object.keys(errors).length === 0) {
          setLoadingSearchAssign(true);
          const filteredSearch: Record<string, string> = {};
    
          Object.entries(searchAssignFormData).forEach(([key, value]) => {
              if (value) {
                filteredSearch[key] = typeof value === 'string' ? value.trim() : value;
              }
          });
          setSearchAssign(filteredSearch);
          setPageNo(1);
          setPageSize(pageSize);
        }
      };
      const adjustActions = (actions: any[]) => {
        return checkPrivileges('ROLE_STATIONGROUP_UPDATE') 
          ? (checkPrivileges('ROLE_STATIONGROUP_ARCHIVE') 
              ? actions 
              : actions.filter(action => action.label !== 'delete')) 
          : (checkPrivileges('ROLE_STATIONGROUP_ARCHIVE') 
              ? actions.filter(action => action.label !== 'edit') 
              : []);
      }

    return(
        <Box>
            <StationGroupComponent
                openModal={openModal}
                closeModal={openModal}
                handleSecField={handleSecField}
                secondaryLanguage={secondaryLanguage}
                primaryLanguage={primaryLanguage}
                handleSearchClick={handleSearchClick}
                handleInputChange={handleInputChange}
                handleDelete={handleDelete}
                cancelDelete={cancelDelete}
                handleChange={handleChange} 
                handleSubmit={handleSubmit}
                formData={formData}
                handleChangePage={handleChangePage}
                handleChangeRows={handleChangeRows}
                onChangeStatus={onChangeStatus}
                cancelChangeStatus={cancelChangeStatus}
                submitChangeStatus={submitChangeStatus}
                showRowView={showRowView}
                rowView={rowView}
                handleChangeTabs={handleChangeTabs}
                assignUnassignSingleEntity={assignUnassignSingleEntity}
                assignUnassignAllEntities={assignUnassignAllEntities}
                allStationsAssigned={allStationsAssigned}
                columns={checkPrivileges("ROLE_STATIONGROUP_ACTIVE") ? [
                    { id: 'title', label: 'title', numeric: false },
                    { id: 'active', label: 'status', numeric: false },
                    { id: 'actions', label: 'actions', numeric: false },
                ] : [
                  { id: 'title', label: 'title', numeric: false },
                  { id: 'actions', label: 'actions', numeric: false },
              ]}
                data={tableData?.pages[0] || {}}
                stationAssignData={stationAssignData?.pages[0] || {}}
                // stationData={stationData?.pages[0] || {}}
                {...{ openForm, openDeleteModal, enableEdit, pageNo,showSecField, pageSize, statusModal, statusText, search,isBtnEnabled,loading,loadingSearch, searchAssign, loadingSearchAssign, tab, filterAssign }}
                errorMessages={errorMessages}
                searchAssignFormData={searchAssignFormData}
                handleChangeAssignFilter={handleChangeAssignFilter}
                handleChangeSearchAssign={handleChangeSearchAssign}
                handleSubmitSearchAssign={handleSubmitSearchAssign}
                searchAssignErrorMessages={searchAssignErrorMessages}
                clearSearchAssign={clearSearchAssign}
                clearSearch={clearSearch}
                actions1 = {adjustActions([
                  { label: "edit", onClick: onEdit, icon: <EditIcon /> },
                  { label: "delete", onClick: onDelete, icon: <DeleteIcon /> },
                ])}
            />
        </Box>
    )
  }