import { Box, Grid, Typography } from "@mui/material";
import BackendPaginationTable from "common/Table/TableWithPagination";
import { ReactComponent as Enginemodel  } from "assets/SideBarIcons/engine-model.svg";
import ComponentHeaderTwo from "common/ComponentHeaderTwo/ComponentHeaderTwo";
import ModelEngineModalComponent from "./ModelEngineModal";
import FormDialog from "common/Modal/Modal";
import { useTranslation } from 'react-i18next';
import StyledInput from "common/SearchField/Search";
import { ReactComponent as Make } from "assets/SideBarIcons/make.svg";
import MakePopUpComponent from "./MakePopUp";
import ModelPopUpComponent from "./ModelPopUp";
import Loader from "layout/Loader";
import { checkPrivileges } from "Helpers/CheckPrivileges";

interface ModelEngineComponentProps {
  openModal: () => void;
  onOpenMakePopup: () => void;
  onOpenModelPopup: () => void;
  closeModal: () => void;
  submitMake: () => void;
  submitModel: () => void;
  makeErrorMessages: any;
  modelErrorMessages: any;
  makeformData: any;
  modelformData: any;
  handleChange: (field: string, value: any) => void;
  handleChangePage: (field: number) => void;
  handleChangeRows: (field: number) => void;
  handleChangeMake: (field: string, value: any) => void;
  handleChangeModel: (field: string, value: any) => void;
  handleSubmit: () => void;
  handleDelete:  () => void;
  cancelDelete:  () => void;
  search: string;
  handleSearchClick: () => void;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeStatus: (row: any) => void;
  cancelChangeStatus: () => void;
  submitChangeStatus: () => void;
  statusModal: boolean;
  statusText?: string;
  formData: any;
  openForm: boolean;
  columns: any[]; 
  data: any;
  makesData: any;
  modelsData: any; 
  errorMessages: any,
  actions1: { label: string; onClick: ( value: any) => void; icon?: React.ReactNode }[];
  openDeleteModal: boolean;
  enableEdit?:boolean;
  pageNo:number;
  pageSize:number;
  isBtnEnabled: boolean;
  loading: boolean;
  handleSecField: (field: string) => void;
  showModelEngineSecField: boolean;
  showModelEngineMakeSecField: boolean;
  showModelEngineModelSecField: boolean;
  primaryLanguage:string;
  secondaryLanguage:string;
  showRowView: (row: any) => void;
  openMakePopup: boolean;
  openModelPopup: boolean;
  loadingSearch: boolean;
  closeMakePopup: () => void;
  closeModelPopup: () => void;
  loadNext: (field: string, scroll: any) => void;
  loadPrev: (field: string, scroll: any) => void;
  handleDropdownSearch: (field: string, search: string) => void;
  loadingDropdownSearch: boolean;
  clearModel: boolean;
  clearSearch: () => void;
}

const ModelEngineComponent: React.FC <ModelEngineComponentProps> = ({
  openModal,
  closeModal,
  handleChange,
  handleSubmit,
  openModelPopup,
  formData,
  openForm,
  columns,
  data,
  makesData,
  modelsData,
  errorMessages,
  actions1,
  onOpenMakePopup,
  onOpenModelPopup,
  openDeleteModal,
  handleDelete,
  handleSecField,
  primaryLanguage,
  makeErrorMessages,
  modelErrorMessages,
  makeformData,
  modelformData,
  secondaryLanguage,
  cancelDelete,
  submitMake,
  submitModel,
  enableEdit,
  handleChangeMake,
  handleChangeModel,
  search,
  handleSearchClick,
  handleInputChange,
  handleChangePage,
  handleChangeRows,
  onChangeStatus,
  cancelChangeStatus,
  submitChangeStatus,
  pageNo,
  statusModal,

  pageSize,
  isBtnEnabled,
  loading,
  showRowView,
  openMakePopup,
  loadingSearch,
  closeMakePopup,
  closeModelPopup,
  loadNext,
  loadPrev,
  handleDropdownSearch,
  loadingDropdownSearch,
  showModelEngineSecField,
  showModelEngineMakeSecField,
  showModelEngineModelSecField,
  clearModel,
  clearSearch
}) => {
  const { t } = useTranslation();

    return(

        <Box>
          <ComponentHeaderTwo
            showBtn={true}
            showSaveBtn={openForm}
            onClick={openModal}
            onSubmit={handleSubmit}
            heading={(openForm && !enableEdit) ? "addModelEngines" : (enableEdit && openForm) ? "editModelEngines" : t('Model Engines')}
            icon={<Enginemodel height={30} width={30} />}
            btnText={openForm ? "back" : "create"}
            btnType={openForm ? "back" : "create"}
            isBtnEnabled={isBtnEnabled}
            showCreateBtn={checkPrivileges("ROLE_MODELENGINE_CREATE")}
            // btnType="create"
          />

          {openForm ? 
            <ModelEngineModalComponent
              enableEdit={enableEdit}
              openModal={openModal}
              closeModal={closeModal}
              handleChange={handleChange}
              handleSecField={handleSecField}
              onOpenMakePopup={onOpenMakePopup}
              onOpenModelPopup={onOpenModelPopup}
              showModelEngineSecField={showModelEngineSecField}
              secondaryLanguage={secondaryLanguage}
              primaryLanguage={primaryLanguage}
              handleSubmit={handleSubmit}
              formData={formData}
              makesData={makesData.data?.content || []}
              modelsData={modelsData.data?.content || []}
              openForm={openForm}
              errorMessages={errorMessages}
              isBtnEnabled={isBtnEnabled}
              loadNext={loadNext}
              loadPrev={loadPrev}
              handleDropdownSearch={handleDropdownSearch}
              {...{loadingDropdownSearch, clearModel}}
            />
           :  <Box>
                {loading ? <Loader /> :
                  <BackendPaginationTable
                    columns={actions1.length ? columns : columns.filter(action => action.id !== 'actions')}
                    data={data.data?.content || []}
                    pageNo={pageNo}
                    pageSize={pageSize}
                    tableData={data?.data || {}}
                    showCheckbox={false}
                    showActions={true}
                    enableSorting={true}
                    showHeader={true}
                    showSearch={true}
                    showFilter={true}
                    showCreateButton={true}
                    actions={actions1}
                    handleChangePage={handleChangePage}
                    handleChangeRows={handleChangeRows}
                    onChangeStatus={onChangeStatus}
                    rowClick={checkPrivileges("ROLE_MODELENGINE_UPDATE")}
                    showRowView={showRowView}
                    loadingSearch={loadingSearch}
                    headerComponent={
                    <Grid container spacing={2}>  
                      <Grid item xs={12} md={7} lg={5}>
                        <StyledInput
                          fullWidth
                          placeholder={t("title")}
                          handleSearchClick={handleSearchClick}
                          handleChange={handleInputChange}
                          value={search}
                          clearSearch={clearSearch}
                        />
                      </Grid>
                    </Grid>
                    }
                  />
                }
              </Box>
            }

      {openDeleteModal && 
            <FormDialog
                open={openDeleteModal}
                onClose={cancelDelete}
                title={t('delete') + ' ' + t('modelEngine')}
                // icon={<div><Home/></div>}
                content={  
                    <Grid container >
                  <Grid item  xs={12} sm={12} md={12} lg={12}  >
                  <Typography>
                    {t('Are you sure you want to delete?')}
                  </Typography>
                  </Grid>
                  </Grid>
                  }
                  saveFunction={handleDelete}
                submitButtonText="Delete"
              />}
            
            {statusModal && 
          <FormDialog
            open={statusModal}
            onClose={cancelChangeStatus}
            title="Change Status"
            // icon={<div><Home/></div>}
            content={  
              <Grid container >
                <Grid item  xs={12} sm={12} md={12} lg={12}  >
                  <Typography>
                  {t(`areYouSure`)}?
                  </Typography>
                </Grid>
              </Grid>
            }
            saveFunction={submitChangeStatus}
            submitButtonText="OK"
          />
        }

      {openMakePopup &&
        <FormDialog
          open={openMakePopup}
          onClose={closeMakePopup}
          title="addMake"
          icon={<Make height={28} width={28} />}
          content={
            <MakePopUpComponent
            handleSecField={handleSecField}
            showModelEngineMakeSecField={showModelEngineMakeSecField}
            secondaryLanguage={secondaryLanguage}
            primaryLanguage={primaryLanguage}
              openModal={onOpenMakePopup}
              closeModal={onOpenMakePopup}
              handleChange={handleChangeMake}
              handleSubmit={submitMake}
              formData={makeformData}
              openForm={openMakePopup}
              errorMessages={makeErrorMessages}
            />
          }
          saveFunction={submitMake}
          submitButtonText={t('save')}
          createPopup={true}
        />
      }

{openModelPopup &&
        <FormDialog
          open={openModelPopup}
          onClose={closeModelPopup}
          title="addModels"
          icon={<Make height={28} width={28} />}
          content={
            <ModelPopUpComponent
            enableEdit={enableEdit}
            handleSecField={handleSecField}
            showModelEngineModelSecField={showModelEngineModelSecField}
            makesData={makesData.data?.content || []}
            secondaryLanguage={secondaryLanguage}
            primaryLanguage={primaryLanguage}
              openModal={onOpenModelPopup}
              closeModal={onOpenModelPopup}
              handleChange={handleChangeModel}
              handleSubmit={submitModel}
              formData={modelformData}
              openForm={openModelPopup}
              errorMessages={modelErrorMessages}
              handleDropdownSearch={handleDropdownSearch}
              loadNext={loadNext}
              loadPrev={loadPrev}
              {...{loadingDropdownSearch}}
            />
          }
          saveFunction={submitModel}
          submitButtonText={t('save')}
          createPopup={true}
        />
      }

        </Box>

    )
}

export default ModelEngineComponent;