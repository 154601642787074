import { Box, Grid, Typography } from "@mui/material";
import BackendPaginationTable from "common/Table/TableWithPagination";
import { ReactComponent as Pricelist  } from "assets/SideBarIcons/price-tag.svg";
import ComponentHeaderTwo from "common/ComponentHeaderTwo/ComponentHeaderTwo";
import PriceListModalComponent from "./PriceListModal";
import FormDialog from "common/Modal/Modal";
import { useTranslation } from 'react-i18next';
import StyledInput from "common/SearchField/Search";
import Loader from "layout/Loader";
import VerticalTabs from "common/ComponentSidebar/ComponentSideBar";
import PriceListPackagesTable from "./PriceListPackagesTable";
import PriceListServicesTable from "./PriceListServicesTable";
import PriceListItemsTable from "./PriceListItemsTable";
import { checkPrivileges } from "Helpers/CheckPrivileges";

interface PriceListComponentProps {
  handleSecField: () => void;
  showSecField: boolean;
  primaryLanguage:string;
  secondaryLanguage:string;
  openModal: () => void;
  closeModal: () => void;
  handleChange: (field: string, value: any) => void;
  handleChangePage: (field: number) => void;
  handleChangeRows: (field: number) => void;
  handleSubmit: () => void;
  loadingSearch: boolean;
  handleDelete:  () => void;
  cancelDelete:  () => void;
  search: string;
  handleSearchClick: () => void;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeStatus: (row: any) => void;
  cancelChangeStatus: () => void;
  submitChangeStatus: () => void;
  formData: any;
  openForm: boolean;
  columns: any[]; 
  data: any;
  errorMessages: any,
  actions1: { label: string; onClick: ( value: any) => void; icon?: React.ReactNode }[];
  openDeleteModal: boolean;
  statusModal: boolean;
  statusText?: string;
  enableEdit?:boolean;
  pageNo:number;
  pageSize:number;
  isBtnEnabled: boolean;
  loading: boolean;
  showRowView: (row: any) => void;
  rowView: boolean;
  packageAssignData: any;
  packageFormData: any;
  handleChangePackage: (field: string, value: any) => void;
  packageErrorMessages: any;
  handleChangeTabs: (tabId: number) => void;
  assignUnassignSingleEntity: (isChecked: boolean, row: any) => void;
  serviceAssignData: any;
  serviceFormData: any;
  handleChangeService: (field: string, value: any) => void;
  serviceErrorMessages: any;
  itemAssignData: any;
  itemFormData: any;
  handleChangeItem: (field: string, value: any) => void;
  itemErrorMessages: any;
  searchAssign: string;
  loadingSearchAssign: boolean;
  handleBlurItem: (row: any) => void;
  handleBlurService: (row: any) => void;
  handleBlurPackage: (row: any) => void;
  clearSearch: () => void;
}

const PriceListComponent: React.FC <PriceListComponentProps> = ({
  showSecField,
  handleSecField,
  primaryLanguage,
  secondaryLanguage,
  openModal,
  closeModal,
  handleChange,
  handleSubmit,
  formData,
  openForm,
  columns,
  data,
  loadingSearch,
  errorMessages,
  actions1,
  openDeleteModal,
  statusModal,

  handleDelete,
  cancelDelete,
  enableEdit,
  handleSearchClick,
  handleInputChange,
  search,
  handleChangePage,
  handleChangeRows,
  onChangeStatus,
  cancelChangeStatus,
  submitChangeStatus,
  pageNo,
  pageSize,
  isBtnEnabled,
  loading,
  showRowView,
  rowView,
  packageAssignData,
  packageFormData,
  handleChangePackage,
  packageErrorMessages,
  handleChangeTabs,
  assignUnassignSingleEntity,
  serviceAssignData,
  serviceFormData,
  handleChangeService,
  serviceErrorMessages,
  itemAssignData,
  itemFormData,
  handleChangeItem,
  itemErrorMessages,
  searchAssign,
  loadingSearchAssign,
  handleBlurItem,
  handleBlurService,
  handleBlurPackage,
  clearSearch
}) => {
  const { t } = useTranslation();
  const packageColumns = [
    { id: 'title', label: 'title', numeric: false },
    { id: 'price', label: 'price', numeric: false },
  ]
  const serviceColumns = [
    { id: 'title', label: 'title', numeric: false },
    { id: 'price', label: 'price', numeric: false },
  ]
  const itemColumns = [
    { id: 'title', label: 'name', numeric: false },
    { id: 'price', label: 'price', numeric: false },
  ]
  const tabs = [
    { 
      id: 0, title: `edit`, 
        child: <PriceListModalComponent
          enableEdit={enableEdit}
          handleSecField={handleSecField}
          showSecField={showSecField}
          secondaryLanguage={secondaryLanguage}
          primaryLanguage={primaryLanguage}
          openModal={openModal}
          closeModal={closeModal}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          formData={formData}
          openForm={true}
          errorMessages={errorMessages}
          isBtnEnabled={isBtnEnabled}
        />
    },
    { id: 1, title: `packages`, 
      child: <PriceListPackagesTable 
        columns={packageColumns}
        data={packageAssignData.data?.content || []}
        pageNo={pageNo}
        pageSize={pageSize}
        tableData={packageAssignData?.data || {}}
        showActions={true}
        enableSorting={true}
        showHeader={true}
        showSearch={true}
        showFilter={true}
        showCreateButton={false}
        actions={[]}
        handleChangePage={handleChangePage}
        handleChangeRows={handleChangeRows}
        assignUnassignSingleEntity={assignUnassignSingleEntity}
        loadingSearch={loadingSearchAssign}
        priceListFormData={packageFormData}
        handleChangePriceList={handleChangePackage}
        priceListErrorMessages={packageErrorMessages}
        handleBlurPriceList={handleBlurPackage}
        assignPrivilege={checkPrivileges('ROLE_PACKAGE_PRICELIST_ASSIGN')}
        unassignPrivilege={checkPrivileges('ROLE_PACKAGE_PRICELIST_UNASSIGN')}
        headerComponent={(
          <Grid container spacing={2}>  
            <Grid item xs={12} md={7} lg={5}>
              <StyledInput
                fullWidth
                placeholder={t("title")}
                handleSearchClick={handleSearchClick}
                handleChange={handleInputChange}
                value={searchAssign}
                clearSearch={clearSearch}
              />
            </Grid>
          </Grid>
        )}
      />
    },
    { id: 2, title: `service`, 
      child: <PriceListServicesTable 
        columns={serviceColumns}
        data={serviceAssignData.data?.content || []}
        pageNo={pageNo}
        pageSize={pageSize}
        tableData={serviceAssignData?.data || {}}
        showActions={true}
        enableSorting={true}
        showHeader={true}
        showSearch={true}
        showFilter={true}
        showCreateButton={false}
        actions={[]}
        handleChangePage={handleChangePage}
        handleChangeRows={handleChangeRows}
        assignUnassignSingleEntity={assignUnassignSingleEntity}
        loadingSearch={loadingSearchAssign}
        priceListFormData={serviceFormData}
        handleChangePriceList={handleChangeService}
        priceListErrorMessages={serviceErrorMessages}
        handleBlurPriceList={handleBlurService}
        assignPrivilege={checkPrivileges('ROLE_SERVICE_PRICELIST_ASSIGN')}
        unassignPrivilege={checkPrivileges('ROLE_SERVICE_PRICELIST_UNASSIGN')}
        headerComponent={(
          <Grid container spacing={2}>  
            <Grid item xs={12} md={7} lg={5}>
              <StyledInput
                fullWidth
                placeholder={t("title")}
                handleSearchClick={handleSearchClick}
                handleChange={handleInputChange}
                value={searchAssign}
                clearSearch={clearSearch}
              />
            </Grid>
          </Grid>
        )}
      />
    },
    { id: 3, title: `items`, 
      child: <PriceListItemsTable 
        columns={itemColumns}
        data={itemAssignData.data?.content || []}
        pageNo={pageNo}
        pageSize={pageSize}
        tableData={itemAssignData?.data || {}}
        showActions={true}
        enableSorting={true}
        showHeader={true}
        showSearch={true}
        showFilter={true}
        showCreateButton={false}
        actions={[]}
        handleChangePage={handleChangePage}
        handleChangeRows={handleChangeRows}
        assignUnassignSingleEntity={assignUnassignSingleEntity}
        loadingSearch={loadingSearchAssign}
        priceListFormData={itemFormData}
        handleChangePriceList={handleChangeItem}
        priceListErrorMessages={itemErrorMessages}
        handleBlurPriceList={handleBlurItem}
        assignPrivilege={checkPrivileges('ROLE_ITEM_PRICELIST_ASSIGN')}
        unassignPrivilege={checkPrivileges('ROLE_ITEM_PRICELIST_UNASSIGN')}
        headerComponent={(
          <Grid container spacing={2}>  
            <Grid item xs={12} md={7} lg={5}>
              <StyledInput
                fullWidth
                placeholder={t("name")}
                handleSearchClick={handleSearchClick}
                handleChange={handleInputChange}
                value={searchAssign}
                clearSearch={clearSearch}
              />
            </Grid>
          </Grid>
        )}
      />
    }
  ];
  const disableTabs = tabs.map(tab => tab.id).filter(tabId => {
    switch (tabId) {
      case 0:
        return !checkPrivileges('ROLE_PRICELIST_UPDATE');
      case 1:
        return !checkPrivileges('ROLE_PACKAGE_PRICELIST_ASSIGN') && !checkPrivileges('ROLE_PACKAGE_PRICELIST_UNASSIGN');
      case 2:
        return !checkPrivileges('ROLE_SERVICE_PRICELIST_ASSIGN') && !checkPrivileges('ROLE_SERVICE_PRICELIST_UNASSIGN');
      case 3:
        return !checkPrivileges('ROLE_ITEM_PRICELIST_ASSIGN') && !checkPrivileges('ROLE_ITEM_PRICELIST_UNASSIGN');
      default:
        return false;
    }
  });

    return(

        <Box>
          <ComponentHeaderTwo
            showBtn={true}
            showSaveBtn={openForm}
            onClick={openModal}
            onSubmit={handleSubmit}
            heading={(openForm && !enableEdit) ? "addPriceLists" : (enableEdit && openForm || rowView) ? "editPriceLists" : t('priceLists')}
            icon={<Pricelist height={28} width={28} />}
            btnText={openForm || rowView ? "back" : "create"}
            btnType={openForm || rowView ? "back" : "create"}
            isBtnEnabled={isBtnEnabled}
            showCreateBtn={checkPrivileges("ROLE_PRICELIST_CREATE")}
            // btnType="create"
          />

          {openForm ? 
            <PriceListModalComponent
              enableEdit={enableEdit}
              handleSecField={handleSecField}
              showSecField={showSecField}
              secondaryLanguage={secondaryLanguage}
              primaryLanguage={primaryLanguage}
              openModal={openModal}
              closeModal={closeModal}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              formData={formData}
              openForm={openForm}
              errorMessages={errorMessages}
              isBtnEnabled={isBtnEnabled}
            />
            : rowView ?
              <VerticalTabs tabs={tabs} selectedIndex={0} handleChangeTabs={handleChangeTabs}  disableTabNo={disableTabs} />
            : <Box>
                {loading ? <Loader /> :
                  <BackendPaginationTable
                    columns={actions1.length ? columns : columns.filter(action => action.id !== 'actions')}
                    data={data.data?.content || []}
                    pageNo={pageNo}
                    pageSize={pageSize}
                    tableData={data?.data || {}}
                    showCheckbox={false}
                    showActions={true}
                    enableSorting={true}
                    showHeader={true}
                    showSearch={true}
                    showFilter={true}
                    showCreateButton={true}
                    actions={actions1}
                    handleChangePage={handleChangePage}
                    handleChangeRows={handleChangeRows}
                    onChangeStatus={onChangeStatus}
                    loadingSearch={loadingSearch}
                    rowClick={disableTabs.length === tabs.length ? false : true}
                    showRowView={showRowView}
                    headerComponent={
                    <Grid container spacing={2}>  
                      <Grid item xs={12} md={7} lg={5}>
                        <StyledInput
                          fullWidth
                          placeholder={t("title")}
                          handleSearchClick={handleSearchClick}
                          handleChange={handleInputChange}
                          value={search}
                          clearSearch={clearSearch}
                        />
                      </Grid>
                    </Grid>
                    }
                  />
                }
              </Box>
            }

      {openDeleteModal && 
            <FormDialog
                open={openDeleteModal}
                onClose={cancelDelete}
                title={t('delete') + ' ' + t('priceList')}
                // icon={<div><Home/></div>}
                content={  
                    <Grid container >
                  <Grid item  xs={12} sm={12} md={12} lg={12}  >
                  <Typography>
                    {t('Are you sure you want to delete?')}
                  </Typography>
                  </Grid>
                  </Grid>
                  }
                  saveFunction={handleDelete}
                submitButtonText="Delete"
              />}
        {statusModal && 
          <FormDialog
            open={statusModal}
            onClose={cancelChangeStatus}
            title="Change Status"
            // icon={<div><Home/></div>}
            content={  
              <Grid container >
                <Grid item  xs={12} sm={12} md={12} lg={12}  >
                  <Typography>
                  {t(`areYouSure`)}?
                  </Typography>
                </Grid>
              </Grid>
            }
            saveFunction={submitChangeStatus}
            submitButtonText="OK"
          />
        }


        </Box>

    )
}

export default PriceListComponent;